<template>
    <div class="app">
        <v-toolbar flat color="transparent">
            <v-toolbar-title class="dark">
                <h2></h2>
            </v-toolbar-title>
            <v-spacer></v-spacer>
        </v-toolbar>

        <v-card class="justify-center pt-3 pb-5" flat color="transparent">
            <div class="text-center mt-4">
                <img src="../assets/logo_cotago facturacion_web_sin_fondo.png" height="150">
            </div>
        </v-card>

        <v-card class="justify-center" flat color="transparent"> 
            <v-item-group mandatory>
                <v-container>
                    <v-row dense>
                        <v-hover v-slot="{ hover }" open-delay="10">
                            <v-card :elevation="hover ? 16 : 2" :class="{ 'on-hover': hover }"
                                class="mx-auto justify-center mr-5 rounded-lg mb-4" height="200" width="200" link :to="{ name: 'ListaVehiculo' }">
                                <v-card-text class="text-center ">
                                    <v-icon size="120" color="primary">mdi-car</v-icon>
                                    <h2 class="mt-4">Vehiculo</h2>
                                </v-card-text>
                            </v-card>
                        </v-hover>

                        <v-hover v-slot="{ hover }" open-delay="10">
                            <v-card :elevation="hover ? 16 : 2" :class="{ 'on-hover': hover }"
                                class="mx-auto justify-center ml-5 mr-5 rounded-lg mb-4" height="200" width="200" link :to="{ name: 'ListaRemolques' }">
                                <v-card-text class="text-center ">
                                    <v-icon size="120" color="primary">mdi-car-multiple</v-icon>
                                    <h2 class="mt-4">Remolques</h2>
                                </v-card-text>
                            </v-card>
                        </v-hover>

                        <v-hover v-slot="{ hover }" open-delay="10">
                            <v-card :elevation="hover ? 16 : 2" :class="{ 'on-hover': hover }"
                                class="mx-auto justify-center ml-5 mr-5 rounded-lg mb-4" height="200" width="200" link :to="{ name: 'ListaPermisoSCT' }">
                                <v-card-text class="text-center ">
                                    <v-icon size="120" color="primary">mdi-car-select</v-icon>
                                    <h2 class="mt-4">Permiso SCT</h2>
                                </v-card-text>
                            </v-card>
                        </v-hover>

                        <v-hover v-slot="{ hover }" open-delay="10">
                            <v-card :elevation="hover ? 16 : 2" :class="{ 'on-hover': hover }"
                                class="mx-auto justify-center  ml-5 mr-5 rounded-lg mb-4" height="200" width="200" link :to="{ name: 'ListaSeguro' }">
                                <v-card-text class="text-center">
                                    <v-icon size="120" color="primary">mdi-shield-car</v-icon>
                                    <h2 class="mt-4">Seguro</h2>
                                </v-card-text>
                            </v-card>
                        </v-hover>

                        <v-hover v-slot="{ hover }" open-delay="10">
                            <v-card :elevation="hover ? 16 : 2" :class="{ 'on-hover': hover }"
                                class="mx-auto justify-center  ml-5  rounded-lg mb-4" height="200" width="200" link :to="{ name: 'ListaMercancia' }">
                                <v-card-text class="text-center">
                                    <v-icon size="120" color="primary">mdi-package-variant-closed-plus</v-icon>
                                    <h2 class="mt-4">Mercancía</h2>
                                </v-card-text>
                            </v-card>
                        </v-hover>
                    </v-row>
                </v-container>
            </v-item-group>
        </v-card>
        <v-card class="justify-center" flat color="transparent">
            <v-item-group mandatory>
                <v-container>
                    <v-row dense>
                        <v-hover v-slot="{ hover }" open-delay="10">
                            <v-card :elevation="hover ? 16 : 2" :class="{ 'on-hover': hover }"
                                class="mx-auto justify-center  mr-5 rounded-lg mb-4" height="200" width="200" link :to="{ name: 'ListaUbicacionOrigen' }">
                                <v-card-text class="text-center ">
                                    <v-icon size="120" color="primary">mdi-map-marker-radius</v-icon>
                                    <h2 class="mt-4">Ubicación de Origen</h2>
                                </v-card-text>
                            </v-card>
                        </v-hover>

                        <v-hover v-slot="{ hover }" open-delay="10">
                            <v-card :elevation="hover ? 16 : 2" :class="{ 'on-hover': hover }"
                                class="mx-auto justify-center  ml-5 mr-5 rounded-lg mb-4" height="200" width="200" link :to="{ name: 'ListaUbicacionDestino' }">
                                <v-card-text class="text-center">
                                    <v-icon size="120" color="primary">mdi-map-marker-plus</v-icon>
                                    <h2 class="mt-4">Ubicación de Destino</h2>
                                </v-card-text>
                            </v-card>
                        </v-hover>

                        <v-hover v-slot="{ hover }" open-delay="10">
                            <v-card :elevation="hover ? 16 : 2" :class="{ 'on-hover': hover }"
                                class="mx-auto justify-center ml-5 mr-5 rounded-lg mb-4" height="200" width="200" link :to="{ name: 'ListaDirecciones' }">
                                <v-card-text class="text-center ">
                                    <v-icon size="120" color="primary">mdi-map-marker-multiple</v-icon>
                                    <h2 class="mt-4">Direcciones</h2>
                                </v-card-text>
                            </v-card>
                        </v-hover>

                        <v-hover v-slot="{ hover }" open-delay="10">
                            <v-card :elevation="hover ? 16 : 2" :class="{ 'on-hover': hover }"
                                class="mx-auto justify-center  ml-5 mr-5 rounded-lg mb-4" height="200" width="200" link
                                :to="{ name: 'ListaFigurasTransporte' }">
                                <v-card-text class="text-center">
                                    <v-icon size="120" color="primary">mdi-car-cog</v-icon>
                                    <h2 class="mt-4">Figuras de transporte</h2>
                                </v-card-text>
                            </v-card>
                        </v-hover>

                        <v-hover v-slot="{ hover }" open-delay="10">
                            <v-card :elevation="hover ? 16 : 2" :class="{ 'on-hover': hover }"
                                class="mx-auto justify-center ml-5 rounded-lg mb-4" height="200" width="200" link :to="{ name: 'ListaAutotransporte' }">
                                <v-card-text class="text-center ">
                                    <v-icon size="120" color="primary">mdi-truck-cargo-container</v-icon>
                                    <h2 class="mt-4">Autotransporte</h2>
                                </v-card-text>
                            </v-card>
                        </v-hover>

                    </v-row>
                </v-container> 
            </v-item-group>
            <v-main>
                <v-container fluid>
                    <v-fade-transition mode="out-in">
                        router-view></router-view>
                    </v-fade-transition>
                </v-container>
            </v-main>
        </v-card>
    </div>
</template>

<script>
    import axios from 'axios'
    export default {
        name: 'menu',
        components: {

        },

        data: () => ({

        }),
        watch: {

        },
        computed: {

        },
        mounted() {

        },

        methods: {
            averquehace() {
                console.log('Boton de acción')
            },
        }
    }
</script>
<style>
    .app {
        background: url('https://img.freepik.com/vector-gratis/fondo-blanco-minimo_1393-354.jpg?w=2000') no-repeat center center fixed !important;
        background-size: cover;
    }
</style>