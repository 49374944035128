var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app"},[_c('v-toolbar',{attrs:{"flat":"","color":"transparent"}},[_c('v-toolbar-title',{staticClass:"dark"},[_c('h2')]),_c('v-spacer')],1),_c('v-card',{staticClass:"justify-center pt-3 pb-5",attrs:{"flat":"","color":"transparent"}},[_c('div',{staticClass:"text-center mt-4"},[_c('img',{attrs:{"src":require("../assets/logo_cotago facturacion_web_sin_fondo.png"),"height":"150"}})])]),_c('v-card',{staticClass:"justify-center",attrs:{"flat":"","color":"transparent"}},[_c('v-item-group',{attrs:{"mandatory":""}},[_c('v-container',[_c('v-row',{attrs:{"dense":""}},[_c('v-hover',{attrs:{"open-delay":"10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto justify-center mr-5 rounded-lg mb-4",class:{ 'on-hover': hover },attrs:{"elevation":hover ? 16 : 2,"width":"200","height":"200","link":"","to":{ name: 'Comprobantes' }},on:{"click":function($event){_vm.$store.state.nombreComponente = 'Comprobantes'}}},[_c('v-card-text',{staticClass:"text-center"},[_c('v-icon',{attrs:{"size":"120","color":"primary"}},[_vm._v("mdi-file-document-multiple-outline")]),_c('h2',{staticClass:"mt-8"},[_vm._v("Comprobantes")])],1)],1)]}}])}),_c('v-hover',{attrs:{"open-delay":"10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto justify-center  ml-5 mr-5 rounded-lg mb-4",class:{ 'on-hover': hover },attrs:{"elevation":hover ? 16 : 2,"width":"200","height":"200","link":"","to":{ name: 'Catalogos' }},on:{"click":function($event){_vm.$store.state.nombreComponente = 'Cátalogos'}}},[_c('v-card-text',{staticClass:"text-center"},[_c('v-icon',{attrs:{"size":"120","color":"primary"}},[_vm._v("mdi-archive")]),_c('h2',{staticClass:"mt-8"},[_vm._v("Catálogos")])],1)],1)]}}])}),_c('v-hover',{attrs:{"open-delay":"10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto justify-center ml-5 mr-5 rounded-lg mb-4",class:{ 'on-hover': hover },attrs:{"elevation":hover ? 16 : 2,"width":"200","height":"200","link":"","to":{ name: 'Ventas' }},on:{"click":function($event){_vm.$store.state.nombreComponente = 'Ventas'}}},[_c('v-card-text',{staticClass:"text-center"},[_c('v-icon',{attrs:{"size":"120","color":"primary"}},[_vm._v("mdi-point-of-sale")]),_c('h2',{staticClass:"mt-8"},[_vm._v("Ventas")])],1)],1)]}}])}),_c('v-hover',{attrs:{"open-delay":"10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto justify-center  ml-5 rounded-lg mb-4",class:{ 'on-hover': hover },attrs:{"elevation":hover ? 16 : 2,"width":"200","height":"200","link":"","to":{ name: 'Configuracion' }},on:{"click":function($event){_vm.$store.state.nombreComponente = 'Configuración'}}},[_c('v-card-text',{staticClass:"text-center"},[_c('v-icon',{attrs:{"size":"120","color":"primary"}},[_vm._v("mdi-cog-outline")]),_c('h2',{staticClass:"mt-8"},[_vm._v("Configuración")])],1)],1)]}}])})],1)],1)],1)],1),_c('v-main',[_c('v-container',{attrs:{"fluid":""}},[_c('v-fade-transition',{attrs:{"mode":"out-in"}},[_c('router-view')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }