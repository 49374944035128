<template>
<div>
  <!--Dialog loading-->
  <v-dialog v-model="loading" hide-overlay persistent width="300" >
    <v-card color="primary" dark>
      <v-card-text>
        Cargando datos...
        <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>
  <!--diealgo para nuevo PERMISO-->
  <v-dialog v-model="dialogNuevoPermiso" persistent  max-width="900px">
            <PermisoSCT @VentanaPermiso="CierraVentanaNuevoPermiso()"></PermisoSCT>
        </v-dialog>
  <v-card>
    <v-card-title style="font: bold;" class="text-h4 text--primary">
    <!--Boton regresar-->
    <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on"  class="mx-2" icon large link :to="{ name: 'CatalogosCartaPorte' }">
                <v-icon size="40" color="primary">mdi-arrow-left-thick</v-icon>
                </v-btn>
            </template>
            <span>Regresar</span>
        </v-tooltip>
      Lista de Permisos SCT
      <v-spacer></v-spacer>
      <div class="text-center">
        <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" class="mx-2" fab  dark color="blue" @click="listarPermiso()">
                <v-icon dark>mdi-update</v-icon>
                </v-btn>
            </template>
            <span>Actualizar</span>
        </v-tooltip>
        <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" class="mx-2" fab  dark color="green" @click="nuevoPermiso()">
                <v-icon dark>mdi-plus</v-icon>
                </v-btn>
            </template>
            <span>Nuevo</span>
        </v-tooltip>
        </div>
    </v-card-title>
    <v-divider></v-divider>
    <v-data-table :headers="headers" :items="items">
      <!-- BOTONES DE ACCION  -->
      <template v-slot:[`item.actions`]="{ item }" >
        <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                    <v-icon dense class="mr-2" v-bind="attrs" v-on="on" color="blue" @click="editar(item)">mdi-lead-pencil</v-icon>
            </template>
            <span>Editar</span>
        </v-tooltip>
        <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-icon dense class="mr-2" v-bind="attrs" v-on="on" color="red" @click="deleteItem(item)">mdi-delete</v-icon>
            </template>
            <span>Eliminar</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </v-card>
</div>
</template>
<script>
import axios from 'axios'
import PermisoSCT from '../Comprobantes/CartaPorte/PermisoSCT.vue'

export default {
  components:{
    PermisoSCT
        },
data() {
    return {
      headers: [
            { text: 'Acciones', value: 'actions'},
            { text: "Número de permiso", value: "numPermisoSCT" },
            { text: "Permiso SCT", value: "permSCT.clave" },
            { text: "Descripcion", value: "permSCT.descripcion" },
      ],
      items: [],
      // Nuevo Permiso
      dialogNuevoPermiso: false,

      // loading
      loading: false,
    };
  },
  mounted() {
    
  },
  created(){
    this.listarPermiso();
  },
  computed: {
      datosEmpresa() {
        return this.$store.state.usuario;
      },
      permisoSCT(){
                return this.$store.state.permisoSCT;
            }
    },
  methods: {
    //VEHICULO
    nuevoPermiso(){
      let permisoSCT = {
        id: 0
      }
      if (this.$store.state.permisoSCT.id === -1) {
          this.$store.state.permisoSCT = Object.assign({}, permisoSCT)
        }
      this.dialogNuevoPermiso=true;
    },
    CierraVentanaNuevoPermiso() {
      this.dialogNuevoPermiso = false;
    },
    // listar permisos
    async listarPermiso() {
      this.loading = true;
      try {
        let response = await axios.get('CartaPorte/GetPermiso/'  + this.datosEmpresa.empresaBase);
        this.items = response.data; 
        console.log(this.items)
        this.loading = false;
        } catch (error) {
          console.log(error);
      }
    },

    editar(item) {
        let indice = this.items.indexOf(item)
        const permisoSCT = Object.assign({}, item)
        this.$store.state.permisoSCT = Object.assign({}, permisoSCT);
        console.log(this.$store.state.permisoSCT)
        this.dialogNuevoPermiso = true;
      },
      
    async deleteItem(item){
      let id = item.id
       try {
        let response = await axios.post('CartaPorte/DeletePermiso/' + this.datosEmpresa.empresaBase + '/' +id);
        this.listarPermiso();
        this.loading = false;
        } catch (error) {
          console.log(error);
      }
    }
  },
};
</script>