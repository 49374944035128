<template>
    <div>
        <v-card>
            <v-card-text>
                <!-- DATOS GENERALES -->
                <v-system-bar color="blue" dark> <strong>Complemento Carta Porte</strong></v-system-bar>
                <v-row>
                    <!-- VERSION -->
                    <v-col cols="3">
                        <v-text-field label="Versión" v-model="complementoCp.version" readonly
                            hint="Versión del complemento Carta Porte." persistent-hint></v-text-field>
                    </v-col>
                    <!-- TRASPORTE INTERNACIONAL -->
                    <v-col cols="3">
                        <v-select label="Transporte Internacional" :items="itemsTranspInternac"
                            v-model="complementoCp.transpInternac"
                            hint="En este campo se deberá registrar si el traslado de bienes y/o mercancías es de carácter internacional."
                            persistent-hint readonly></v-select>
                    </v-col>
                    <!-- TOTAL DE DISTANCIA RECORRIDA -->
                    <v-col cols="6">
                        <v-text-field label="Total distancia recorrida" suffix="km" v-model="complementoCp.totalDistRec"
                            type="number"
                            hint=" En este campo se deberá registrar la suma de las distancias recorridas en kilómetros, registradas en la sección Ubicación de destino"
                            persistent-hint></v-text-field>
                        <span hidden>{{formatoDosDecimales(complementoCp.totalDistRec)}}</span>
                    </v-col>
                </v-row>
                <!-- TRANSPORTE INTERNACIONAL -->
                <template v-if="complementoCp.transpInternac == 'SI'">
                    <v-system-bar> <strong>Transporte Internacional</strong></v-system-bar>
                    <v-row>
                        <!-- ENTRADA O SALIDA DE MERCANCIAS -->
                        <v-col cols="4">
                            <v-select label="Entrada o salida de mercancías" :items="itemsEntradaSalidaMerc"
                                v-model="complementoCp.entradaSalidaMerc"
                                hint="Este campo se deberá registrar cuando los bienes y/o mercancías ingresen o salgan del territorio nacional."
                                persistent-hint></v-select>
                        </v-col>
                        <!-- PAIS DE ORIGEN O DESTINO -->
                        <v-col cols="4">
                            <v-select label="País de origen o destino" :items="itemsPaisOrigenDestino"
                                v-model="complementoCp.paisOrigenDestino"
                                hint="En este campo se deberá registrar la clave del país de origen o destino de los bienes y/o mercancías transportadas."
                                persistent-hint></v-select>
                        </v-col>
                        <!-- VÍA DE ENTRADA O SALIDA -->
                        <v-col cols="4">
                            <v-select label="Vía de entrada o salida" :items="itemsViaEntradaSalida"
                                v-model="complementoCp.viaEntradaSalida"
                                hint=": En este campo se deberá registrar la clave del transporte a través del cual las mercancías ingresano salen del territorio nacional."
                                persistent-hint></v-select>
                        </v-col>
                    </v-row>
                </template>

                <!-- ORIGEN -->
                <v-system-bar color="blue" dark> <strong>Ubicación de Origen</strong></v-system-bar>
                <v-select label="Origen" 
                    :items="itemsOrigen" v-model="complementoCp.ubicacionOrigen" item-text="ubicacion"
                    return-object hint="En este campo, se deberá registrar el punto de salida." persistent-hint>
                </v-select>
                <!-- boton crear nuevo origen -->
                <!-- append-outer-icon="mdi-map-marker-plus" @click:append-outer="nuevoOrigen()" -->
                <v-row>
                    <!-- FECHA Y HORA DE SALIDA -->
                    <v-col cols="6">
                        <v-datetime-picker label="Fecha de salda" v-model="dateSalida">
                            <template slot="dateIcon">
                                <v-icon>mdi-calendar</v-icon>
                            </template>
                            <template slot="timeIcon">
                                <v-icon>mdi-clock-time-six</v-icon>
                            </template>
                            <template slot="actions" slot-scope="{ parent }">
                                <v-btn color="primary" @click="parent.okHandler">Ok</v-btn>
                            </template>
                        </v-datetime-picker>
                    </v-col>
                </v-row>
                <!-- DESTINO -->
                <v-system-bar color="blue" dark> <strong>Ubicación de Destino</strong></v-system-bar>
                <v-data-table :headers="headersDestino" :items="complementoCp.ubicacionesDestino" item-key="item">
                    <template v-slot:top>
                        <v-toolbar flat>
                            <v-spacer></v-spacer>
                            <v-dialog v-model="dialogDestino" max-width="500px" persistent>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn fab color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                                        <v-icon>mdi-map-marker-plus</v-icon>
                                    </v-btn>
                                </template>
                                <v-card>
                                    <v-toolbar color="blue" dark>
                                        <v-btn icon @click="dialogDestino=false">
                                            <v-icon large>mdi-close-circle</v-icon>
                                        </v-btn>
                                        <v-spacer></v-spacer>
                                        <h3>Destino</h3>
                                        <v-spacer></v-spacer>
                                        <v-btn icon @click="pushDestino()">
                                            <v-icon large>mdi-check-circle</v-icon>
                                        </v-btn>
                                    </v-toolbar>
                                    <v-card-text>
                                        <v-select label="Destino" append-outer-icon="mdi-map-marker-plus"
                                            @click:append-outer="nuevoDestino()" :items="itemsDestino"
                                            v-model="selectDestino" item-text="ubicacion"
                                            return-object></v-select>
                                        <!-- <span>{{selectDestino}}</span> -->
                                        <!-- TOTAL DE LA DISTANCIA RECORRIDA -->
                                        <v-text-field label="Distancia Recorrida"
                                            v-model="selectDestino.distanciaRecorrida" suffix="km"></v-text-field>
                                        <span hidden>{{formatoDosDecimales(selectDestino.distanciaRecorrida)}}</span>

                                        <v-col cols="12">
                                            <v-datetime-picker label="Fecha de salda" v-model="selectDestino.dateDestino">
                                                <template slot="dateIcon">
                                                    <v-icon>mdi-calendar</v-icon>
                                                </template>
                                                <template slot="timeIcon">
                                                    <v-icon>mdi-clock-time-six</v-icon>
                                                </template>
                                                <template slot="actions" slot-scope="{ parent }">
                                                    <v-btn color="primary" @click="parent.okHandler">Ok</v-btn>
                                                </template>
                                            </v-datetime-picker>
                                        </v-col>
                                    </v-card-text>
                                </v-card>
                            </v-dialog>
                        </v-toolbar>
                    </template>
                    <!-- ICONOS PARA EDITAR Y BORRAR -->
                    <template v-slot:item.actions="{ item }">
                        <v-icon color="#fbb03b" @click="editDestino(item)" ref="Receptors">mdi-pencil</v-icon>
                        <v-icon color="#e74747" @click="deleteDestino(item)">mdi-delete</v-icon>
                    </template>
                    <!-- DAMOS EL FROMATO A LA DISTANCIA RECORRIDA -->
                    <template v-slot:item.distanciaRecorrida="{ item }">
                        <span >{{ formatoTresDecimales(item.distanciaRecorrida) }}</span>
                    </template>
                </v-data-table>

                <!-- MERCANCIAS -->
                <v-system-bar color="blue" dark><strong>Mercancias</strong></v-system-bar>
                <v-row>
                    <v-col cols="4">
                        <v-text-field label="Peso bruto total" type="number"
                            hint=" En este campo se deberá registrar la suma del peso bruto de los bienes y/o mercancías que se trasladan."
                            persistent-hint v-model="complementoCp.mercancia.pesoBrutoTotal"></v-text-field>
                    </v-col>
                    <v-col cols="4">
                        <v-autocomplete label="Unidad de peso"
                            hint=" En este campo se deberá registrar la clave de la unidad de peso que corresponda al valor registrado en el campo “PesoBrutoTotal”."
                            persistent-hint :items="itemsClaveUnidadDePeso" :filter="filterClaveUnidadDePeso"
                            item-text="claveUnidadPeso" return-object v-model="complementoCp.mercancia.unidadPeso">
                        </v-autocomplete>
                        <span hidden>{{complementoCp.mercancia.unidadPeso}}</span>
                    </v-col>
                    <v-col cols="4">
                        <v-text-field label="Número total de mercancias" type="number"
                            v-model="complementoCp.mercancia.numTotalMercancias"
                            hint="En este campo se deberá registrar el número total de los bienes y/o mercancías que se trasladan, registrados en la sección “Mercancias a transportar”."
                            persistent-hint></v-text-field>
                    </v-col>
                </v-row>

                <!-- MERCANCIAS MERCANCIA -->
                <v-system-bar color="blue" dark><strong>Mercancias a transportar</strong></v-system-bar>
                <v-data-table :items="complementoCp.mercancia.mercancias" :headers="headersMercancias" item-key="item">
                    <template v-slot:top>
                        <v-toolbar flat>
                            <v-spacer></v-spacer>
                            <v-dialog v-model="dialogMercancias" max-width="800px" persistent>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn fab color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                                        <v-icon>mdi-package-variant</v-icon>
                                    </v-btn>
                                </template>
                                <v-card>
                                    <v-toolbar color="primary" dark>
                                        <v-btn icon @click="dialogMercancias=false">
                                            <v-icon large>mdi-close-circle</v-icon>
                                        </v-btn>
                                        <v-spacer></v-spacer>
                                        <h3>Mercancia</h3>
                                        <v-spacer></v-spacer>
                                        <v-btn icon>
                                            <v-icon large @click="pushMercancia()">mdi-check-circle</v-icon>
                                        </v-btn>
                                    </v-toolbar>
                                    <v-card-text>
                                        <!-- BUSCA LA MERCANCIA -->
                                        <v-autocomplete label="Mercancia" v-model="selectMercancia"
                                            :items="itemsMercancias" :loading="loadingMercancias"
                                            :search-input.sync="searchMercanciasMercancia" item-text="mercancia"
                                            placeholder="Buscar por clave o descripción" return-object
                                            hint="En este campo se deberá registrar el bienes y/o mercancías que se trasladan vía Autotransporte, dado de alta previamente, o que se de de alta en el boton de la derecha"
                                            persistent-hint append-outer-icon="mdi-tag-plus"
                                            @click:append-outer="nuevaMercancia()"></v-autocomplete>
                                        <!-- <span>{{selectMercancia}}</span> -->

                                        <v-text-field label="Cantidad" type="number" v-model="selectMercancia.cantidad"
                                            hint="En este campo se deberá registrar la cantidad de bienes y/o mercancías que se trasladan"
                                            persistent-hint></v-text-field>

                                        <v-text-field label="Dimensiones"
                                            hint="En este campo se podrán registrar las medidas del empaque de los bienes y/o mercancías que se trasladan. Se debe registrar la longitud, la altura y la anchura en centímetros o en pulgadas separando dichos valores por una diagonal. Ejemplo:largo/alto/ancho(plg/cm)"
                                            persistent-hint v-model="selectMercancia.dimensiones"></v-text-field>

                                        <v-text-field label="Peso en kilogramos" type="number"
                                            v-model="selectMercancia.pesoEnKg"
                                            hint=" En este campo se deberá registrar en kilogramos el peso estimado de los bienes y/o mercancías que se trasladan."
                                            persistent-hint></v-text-field>

                                        <v-text-field label="Valor de la mercancía" type="number"
                                            v-model="selectMercancia.valorMercancia"
                                            hint="En este campo se podrá registrar el valor estimado de los bienes y/o mercancías que se trasladan."
                                            persistent-hint></v-text-field>
                                    </v-card-text>
                                </v-card>
                            </v-dialog>
                        </v-toolbar>
                    </template>
                    <!-- ICONOS PARA EDITAR Y BORRAR -->
                    <template v-slot:item.actions="{ item }">
                        <v-icon color="#fbb03b" @click="editMercancia(item)" ref="Receptors">mdi-pencil</v-icon>
                        <v-icon color="#e74747" @click="deleteMercancia(item)">mdi-delete</v-icon>
                    </template>
                    <!-- FORMATO DECIMAL -->
                    <template v-slot:[`item.cantidad`]="{ item }">
                        <span>{{ formatoTresDecimales(item.cantidad) }}</span>
                    </template>
                    <template v-slot:[`item.pesoEnKg`]="{ item }">
                        <span>{{ formatoTresDecimales(item.pesoEnKg) }}</span>
                    </template>
                    <template v-slot:[`item.valorMercancia`]="{ item }">
                        <span>{{ formatoDosDecimales(item.valorMercancia) }}</span>
                    </template>
                </v-data-table>

                <!-- AUTO TRANSPORTE -->
                <v-system-bar color="blue" dark> <strong>Autotransporte</strong></v-system-bar>
                <v-row>
                    <v-col cosl="12">
                        <v-select label="Vehiculo" :items="itemsAutotransporte" v-model="complementoCp.autotransporte"
                            return-object item-text="autotransporte"></v-select>
                        <!-- <span>{{complementoCp.autotransporte}}</span> -->
                    </v-col>
                </v-row>
                <!-- boton para crear nuevo autotransporte -->
                <!-- append-outer-icon="mdi-car-select" @click:append-outer="nuevoOrigen()" -->
                <!-- Figura del Transporte -->
                <v-system-bar color="blue" dark> <strong>Figura del Transporte</strong></v-system-bar>
                <v-data-table :headers="headersTipoFiguraTransporte"
                    :items="complementoCp.figuraDelTransporte.tiposFigura">
                    <template v-slot:top>
                        <v-toolbar flat>
                            <v-spacer></v-spacer>
                            <v-dialog v-model="dialogFigurasTransporte" max-width="800px" persistent>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn fab color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                                        <v-icon>mdi-account-multiple-plus</v-icon>
                                    </v-btn>
                                </template>
                                <v-card>
                                    <v-toolbar color="blue" dark>
                                        <v-btn icon @click="dialogFigurasTransporte=false">
                                            <v-icon large>mdi-close-circle</v-icon>
                                        </v-btn>
                                        <v-spacer></v-spacer>
                                        <h3>Figuras de transporte</h3>
                                        <v-spacer></v-spacer>
                                        <v-btn icon @click="pushDestino()">
                                            <v-icon large>mdi-check-circle</v-icon>
                                        </v-btn>
                                    </v-toolbar>
                                    <v-card-text>
                                        <v-select label="Tipo de figura" :items="itemsClaveTipoFiguraTransporte"
                                            return-object item-text="descripcion" v-model="selectTipoFigura"></v-select>
                                        <v-data-table :headers="headersTipoFiguraTransporte"
                                            :items="itemsTipoFiguraTransporte">
                                            <!-- ICONOS PARA AGREGAR -->
                                            <template v-slot:item.actions="{ item }">
                                                <v-icon color="#009245" @click="agregaFiguraTransporte(item)"
                                                    ref="Receptors">mdi-plus</v-icon>
                                            </template>
                                        </v-data-table>
                                    </v-card-text>
                                </v-card>
                            </v-dialog>
                        </v-toolbar>
                    </template>
                    <!-- ICONOS PARA EDITAR Y BORRAR -->
                    <template v-slot:item.actions="{ item }">
                        <v-icon color="#e74747" @click="deleteFigura(item)">mdi-delete</v-icon>
                    </template>
                    <!-- DAMOS EL FROMATO A LA DISTANCIA RECORRIDA -->
                    <template v-slot:item.distanciaRecorrida="{ item }">
                        <span>{{ formatoTresDecimales(item.distanciaRecorrida) }}</span>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
    </div>
</template>
<script>
    import axios from 'axios'
    import moment from 'moment';
    import { format, parseISO } from 'date-fns';
    import print from 'print-js';
    import pdf from 'vue-pdf';
    import { VueEditor } from "vue2-editor";
    import Vue from 'vue'
    import VueCookies from 'vue-cookies'

    import DatetimePicker from 'vuetify-datetime-picker'


    Vue.use(DatetimePicker)

    export default {
        name: 'IngresoCpComplemento',
        components: {

        },

        data: () => ({
            itemsTranspInternac: ['SI', 'NO'],
            itemsEntradaSalidaMerc: [],
            itemsPaisOrigenDestino: [],
            itemsViaEntradaSalida: [],

            //ORIGEN
            itemsOrigen: [],
            // menuDateSalida:false,
            // menuTimeSalida:false,
            // dateSalida: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
            // timeSalida:format(parseISO(new Date().toISOString()), 'HH:mm'),
            dateSalida: new Date(),

            //DESTINOS
            dialogDestino: false,
            dateDestino: new Date(),
            // menuDateDestino: false,
            // menuTimeDestino: false,
            // dateDestino: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
            // timeDestino: format(parseISO(new Date().toISOString()), 'HH:mm'),
            itemsDestino: [],
            selectDestino: {},
            headersDestino: [
                { text: 'Acciones', sortable: false, value: 'actions' },
                { text: 'item', sortable: false, value: 'item' },
                { text: 'ID Ubicacion', sortable: false, value: 'iDUbicacion' },
                { text: 'Destinatario', sortable: false, value: 'nombreRemitenteDestinatario' },
                { text: 'Fecha de llegada', sortable: false, value: 'fecha' },
                { text: 'Distancia recorrida', sortable: false, value: 'distanciaRecorrida', align: 'right' },
            ],
            indexDestino: -1,

            //MERCANCIAS
            itemsClaveUnidadDePeso: [],
            itemsMercancias: [],
            headersMercancias: [
                { text: 'Acciones', sortable: false, value: 'actions' },
                { text: 'item', sortable: false, value: 'item' },
                { text: 'Mercancia', sortable: false, value: 'mercancia' },
                { text: 'Cantidad', sortable: false, value: 'cantidad', align: 'right' },
                { text: 'Unidad', sortable: false, value: 'unidad' },
                { text: 'Peso en KG', sortable: false, value: 'pesoEnKg', align: 'right' },
                { text: 'Valor en mercancia', sortable: false, value: 'valorMercancia', align: 'right' },
                { text: 'Moneda', sortable: false, value: 'moneda.clave' },
            ],
            selectMercancia: {},
            dialogMercancias: false,
            loadingMercancias: false,
            limitMercancias: 60,
            editIndexMercancia: -1,
            searchMercanciasMercancia: null,
            indexMercancia: -1,

            //AUTOTRANSPORTE
            itemsAutotransporte: [],

            //FIGURAS DE TRANSPORTE
            dialogFigurasTransporte: false,
            itemsClaveTipoFiguraTransporte: [
                { clave: '01', descripcion: 'Operador', figuraTransporte: '01 | Operador' },
                { clave: '02', descripcion: 'Propietario', figuraTransporte: '02 | Propietario' },
                { clave: '03', descripcion: 'Arrendador', figuraTransporte: '03 | Arrendador' },
                { clave: '04', descripcion: 'Notificado', figuraTransporte: '04 | Notificado' },
            ],
            headersTipoFiguraTransporte: [
                { text: 'Acciones', sortable: false, value: 'actions' },
                { text: 'RFC', sortable: false, value: 'rfcFigura' },
                { text: 'Figura', sortable: false, value: 'tiposFigura' },
            ],
            // itemsTipoFiguraTransporte: [],
            itemsTipoFiguraTransporteB: [],
            selectTipoFigura: {},
        }),

        watch: {
            async searchMercanciasMercancia(val) {
                if (this.itemsmercancias.length > 0) return
                if (this.loadingMercancias) return
                this.loadingMercancias = true
                try {
                    let response = await axios.get('CartaPorte/GetMercancias/' + this.datosEmpresa.empresaBase)
                    this.itemsMercancias = response.data;
                } catch (error) {
                    console.log(error)
                }
                this.loadingMercancias = false
            },
        },

        computed: {
            computedDateSalida() {
                return this.complementoCp.ubicacionOrigen.fecha ? moment(this.complementoCp.ubicacionOrigen.fecha).format('DD/MM/yyyy') : ''
            },
            computedDateDestino() {
                return this.dateDestino ? moment(this.dateDestino).format('DD/MM/yyyy') : ''
            },
            itemsmercancias() {
                return this.itemsMercancias.map(entry => {
                    const Description = entry.descripcion.length > this.limitMercancias
                        ? entry.descripcion.slice(0, this.limitMercancias) + '...'
                        : entry.descripcion

                    return Object.assign({}, entry, { Description })
                })
            },
            complementoCp() {
                return this.$store.state.ingresoCPStore.cartaPorte;
            },
            itemsTipoFiguraTransporte() {
                return this.itemsTipoFiguraTransporteB.filter(tipo => tipo.tipoFigura.clave === this.selectTipoFigura.clave);
            },
            datosEmpresa() {
                return this.$store.state.usuario;
            },
        },

        created() {
            this.getOrigen();
            this.getDestino();
            this.getMercancias();
            this.getAutotransporte();
            this.getFiguras();
            this.getUnidadPeso();
        },

        methods: {
            //FORMATO NUMERICO
            formatoDosDecimales(value) {
                let val = (value / 1).toFixed(2)
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            },
            formatoTresDecimales(value) {
                let val = (value / 1).toFixed(3)
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            },

            //CATALOGOS
            async getOrigen() {
                try {
                    let response = await axios.get('CartaPorte/GetUbicaciones/' + this.datosEmpresa.empresaBase + '/OR')
                    this.itemsOrigen = response.data;
                } catch (error) {

                }
            },
            async getDestino() {
                try {
                    let response = await axios.get('CartaPorte/GetUbicaciones/' + this.datosEmpresa.empresaBase + '/DE')
                    this.itemsDestino = response.data;
                } catch (error) {

                }
            },
            async getMercancias() {
                try {
                    let response = await axios.get('CartaPorte/GetMercancias/' + this.datosEmpresa.empresaBase)
                    this.itemsMercancias = response.data;
                } catch (error) {

                }
            },
            async getAutotransporte() {
                try {
                    let response = await axios.get('CartaPorte/GetAutoTransporte/' + this.datosEmpresa.empresaBase)
                    this.itemsAutotransporte = response.data;
                    console.log('autotransporte',this.itemsAutotransporte)
                    for(let a of this.itemsAutotransporte){
                        a.autotransporte = a.id + ' | ' + a.identificacionVehicular.placaVM
                        for(let s of a.seguros){
                            if(s.tipo === 'RC'){
                              s.seguro = s.aseguraRespCivil + ' | ' +s.polizaRespCivil 
                              a.aseguraRespCivil = Object.assign({}, s)
                            }
                            if(s.tipo === 'MA'){
                              s.seguro = s.aseguraMedAmbiente + ' | ' +s.polizaMedAmbiente 
                              a.aseguraMedAmbiente = Object.assign({}, s)
                            }
                            if(s.tipo === 'CA'){
                              s.seguro = s.aseguraCarga + ' | ' +s.polizaCarga 
                              a.aseguraCarga = Object.assign({}, s)
                            }
                            if (a.remolques.length == 2){
                              a.remolqueA =a.remolques[0]
                              a.remolqueB =a.remolques[1]
                            }
                            if (a.remolques.length == 1){
                              a.remolqueA =a.remolques[0]
                            }
                          }
                        }
                } catch (error) {
                    console.log(error)
                }
            },
            async getFiguras() {
                try {
                    let response = await axios.get('CartaPorte/GetFiguras/' + this.datosEmpresa.empresaBase)
                    // this.itemsAutotransporte = response.data;
                    this.itemsTipoFiguraTransporteB = response.data
                    console.log(response.data)
                } catch (error) {
                    console.log(error)
                }
            },
            async getUnidadPeso() {
                try {
                    let response = await axios.get('CatalogosCartaPorte/GetClaveUnidadPeso/')
                    // this.itemsAutotransporte = response.data;
                    this.itemsClaveUnidadDePeso = response.data
                    console.log(response.data)
                } catch (error) {
                    console.log(error)
                }
            },

            //VALIDA OBJETOS VACIOS
            validaObjeto(obj) {
                return Object.keys(obj).length === 0;
            },

            // FUNCIONES DESTINO
            pushDestino() {
                console.log(this.selectDestino)
                const distancia = Number(this.selectDestino.distanciaRecorrida);
                if (this.validaObjeto(this.selectDestino)) {
                    console.log('Indique un destino')
                    return
                }
                if (distancia <= 0) {
                    console.log('Distancia mayor a 0')
                    return
                }

                const destino = Object.assign({}, this.selectDestino)
                if (this.indexDestino > -1) {
                    Object.assign(this.complementoCp.ubicacionesDestino[this.indexDestino], destino)
                } else {
                    this.complementoCp.ubicacionesDestino.push(destino)
                }

                this.selectDestino = Object.assign({})
                console.log(this.complementoCp.ubicacionesDestino)

                //ASIGNAMOS EL NUMERO DE ITEM
                let cont = 1
                let distanciaR = 0
                for (let i of this.complementoCp.ubicacionesDestino) {
                    i.item = cont;
                    i.fecha = format(parseISO(i.dateDestino.toISOString()), 'yyyy-MM-dd HH:mm'), 
                    // i.fecha = format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'), i.dateDestino  
                    distanciaR = distanciaR + Number(i.distanciaRecorrida)
                    cont++;
                }
                this.complementoCp.totalDistRec = distanciaR
                this.indexDestino = -1
            },
            editDestino(item) {
                this.indexDestino = this.complementoCp.ubicacionesDestino.indexOf(item)
                this.selectDestino = Object.assign({}, item)
                this.dialogDestino = true
            },
            deleteDestino(item) {
                const index = this.complementoCp.ubicacionesDestino.indexOf(item)
                this.complementoCp.ubicacionesDestino.splice(index, 1)
                //ORDENAMOS LOS DETINOS
                let cont = 1
                let distanciaR = 0
                for (let i of this.complementoCp.ubicacionesDestino) {
                    i.item = cont;
                    i.fecha = i.fecha 
                    // + ' ' + i.hora
                    distanciaR = distanciaR + Number(i.distanciaRecorrida)
                    cont++;
                }
                this.complementoCp.totalDistRec = distanciaR
            },


            //CLAVE DE UNIDAD DE PESO
            filterClaveUnidadDePeso(item, queryText, itemText) {
                const textOne = item.clave.toLowerCase()
                const textTwo = item.nombre.toLowerCase()
                const searchText = queryText.toLowerCase()

                return textOne.indexOf(searchText) > -1 ||
                    textTwo.indexOf(searchText) > -1
            },

            //MERCANCIAS
            pushMercancia() {
                //VALIDAMOS
                const cantidad = Number(this.selectMercancia.cantidad);
                const pesoEnKg = Number(this.selectMercancia.pesoEnKg);
                const valorMercancia = Number(this.selectMercancia.valorMercancia);

                if (this.validaObjeto(this.selectMercancia)) {
                    console.log('Indique una mercancia')
                    return
                }

                if (cantidad <= 0) {
                    console.log('Cantidad mayor a 0')
                    return
                }

                if (pesoEnKg <= 0) {
                    console.log('Peso en KG mayor a 0')
                    return
                }

                if (valorMercancia <= 0) {
                    console.log('Valor de la mercancia mayor a 0')
                    return
                }

                //AGREGAMOS O ACTUALIZAMOS
                const mercancia = Object.assign({}, this.selectMercancia)
                if (this.indexMercancia > -1) {
                    Object.assign(this.complementoCp.mercancia.mercancias[this.indexMercancia], mercancia)
                } else {
                    this.complementoCp.mercancia.mercancias.push(mercancia)
                }

                //INICIALIZAMOS EL OBJETO
                this.selectMercancia = Object.assign({})

                //ASIGNAMOS EL NUMERO DE ITEM
                let cont = 1
                let distanciaR = 0
                for (let i of this.complementoCp.mercancia.mercancias) {
                    i.item = cont;
                    cont++;
                }
                this.indexMercancia = -1
                this.complementoCp.mercancia.numTotalMercancias = cont - 1
            },
            editMercancia(item) {
                this.indexMercancia = this.complementoCp.mercancia.mercancias.indexOf(item)
                this.selectMercancia = Object.assign({}, item)
                this.dialogMercancias = true
            },
            deleteMercancia(item) {
                const index = this.complementoCp.mercancia.mercancias.indexOf(item)
                this.complementoCp.mercancia.mercancias.splice(index, 1)
                //ORDENAMOS LOS DETINOS
                let cont = 1
                for (let i of this.complementoCp.mercancia.mercancias) {
                    i.item = cont;
                    cont++;
                }
                this.complementoCp.mercancia.numTotalMercancias = cont - 1
            },

            //FIGURAS DE TRANSPORTE
            agregaFiguraTransporte(item) {
                this.complementoCp.figuraDelTransporte.tiposFigura.push(item)

                //METODO PARA EVITAR QUE SE AGREGUEN DUPLICADOS
                var arrTwo = this.complementoCp.figuraDelTransporte.tiposFigura
                const filteredArray = arrTwo.filter(function (ele, pos) {
                    return arrTwo.indexOf(ele) == pos;
                })
                this.complementoCp.figuraDelTransporte.tiposFigura = filteredArray
            },
            deleteFigura(item) {
                let inidce = this.complementoCp.figuraDelTransporte.tiposFigura.indexOf(item)
                this.complementoCp.figuraDelTransporte.tiposFigura.splice(inidce, 1)
            }

        },
    }
</script>