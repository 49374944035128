<template>
<div>
  <!--Dialog loading-->
  <v-dialog v-model="loading" hide-overlay persistent width="300">
    <v-card color="primary" dark>
      <v-card-text>
        Cargando datos...
        <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  <v-card>
    <v-card-title style="font: bold;" class="text-h4 text--primary">
    <!--Boton regresar-->
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on"  class="mx-2" icon large link :to="{ name: 'Comprobantes' }">
          <v-icon size="40" color="primary">mdi-arrow-left-thick</v-icon>
        </v-btn>
      </template>
      <span>Regresar</span>
    </v-tooltip>
    Comercios Exteriores
    <v-spacer></v-spacer> 
    <!--Fecha Inicial-->
    <v-col cols="12" sm="3" md="3">
      <v-menu v-model="calendario" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field v-model="fechaI" label="Fecha Inicial" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
        </template>
        <v-date-picker v-model="fechaI" @input="calendario = false"></v-date-picker>
      </v-menu>
    </v-col>
    <!--Fecha Final-->
    <v-col cols="12" sm="3" md="3">
      <v-menu v-model="calendario2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field v-model="fechaF" label="Fecha Final" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
        </template>
        <v-date-picker v-model="fechaF" @input="calendario2 = false"></v-date-picker>
      </v-menu>
    </v-col>
      <div class="text-center">
        <!--Boton para  buscar-->
        <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" class="mx-2" fab  dark color="blue" @click="buscarComprobantes()">
                <v-icon  dark>mdi-magnify</v-icon>
                </v-btn>
            </template>
            <span>Buscar</span>
        </v-tooltip>
        <!--Boton para crear nuevo-->
        <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" class="mx-2" fab  dark color="green">
                <v-icon dark>mdi-plus</v-icon>
                </v-btn>
            </template>
            <span>Nuevo</span>
        </v-tooltip>
        </div>
    </v-card-title>
    <v-divider></v-divider>
    <!--Tabla-->
    <v-data-table :headers="headers" :items="items">
            <!-- BOTONES DE ACCION  -->
      <template v-slot:[`item.actions`]="{ item }">
        <template v-if="item.estatus === 'Sin timbrar'">
          <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="blue" dense class="mr-2" v-bind="attrs" v-on="on" dark>mdi-lead-pencil</v-icon>
              </template>
              <span>Editar</span>
          </v-tooltip>
          <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                  <v-icon color="red" dense class="mr-2" v-bind="attrs" v-on="on" dark>mdi-delete-empty</v-icon>
              </template>
              <span>Eliminar</span>
          </v-tooltip>
        </template>

        <template v-if="item.estatus === 'Vigente'">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="red" dense class="mr-2" v-bind="attrs" v-on="on" dark @click="nuevaCancelacion()">mdi-cancel</v-icon>
            </template>
            <span>Cancelar</span>
          </v-tooltip>
        </template>

        <template v-if="item.estatus != 'Sin timbrar'">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="green" dense class="mr-2" v-bind="attrs" v-on="on" dark>mdi-list-status</v-icon>
            </template>
            <span>Estatus SAT</span>
          </v-tooltip>
        </template>
        
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="blue" dense class="mr-2" v-bind="attrs" v-on="on" dark>mdi-view-agenda</v-icon>
          </template>
          <span>Similar</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="orange" dense class="mr-2" v-bind="attrs" v-on="on" dark>mdi-file-pdf-box</v-icon>
          </template>
          <span>PDF</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </v-card>
</div>
</template>
<script>

import axios from 'axios'
import moment from 'moment';
import { format, parseISO } from 'date-fns';
import print from 'print-js';
export default {
data() {
    return {
      headers: [
        { text: 'Acciones', value: 'actions'},
        { text: "Version", value: "version" },
        { text: "Serie", value: "serie" },
        { text: "Folio", value: "folio" },
        { text: "RFC", value: "receptor.rfc" },
        { text: "Nombre", value: "receptor.nombre" },
        { text: "SubTotal", value: "subTotal" },
        { text: "Total", value: "total" },
        { text: "Moneda", value: "moneda" },
        { text: "Fecha", value: "fecha" },
        { text: "Estatus", value: "estatus" },
        { text: "Forma de Pago", value: "formaPago" },
        { text: "Metodo de Pago", value: "metodoPago" },
        { text: "Condiciones de Pago", value: "condicionesDePago" },
      ],
      items: [],

      fechaI: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
      fechaF: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),

      calendario: false,
      calendario2: false,

      tipo: 'Comercio exterior',
      rfc: 'ESC9710075Q2',  

      loading: false,
    };
  },
  mounted() {
    
  },
  computed: {
      datosEmpresa() {
        return this.$store.state.usuario;
      },
    },

  methods: {
    async buscarComprobantes() {
      this.loading = true;
      try {
        let response = await axios.get('Comprobantes/ListarComprobantes/'+ this.datosEmpresa.empresaBase + '/' 
        + this.rfc +'/'+ this.tipo +'/'+ this.fechaI +'/'+ this.fechaF);
        console.log(response.data);
        this.items = response.data;
      this.loading = false;
      } 
      catch (error) {
        console.log(error);
      }
    },
  },
};
</script>