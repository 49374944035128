<template>
    <div>
         <!--Dialog loading-->
        <v-dialog v-model="loading" hide-overlay persistent width="300">
            <v-card color="primary" dark>
                <v-card-text>
                {{loadingMessage}}
                    <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-card>
            <v-toolbar color="primary" dark>
                <v-btn icon @click="CerrarVentanaCancel()">
                    <v-icon large>mdi-close-circle</v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <h3>Cancelacion</h3>
                <v-spacer></v-spacer>
                <v-btn icon @click="cancelar()">
                    <v-icon large>mdi-check-circle</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text>
                <span hidden>{{ ObjetoCancelacion }}</span>
                <v-row>
                    <v-col cols="12" md="12" sm="6">
                        <v-text-field label="Nombre receptor"  readonly v-model="nombre"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" sm="6">
                        <v-text-field label="RFC receptor" v-model="rfc"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" sm="6">
                        <v-text-field label="Total" readonly v-model="total"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="12" sm="6">
                        <v-text-field label="Folio Fiscal - UUID" readonly v-model="folioFiscal"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="12" sm="6">
                        <v-select label="Motivo" :items="itemsMotivosCancelacion" return-object item-text="motivoCancelacion" v-model="motivoCancelacion"></v-select>
                    </v-col>
                    <template v-if="motivoCancelacion.motivoCancelacion == '01 | Comprobante emitido con errores con relación.'">
                    <v-col cols="12" md="12" sm="6">
                        <v-text-field label="Buscar UUID a sustituir" v-model="BuscaruuidSustituir" return-object append-outer-icon="mdi-magnify" @click:append-outer="buscarUuidSustitucion()" @keydown.enter="buscarUuidSustitucion()"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="12" sm="6">
                        <v-text-field label="UUID a sustituir" readonly v-model="uuidSustituir"></v-text-field>
                    </v-col>
                    <!--Tabla UUID A SUSTITUIR-->
                    <v-data-table :headers="headersUUID" :items="itemsComprobantes" v-model="select"  class="elevation-1">
                    <!-- BOTONES DE ACCION  -->
                        <template v-slot:[`item.actions`]="{ item }">
                            <v-icon color="green" dense class="mr-2"  @click="editItem(item)" dark>mdi-checkbox-outline</v-icon>
                        </template>
                    </v-data-table> 
                    </template>
                </v-row>  
            </v-card-text>
        </v-card>

        <!-- SNACKBAR PARA NOTIFICAR LOS ERRORES -->
        <v-snackbar :timeout="10000" v-model="snackbar" color="primary darken-2" elevation="30">{{ snackbarMessage }}
            <template v-slot:action="{ attrs }">
                <v-icon dark @click="snackbar = false">
                  mdi-close-circle
                </v-icon>
              </template>
        </v-snackbar>    
    </div>
</template>
  
<script>
import axios from 'axios' 

export default {
    name: 'Cancelacion',

    data: () => ({
        nombre: '',
        rfc: '',
        folioFiscal: '',
        total: '',
        BuscaruuidSustituir: '',
        uuidSustituir: '',
        variable : '',
        //Motivos
        itemsMotivosCancelacion: [],
        motivoCancelacion : {},

        select:[],

        headersUUID: [
        { text: 'Acciones', value: 'actions'},
        { text: "Serie", value: "serie" },
        { text: "Folio", value: "folio" },
        { text: "Folio Fiscal", value: "folioFiscal" },
        { text: "RFC", value: "receptor.rfc" },
        { text: "Nombre", value: "receptor.nombre" },
        { text: "SubTotal", value: "subTotal" },
        { text: "Total", value: "total" },
        { text: "Moneda", value: "moneda" },
        { text: "Estatus", value: "estatus" },
        { text: "Forma de Pago", value: "formaPago" },
        { text: "Metodo de Pago", value: "metodoPago" },
        { text: "Condiciones de Pago", value: "condicionesDePago" },
      ],
      itemsComprobantes: [],

      // SNACKBAR
        snackbar:false,
        snackbarMessage:'',

        loading: false,
        loadingMessage:'',
    }),

    watch:{

    },

    computed: {
        ObjetoCancelacion(){
            this.nombre = this.$store.state.cancelacion.factura.receptor.nombre
            this.rfc = this.$store.state.cancelacion.factura.receptor.rfc
            this.folioFiscal = this.$store.state.cancelacion.factura.folioFiscal
            this.total = this.functionFormatPrice(this.$store.state.cancelacion.factura.total)
            return true
        },
         datosEmpresa() {
                return this.$store.state.usuario;
            },

    },

    created () {
        this.GetMotivoCancelacion();
    },

    methods: {
        editItem (item) {
            console.log(item)
            this.uuidSustituir = item.folioFiscal;
        },

        CerrarVentanaCancel() {
            this.$emit("VentanaCancelacion");
        }, 
        functionFormatPrice(value) {
            let val = (value/1).toFixed(2)
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },
        async GetMotivoCancelacion(){
            try{
                let response = await axios.get('CatalogoComprobantes/GetMotivoCancelacion') 
                this.itemsMotivosCancelacion = response.data
                console.log(response.data)
            }catch(err){
                console.log(err) 
            }
        },
        async cancelar(){
            const indice = this.$store.state.cancelacion.indice
            const factura = this.$store.state.cancelacion.factura
            console.log(indice)

            let datosCancelacion = {
                idComprobante: this.$store.state.cancelacion.factura.idComprobante,
                rfc: this.datosEmpresa.rfc,
                folioFiscal: this.$store.state.cancelacion.factura.folioFiscal,
                motivoCancelacion: this.motivoCancelacion.clave,
                uuidSustituir: this.uuidSustituir,
                total: this.$store.state.cancelacion.factura.total,
                estatus: 'Cancelado'
            }
            
            if (datosCancelacion.motivoCancelacion == "" || !datosCancelacion.motivoCancelacion) {
                this.snackbarMessage = 'Indique el motivo de cancelacion. '
                this.snackbar = true
                return;
            }

            this.loading = true;
            this.loadingMessage = 'Cancelando UUID...'
            console.log(datosCancelacion)

            try {
                let response = await axios.post('Comprobantes/PutCancelacion/'+ this.datosEmpresa.empresaBase +'/', datosCancelacion)
                this.CerrarVentanaCancel();
                this.snackbarMessage = 'UUID cancelado. '
                this.snackbar = true
                this.loading = false;

            }catch(err){
                console.log(err.response.data)
                this.snackbarMessage = err.response.data;
                this.snackbar = true;
                this.loading = false;

            } 

        },
        async buscarUuidSustitucion(){
         var variable = this.BuscaruuidSustituir
            try{
                let response = await axios.get('Comprobantes/GetComprobantesPorVariable/'+ this.datosEmpresa.empresaBase +'/' + variable)
                this.itemsComprobantes = response.data
            }catch(error){
                console.log(error)
            }
        }, 
    },
}
</script>
