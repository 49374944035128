<template>
    <div>
        <v-card>
            <v-toolbar color="primary" dark>
                <v-btn icon>
                    <v-icon large>mdi-close-circle</v-icon>
                  </v-btn>
                  <v-spacer></v-spacer>
                  <h3>Concepto</h3>
                  <v-spacer></v-spacer>
                  <v-btn icon @click="save()">
                    <v-icon large>mdi-check-circle</v-icon>
                  </v-btn>
            </v-toolbar>
            <v-card-text>
                <v-row>
                    <!-- CLAVE DEL PRODUCTO O SERVICIO -->
                    <v-col cols="12">
                        <v-autocomplete label="Clave del producto o servicio" :items="itemsClaveProdServ" :loading="loadingClaveProdServ" :search-input.sync="searchClaveProdServ" item-text="claveProdServ" hint="En este campo se debe registrar una clave que permita clasificar los conceptos del comprobante como productos o servicios; se deben utilizar las claves de los diversos productos o servicios de conformidad con el catálogo c_ClaveProdServ publicado en el Portal del SAT, cuando los conceptos que se registren por sus actividades correspondan a estos." persistent-hint return-object placeholder="Ingrese el parámetro de bíusqueda" v-model="claveProdServ"></v-autocomplete>
                    </v-col>
                    <!-- OBJETO DE IMPUESTOS -->
                    <v-col cols="12">
                        <v-select label="Objeto de impuestos" :items="itemsObjetoImp" v-model="objetoImp" return-object item-text="objetoImp" hint="Se debe registrar la clave correspondiente para indicar si la operación comercial es objeto o no de impuesto." persistent-hint></v-select>
                    </v-col>
                    <!-- NO DE IDENTIFICACION -->
                    <v-col cols="12">
                        <v-text-field label="No de identificación" hint="En este campo se puede registrar el número de parte, identificador del producto o del servicio, la clave de producto o servicio, SKU (número de referencia) o equivalente, propia de la operación del contribuyente emisor del comprobante fiscal descrito en el presente concepto" maxlength="100" counter persistent-hint v-model="noIdentificacion"></v-text-field>
                    </v-col>
                    <!-- DESCRIPCIÓN -->
                    <v-col cols="12">
                        <v-text-field label="Descripcion" hint=" En este campo se debe registrar la descripción del bien o servicio propio de la empresa por cada concepto." maxlength="1000" counter persistent-hint v-model="descripcion"></v-text-field>
                    </v-col>
                    <!-- CLAVE DE UNIDAD -->
                    <v-col cols="6">
                        <v-autocomplete label="Clave de unidad" :items="itemsClaveUnidad" :loading="loadingClaveUnidad" :search-input.sync="searchClaveUnidad" item-text="claveUnidad" hint="En este campo se debe registrar la clave de unidad de medida estandarizada de conformidad con el catálogo c_ClaveUnidad publicado en el Portal del SAT, aplicable para la cantidad expresada en cada concepto. La unidad debe corresponder con la descripción del concepto." persistent-hint return-object placeholder="Ingrese el parámetro de bíusqueda" return-object v-model="claveUnidad"></v-autocomplete>
                    </v-col>
                    <!-- UNIDAD -->
                    <v-col cols="6">
                        <v-text-field label="Unidad" hint="En este campo se puede registrar la unidad de medida del bien o servicio propio de la operación del emisor, aplicable para la cantidad expresada en cada concepto. La unidad debe corresponder con la descripción del concepto" persistent-hint maxlength="20" counter v-model="unidad"></v-text-field>
                    </v-col>
                    <!-- VALOR UNITARIO -->
                    <v-col cols="6">
                        <v-text-field label="Valor unitario" type="number" hint="En este campo se debe registrar el importe unitario del bien o servicio por cada concepto, el cual puede contener de cero hasta seis decimales." persistent-hint v-model="valorUnitario"></v-text-field>
                    </v-col>
                    <!-- PRECIO UNITARIO -->
                    <v-col cols="6">
                        <v-text-field label="Precio unitario" type="number" hint="En este campo se debe registrar el precio unitario del bien o servicio por cada concepto, el cual puede contener de cero hasta seis decimales." persistent-hint v-model="precioUnitario"></v-text-field>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>

        <!-- DIALOG DE QUE ESTA HACIENDO ALGO -->
        <div>
            <v-dialog v-model="dialog" hide-overlay persistent width="300">
              <v-card color="primary" dark>
                <v-card-text>
                  {{ dialogMessage }}
                  <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                </v-card-text>
              </v-card>
            </v-dialog>
        </div>

        <!-- SNACKBAR PARA NOTIFICAR LOS ERRORES -->
        <v-snackbar :timeout="5000" v-model="snackbar" color="primary darken-2" elevation="30">{{ snackbarMessage }}
            <template v-slot:action="{ attrs }">
                <v-icon dark @click="snackbar = false">
                  mdi-close-circle
                </v-icon>
              </template>
        </v-snackbar>
    </div>

</template>
<script>
    import axios from 'axios'

    export default {
        name: 'Concepto',
        components:{

        },

        data: () => ({
            //CLAVE DEL PRODUCTO O SERVICIO
            claveProdServ: {},
            itemsClaveProdServ: [],
            limitClaveProdServ: 60,
            loadingClaveProdServ: false, 
            searchClaveProdServ: null,

            noIdentificacion: '',

            //CLAVE DE UNIDAD
            claveUnidad: {},
            itemsClaveUnidad: [],
            limitClaveUnidad: 60,
            loadingClaveUnidad: false,
            searchClaveUnidad : null,

            unidad: '',
            descripcion: '',
            valorUnitario: 0.00,
            precioUnitario: 0.00,

            itemsObjetoImp: [],
            objetoImp:{
                clave: '02',
                descripcion: 'Sí objeto de impuesto.',
                objetoImp: '02 | Sí objeto de impuesto.'
            },
            
            //
            dialogMessage:'',
            dialog: false,
            snackbarMessage:'',
            snackbar: false, 
        }),

        watch:{
            async searchClaveProdServ(){
                if (this.itemsclaveProdServ.length > 0) return
                if (this.loadingClaveProdServ) return
                this.loadingClaveProdServ = true
                try{
                    let response = await axios.get('CatalogoComprobantes/GetClaveProdServ')
                    this.itemsClaveProdServ = response.data;
                }catch(error){
                    console.log(error)
                }
                this.loadingClaveProdServ = false
            },
            async searchClaveUnidad(){
                if(this.itemsclaveUnidad.length > 0) return
                if(this.loadingClaveUnidad) return
                this.loadingClaveUnidad = true
                try{
                    let response = await axios.get('CatalogoComprobantes/GetClaveUnidad')
                    this.itemsClaveUnidad = response.data;
                }catch(err){
                    console.log(err)
                }
                this.loadingClaveUnidad = false
            },
        },

        computed: {
            itemsclaveProdServ(){
                return this.itemsClaveProdServ.map(entry => {
                const Description = entry.descripcion.length > this.limitClaveProdServ
                    ? entry.descripcion.slice(0, this.limitClaveProdServ) + '...'
                    : entry.descripcion

                return Object.assign({}, entry, { Description })
                })
            },
            itemsclaveUnidad(){
                return this.itemsClaveUnidad.map(entry => {
                    const Description = entry.nombre.length > this.limitClaveUnidad
                    ? entry.nombre.slice(0, this.limitClaveUnidad) + '...'
                    :entry.nombre
                    return Object.assign( {}, entry, { Description })
                })
            },
             datosEmpresa() {
                return this.$store.state.usuario;
            },
        },

        created () {
            this.getObjetoImp();
        },

        methods: {
            //CATALOGO INICIAL
            async getObjetoImp(){
                try{
                    let response = await axios.get('CatalogoComprobantes/GetObjetoImp')
                    this.itemsObjetoImp = response.data
                }catch(err){
                    console.log(err)
                }
            },
            
            save(){
                this.dialog = true
                this.dialogMessage = "Guardando concepto espere..."
                this.postConcepto();
            },

            async postConcepto(){
                let concepto = {
                    claveProdServ: this.claveProdServ.clave,
                    noIdentificacion: this.noIdentificacion, 
                    claveUnidad: this.claveUnidad.c_ClaveUnidad,
                    unidad: this.unidad,
                    descripcion: this.descripcion,
                    valorUnitario: this.valorUnitario,
                    precioUnitario: this.precioUnitario,
                    objetoImp: this.objetoImp.clave,
                    cuentaPredial:{
                        numero:'',
                    }
                };
                try {
                    let response = await axios.post('Conceptos/PostConceptosComprobantes/'+ this.datosEmpresa.empresaBase, concepto)
                    console.log(response.data)
                    this.dialog = false;
                    this.snackbar = true;
                    this.snackbarMessage = 'Concepto guardado con éxito!!!';
                } catch (error) {
                    console.log(error)
                    this.dialog = false;
                    this.snackbar = true;
                    this.snackbarMessage = 'Error al guardar, verifique los datos';
                }
            },
        },
    }
</script>