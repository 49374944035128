<template>
<div>
  <!--Dialog loading-->
  <v-dialog v-model="loading" hide-overlay persistent width="300">
    <v-card color="primary" dark>
      <v-card-text>
        {{loadingMessage}}
        <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  <v-card>
    <v-card-title style="font: bold;" class="text-h4 text--primary">
    <!--Boton regresar-->
    <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on"  class="mx-2" icon large link :to="{ name: 'Comprobantes' }">
                <v-icon size="40" color="primary">mdi-arrow-left-thick</v-icon>
                </v-btn>
            </template>
            <span>Regresar</span>
        </v-tooltip>
      Lista de Honorarios
      <v-spacer></v-spacer>
      <!--Fecha Inicial-->
      <v-col cols="12" sm="3" md="3">
      <v-menu v-model="calendario" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field v-model="fechaI" label="Fecha Inicial" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
        </template>
        <v-date-picker v-model="fechaI" @input="calendario = false"></v-date-picker>
      </v-menu>
    </v-col>
    <!--Fecha Final-->
    <v-col cols="12" sm="3" md="3">
      <v-menu v-model="calendario2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field v-model="fechaF" label="Fecha Final" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
        </template>
        <v-date-picker v-model="fechaF" @input="calendario2 = false"></v-date-picker>
      </v-menu>
    </v-col>
      <div class="text-center">
        <!--Boton para  buscar-->
        <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" class="mx-2" fab  dark color="blue" @click="buscarComprobantes()">
                <v-icon  dark>mdi-magnify</v-icon>
                </v-btn>
            </template>
            <span>Buscar</span>
        </v-tooltip>
        <!--Boton para crear nuevo-->
        <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" class="mx-2" fab  dark color="green" @click="nuevaFactura()">
                <v-icon dark>mdi-plus</v-icon>
                </v-btn>
            </template>
            <span>Nuevo</span>
        </v-tooltip>
        </div>
    </v-card-title>
    <v-divider></v-divider>
    <v-data-table :headers="headers" :items="items" :sort-by="['folio', 'fecha']" sort-desc> 
    <!-- FORMATO CON DECIMALES DEL IMPORTE -->
      <template v-slot:item.subTotal="{ item }">
          <span>{{ functionFormatPrice(item.subTotal) }}</span>
      </template>
      <!-- FORMATO CON DECIMALES DEL TOTAL -->
      <template v-slot:item.total="{ item }">
          <span>{{ functionFormatPrice(item.total) }}</span>
      </template>
      <!-- muestra el color del estatus de las facturas -->
      <template v-slot:item.folio="{ item }">
        <v-chip :color="getColor(item.estatus)" dark>{{ item.folio }}</v-chip>
    </template>
      <!-- BOTONES DE ACCION  -->
      <template v-slot:[`item.actions`]="{ item }">
        <template v-if="item.estatus === 'Sin timbrar'">
          <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="blue" dense class="mr-2" v-bind="attrs" v-on="on" dark @click="timbrarGuardado(item)">mdi-lead-pencil</v-icon>
              </template>
              <span>Editar</span>
          </v-tooltip>
          <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                  <v-icon color="red" dense class="mr-2" v-bind="attrs" v-on="on" dark @click="eliminarItem(item)">mdi-delete-empty</v-icon>
              </template>
              <span>Eliminar</span>
          </v-tooltip>
        </template>

        <template v-if="item.estatus === 'Vigente'">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="red" dense class="mr-2" v-bind="attrs" v-on="on" dark @click="nuevaCancelacion(item)">mdi-cancel</v-icon>
            </template>
            <span>Cancelar</span>
          </v-tooltip>
        </template>

        <template v-if="item.estatus != 'Sin timbrar'">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="green" dense class="mr-2" v-bind="attrs" v-on="on" dark @click="consultarSat(item)">mdi-list-status</v-icon>
            </template>
            <span>Estatus SAT</span>
          </v-tooltip>
        </template>
        
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="blue" dense class="mr-2" v-bind="attrs" v-on="on" dark @click="GetSimilares(item)">mdi-view-agenda</v-icon>
          </template>
          <span>Similar</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="orange" dense class="mr-2" v-bind="attrs" v-on="on" dark @click="GetComprobante(item)">mdi-file-pdf-box</v-icon>
          </template>
          <span>PDF</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </v-card>
  <!-- SNACKBAR PARA NOTIFICAR LOS ERRORES -->
    <v-snackbar :timeout="5000" v-model="snackbar" color="primary darken-2" elevation="30">{{ snackbarMessage }}
      <template v-slot:action="{ attrs }">
        <v-icon dark @click="snackbar = false">
          mdi-close-circle
      </v-icon>
    </template>
  </v-snackbar>

  <v-dialog persistent v-model="dialogFacturaNueva">
    <honorarios @closeFactura="closeDialogFactura()"></honorarios>
  </v-dialog>

  <v-dialog v-model="dialogNuevaCancelacion" max-width="1000px">
    <cancelacion @VentanaCancelacion="CierraVentanaCancelacion()"></cancelacion>
  </v-dialog>
  <!-- DIALOG PARA MOSTRAR EL PDF -->
  <v-dialog v-model="dialogPdf" persistent>
    <v-card>
      <v-toolbar>
        <v-btn fab dark small @click="dialogPdf=false" color="primary darken-1"><v-icon>mdi-close</v-icon></v-btn>
        <v-spacer></v-spacer>
        <v-toolbar-title>Vista Previa</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn fab dark small color="primary darken-1" class="mx-2" @click="$refs.pdf.print()"><v-icon>mdi-printer</v-icon></v-btn>
        <v-btn fab dark small color="primary darken-1" class="mx-2" @click="descargaComprobante()"><v-icon>mdi-download</v-icon></v-btn>
        <v-btn fab dark small color="primary darken-1" @click="dialogCorreo=true"><v-icon>mdi-email-send</v-icon></v-btn>
      </v-toolbar>
      <v-system-bar color="primary" height="6"></v-system-bar>
      <v-card-text>
        <template>
          <div class="text-center">
            <v-icon color="primary" @click="page--">mdi-minus</v-icon>
            {{page}} /{{numPages}}
            <v-icon color="primary" @click="page++">mdi-plus</v-icon>
            <div style="width: 100%">
              <div v-if="loadedRatio > 0 && loadedRatio < 1" style="background-color: green; color: white; text-align: center" :style="{ width: loadedRatio * 100 + '%' }">{{ Math.floor(loadedRatio * 100) }}%</div>
              <pdf ref="pdf" style="border: 1px solid red" :src="srcPdf" :page="page" :rotate="rotate" @progress="loadedRatio = $event" @num-pages="numPages = $event" @link-clicked="page = $event"></pdf>
            </div>
          </div>
        </template>
      </v-card-text>
    </v-card>
    <template>      
    </template>
  </v-dialog>
</div>
</template>
<script>
import axios from 'axios'
import moment from 'moment';
import { format, parseISO } from 'date-fns';
import print from 'print-js';
import Honorarios from '../Comprobantes/Honorarios/Honorarios'
import Cancelacion from '../Comprobantes/Cancelacion/Cancelacion'
import pdf from 'vue-pdf'

export default {
  components:{
    Honorarios,
    pdf: pdf,
    Cancelacion,
  },
data() {
    return {
      headers: [
            { text: 'Acciones', value: 'actions'},
            { text: "Version", value: "version" },
            { text: "Serie", value: "serie" },
            { text: "Folio", value: "folio" },
            { text: "RFC", value: "receptor.rfc" },
            { text: "Nombre", value: "receptor.nombre" },
            { text: "SubTotal", value: "subTotal" },
            { text: "Total", value: "total" },
            { text: "Moneda", value: "moneda" },
            { text: "Fecha", value: "fecha" },
            { text: "Estatus", value: "estatus" },
            { text: "Forma de Pago", value: "formaPago" },
            { text: "Metodo de Pago", value: "metodoPago" },
            { text: "Folio fiscal", value: "folioFiscal" },
            {text: "Estado de cancelacion", value: "estatusCancelacion"}

      ],

      fechaI: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
      fechaF: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),

      calendario: false,
      calendario2: false,

      tipo: 'Honorarios',
      //rfc: 'IIA040805DZ4',

      loading: false,

      //NUEVA FACTURA 
      dialogFacturaNueva: false, 

      // CANCELAR
      dialogNuevaCancelacion: false,
      
      // SNACKBAR
      snackbar:false,
      snackbarMessage:'',
      loadingMessage:'',

      //DATOS DE LA EMPRESA
      empresa: {},

      //PARA PDF Y XML
      dialogPdf: false,
      srcPdf:'',
      loadedRatio: 0,
      page: 1,
      numPages: 0,
      rotate: 0,
      nombreArchivo:'',
      scrXml: '',
    };
  },
  mounted() {
  },
  created() {
    this.GetEmpresa();
  },
  computed: {
    items(){
      return this.$store.state.listHonorariosStore;
    },
    datosEmpresa(){
      return this.$store.state.usuario;
    },
  },
  methods: {
    // cerrar ventana cancelar
    CierraVentanaCancelacion() {
      this.dialogNuevaCancelacion = false;
    },
     //OBTIENE LOS DATOS DE LA EMPRESA
    async GetEmpresa() {
      try {
        let response = await axios.get('Empresa/GetEmpresa/' + this.datosEmpresa.empresaBase)
        this.empresa = response.data
      } catch (err) {
        console.log(err)
      }
    },
    // consultar sat
    async consultarSat(item){
      let consultar = {
        idComprobante : item.idComprobante,
        folioFiscal : item.folioFiscal,
        total : item.total,
        rfcEmisor: this.empresa.rfc,
        rfcReceptor: item.receptor.rfc,
      }
      this.loadingMessage = 'Consultando estatus en el SAT... '
      this.loading = true;
      console.log(consultar)
      try {
        let response = await axios.post('Comprobantes/ConsultaSat/'+ this.datosEmpresa.empresaBase +'/', consultar)
        this.loading = false;
        this.buscarComprobantes();
        this.snackbarMessage='Estatus actualizado'
        this.snackbar = true
      }catch(err){
        console.log(err)
        this.loading = false;
        this.snackbarMessage='Error al actualizar'
        this.snackbar = true
      } 
    }, 

    async buscarComprobantes() {
      this.loading = true;
      this.loadingMessage = 'Cargando datos...'

      try {
        let response = await axios.get('Comprobantes/ListarComprobantes/' + this.datosEmpresa.empresaBase +'/' 
         + this.empresa.rfc +'/'+ this.tipo +'/'+ this.fechaI +'/'+ this.fechaF);
        this.$store.state.listHonorariosStore = response.data;
        console.log(this.items);
        this.loading = false;
      } catch (error) {
        console.log(error);
      } 
    },

    functionFormatPrice(value) {
      let val = (value/1).toFixed(2)
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    getColor (estatus) {
      if (estatus == 'Vigente') return 'green'
      else if (estatus == 'Cancelado') return 'red darken-1'
       else if (estatus == 'Sin timbrar') return 'blue' 
        else if (estatus == 'Eliminado') return 'black'
      else return 'gray'
    },
    async eliminarItem(item){
        this.loading = true;
        this.loadingMessage = 'Eliminar combrobante guardado...'
        let id = item.idComprobante
        console.log(id)
        try {
          let response = await axios.post('Comprobantes/EliminarComprobante/' + this.datosEmpresa.empresaBase + '/' + id);
          this.loading = false;
        } catch (error) {
          console.log(error);
          this.loading = false;
        }
          this.buscarComprobantes();
      },
      GetSimilares(item){
        console.log(item)
        item.comprobanteJson.id = 0;
        item.comprobanteJson.folio = 0;
        item.id = 0
        item.comprobanteJson.fecha = format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
        item.comprobanteJson.hora = format(parseISO(new Date().toISOString()), 'HH:mm'),

        this.$store.state.honorariosStore = Object.assign({}, item.comprobanteJson)
        this.dialogFacturaNueva = true
      },
      timbrarGuardado(item){
        console.log(item)
        item.comprobanteJson.id = item.idComprobante;
        item.comprobanteJson.fecha = format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
        item.comprobanteJson.hora = format(parseISO(new Date().toISOString()), 'HH:mm'),
        

        this.$store.state.honorariosStore = Object.assign({}, item.comprobanteJson)
        this.dialogFacturaNueva = true
        console.log(this.$store.state.honorariosStore)
      },
    nuevaFactura(){
      let comprobante ={
        id : 0,
        version: '4.0',
        // serie: this.selectSerie,
        serie: null,
        folio: 0,
        fecha: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
        hora: format(parseISO(new Date().toISOString()), 'HH:mm'),
        formaPago: null,
        condicionesDePago: null,
        subTotal: 0,
        descuento: 0,
        moneda: { clave: 'MXN', descripcion:'Peso Mexicano', moneda:'MXN | Peso Mexicano' },
        tipoCambio: 1,
        total: 0,
        tipoDeComprobante: 'I',
        exportacion: '01',
        metodoPago: null,
        lugarExpedicion:this.empresa.cp,
        confirmacion: null,
        estatus: '',
        tipo: 'Honorarios',
        emisor:{
          rfc: this.empresa.rfc,
          nombre: this.empresa.nombreEmpresa,
          regimenFiscal: this.empresa.regimenFiscal,
        },
        receptor:{},
        conceptos: [],
        impuestos:{
          traslados:[],
          retenciones:[],
          totalImpuestosRetenidos: 0.00,
          totalImpuestosTrasladados: 0.00,
        },
        oc: '',
        notas: '',
        cfdiRelacionados: {
          tipoRelacion: { clave:'', descripcion:'', tipoRelacion:'' },
          cfdiRelacionados: [],
        }
      }

      this.$store.state.honorariosStore.hora = format(parseISO(new Date().toISOString()), 'HH:mm');
      if(this.$store.state.honorariosStore.id === -1){
        this.$store.state.honorariosStore = Object.assign({}, comprobante)
      }
      this.dialogFacturaNueva = true;

    },

    // cancelar comprobante 
    nuevaCancelacion(item){
      let indice = this.items.indexOf(item);
      const factura = Object.assign({}, item)
      console.log(factura);

      let ObjetoCancelacion = {
        factura: Object.assign({}, factura),
        indice: indice,
        tipo: 'Honorarios',
      };
      
      this.$store.state.cancelacion = Object.assign({}, ObjetoCancelacion);
      this.dialogNuevaCancelacion = true;
      // this.editedIndex = this.items.indexOf(item)
      // this.editedItem = Object.assign({}, item)
    },

    closeDialogFactura(){
      this.dialogFacturaNueva = false
    },

    async GetComprobante(item){
        if(item.comprobanteJson.estatus != "Sin timbrar"){
        this.nombreArchivo = item.receptor.rfc + '_' + item.serie + '_' + item.folio + '_' + item.folioFiscal
        var comprobante = item.comprobanteJson
        this.loading = true;
        this.loadingMessage = 'Recuperando archivos...'
        console.log(comprobante)
        try {
          let response = await axios.post('https://api-framework.contago.com.mx/api/pdfs/PdfFactura/', comprobante);
          this.loading = false;
          this.dialogPdf = true;
          this.srcPdf = 'data:application/pdf;base64,' + response.data.pdf_
          this.scrXml = response.data.xml_
          this.page = 1;
        } catch (error) {
          console.log(error);
          this.loading = false;
        }
        }else{
          this.nombreArchivo = 'VistaPrevia' + '_' + item.receptor.rfc 

                var comprobante = item.comprobanteJson
                this.dialog = true;
                this.dialogMessage = 'Cargando vista...'
                console.log(comprobante)

                try {
                    let response = await axios.post('https://api-framework.contago.com.mx/api/pdfs/PdfVisualizar/', comprobante);
                    this.dialog = false;
                    this.dialogPdf = true;
                    this.srcPdf = 'data:application/pdf;base64,' + response.data.pdf_
                    this.scrXml = response.data.xml_
                    this.page = 1;
                } catch (error) {
                    console.log(error);
                    this.dialog = false;
                }
        }
    },

    //PARA DESCARGAR XML Y PDF
    descargaComprobante(){
      var pdf = this.srcPdf.split(',')
      this.download(pdf[1], this.nombreArchivo, 'pdf');
      this.download(this.scrXml, this.nombreArchivo, 'xml');
    },

    download(archivo, nombre ,tipo) {
      var arrBuffer = this.base64ToArrayBuffer(archivo);
      var newBlob = new Blob([arrBuffer]);

      var data = window.URL.createObjectURL(newBlob);
      var link = document.createElement('a');
      document.body.appendChild(link);
      link.href = data;
      link.download = nombre+'.'+tipo;
      link.click();
      window.URL.revokeObjectURL(data);
      link.remove();
    },

    base64ToArrayBuffer(base64) {
      var binary_string =  window.atob(base64);
      var len = binary_string.length;
      var bytes = new Uint8Array( len );
      for (var i = 0; i < len; i++)        {
        bytes[i] = binary_string.charCodeAt(i);
      }
      return bytes.buffer;
    },
  },
};
</script>