<template>
    <div>
        <v-card>
            <v-toolbar color="primary" dark>
                <v-btn icon @click="CerrarVentanaSeg()">
                    <v-icon large>mdi-close-circle</v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <h3>Seguro</h3>
                <v-spacer></v-spacer>
                <v-btn icon @click="PostSeguro()">
                    <v-icon large>mdi-check-circle</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text>
                <span hidden>{{this.$store.state.seguros}}</span>
                <v-select label="Tipo de seguro" :items="itemsTipoSeguro" hint="Seleccione el tipo de seguro"
                    v-model="seguros.tipo" persistent-hint></v-select>

                <template v-if="seguros.tipo === 'Responsabilidad Civil'">
                    <v-text-field label="Aseguradora de responsabilidad civil"
                        hint="En este campo se deberá registrar el nombre de la aseguradora que cubre los riesgos de responsabilidad civil del autotransporte utilizado para el traslado de los bienes y/o mercancías."
                        maxlength="50" counter v-model="seguros.aseguraRespCivil"></v-text-field>
                    <v-text-field label="Número de póliza de seguro por responsabilidad civil"
                        hint="En este campo se deberá registrar el número de la póliza de seguro que emite la aseguradora que cubre los riesgos de responsabilidad civil del autotransporte utilizado para el traslado de los bienes y/o mercancías."
                        maxlength="30" counter v-model="seguros.polizaRespCivil"></v-text-field>
                </template>

                <template v-if="seguros.tipo === 'Daños al medio ambiente'">
                    <v-text-field label="Aseguradora de daños al medio ambiente"
                        hint="En este campo se podrá registrar el nombre de la aseguradora que cubre los posibles daños al medio ambiente, que es aplicable para los transportistas que realicen el traslado de materiales o residuos peligrosos, es decir, cuando se capture información en el campo “MaterialPeligroso”"
                        maxlength="50" counter v-model="seguros.aseguraMedAmbiente"></v-text-field>
                    <v-text-field label="Número de póliza de seguro por daños al medio ambiente"
                        hint=" En este campo se podrá registrar el número de póliza asignado por la aseguradora, que cubre los posibles daños al medio ambiente aplicable para los transportistas que realicen el traslado de materiales o residuos peligrosos."
                        maxlength="30" counter v-model="seguros.polizaMedAmbiente"></v-text-field>
                </template>

                <template v-if="seguros.tipo === 'Carga transportada'">
                    <v-text-field label="Aseguradora de la carga transportada"
                        hint="En este campo se podrá registrar el nombre de la aseguradora que cubre los riesgos de la carga (bienes y/o mercancías) transportada."
                        maxlength="50" counter v-model="seguros.aseguraCarga"></v-text-field>
                    <v-text-field label="Número de póliza de seguro de la carga transportada"
                        hint="En este campo se podrá registrar el número de póliza que emite la aseguradora que cubre los riesgos de la carga (bienes y/o mercancías) transportada."
                        maxlength="30" counter v-model="seguros.polizaCarga"></v-text-field>
                    <v-text-field label="Valor de la prima del seguro" type="number"
                        hint="En este campo se podrá registrar el valor del importe de la prima del seguro contratado, por el cargo adicional acordado entre el transportista y el cliente conforme a lo establecido en las disposiciones jurídicas que para tal efecto emita la SCT."
                        v-model="seguros.primaSeguro"></v-text-field>
                </template>
                <v-menu v-model="calendario2" :close-on-content-click="false" :nudge-right="40"
                    transition="scale-transition" offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="vigencia" label="Fecha Final" prepend-icon="mdi-calendar"
                            readonly v-bind="attrs" v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="vigencia" @input="calendario2 = false"></v-date-picker>
                </v-menu>
            </v-card-text>
        </v-card>
        <div>
            <v-dialog v-model="dialog" hide-overlay persistent width="300">
                <v-card color="primary" dark>
                    <v-card-text>
                        {{ dialogMessage }}
                        <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </div>
        <!-- SNACKBAR PARA NOTIFICAR LOS ERRORES -->
        <v-snackbar :timeout="5000" v-model="snackbar" color="primary darken-2" elevation="30">{{ snackbarMessage }}
            <template v-slot:action="{ attrs }">
                <v-icon dark @click="snackbar = false">
                    mdi-close-circle
                </v-icon>
            </template>
        </v-snackbar>
    </div>
</template>
<script>
    import axios from 'axios'
    import moment from 'moment';
    import { format, parseISO } from 'date-fns';
    import print from 'print-js';
    export default {
        name: 'Seguro',
        components: {

        },

        data: () => ({
            //DIALOG
            dialog: false,
            dialogMessage: '',

            // SNACKBAR
            snackbar: false,
            snackbarMessage: '',

            itemsTipoSeguro: ['Responsabilidad Civil', 'Daños al medio ambiente', 'Carga transportada'],
            tipoSeguro: 'Responsabilidad Civil',

            aseguradora_a: '',
            aseguradora_b: '',
            aseguradora_c: '',

            numPoliza_a: '',
            numPoliza_b: '',
            numPoliza_c: '',

            pirmaSeguro: 0,

            vigencia: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
            calendario2: false,
        }),
        watch: {

        },
        computed: {
            datosEmpresa() {
                return this.$store.state.usuario;
            },
            seguros() {
               //this.vigencia = format(parseISO(this.$store.state.facturaStore.vigencia.toISOString()), 'yyyy-MM-dd')

                return this.$store.state.seguros;
            },
            // computedDatese() {
            //     return this.$store.state.facturaStore.vigencia ? moment(this.seguros.vigencia).format('DD/MM/yyyy') : ''
            // },
        },
        mounted() {

        },

        methods: {
            //Cerrar ventana
            CerrarVentanaSeg() {
                this.$store.state.seguros.id = -1
                this.$emit("VentanaNuevoSeguro");
            },
            async PostSeguro() {
                let seguro = Object.assign({}, this.$store.state.seguros)
                console.log(seguro)
                switch (this.seguros.tipo) {
                    case 'Responsabilidad Civil':
                        seguro.aseguraRespCivil = this.seguros.aseguraRespCivil
                        seguro.polizaRespCivil = this.seguros.polizaRespCivil
                        seguro.primaSeguro = 0
                        seguro.tipo = 'Responsabilidad Civil'
                        seguro.vigencia = this.vigencia
                        break;
                    case 'Daños al medio ambiente':
                        seguro.aseguraMedAmbiente = this.seguros.aseguraMedAmbiente
                        seguro.polizaMedAmbiente = this.seguros.polizaMedAmbiente
                        seguro.vigencia = this.vigencia
                        seguro.primaSeguro = 0
                        seguro.tipo = 'Daños al medio ambiente'
                        break;
                    case 'Carga transportada':
                        seguro.aseguraCarga = this.seguros.aseguraCarga
                        seguro.polizaCarga = this.seguros.polizaCarga
                        seguro.vigencia =this.vigencia
                        seguro.primaSeguro = this.seguros.primaSeguro
                        seguro.tipo = 'Carga transportada'
                        break;
                }

                if (seguro.id > 0) {
                    try {
                        let response = await axios.post('CartaPorte/UpdateSeguro/' + this.datosEmpresa.empresaBase, seguro);
                        console.log(response)
                        this.snackbarMessage = 'Guardado con exito.'
                        this.snackbar = true
                        this.$store.state.seguros.id = -1

                        this.CerrarVentanaSeg();
                    } catch (err) {
                        console.log(err)
                        this.snackbarMessage = 'Error al guardar, intentelo de nuevo.'
                        this.snackbar = true
                    }
                } else {
                    try {
                        let response = await axios.post('CartaPorte/PostSeguro/' + this.datosEmpresa.empresaBase, seguro);
                        console.log(response)
                        this.snackbarMessage = 'Guardado con exito.'
                        this.snackbar = true
                        this.$store.state.seguros.id = -1

                        this.CerrarVentanaSeg();
                    } catch (err) {
                        console.log(err)
                        this.snackbarMessage = 'Error al guardar, intentelo de nuevo.'
                        this.snackbar = true
                    }
                }

            },
        }
    }
</script>