import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from '../store/index'

import Concepto from '../components/Comprobantes/ComprobanteFactura.vue'
import Comprobantes from '../views/Comprobantes.vue'
import Catalogos from '../views/Catalogos.vue'
import Ventas from '../views/Ventas.vue'
import Configuracion from '../components/Configuracion/Configuracion.vue'
import CatalogosCartaPorte from '../views/CatalogosCartaPorte.vue'
import ListaFactura from '../components/Listas/ListaFactura.vue'
import ListaAdenda from '../components/Listas/ListaAdenda.vue'
import ListaArrendamientos from '../components/Listas/ListaArrendamientos.vue'
import ListaCartaPorte from '../components/Listas/ListaCartaPorte.vue'
import ListaComercioExterior from '../components/Listas/ListaComercioExterior.vue'
import ListaComplementoPago from '../components/Listas/ListaComplementoPago.vue'
import ListaHonorarios from '../components/Listas/ListaHonorarios.vue'
import ListaNotaCredito from '../components/Listas/ListaNotaCredito.vue'
import ListaTrasladoCartaPorte from '../components/Listas/ListaTrasladoCartaPorte.vue'

import ListaFigurasTransporte from '../components/Listas/ListaFigurasTransporte.vue'
import ListaAutotransporte from '../components/Listas/ListaAutotransporte.vue'
import ListaMercancia from '../components/Listas/ListaMercancia.vue'
import ListaSeguro from '../components/Listas/ListaSeguro.vue'
import ListaUbicacionDestino from '../components/Listas/ListaUbicacionDestino.vue'
import ListaUbicacionOrigen from '../components/Listas/ListaUbicacionOrigen.vue'
import ListaVehiculo from '../components/Listas/ListaVehiculo.vue'
import ListaCotizaciones from '../components/Listas/ListaCotizaciones.vue'
import ListaRemisiones from '../components/Listas/ListaRemisiones.vue'
import ListaReceptor from '../components/Listas/ListaReceptor.vue'
import ListaConceptos from '../components/Listas/ListaConceptos.vue'
import ListaPermisoSCT from '../components/Listas/ListaPermisoSCT.vue'
import ListaRemolques from '../components/Listas/ListaRemolques.vue'
import ListaDirecciones from '../components/Listas/ListaDirecciones.vue'

import Login from '../views/Login.vue'

Vue.use(VueRouter)

const routes = [
  { path: '/', name: 'Home', component: Home, meta: { Administrador: true } },
  { path: '/Concepto', name: 'Concepto', component: Concepto, meta: { Administrador: true } },
  { path: '/Comprobantes', name: 'Comprobantes', component: Comprobantes, meta: { Administrador: true } },
  { path: '/Ventas', name: 'Ventas', component: Ventas, meta: { Administrador: true }  },
  { path: '/Catalogos', name: 'Catalogos', component: Catalogos, meta: { Administrador: true } },
  { path: '/Configuracion', name: 'Configuracion', component: Configuracion, meta: { Administrador: true } },
  { path: '/ListaFactura', name: 'ListaFactura', component: ListaFactura, meta: { Administrador: true } },
  { path: '/ListaAdenda', name: 'ListaAdenda', component: ListaAdenda, meta: { Administrador: true } },
  { path: '/ListaArrendamientos', name: 'ListaArrendamientos', component: ListaArrendamientos, meta: { Administrador: true } },
  { path: '/ListaCartaPorte', name: 'ListaCartaPorte', component: ListaCartaPorte, meta: { Administrador: true } },
  { path: '/ListaComercioExterior', name: 'ListaComercioExterior', component: ListaComercioExterior, meta: { Administrador: true } },
  { path: '/ListaComplementoPago', name: 'ListaComplementoPago', component: ListaComplementoPago, meta: { Administrador: true } },
  { path: '/ListaHonorarios', name: 'ListaHonorarios', component: ListaHonorarios, meta: { Administrador: true } },
  { path: '/ListaNotaCredito', name: 'ListaNotaCredito', component: ListaNotaCredito, meta: { Administrador: true } },
  { path: '/ListaTrasladoCartaPorte', name: 'ListaTrasladoCartaPorte', component: ListaTrasladoCartaPorte, meta: { Administrador: true } },
  { path: '/CatalogosCartaPorte', name: 'CatalogosCartaPorte', component: CatalogosCartaPorte, meta: { Administrador: true } },
  { path: '/ListaFigurasTransporte', name: 'ListaFigurasTransporte', component: ListaFigurasTransporte, meta: { Administrador: true } },
  { path: '/ListaAutotransporte', name: 'ListaAutotransporte', component: ListaAutotransporte, meta: { Administrador: true } },
  { path: '/ListaMercancia', name: 'ListaMercancia', component: ListaMercancia, meta: { Administrador: true } },
  { path: '/ListaSeguro', name: 'ListaSeguro', component: ListaSeguro, meta: { Administrador: true } },
  { path: '/ListaUbicacionDestino', name: 'ListaUbicacionDestino', component: ListaUbicacionDestino, meta: { Administrador: true } }, 
  { path: '/ListaUbicacionOrigen', name: 'ListaUbicacionOrigen', component: ListaUbicacionOrigen, meta: { Administrador: true } },
  { path: '/ListaVehiculo', name: 'ListaVehiculo', component: ListaVehiculo, meta: { Administrador: true } },
  { path: '/ListaCotizaciones', name: 'ListaCotizaciones', component: ListaCotizaciones, meta: { Administrador: true } },
  { path: '/ListaRemisiones', name: 'ListaRemisiones', component: ListaRemisiones, meta: { Administrador: true } },
  { path: '/ListaReceptor', name: 'ListaReceptor', component: ListaReceptor, meta: { Administrador: true } },
  { path: '/ListaConceptos', name: 'ListaConceptos', component: ListaConceptos, meta: { Administrador: true } },
  { path: '/ListaPermisoSCT', name: 'ListaPermisoSCT', component: ListaPermisoSCT, meta: { Administrador: true } },
  { path: '/ListaRemolques', name: 'ListaRemolques', component: ListaRemolques, meta: { Administrador: true } },
  { path: '/ListaDirecciones', name: 'ListaDirecciones', component: ListaDirecciones, meta: { Administrador: true } },

  {path: '/Login', name: 'Login', component: Login, meta: { libre: true, }}
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL, 
  routes
})
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.libre)) {
    next()
  } else if (store.state.usuario && store.state.usuario.rol == 'Administrador') {
    if (to.matched.some(record => record.meta.Administrador)) {
      next()
    }
  } else {
    next({
      name: 'Login'
    })
  }
})
export default router
