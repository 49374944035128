<template>
    <div>
        <v-card>
            <v-toolbar color="primary" dark>
                <v-btn icon @click="cerrar()">
                    <v-icon large>mdi-close-circle</v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <h3>Cliente / Receptor</h3>
                <v-spacer></v-spacer>
                <v-btn icon @click="postReceptor()">
                    <v-icon large>mdi-check-circle</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text>
                <span hidden>{{this.$store.state.receptorStore}}</span>
                <v-system-bar> <strong>Datos fiscales</strong></v-system-bar>
                <v-text-field label="RFC" :rules="[rules.required, rules.counter]" counter maxlength="13"
                    v-model="receptor.rfc" hint="RFC del contribuyente" persistent-hint
                    append-outer-icon="mdi-checkbox-marked-circle-outline" @click:append-outer="ValidaRfc()">
                </v-text-field>
                <v-text-field label="Nombre" :rules="[rules.required]" v-model="receptor.nombre"
                    hint="Razón social del contribuyente" persistent-hint counter maxlength="254"></v-text-field>
                <v-text-field label="Domicilio fiscal" :rules="[rules.required]"
                    v-model="receptor.domicilioFiscalReceptor" hint="Código postal" persistent-hint counter
                    maxlength="5"></v-text-field>

                <template v-if="validaRfc">
                    <v-select label="Residencia fiscal" v-model="receptor.residenciaFiscal"></v-select>
                    <v-text-field label="Num. reg. id tributario" v-model="receptor.numRegIdTrib" counter
                        maxlength="40"></v-text-field>
                </template>
                <!-- REGIMEN FISCAL -->
                <template v-if="validaRegimen">
                    <v-select label="Regimen fiscal" :items="itemsRegimenFiscal" :rules="[rules.required]"
                        v-model="receptor.regimenFiscal" return-object item-text="regimenFiscal"></v-select>
                </template>
                <!-- USO DE CFDI -->
                <template v-if="validaUsoCfdi">
                    <v-select label="Uso de CFDI" :items="itemsUsoCfdi" :rules="[rules.required]"
                        v-model="receptor.usoCFDI" return-object item-text="usoCfdi"></v-select>
                </template>

                <v-system-bar><strong>Datos de contacto</strong></v-system-bar>
                <v-data-table :headers="headersContacto" :items="receptor.contactos" class="elevation-1">
                    <!-- CABECERA -->
                    <template v-slot:top>
                        <v-toolbar flat>
                            <v-spacer></v-spacer>
                            <!-- DIALOGA PARA AGREGAR LOS DATOS DE CONTACTO -->
                            <v-dialog v-model="dialogContacto" max-width="500px">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn small fab color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                                        <v-icon>mdi-plus</v-icon>
                                    </v-btn>
                                </template>
                                <v-card>
                                    <v-toolbar color="primary" dark>
                                        <v-btn icon>
                                            <v-icon large @click="close()">mdi-close-circle</v-icon>
                                        </v-btn>
                                        <v-spacer></v-spacer>
                                        <v-btn icon>
                                            <v-icon large @click="agregaContacto()">mdi-check-circle</v-icon>
                                        </v-btn>
                                    </v-toolbar>
                                    <v-card-text>
                                        <v-container>
                                            <v-row>
                                                <v-col cols="12">
                                                    <v-text-field v-model="contacto.destinatario" label="Destinatario"
                                                        :rules="[rules.required]"></v-text-field>
                                                    <v-text-field v-model="contacto.correo" label="Correo"
                                                        :rules="[rules.required, rules.email]"></v-text-field>
                                                    <v-text-field v-model="contacto.telefono" label="Teléfono">
                                                    </v-text-field>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-card-text>
                                </v-card>
                            </v-dialog>
                        </v-toolbar>
                    </template>

                    <!-- ICONOS PARA EDITAR Y BORRAR -->
                    <template v-slot:item.actions="{ item }">
                        <v-icon color="#fbb03b" class="mr-2" @click="editarContacto(item)">mdi-pencil</v-icon>
                        <v-icon color="#e74747" @click="eliminarContacto(item)">mdi-delete</v-icon>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>

        <!-- DIALOG DE QUE ESTA HACIENDO ALGO -->
        <div>
            <v-dialog v-model="dialog" hide-overlay persistent width="300">
                <v-card color="primary" dark>
                    <v-card-text>
                        {{ dialogMessage }}
                        <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </div>

        <!-- SNACKBAR PARA NOTIFICAR LOS ERRORES -->
        <v-snackbar :timeout="5000" v-model="snackbar" color="primary darken-2" elevation="30">{{ snackbarMessage }}
            <template v-slot:action="{ attrs }">
                <v-icon dark @click="snackbar = false">
                    mdi-close-circle
                </v-icon>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
    import axios from 'axios'
    import { mapState } from 'vuex'

    export default {
        name: 'Receptor',
        components: {

        },

        data: () => ({
            itemsRegimenFiscal: [],
            itemsRegimenFiscal_: [],
            itemsUsoCfdi: [],
            itemsUsoCfdi_: [],

            rules: {
                required: value => !!value || 'Obligatorio.',
                counter: value => value.length >= 12 || 'Se necesitan al menos 12 caracteres',
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || 'E-mail inválido.'
                },
            },

            //LOADING DE QUE ESTA HACIENDO ALGO
            dialog: false,
            dialogMessage: '',
            snackbar: false,
            snackbarMessage: '',

            //DATOS DE CONTACTO
            headersContacto: [
                { text: 'Acciones', sortable: false, value: 'actions' },
                { text: 'Destinatario', value: 'destinatario' },
                { text: 'Correo', value: 'correo' },
                { text: 'Teléfono', value: 'telefono' }
            ],
            itemsContacto: [],
            dialogContacto: false,
            editedIndexContacto: -1,
            contacto: {
                destinatario: '',
                correo: '',
                telefono: ''
            },
            defaultContacto: {
                destinatario: '',
                correo: '',
                telefono: ''
            },
        }),

        watch: {

        },
        computed: {
            validaRfc() {
                if (this.rfc === 'XAXX010101000') {
                    this.regimenFiscal = { clave: "616", descripcion: "Sin obligaciones fiscales", fisica: "Sí", moral: "No", regimenFiscal: "616 | Sin obligaciones fiscales" }
                    this.usoCFDI = { clave: "S01", descripcion: "Sin efectos fiscales.", fisica: "Sí", moral: "Sí", usoCfdi: "S01 | Sin efectos fiscales." }
                    return false;
                }
                if (this.rfc === 'XEXX010101000') {
                    return true;
                } else {
                    return false;
                }
            },
            validaRegimen() {
                if (this.receptor.rfc.length === 13) {
                    return this.itemsRegimenFiscal = this.itemsRegimenFiscal_.filter(regimen => regimen.fisica === 'Sí');
                }
                if (this.receptor.rfc.length === 12) {
                    return this.itemsRegimenFiscal = this.itemsRegimenFiscal_.filter(regimen => regimen.moral === 'Sí');
                }
            },

            validaUsoCfdi() {
                if (this.receptor.rfc.length === 13) {
                    return this.itemsUsoCfdi = this.itemsUsoCfdi_.filter(usoCfdi => usoCfdi.fisica === 'Sí');
                }
                if (this.receptor.rfc.length === 12) {
                    return this.itemsUsoCfdi = this.itemsUsoCfdi_.filter(usoCfdi => usoCfdi.moral === 'Sí');
                }
            },

            receptor() {
                return this.$store.state.receptorStore
            },
            datosEmpresa() {
                return this.$store.state.usuario;
            },
            items(){
      return this.$store.state.listReceptorStore;
    },
        },

        mounted() {
            this.GetUsoCdfi();
            this.GetRegimen();
        },

        methods: {
            cerrar() {
                this.$store.state.receptorStore.id = -1
                this.$emit("VentanaNuevoReceptor");
            },
            async GetUsoCdfi() {
                try {
                    let response = await axios.get('CatalogoComprobantes/GetUsocfdi')
                    console.log(response.data)
                    this.itemsUsoCfdi_ = response.data
                } catch (err) {

                }
            },
            async GetRegimen() {
                try {
                    let response = await axios.get('CatalogoComprobantes/GetRegimenFiscal')
                    console.log(response.data)
                    this.itemsRegimenFiscal_ = response.data
                } catch (err) {

                }
            },

            async ValidaRfc() {
                //VERIFICAMOS QUE EL RFC SEA DIFERENTE DE VACIO
                if (this.rfc === '') {
                    this.snackbarMessage = 'Error: Indique un RFC'
                    this.snackbar = true;
                    return
                }

                //VALIDAMOS EL RFC
                this.dialog = true;
                this.dialogMessage = 'Validando RFC espere...'
                try {
                    let respuesta = await axios.get('https://conectame.ddns.net/rest/api.php?m=rfc&user=prueba&pass=sC%7D9pW1Q%5Dc&val=' + this.receptor.rfc)
                    this.receptor.nombre = respuesta.data.RazonSocial
                    this.dialog = false;
                } catch (error) {
                    this.dialog = false;
                    this.receptor.rfc = '';
                    this.snackbar = true;
                    this.snackbarMessage = 'Error al validar RFC, verifique los datos'
                }
            },
            editarContacto(item) {
                this.editedIndexContacto = this.itemsContacto.indexOf(item)
                this.contacto = Object.assign({}, item);
                this.dialogContacto = true;
            },
            agregaContacto() {
                if (!this.contacto.destinatario) {
                    this.snackbar = true
                    this.snackbarMessage = 'Indique un destinatario'
                    return
                }
                if (!this.contacto.correo) {
                    this.snackbar = true
                    this.snackbarMessage = 'Indique el correo'
                    return
                }

                console.log(this.rules.required)
                if (this.editedIndexContacto > -1) {
                    Object.assign(this.itemsContacto[this.editedIndexContacto], this.contacto)
                } else {
                    this.itemsContacto.push(this.contacto)
                }
                this.close()
            },
            close() {
                this.dialogContacto = false
                this.$nextTick(() => {
                    this.contacto = Object.assign({}, this.defaultContacto)
                    this.editedIndexContacto = -1
                })
            },
            eliminarContacto(item) {
                let index = this.itemsContacto.indexOf(item)
                this.itemsContacto.splice(index, 1)
            },
            async listarReceptores() {
                this.loading = true;
                try {
                    let response = await axios.get('Comprobantes/GetReceptor/' + this.datosEmpresa.empresaBase);
                    this.$store.state.listReceptorStore = response.data;
                    console.log(response)
                    this.loading = false;
                } catch (error) {
                    console.log(error);
                }
            },
            async postReceptor() {
                let receptor = Object.assign({}, this.$store.state.receptorStore)
                console.log(receptor)
                if (receptor.id > 0) {
                    try {
                        let response = await axios.post('Comprobantes/UpdateReceptor/' + this.datosEmpresa.empresaBase, receptor)
                        console.log(response.data)
                        //this.receptor = Object.assign({})
                        this.snackbarMessage = 'Se actualizo correctamente'
                        this.snackbar = true
                        this.listarReceptores();
                        this.cerrar();
                    } catch (err) {
                        console.log(err)
                        this.snackbarMessage = 'Error al actualizar'
                        this.snackbar = true
                    }
                } else {
                    try {
                        let response = await axios.post('Comprobantes/PostReceptor/' + this.datosEmpresa.empresaBase, receptor)
                        console.log(response.data)
                        //this.receptor = Object.assign({})
                        this.snackbarMessage = 'Se guardo correctamente'
                        this.snackbar = true
                        this.listarReceptores();
                        this.cerrar();
                    } catch (err) {
                        console.log(err)
                        this.snackbarMessage = 'Error al guardar'
                        this.snackbar = true
                    }
                }

            },
        },
    }
</script>