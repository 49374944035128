<template>
    <div>
        <v-card>
            <v-toolbar color="primary" dark>
                <v-btn icon @click="cerrar()">
                    <v-icon large>mdi-close-circle</v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <h3>Concepto</h3>
                <v-spacer></v-spacer>
                <v-btn icon @click="postConcepto()">
                    <v-icon large>mdi-check-circle</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text>
                <span hidden>{{this.$store.state.conceptoStore}}</span>
                <v-row>
                    <!-- CLAVE DEL PRODUCTO O SERVICIO SAT -->
                    <v-col cols="12">
                        <v-autocomplete label="Clave del producto o servicio" :items="itemsClaveProdServ"
                            :loading="loadingClaveProdServ" :search-input.sync="searchClaveProdServ"
                            item-text="claveProdServ" return-object placeholder="Ingrese el parámetro de bíusqueda"
                            v-model="concepto.claveProdServ"></v-autocomplete>
                    </v-col>
                    <!-- OBJETO DE IMPUESTOS -->
                    <v-col cols="12" md="6" sm="12">
                        <v-select label="Objeto de impuestos" :items="itemsObjetoImp" v-model="concepto.objetoImp"
                            return-object item-text="objetoImp"></v-select>
                    </v-col>
                    <!-- NO DE IDENTIFICACION, CLAVE INTERNA -->
                    <v-col cols="12" md="6" sm="12">
                        <v-text-field label="No. de identificación" v-model="concepto.noIdentificacion"></v-text-field>
                    </v-col>
                    <!-- DESCRIPCION INTERNA DEL PRODUCTO O SERVICIO -->
                    <v-col cols="12">
                        <v-text-field label="Descripción" v-model="concepto.descripcion"></v-text-field>
                    </v-col>
                    <!-- CLAVE DE UNIDAD -->
                    <v-col cols="12" md="6" sm="12">
                        <v-autocomplete label="Clave de unidad" :items="itemsClaveUnidad" :loading="loadingClaveUnidad"
                            :search-input.sync="searchClaveUnidad" item-text="claveUnidad" return-object
                            placeholder="Ingrese el parámetro de bíusqueda" return-object
                            v-model="concepto.claveUnidad"></v-autocomplete>
                    </v-col>
                    <!-- UNIDAD DE MEDIDA INTERNA -->
                    <v-col cols="12" md="6" sm="12">
                        <v-text-field label="Unidad" v-model="concepto.unidad"></v-text-field>
                    </v-col>
                    <!-- VALOR UNITARIO DEL BIEN O SERVICIO -->
                    <v-col cols="4">
                        <v-text-field label="Valor unitario" v-model="concepto.valorUnitario"></v-text-field>
                        <span>{{ formatoDosDecimales(concepto.valorUnitario) }}</span>
                    </v-col>
                    <!-- PRECIO UNITARIO DEL BIEN O SERVICIO -->
                    <v-col cols="4">
                        <v-text-field label="Precio unitario" v-model="concepto.precioUnitario"></v-text-field>
                        <span>{{ formatoDosDecimales(concepto.precioUnitario) }}</span>
                    </v-col>
                    <!-- CUENTA PREDIAL -->
                    <v-col cols="4">
                        <v-text-field label="Cuenta predial" v-model="concepto.cuentaPredial.numero"></v-text-field>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>

        <!-- DIALOG DE QUE ESTA HACIENDO ALGO -->
        <div>
            <v-dialog v-model="dialog" hide-overlay persistent width="300">
                <v-card color="primary" dark>
                    <v-card-text>
                        {{ dialogMessage }}
                        <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </div>

        <!-- SNACKBAR PARA NOTIFICAR LOS ERRORES -->
        <v-snackbar :timeout="5000" v-model="snackbar" color="primary darken-2" elevation="30">{{ snackbarMessage }}
            <template v-slot:action="{ attrs }">
                <v-icon dark @click="snackbar = false">
                    mdi-close-circle
                </v-icon>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
    import axios from 'axios'

    export default {
        name: 'Concepto',
        components: {

        },

        data: () => ({
            //LOADING DE QUE ESTA HACIENDO ALGO
            dialog: false,
            dialogMessage: '',
            snackbar: false,
            snackbarMessage: '',

            //CLAVE DEL PRODUCTO O SERVICIO
            itemsClaveProdServ: [],
            limitClaveProdServ: 60,
            loadingClaveProdServ: false,
            searchClaveProdServ: null,

            //CLAVE DE UNIDAD
            itemsClaveUnidad: [],
            limitClaveUnidad: 60,
            loadingClaveUnidad: false,
            searchClaveUnidad: null,

            itemsObjetoImp: [],
        }),

        watch: {
            async searchClaveProdServ(val) {
                if (this.itemsclaveProdServ.length > 0) return
                if (this.loadingClaveProdServ) return
                this.loadingClaveProdServ = true
                try {
                    let response = await axios.get('CatalogoComprobantes/GetClaveProdServ')
                    this.itemsClaveProdServ = response.data;
                } catch (error) {
                    console.log(error)
                }
                this.loadingClaveProdServ = false
            },
            async searchClaveUnidad(val) {
                if (this.itemsclaveUnidad.length > 0) return
                if (this.loadingClaveUnidad) return
                this.loadingClaveUnidad = true
                try {
                    let response = await axios.get('CatalogoComprobantes/GetClaveUnidad')
                    this.itemsClaveUnidad = response.data;
                } catch (err) {
                    console.log(err)
                }
                this.loadingClaveUnidad = false
            },
        },
        computed: {
            concepto() {
                return this.$store.state.conceptoStore
            },
            datosEmpresa() {
                return this.$store.state.usuario;
            },
            items() {
                return this.$store.state.listConceptosStore;
            },
            itemsclaveProdServ() {
                return this.itemsClaveProdServ.map(entry => {
                    const Description = entry.descripcion.length > this.limitClaveProdServ
                        ? entry.descripcion.slice(0, this.limitClaveProdServ) + '...'
                        : entry.descripcion

                    return Object.assign({}, entry, { Description })
                })
            },
            itemsclaveUnidad() {
                return this.itemsClaveUnidad.map(entry => {
                    const Description = entry.nombre.length > this.limitClaveUnidad
                        ? entry.nombre.slice(0, this.limitClaveUnidad) + '...'
                        : entry.nombre
                    return Object.assign({}, entry, { Description })
                })
            },
        },

        created() {
            this.Get_lista_c_claveprodserv();
            this.Get_lista_c_claveunidadStore();
            this.GetObjetoImp();
        },

        methods: {
            cerrar() {
                this.$store.state.conceptoStore.id = -1
                this.$emit("VentanaNuevoConcepto");
            },
            async Get_lista_c_claveprodserv() {
                if (this.$store.state.lista_c_claveprodservStore.length < 1) {
                    try {
                        let response = await axios.get('CatalogoComprobantes/GetClaveProdServ')
                        this.$store.state.lista_c_claveprodservStore = response.data;
                    } catch (error) {
                        console.log(error)
                    }
                }
                this.itemsClaveProdServ = this.$store.state.lista_c_claveprodservStore.slice();
            },
            async Get_lista_c_claveunidadStore() {
                if (this.$store.state.lista_c_claveunidadStore.length < 1) {
                    try {
                        let response = await axios.get('CatalogoComprobantes/GetClaveUnidad')
                        this.$store.state.lista_c_claveunidadStore = response.data;
                    } catch (error) {
                        console.log(error)
                    }
                }
                this.itemsClaveUnidad = this.$store.state.lista_c_claveunidadStore.slice();
            },
            async GetObjetoImp() {
                try {
                    let response = await axios.get('CatalogoComprobantes/GetObjetoImp')
                    this.itemsObjetoImp = response.data
                } catch (err) {
                    console.log(err)
                }
            },

            async listarConceptos() {
                this.loading = true;
                try {
                    let response = await axios.get('Comprobantes/GetConceptos/' + this.datosEmpresa.empresaBase);
                    this.$store.state.listConceptosStore = response.data;
                    console.log(this.items)
                    this.loading = false;
                } catch (error) {
                    console.log(error);
                }
            },
            //FORMATO NUMERICO
            formatoDosDecimales(value) {
                let val = (value / 1).toFixed(2)
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            },
            async postConcepto() {
                const concepto = Object.assign({}, this.concepto)
                if (concepto.id > 0) {
                    try {
                        let response = await axios.post('Comprobantes/UpdateConcepto/' + this.datosEmpresa.empresaBase, concepto)
                        console.log(response.data);
                        //this.concepto = Object.assign({})
                        this.snackbarMessage = 'Se actualizo correctamente'
                        this.snackbar = true
                        this.$store.state.conceptoStore.id = -1
                        this.listarConceptos();
                        this.cerrar();
                    } catch (err) {
                        console.log(err)
                        this.snackbarMessage = 'Error al actualizar'
                        this.snackbar = true
                    }
                } else {
                    try {
                        let response = await axios.post('Comprobantes/PostConcepto/' + this.datosEmpresa.empresaBase, concepto)
                        console.log(response.data);
                        //this.concepto = Object.assign({})
                        this.snackbarMessage = 'Se guardo correctamente'
                        this.snackbar = true
                        this.$store.state.conceptoStore.id = -1
                        this.listarConceptos();
                        this.cerrar();
                    } catch (err) {
                        console.log(err)
                        this.snackbarMessage = 'Error al guardar'
                        this.snackbar = true
                    }
                }
            }
        },
    }
</script>