<template>
  <div>
    <v-toolbar flat>
      <v-toolbar-title class="dark">
        <h2></h2>
      </v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-card class="justify-center" flat>
      <v-row dense>
        <v-hover v-slot="{ hover }" open-delay="10">
          <v-card :elevation="hover ? 16 : 2" :class="{ 'on-hover': hover }" class="mx-auto justify-center" width="350" link :to="{ name: 'ListaCotizaciones' }"
            @click="$store.state.nombreComponente = 'Cotizaciones'">
            <v-card-text class="text-center">
              <v-icon size="150" color="primary">mdi-calculator</v-icon>
              <h2 class="mt-8">Cotizaciones</h2>
            </v-card-text>
          </v-card>
        </v-hover>

        <v-hover v-slot="{ hover }" open-delay="10">
          <v-card :elevation="hover ? 16 : 2" :class="{ 'on-hover': hover }" class="mx-auto justify-center" width="350" link :to="{ name: 'ListaRemisiones' }"
            @click="$store.state.nombreComponente = 'Remisiones'">
            <v-card-text class="text-center">
              <v-icon size="150" color="primary">mdi-file-table</v-icon>
              <h2 class="mt-8">Remisiones</h2>
            </v-card-text>
          </v-card>
        </v-hover>

      </v-row>
      <v-main>
        <v-container fluid>
          <v-fade-transition mode="out-in">
            <router-view></router-view>
          </v-fade-transition>
        </v-container>
      </v-main>
    </v-card>

  </div>
</template>

<script>
  import axios from 'axios'
  export default {
    name: 'menu',
    components: {

    },

    data: () => ({

    }),
    watch: {

    },
    computed: {

    },
    mounted() {

    },

    methods: {
      averquehace() {
        console.log('Boton de acción')
      },
    }
  }
</script>