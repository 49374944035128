var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app"},[_c('v-toolbar',{attrs:{"flat":"","color":"transparent"}},[_c('v-toolbar-title',{staticClass:"dark"},[_c('h2')]),_c('v-spacer')],1),_c('v-card',{staticClass:"justify-center pt-3 pb-5",attrs:{"flat":"","color":"transparent"}},[_c('div',{staticClass:"text-center mt-4"},[_c('img',{attrs:{"src":require("../assets/logo_cotago facturacion_web_sin_fondo.png"),"height":"150"}})])]),_c('v-card',{staticClass:"justify-center",attrs:{"flat":"","color":"transparent"}},[_c('v-item-group',{attrs:{"mandatory":""}},[_c('v-container',[_c('v-row',{attrs:{"dense":""}},[_c('v-hover',{attrs:{"open-delay":"10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto justify-center mr-5  rounded-lg mb-4",class:{ 'on-hover': hover },attrs:{"elevation":hover ? 16 : 2,"height":"220","width":"220","link":"","to":{ name: 'ListaReceptor' }},on:{"click":function($event){_vm.$store.state.nombreComponente = 'Catalogos/Receptor'}}},[_c('v-card-text',{staticClass:"text-center"},[_c('v-icon',{attrs:{"size":"150","color":"primary"}},[_vm._v("mdi-account-arrow-left")]),_c('h2',{staticClass:"mt-4"},[_vm._v("Cliente / Receptor")])],1)],1)]}}])}),_c('v-hover',{attrs:{"open-delay":"10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto justify-center ml-5 mr-5 rounded-lg mb-4",class:{ 'on-hover': hover },attrs:{"elevation":hover ? 16 : 2,"height":"220","width":"220","link":"","to":{ name: 'ListaConceptos' }},on:{"click":function($event){_vm.$store.state.nombreComponente = 'Catalogos/Conceptos'}}},[_c('v-card-text',{staticClass:"text-center"},[_c('v-icon',{attrs:{"size":"150","color":"primary"}},[_vm._v("mdi-basket")]),_c('h2',{staticClass:"mt-4"},[_vm._v("Conceptos")])],1)],1)]}}])}),_c('v-hover',{attrs:{"open-delay":"10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto justify-center ml-5 mr-5 rounded-lg mb-4",class:{ 'on-hover': hover },attrs:{"elevation":hover ? 16 : 2,"height":"220","width":"220","link":"","to":{ name: 'CatalogosCartaPorte' }},on:{"click":function($event){_vm.$store.state.nombreComponente = 'Catalogos/CartaPorte'}}},[_c('v-card-text',{staticClass:"text-center"},[_c('v-icon',{attrs:{"size":"150","color":"primary"}},[_vm._v("mdi-chart-timeline")]),_c('h2',{staticClass:"mt-4"},[_vm._v("Carta Porte")])],1)],1)]}}])}),_c('v-hover',{attrs:{"open-delay":"10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto justify-center ml-5 rounded-lg mb-4",class:{ 'on-hover': hover },attrs:{"elevation":hover ? 16 : 2,"height":"220","width":"220","link":"","to":{ name: '' }}},[_c('v-card-text',{staticClass:"text-center"},[_c('v-icon',{attrs:{"size":"150","color":"primary"}},[_vm._v("mdi-file-undo-outline")]),_c('h2',{staticClass:"mt-4"},[_vm._v("Comercio Exterior")])],1)],1)]}}])})],1)],1)],1),_c('v-main',[_c('v-container',{attrs:{"fluid":""}},[_c('v-fade-transition',{attrs:{"mode":"out-in"}},[_c('router-view')],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }