<template>
    <div>
        <v-card>
            <v-toolbar color="primary" dark>
                <v-btn icon @click="CerrarVentanaFigTra()">
                    <v-icon large>mdi-close-circle</v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <h3>{{ cabecera }}</h3>
                <span hidden></span>
                <v-spacer></v-spacer>
                <v-btn icon @click="save()">
                    <v-icon large>mdi-check-circle</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text>
                <span hidden>{{figuras}}</span>
                <!-- DATOS GENERALES -->
                <v-system-bar color="blue" dark><strong>DATOS GENERALES</strong></v-system-bar>
                <v-row>
                    <v-col cols="12">
                        <v-select label="Tipo de figura de transporte" :items="itemsTipoFigura"
                            v-model="figuras.tipoFigura" item-text="figuraTransporte"
                            hint=" En este campo se deberá registrar la clave de la figura de transporte conforme al catálogo c_FiguraTransporte del complemento Carta Porte que se encuentra publicado en el Portal de Internet del SAT."
                            persistent-hint return-object></v-select>
                    </v-col>

                    <v-col cols="12">
                        <v-text-field label="RFC de la figura de transporte"
                            hint="En este campo se deberá registrar la clave en el RFC de la figura de transporte que interviene en el traslado de bienes y/o mercancías. Si la figura de transporte que interviene en el traslado de bienes y/o mercancías es un residente en el extranjero, este campo se debe omitir."
                            persistent-hint v-model="figuras.rfcFigura" maxlength="13" counter></v-text-field>
                    </v-col>

                    <v-col cols="12">
                        <v-text-field label="Nombre de figura"
                            hint=": En este campo se deberá registrar el nombre de la figura de transporte que interviene en el traslado de bienes y/o mercancías"
                            persistent-hint v-model="figuras.nombreFigura" maxlength="254" counter>
                        </v-text-field>
                    </v-col>

                    <v-col sm="12">
                        <v-text-field label="Número de licencia"
                            hint="En este campo se deberá registrar el número de la licencia de conducir o permiso otorgado al operador de la unidad en la que se está realizando el traslado de bienes y/o mercancías vía autotransporte."
                            persistent-hint v-model="figuras.numLicencia" maxlength="16" counter>
                        </v-text-field>
                    </v-col>

                    <v-col cols="12" md="6" sm="12">
                        <v-text-field label="Número de identificación o registro fiscal de la figura de transporte"
                            hint="En este campo se podrá registrar el número de identificación fiscal que corresponde al tipo de la figura de transporte."
                            v-model="figuras.numRegIdTribFigura" maxlength="40" counter></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" sm="12">
                        <v-text-field label="Residencia fiscal de la figura de transporte"
                            hint="En este campo podrás registrar la clave del país que corresponde al tipo de figura de transporte, siempre que se haya registrado un valor en el campo “NumRegIdTrib”"
                            v-model="figuras.residenciaFiscalFigura"></v-text-field>
                    </v-col>
                </v-row>
                <!-- PARTES DEL  TRANSPORTE -->
                <template v-if="figuras.tipoFigura.clave === '02' || figuras.tipoFigura.clave === '03'">
                    <v-system-bar color="blue" dark><strong>PARTES DEL TRANSPORTE</strong></v-system-bar>
                    <v-select label="Parte del transporte" :items="itemsParteTransporte" item-text="parteTransporte"
                        v-model="figuras.partesDelTransporte.parteTransporte" return-object></v-select>
                </template>
                <!-- DIRECCION -->
                <v-system-bar color="blue" dark><strong>DIRECCIÓN</strong></v-system-bar>
                <v-select label="Dirección" :items="itemsDirecciones" item-text="direccion"
                    v-model="figuras.domicilio" return-object></v-select>

            </v-card-text>
        </v-card>
        <!-- DIALOG DE QUE ESTA HACIENDO ALGO -->
        <div>
            <v-dialog v-model="dialog" hide-overlay persistent width="300">
                <v-card color="primary" dark>
                    <v-card-text>
                        {{ dialogMessage }}
                        <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </div>
        <!-- SNACKBAR PARA NOTIFICAR LOS ERRORES -->
        <v-snackbar :timeout="5000" v-model="snackbar" color="primary darken-2" elevation="30">{{ snackbarMessage }}
            <template v-slot:action="{ attrs }">
                <v-icon dark @click="snackbar = false">
                    mdi-close-circle
                </v-icon>
            </template>
        </v-snackbar>
    </div>
</template>
<script>
    import axios from 'axios'
    export default {
        name: 'FigurasTransporte',
        components: {

        },

        data: () => ({
            //DIALOG
            dialog: false,
            dialogMessage: '',

            // SNACKBAR
            snackbar: false,
            snackbarMessage: '',

            cabecera: 'Figuras del transporte',
            //GENERALES
            itemsTipoFigura: [],
            tipoFigura: {},
            rFCFigura: '',
            numLicencia: '',
            nombreFigura: '',

            itemsParteTransporte: [],
            parteTransporte: {},

            numRegIdTribFigura: '',
            residenciaFiscalFigura: '',

            itemsDirecciones: [],
            direecion: '',
        }),
        watch: {

        },
        computed: {
            datosEmpresa() {
                return this.$store.state.usuario;
            },
            figuras() {
                return this.$store.state.figurasTransporte
            }

        },
        mounted() {
            this.listarDirecciones();
            this.GetFiguraTransporte();
            this.GetParteTransporte();
        },

        methods: {
            CerrarVentanaFigTra() {
                this.$store.state.figurasTransporte.id = -1

                this.$emit("VentanaNuevaFiguraTransporte");
            },
            async GetFiguraTransporte() {
                try {
                    let response = await axios.get('CatalogosCartaPorte/GetFiguraTransporte');
                    this.itemsTipoFigura = response.data
                } catch (error) {
                    console.log(error)
                }
            },

            async GetParteTransporte() {
                try {
                    let response = await axios.get('CatalogosCartaPorte/GetParteTransporte');
                    this.itemsParteTransporte = response.data
                } catch (error) {
                    console.log(error)
                }
            },

            async listarDirecciones() {
                try {
                    let response = await axios.get('CartaPorte/GetDirecciones/' + this.datosEmpresa.empresaBase);
                    this.itemsDirecciones = response.data
                    console.log(this.itemsDirecciones)
                } catch (error) {
                    console.log(error)
                }
            },

            async save() {
                let parteTransporte_ = ''
                if (this.$store.state.figurasTransporte.tipoFigura.clave === '02' || this.$store.state.figurasTransporte.tipoFigura.clave === '03') {
                    parteTransporte_ = this.$store.state.figurasTransporte.partesDelTransporte.parteTransporte
                }
                let TiposFigura = Object.assign({}, this.$store.state.figurasTransporte)
                console.log(TiposFigura)
                if (this.$store.state.figurasTransporte.id > 0) {
                    console.log("editar")
                        try {
                        let response = await axios.post('CartaPorte/UpdateFiguras/'+ this.datosEmpresa.empresaBase, TiposFigura);
                        this.snackbarMessage = 'Guardado con exito.'
                        this.snackbar = true

                        this.CerrarVentanaFigTra();
                    } catch (error) {
                        console.log(error)
                        this.snackbarMessage = 'Error al actualizar, intentelo de nuevo.'
                        this.snackbar = true
                    }
                } else {
                    console.log("guardar")
                        try {
                        let response = await axios.post('CartaPorte/PostFiguras/'+ this.datosEmpresa.empresaBase, TiposFigura);
                        this.snackbarMessage = 'Guardado con exito.'
                        this.snackbar = true

                        this.CerrarVentanaFigTra();
                    } catch (error) {
                        console.log(error)
                        this.snackbarMessage = 'Error al guardar, intentelo de nuevo.'
                        this.snackbar = true
                    }
                }

            },
        }
    }
</script>