<template>
    <div>
        <v-card>
            <v-toolbar color="primary" dark>
                <v-btn icon @click="CerrarVentanaDes()">
                    <v-icon large>mdi-close-circle</v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <h3>{{ cabecera }}</h3>
                <span hidden></span>
                <v-spacer></v-spacer>
                <v-btn icon @click="PostOrigen()">
                    <v-icon large>mdi-check-circle</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text>
                <span hidden>{{ this.$store.state.destinos }}</span>
                <!-- DATOS GENERALES -->
                <v-system-bar color="blue" dark><strong>DATOS GENERALES</strong></v-system-bar>
                <v-row>
                    <v-col cols="12">
                        <v-text-field label="ID de ubicación"
                            hint="Cuando las mercancías tengan diferentes ubicaciones de tipo origen, se les debe asignar un identificador conformado de 6 dígtos, Ejemplo: 000001 , el acrónimo DE se asigna de manera automática al generar el comprobante"
                            persistent-hint v-model="destinos.iDUbicacion" maxlength="6" counter
                            @blur="validaIdUbicacion"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" sm="12">
                        <v-text-field label="RFC del destinatario"
                            hint="Se deberá registrar el RFC del destinatario, 13 posisiones persona física, 12 posiciones persona moral"
                            persistent-hint v-model="destinos.rfcRemitenteDestinatario" maxlength="13" counter>
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" sm="12">
                        <v-text-field label="Nombre del destinatario"
                            hint=": En este campo se debe de registrar el nombre del destinatario de los bienes y/o mercancías que se trasladan."
                            persistent-hint v-model="destinos.nombreRemitenteDestinatario" maxlength="254" counter>
                        </v-text-field>
                    </v-col>
                    <template v-if="validaRfc">
                        <v-col cols="12" md="6" sm="12">
                            <v-text-field label="Número de identificación o registro fiscal"
                                hint="En este campo se podrán registrar los datos de identificación fiscal del destinatario"
                                v-model="destinos.numRegIdTrib" maxlength="40" counter></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" sm="12">
                            <v-text-field label="Residencia Fiscal"
                                hint="En este campo podrás registrar la clave del país del destinatario, siempre que se haya registrado un valor en el campo “NumRegIdTribFigura” indicado en el aparatdo Tipo de figura"
                                v-model="destinos.residenciaFiscal"></v-text-field>
                        </v-col>
                    </template>
                </v-row>

                <!-- DIRECCION -->
                <v-system-bar color="blue" dark><strong>DIRECCIÓN</strong></v-system-bar>
                <v-row>
                    <v-col cols="12">
                        <v-select label="Pais" :items="itemsPais" v-model="pais" append-outer-icon="mdi-check"
                            item-text="pais"
                            hint="Valide el pais para listar los estados, en caso de ser: México, Estados Unidos o Canadá"
                            persistent-hint @click:append-outer="listarEstados" return-object></v-select>
                    </v-col>
                    <v-col cols="12" md="6" sm="12">
                        <v-select label="Estado" :items="itemsEstado" v-model="destinos.domicilio.estado"
                            item-text="estado" return-object></v-select>
                        <span>{{estado}}</span>
                    </v-col>
                    <v-col cols="12" md="6" sm="12">
                        <v-text-field label="Código postal" v-model="destinos.domicilio.codigoPostal"
                            append-outer-icon="mdi-check"
                            hint="Valide el código postal para listar los municipíos, localidades y colonias"
                            persistent-hint @click:append-outer="validaCodigoPostal"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" sm="12">
                        <v-select label="Municipio" :items="itemsMunicipio" v-model="destinos.domicilio.municipio"
                            item-text="municipio" return-object></v-select>
                    </v-col>
                    <v-col cols="12" md="6" sm="12">
                        <v-select label="Localidad" :items="itemsLocalidad" v-model="destinos.domicilio.localidad"
                            item-text="localidad" return-object></v-select>
                    </v-col>
                    <v-col cols="12">
                        <v-select label="Colonia" :items="itemsColonia" v-model="destinos.domicilio.colonia"
                            item-text="colonia" return-object></v-select>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field label="Calle" v-model="destinos.domicilio.calle"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" sm="12">
                        <v-text-field label="Número exterior" v-model="destinos.domicilio.numeroExterior">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" sm="12">
                        <v-text-field label="Número interior" v-model="destinos.domicilio.numeroInterior">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field label="Referencia" v-model="destinos.domicilio.referencia"></v-text-field>
                    </v-col>
                </v-row>
                <span hidden>AAA:{{ojalaFuncione}}</span>

            </v-card-text>
        </v-card>

        <!-- DIALOG DE QUE ESTA HACIENDO ALGO -->
        <div>
            <v-dialog v-model="dialog" hide-overlay persistent width="300">
                <v-card color="primary" dark>
                    <v-card-text>
                        {{ dialogMessage }}
                        <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </div>

        <!-- SNACKBAR PARA NOTIFICAR LOS ERRORES -->
        <v-snackbar :timeout="5000" v-model="snackbar" color="primary darken-2" elevation="30">{{ snackbarMessage }}
            <template v-slot:action="{ attrs }">
                <v-icon dark @click="snackbar = false">
                    mdi-close-circle
                </v-icon>
            </template>
        </v-snackbar>
    </div>
</template>
<script>
    import axios from 'axios'
    export default {
        name: 'UbicacionOrigenDestino',
        components: {

        },

        data: () => ({
            //DIALOG
            dialog: false,
            dialogMessage: '',

            // SNACKBAR
            snackbar: false,
            snackbarMessage: '',

            cabecera: 'Destino',
            //GENERALES
            iDUbicacion: '',
            tipoUbicacion: '',
            rfcRemitenteDestinatario: '',
            nombreRemitenteDestinatario: '',
            numRegIdTrib: '',
            residenciaFiscal: '',

            //DIRECCION
            pais: { clave: 'MEX', descripcion: 'México', pais: 'MEX | México' },
            itemsPais: [],
            codigoPostal: '',
            estado: '',
            itemsEstado: [],
            municipio: '',
            itemsMunicipio: [],
            referencia: '',
            localidad: '',
            itemsLocalidad: [],
            colonia: '',
            itemsColonia: [],
            numeroInterior: '',
            numeroExterior: '',
            calle: '',
        }),
        created(){
            // this.validaCodigoPostal()
        },

        watch: {

        },
        computed: {
            validaRfc() {
                if (this.rfcRemitenteDestinatario == 'XEXX010101000') {
                    return true;
                } else {
                    return false;
                }
            },
            datosEmpresa() {
                return this.$store.state.usuario;
            },
            destinos() {
                return this.$store.state.destinos;
            },

            ojalaFuncione(){
                console.log('entra');
                let destinos = this.$store.state.destinos;
                if (this.destinos.domicilio.pais.clave === 'MEX') {
                    axios
                    .get('CatalogosCartaPorte/GetCodigoPostal/' + destinos.domicilio.codigoPostal + '/' + destinos.domicilio.estado.clave)
                    .then(result => {
                        let response = result;
                        this.listarMunicipio(destinos.domicilio.estado.clave, response.data.c_Municipio);
                        this.listarColonia(destinos.domicilio.codigoPostal);
                        this.listarLocalidad(destinos.domicilio.estado.clave, response.data.c_Localidad);
                        return 'SI'
                    })
                    .catch(error => {
                        console.log(error);
                        return 'NO'
                    });
                }
            }
        },
        mounted() {
            this.listarPaises();
            this.listarEstados();
        },

        methods: {
            CerrarVentanaDes() {
                this.$store.state.destinos.id = -1
                this.$emit("VentanaDestino");
            },
            async listarPaises() {
                try {
                    let response = await axios.get('CatalogosCartaPorte/GetPaises');
                    this.itemsPais = response.data
                    console.log(response.data)
                } catch (error) {
                    console.log(error)
                }
            },

            async listarEstados() {
                this.dialogMessage = 'Validando país'
                this.dialog = true;
                try {
                    let response = await axios.get('CatalogosCartaPorte/GetEstado/' + this.destinos.domicilio.pais.clave);
                    this.itemsEstado = response.data
                    this.dialog = false;
                } catch (error) {
                    console.log(error)
                    this.dialog = false;
                }
            },

            async validaCodigoPostal() {
                if (this.destinos.domicilio.codigoPostal == '' || !this.destinos.domicilio.codigoPostal) {
                    this.snackbarMessage = 'Indique un código postal para continuar'
                    this.snackbar = true
                    return
                }

                if (!this.destinos.domicilio.estado) {
                    this.snackbarMessage = 'Indique un estado para continuar'
                    this.snackbar = true
                    return
                }

                if (this.destinos.domicilio.pais.clave === 'MEX') {
                    try {
                        let response = await axios.get('CatalogosCartaPorte/GetCodigoPostal/' + this.destinos.domicilio.codigoPostal + '/' + this.destinos.domicilio.estado.clave);
                        this.listarMunicipio(this.destinos.domicilio.estado.clave, response.data.c_Municipio);
                        this.listarLocalidad(this.destinos.domicilio.estado.clave, response.data.c_Localidad);
                        this.listarColonia(this.destinos.domicilio.codigoPostal);
                    } catch (error) {
                        console.log(error)
                    }
                }
            },
            async listarMunicipio(estado, municipio) {
                console.log(estado, municipio)
                try {
                    let response = await axios.get('CatalogosCartaPorte/GetMunicipio/' + estado + '/' + municipio);
                    this.itemsMunicipio = response.data
                } catch (error) {
                    console.log(error)
                }
            },

            async listarLocalidad(estado, localidad) {
                try {
                    let response = await axios.get('CatalogosCartaPorte/GetLocalidad/' + estado + '/' + localidad);
                    this.itemsLocalidad = response.data
                } catch (error) {
                    console.log(error)
                }
            },

            async listarColonia(codigoPostal) {
                try {
                    let response = await axios.get('CatalogosCartaPorte/GetColonia/' + codigoPostal);
                    this.itemsColonia = response.data
                } catch (error) {
                    console.log(error)
                }
            },

            validaIdUbicacion() {
                if (this.destinos.iDUbicacion) {
                    try {
                        let variable = this.destinos.iDUbicacion
                        let contador = this.destinos.iDUbicacion.length
                        for (var i = 0; i < 6 - contador; i++) {
                            variable = '0' + variable
                        }
                        this.destinos.iDUbicacion = variable
                    } catch (err) {
                        console.log('Error: ' + err)
                    }
                }
            },

            async PostOrigen() {
                if (this.destinos.iDUbicacion == "" || !this.destinos.iDUbicacion) {
                    this.snackbarMessage = 'Indique ID de ubicación para continuar'
                    this.snackbar = true
                    return;
                }
                if (this.destinos.rfcRemitenteDestinatario == "" || !this.destinos.rfcRemitenteDestinatario) {
                    this.snackbarMessage = 'Indique el RFC destinatario para continuar'
                    this.snackbar = true
                    return;
                }
                if (this.destinos.nombreRemitenteDestinatario == "" || !this.destinos.nombreRemitenteDestinatario) {
                    this.snackbarMessage = 'Indique el Nombre del destinatario para continuar'
                    this.snackbar = true
                    return;
                }
                if (this.destinos.domicilio.estado == "" || !this.destinos.domicilio.estado) {
                    this.snackbarMessage = 'Indique el estado para continuar'
                    this.snackbar = true
                    return;
                }
                if (this.destinos.domicilio.pais.pais == "" || !this.destinos.domicilio.pais.pais) {
                    this.snackbarMessage = 'Indique el pais para continuar'
                    this.snackbar = true
                    return;
                }
                if (this.destinos.domicilio.codigoPostal == "" || !this.destinos.domicilio.codigoPostal) {
                    this.snackbarMessage = 'Indique el Codigo Postal para continuar'
                    this.snackbar = true
                    return;
                }
                if (this.destinos.domicilio.municipio == "" || !this.destinos.domicilio.municipio) {
                    this.snackbarMessage = 'Indique el Municipio para continuar'
                    this.snackbar = true
                    return;
                }
                if (!this.destinos.domicilio.localidad) {
                    this.destinos.domicilio.localidad = {}
                }
                if (this.destinos.domicilio.colonia == "" || !this.destinos.domicilio.colonia) {
                    this.snackbarMessage = 'Indique la colonia para continuar'
                    this.snackbar = true
                    return;
                }
                this.$store.state.destinos.tipoUbicacion = 'DE'
                let destino = Object.assign({}, this.$store.state.destinos)

                if (this.$store.state.destinos.id > 0) {
                    console.log('editar')
                    try {
                        let response = await axios.post('CartaPorte/UpdateUbicacion/' + this.datosEmpresa.empresaBase + '/', destino);
                        console.log(response.data)
                        this.snackbarMessage = 'Actualizado con exito.'
                        this.snackbar = true
                        this.$store.state.destinos.id = -1
                        this.CerrarVentanaDes();
                    } catch (error) {
                        console.log(error)
                        this.snackbarMessage = 'Error al actulizar, intentelo de nuevo.'
                        this.snackbar = true
                    }
                } else {
                    try {
                        let response = await axios.post('CartaPorte/PostUbicacion/' + this.datosEmpresa.empresaBase, destino);
                        console.log(response)
                        this.snackbarMessage = 'Guardado con exito.'
                        this.snackbar = true
                        this.$store.state.destinos.id = -1

                        this.CerrarVentanaDes();
                    } catch (error) {
                        console.log(error)
                        this.snackbarMessage = 'Error al guardar, intentelo de nuevo.'
                        this.snackbar = true
                    }
                }

            },
        }
    }
</script>