<template>
    <div>
        <v-card>
            <v-card-text>
                <!-- SUMAS TOTAL IMPUESTOS -->
                <v-row>
                    <template v-if="comprobante.totales.totalRetencionesIVA != 0">
                        <v-col cols="12" md="4" sm="12">
                            <v-text-field label="TotalRetencionesIVA" v-model="comprobante.totales.totalRetencionesIVA" readonly></v-text-field>
                        </v-col>
                    </template>
                    <template v-if="comprobante.totales.totalRetencionesISR">
                        <v-col cols="12" md="4" sm="12">
                            <v-text-field label="TotalRetencionesISR" v-model="comprobante.totales.totalRetencionesISR" readonly></v-text-field>
                        </v-col>
                    </template>
                    <template v-if="comprobante.totales.totalRetencionesIEPS">
                        <v-col cols="12" md="4" sm="12">
                            <v-text-field label="TotalRetencionesIEPS" v-model="comprobante.totales.totalRetencionesIEPS" readonly></v-text-field>
                        </v-col>
                    </template>
                </v-row>
                <!-- SUMAS POR IMPUESTO IVA -->
                <v-row>
                    <template v-if="comprobante.totales.totalTrasladosBaseIVA16 != 0">
                        <v-col cols="12" md="4" sm="12">
                            <v-text-field label="TotalTrasladosBaseIVA16" v-model="comprobante.totales.totalTrasladosBaseIVA16" readonly></v-text-field>
                        </v-col>
                    </template>
                    <template v-if="comprobante.totales.totalTrasladosImpuestoIVA16 != 0">
                        <v-col cols="12" md="4" sm="12">
                            <v-text-field label="TotalTrasladosImpuestoIVA16" v-model="comprobante.totales.totalTrasladosImpuestoIVA16" readonly></v-text-field>
                        </v-col>
                    </template>
                    <template v-if="comprobante.totales.totalTrasladosBaseIVA8 != 0">
                        <v-col cols="12" md="4" sm="12">
                            <v-text-field label="TotalTrasladosBaseIVA8" v-model="comprobante.totales.totalTrasladosBaseIVA8" readonly></v-text-field>
                        </v-col>
                    </template>
                    <template v-if="comprobante.totales.totalTrasladosImpuestoIVA8 != 0">
                        <v-col cols="12" md="4" sm="12">
                            <v-text-field label="TotalTrasladosImpuestoIVA8" v-model="comprobante.totales.totalTrasladosImpuestoIVA8" readonly></v-text-field>
                        </v-col>
                    </template>
                    <template v-if="comprobante.totales.totalTrasladosBaseIVA0 != 0">
                        <v-col cols="12" md="4" sm="12">
                            <v-text-field label="TotalTrasladosBaseIVA0" v-model="comprobante.totales.totalTrasladosBaseIVA0" readonly></v-text-field>
                        </v-col>
                    </template>
                    <template v-if="comprobante.totales.totalTrasladosImpuestoIVA0 != 0">
                        <v-col cols="12" md="4" sm="12">
                            <v-text-field label="TotalTrasladosImpuestoIVA0" v-model="comprobante.totales.totalTrasladosImpuestoIVA0" readonly></v-text-field>
                        </v-col>
                    </template>
                    <template v-if="comprobante.totales.totalTrasladosBaseIVAExento != 0">
                        <v-col cols="12" md="4" sm="12">
                            <v-text-field label="TotalTrasladosBaseIVAExento" v-model="comprobante.totales.totalTrasladosBaseIVAExento" readonly></v-text-field>
                        </v-col>
                    </template>
                </v-row>
                <!-- SUMA DE PAGOS -->
                <v-row>
                    <v-col cols="12" md="4" sm="12">
                        <v-text-field label="MontoTotalPagos" v-model="comprobante.totales.montoTotalPagos" readonly></v-text-field>
                    </v-col>
                </v-row>
                <!-- DATOS DE CADA PAGO -->
                <v-row>
                    <!-- FECHA DE PAGO -->
                    <v-col cols="12" md="3" sm="6">
                        <v-datetime-picker label="Fecha de Pago" v-model="fechaPago">
                            <template slot="dateIcon">
                                <v-icon>mdi-calendar</v-icon>
                            </template>
                            <template slot="timeIcon">
                                <v-icon>mdi-clock-time-six</v-icon>
                            </template>
                            <template slot="actions" slot-scope="{ parent }">
                                <v-btn color="primary" @click="parent.okHandler">Ok</v-btn>
                            </template>
                        </v-datetime-picker>
                    </v-col>
                    <!-- FORMA DE PAGO -->
                    <v-col cols="12" md="3" sm="12">
                        <v-select label="Forma de pago" :items="itemsFormaPago" v-model="comprobante.pagos[0].formaDePagoP" item-text="formaPago" return-object></v-select>
                    </v-col>
                    <!-- MONEDA -->
                    <v-col cols="12" md="3" sm="12">
                        <v-select label="Moneda" :items="itemsMonedas" v-model="comprobante.pagos[0].monedaP" @change="ValidaMoneda()" item-text="moneda" return-object></v-select>
                    </v-col>
                    <!-- TIPO DE CAMBIO -->
                    <v-col cols="12" md="3" sm="12">
                        <v-text-field label="Tipo de cambio" v-model="comprobante.pagos[0].tipoCambioP" type="number" :readonly="enabledTipoCambio"></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-card>
                            <v-card-title>
                                Documentos Relacionados
                            <v-spacer></v-spacer>
                            <!-- AGREGAR DOCUMENTO RELACIONADO -->
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon v-bind="attrs" v-on="on" class="mx-2" @click="AbreDialogPagos()">
                                    <v-icon color="green" dark>mdi-plus</v-icon>
                                    </v-btn>
                                </template>
                                <span>Agregar</span>
                            </v-tooltip>
                            </v-card-title>
                            <v-card-text>
                                <v-data-table :items="comprobante.pagos[0].doctoRelacionados" :headers="headersDocumentoRelacionado">
                                    <!-- FORMATO CON DECIMALES DEL IMPORTE -->
                                    <template v-slot:item.impSaldoAnt="{ item }">
                                        <span>{{ functionFormatPrice(item.impSaldoAnt) }}</span>
                                    </template>
                                    <template v-slot:item.impPagado="{ item }">
                                        <span>{{ functionFormatPrice(item.impPagado) }}</span>
                                    </template>
                                    <template v-slot:item.impSaldoInsoluto="{ item }">
                                        <span>{{ functionFormatPrice(item.impSaldoInsoluto) }}</span>
                                    </template>
                                    <!-- ACCIONES -->
                                    <template v-slot:[`item.actions`]="{ item }">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                            <v-icon color="red" dense class="mr-2" v-bind="attrs" v-on="on" dark @click="deletePago(item)">mdi-delete</v-icon>
                                            </template>
                                            <span>Borrar pago</span>
                                        </v-tooltip>
                                    </template>
                                </v-data-table>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>

        <!-- DIALOG PARA AGREGAR PAGOS -->
        <v-dialog v-model="dialogPagos" persistent>
            <v-card>
                <v-toolbar>
                    <v-btn fab icon @click="dialogPagos=false" color="primary darken-1">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-toolbar-title class="text-center">Seleccionar comprobante a cobrar <br>{{this.$store.state.complementoPagoStore.receptor.receptor}}</v-toolbar-title>
                    <v-spacer></v-spacer>
                </v-toolbar>
                <v-system-bar color="primary" height="6"></v-system-bar>
                <v-card-text>
                    <v-text-field label="Filtrar"></v-text-field>
                    <v-data-table :items="itemsComprobantePagos" :headers="headersComprobantePagos" item-key="resultado.folioFiscal">
                        <!-- FORMATO CON DECIMALES DEL IMPORTE -->
                        <template v-slot:item.impComprobante="{ item }">
                            <span>{{ functionFormatPrice(item.impComprobante) }}</span>
                        </template>
                        <!-- FORMATO CON DECIMALES DEL IMPORTE -->
                        <template v-slot:item.impNotaCredito="{ item }">
                            <span>{{ functionFormatPrice(item.impNotaCredito) }}</span>
                        </template>
                        <template v-slot:item.impSaldoAnt="{ item }">
                            <span>{{ functionFormatPrice(item.impSaldoAnt) }}</span>
                        </template>
                        <template v-slot:item.impSaldoInsoluto="{ item }">
                            <span>{{ functionFormatPrice(item.impSaldoInsoluto) }}</span>
                        </template>
                        <!-- BOTON DE PAGO -->
                        <template v-slot:[`item.actions`]="{ item }">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon color="green" dense class="mr-2" v-bind="attrs" v-on="on" dark @click="abreDialogMontoCobro(item)">mdi-cash-multiple</v-icon>
                                </template>
                                <span>Cobrar</span>
                              </v-tooltip>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-dialog>
        <!-- DIALOG DONDE SE INDICA EL MONTO A PAGAR -->
        <v-dialog v-model="dialogMonto" persistent width="700">
            <v-card>
                <v-toolbar>
                    <v-btn fab icon @click="dialogMonto=false" color="primary darken-1">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-toolbar-title>Indique el importe a cobrar</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn fab icon color="primary darken-1" @click="putListaPagos()">
                        <v-icon>mdi-check</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-system-bar color="primary" height="6" ></v-system-bar>
                <v-card-text>
                    <br>
                    <v-row>
                        <v-col cols="6">
                            <v-text-field label="Serie" v-model="comprobanteAPagar.serie" readonly></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field label="Folio" v-model="comprobanteAPagar.folio" readonly></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field label="Folio Fiscal" v-model="comprobanteAPagar.idDocumento" readonly></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <vuetify-money label="Saldo Anterior" :options="options" v-model="comprobanteAPagar.impSaldoAnt" readonly></vuetify-money>
                        </v-col>
                        <v-col cols="6">
                            <vuetify-money label="Saldo Insoluto" :options="options" v-model="comprobanteAPagar.impSaldoInsoluto" readonly></vuetify-money>
                        </v-col>
                        <v-col cols="6">
                            <v-select label="Moneda" :items="itemsMonedas" v-model="comprobanteAPagar.monedaDR" item-text="moneda" return-object readonly></v-select>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field label="Equivalencia" v-model="comprobanteAPagar.equivalenciaDR"></v-text-field>
                        </v-col>
                        <v-col cols="8">
                            <v-text-field label="Importe a Cobrar" v-model="comprobanteAPagar.impPagado" @keydown.enter="putListaPagos()" append-outer-icon="mdi-check" @click:append-outer="putListaPagos()"></v-text-field>
                            <span>{{ functionFormatPrice(comprobanteAPagar.impPagado) }}</span>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
            <!-- SANCKBAR PARA MENSAJES -->
            <div>
                <v-snackbar :timeout="5000" v-model="snackbar" color="primary darken-2" elevation="30" absolute>{{ snackbarMessage }}
                    <template v-slot:action="{ attrs }">
                    <v-icon dark @click="snackbar = false">
                        mdi-close-circle
                    </v-icon>
                </template>
                </v-snackbar>
            </div>
        </v-dialog>
        <!-- DIALOG DE QUE ESTA HACIENDO ALGO -->
        <div>
            <v-dialog v-model="dialog" hide-overlay persistent width="300">
                <v-card color="primary" dark>
                    <v-card-text>
                        {{ dialogMessage }}
                        <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </div>
    </div>
</template>
<script>
    import axios from 'axios'
    import moment from 'moment';
    import { format, parseISO } from 'date-fns';
    import print from 'print-js';
    import pdf from 'vue-pdf';
    import { VueEditor } from "vue2-editor";
    import Vue from 'vue'
    import VueCookies from 'vue-cookies'
    import DatetimePicker from 'vuetify-datetime-picker'
    import VuetifyMoney from "vuetify-money";
    
    Vue.use(VuetifyMoney);
    Vue.use(DatetimePicker)    
    Vue.use(VueCookies)

    export default {
        name: 'ComplementoPagoDocumentos',
        components: {
            print,
            VueEditor,
            pdf: pdf,
        },

        data: () => ({

            // formaDePagoP:{},
            itemsFormaPago: [],
            itemsMonedas:[],
            enabledTipoCambio: false,
            monto: 0.00,
            numOperacion: '',

            rfcEmisorCtaOrd: '',
            nomBancoOrdExt: '',
            ctaOrdenante: '',
            rfcEmisorCtaBen: '',
            ctaBeneficiario: '',
            tipoCadPago: {
                clave: '',
                descripcion: '',
                tipoCadenaPago: '',
            },
            certPago: '',
            cadPago: '',
            selloPago: '',

            //DETALLES DE LOS PAGOS
            dialogPagos: false,
            itemsComprobantePagos: [],
            headersComprobantePagos:[
                { text: 'Acciones', value: 'actions'},
                { text: "Serie", value: "serie" },
                { text: "Folio", value: "folio" },
                { text: "Fecha Comprobante", value: "fechaComprobante" },
                // { text: "Saldo Anterior", value: "impSaldoAnt", align: 'right' },
                { text: "Saldo Insoluto", value: "impSaldoInsoluto", align: 'right' },
                { text: "Total Facturado", value: "impComprobante", align: 'right' },
                { text: "NC", value: "impNotaCredito", align: 'right' },
                { text: "Moneda", value: "monedaDR.clave" },
                { text: "Folio Fiscal", value: "idDocumento" },
            ],
            headersDocumentoRelacionado:[
                { text: 'Acciones', value: 'actions'},
                { text: "Id Documento", value: "idDocumento" },
                { text: "Serie", value: "serie" },
                { text: "Folio", value: "folio" },
                { text: "Moneda DR", value: "monedaDR.clave" },
                { text: "Equivalencia DR", value: "equivalenciaDR" },
                { text: "Num Parcialidad", value: "numParcialidad" },
                { text: "Saldo Anterior", value: "impSaldoAnt", align: 'right' },
                { text: "Importe Pagado", value: "impPagado", align: 'right' },
                { text: "Saldo Insoluto", value: "impSaldoInsoluto", align: 'right' },
                { text: "Objeto Imp DR", value: "objetoImpDR.descripcion", align: 'right' },
            ],
            dialogMonto: false,
            comprobanteAPagar:{
                idDocumento: '',
                serie: '',
                folio: '',
                monedaDr: {},
                equivalenciaDr: 0,
                numParcialidad: 1,
                impSaldoAnt: 0,
                impPagado: 0,
                impSaldoInsoluto: 0,
                objetoImpDR: {},
                impuestosDR:{
                    retencionesDR:[],
                    trasladosDR:[],
                },
                impuestosComprobante:{},
            },

            //DIALOG
            dialog: false,
            dialogMessage: '',

            //OPCIONES
            options: {
                locale: "en-US",
                prefix: "",
                suffix: "",
                length: 11,
                precision: 2
            },
            
            //SNACKBAR
            snackbar: false,
            snackbarMessage: '',

            //LISTA DE RETENIDOS Y TRASLADADOS
            listRetenidos: [],
            listTrasladados: [],
        }),

        watch: {
            
        },

        computed: {
            datosEmpresa() {
                return this.$store.state.usuario;
            },
            fechaPago(){
                let a = new Date()
                let fecha =  moment(a).format('yyyy-MM-DD') + ' 12:00'
                console.log(fecha)
                return fecha
            },
            comprobante(){
                console.log(this.$store.state.complementoPagoStore.pagos)
                return this.$store.state.complementoPagoStore.pagos
            },
            
        },

        created() {
            this.GetFormaPago()
            this.GetMoneda()
        },

        methods: {
            functionFormatPrice(value) {
                let val = (value/1).toFixed(2)
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            },
            async GetFormaPago() {
                try {
                    let response = await axios.get('CatalogoComprobantes/GetFormaPago')
                    let lista = response.data
                    lista.splice(21, 1)
                    this.itemsFormaPago = lista
                } catch (err) {
                    console.log(err)
                }
            },
            async GetMoneda() {
                try {
                    let response = await axios.get('CatalogoComprobantes/GetMoneda')
                    this.itemsMonedas = response.data
                } catch (err) {
                    console.log(err)
                }
            },
            ValidaMoneda() {
                if (this.comprobante.pagos[0].monedaP.clave === 'MXN') {
                    this.enabledTipoCambio = true;
                    this.comprobante.pagos[0].tipoCambioP = "1"
                }
                else {
                    this.enabledTipoCambio = false;
                    this.comprobante.pagos[0].tipoCambioP = ""
                }
            },
            async GetComprobantePagos(){
                this.dialog = true
                this.dialogMessage = 'Consultando comprobantes pendientes'
                try {
                    let response = await axios.get('ComplementoDePago/GetPendientes/'+ this.datosEmpresa.empresaBase +'/'+ this.datosEmpresa.rfc +'/' + this.$store.state.complementoPagoStore.receptor.rfc + '/' + this.comprobante.pagos[0].monedaP.clave)
                    // console.log(response.data)
                    for(var c of response.data){
                        c.monedaDR = this.comprobante.pagos[0].monedaP
                    }
                    this.itemsComprobantePagos = response.data
                    this.dialog = false
                } catch (err) {
                    console.log(err)
                    this.dialog = false
                }
            },
            AbreDialogPagos(){
                this.GetComprobantePagos();
                this.dialogPagos = true;
            },
            abreDialogMontoCobro(item){
                item.impPagado = item.impSaldoInsoluto
                this.comprobanteAPagar = Object.assign( {}, item )
                this.dialogMonto = true
            },
            putListaPagos(){
                this.snackbar = false;
                let comprobante = Object.assign( {} , this.comprobanteAPagar)
                let pago =  Number(comprobante.impPagado);
                const saldoInsoluto = Number(comprobante.impSaldoInsoluto);
                const total = Number(comprobante.impComprobante - comprobante.impNotaCredito);
                const porcentaje = Math.round((pago / total)*1000000)/1000000;
                //VALIDAMOS QUE NO EXEDA EL PAGO, NI QUE SEA MENOS A 0
                if(pago <= 0){
                    this.snackbar = true;
                    this.snackbarMessage ="Indique un importe mayor o diferente a 0"
                    return
                }
                if(pago > saldoInsoluto){
                    this.snackbar = true;
                    this.snackbarMessage ="El pago debe de ser menor al saldo insoluto"
                    return
                }

                //VALIDAMOS QUE EL COMPROBANTE NO EXISTA EN LA LISTA
                let validaDocumento = this.comprobante.pagos[0].doctoRelacionados.find(x => x.idDocumento === comprobante.idDocumento)
                if(validaDocumento){
                    this.snackbar = true;
                    this.snackbarMessage ="El documento ya cuenta con un pago registrado"
                    return
                }

                //CAPTURAMOS EL INDICE DEL COMPROBANTE PARA QUITARLO DE LA LISTA
                let editedIndex = this.itemsComprobantePagos.findIndex(x => x.idDocumento === comprobante.idDocumento)
                // console.log(editedIndex)

                // comprobante.impPagado = this.comprobanteAPagar.impPagado
                comprobante.impPagado = pago
                comprobante.impSaldoAnt = comprobante.impSaldoAnt
                comprobante.impSaldoInsoluto = comprobante.impSaldoAnt - pago;
                comprobante.numParcialidad = comprobante.numParcialidad + 1

                // RECORREMOS LOS IMPUESTOS RETENIDOS
                for(var retenidos of comprobante.impuestosComprobante.retencionesDR){
                    var importe = Math.round(retenidos.importeDR * porcentaje * 100)/100
                    var base = Math.round(retenidos.baseDR * porcentaje*100)/100
                    let r  = {
                        baseDR: base,
                        importeDR: importe,
                        impuestoDR: retenidos.impuestoDR, 
                        tasaOCuotaDR: retenidos.tasaOCuotaDR,
                        tipoFactorDR: retenidos.tipoFactorDR,
                    }
                    comprobante.impuestosDR.retencionesDR.push(r);
                    this.listRetenidos.push(r)
                    r = {}
                }
                //RECORREMOS LOS IMPUESTOS TRASNLADADOS
                for(var trasladados of comprobante.impuestosComprobante.trasladosDR){
                    var importe = Math.round(trasladados.importeDR * porcentaje * 100)/100
                    var base = Math.round(trasladados.baseDR * porcentaje*100)/100
                    let t  = {
                        baseDR: base,
                        importeDR: importe,
                        impuestoDR: trasladados.impuestoDR, 
                        tasaOCuotaDR: trasladados.tasaOCuotaDR,
                        tipoFactorDR: trasladados.tipoFactorDR,
                    }
                    comprobante.impuestosDR.trasladosDR.push(t);
                    this.listTrasladados.push(t);
                    t = {}
                }
                
                //AGREGAMOS EL PAGO A LA LISTA DE COMPROBANTES
                this.comprobante.pagos[0].doctoRelacionados.push(comprobante)

                //SUMATORIA DE LOS IMPUESTOS
                this.comprobante.pagos[0].impuestosP.retencionesP.retencionP = []
                var sumRetenidos = this.agrupaRetenidos(this.listRetenidos, 'impuestoDR');
                for (const prop in sumRetenidos) {
                    const ret = Object.assign({}, sumRetenidos[prop])
                    this.comprobante.pagos[0].impuestosP.retencionesP.retencionP.push(ret)
                }
                
                this.comprobante.pagos[0].impuestosP.trasladosP.trasladoP = []
                var sumTrasladados = this.agrupaTrasladados(this.listTrasladados, 'impuestoDR', 'tasaOCuotaDR', 'tipoFactorDR');
                for (const prop in sumTrasladados) {
                    const tra = Object.assign({}, sumTrasladados[prop])
                    this.comprobante.pagos[0].impuestosP.trasladosP.trasladoP.push(tra)
                }
                //SUMAMOS LOS TOTALES
                this.sumasTotales();
                //BORRAMOS EL COMPORBANTE PAGADO
                comprobante = {};
                this.itemsComprobantePagos.splice(editedIndex, 1)
                this.dialogMonto = false

                console.log(this.comprobante)
            },
            agrupaTrasladados(miarray, propA, propB, propC) {
                return miarray.reduce(function (groups, item) {
                    var val = item[propA, propB, propC];

                    groups[val] = groups[val] || { baseP: 0, importeP: 0, impuestoP: item.impuestoDR, tasaOCuotaP: item.tasaOCuotaDR, tipoFactorP: item.tipoFactorDR };

                    groups[val].baseP += item.baseDR;
                    groups[val].importeP += item.importeDR;
                    groups[val].importeP = Math.round(groups[val].importeP*100)/100;
                    groups[val].baseP = Math.round(groups[val].baseP*100)/100;
                    return groups;
                }, {});
            },
            agrupaRetenidos(miarray, propA) {
                return miarray.reduce(function (groups, item) {
                    var val = item[propA];

                    groups[val] = groups[val] || { importeP: 0, impuestoP: item.impuestoDR };

                    groups[val].importeP += item.importeDR;
                    groups[val].importeP = Math.round(groups[val].importeP*100)/100;
                    return groups;
                }, {});
            },
            sumasTotales(){
                //RETENIDOS
                var retencionIva = this.comprobante.pagos[0].impuestosP.retencionesP.retencionP.filter(x => x.impuestoP === '002')
                var retencionIsr = this.comprobante.pagos[0].impuestosP.retencionesP.retencionP.filter(x => x.impuestoP === '001')
                var retencionIeps = this.comprobante.pagos[0].impuestosP.retencionesP.retencionP.filter(x => x.impuestoP === '003')

                this.comprobante.totales.totalRetencionesIVA = this.sum(retencionIva, 'importeP')
                this.comprobante.totales.totalRetencionesISR = this.sum(retencionIsr, 'importeP')
                this.comprobante.totales.totalRetencionesIEPS = this.sum(retencionIeps, 'importeP')
                
                //TRASLADADOS
                var trasladoIva16 = this.comprobante.pagos[0].impuestosP.trasladosP.trasladoP.filter(x => x.tasaOCuotaP == '0.16' && x.impuestoP === '002')
                var trasladoIva8 = this.comprobante.pagos[0].impuestosP.trasladosP.trasladoP.filter(x => x.tasaOCuotaP == '0.08' && x.impuestoP === '002')
                var trasladoIva0 = this.comprobante.pagos[0].impuestosP.trasladosP.trasladoP.filter(x => x.tasaOCuotaP == '0.00' && x.impuestoP === '002')
                var trasladoIvaExento = this.comprobante.pagos[0].impuestosP.trasladosP.trasladoP.filter(x => x.tipoFactorP === 'Exento' && x.impuestoP === '002')

                this.comprobante.totales.totalTrasladosBaseIVA16 = this.sum(trasladoIva16, 'baseP')
                this.comprobante.totales.totalTrasladosImpuestoIVA16 = this.sum(trasladoIva16, 'importeP')
                this.comprobante.totales.totalTrasladosBaseIVA8 = this.sum(trasladoIva8, 'baseP')
                this.comprobante.totales.totalTrasladosImpuestoIVA8 = this.sum(trasladoIva8, 'importeP')
                this.comprobante.totales.totalTrasladosBaseIVA0 = this.sum(trasladoIva0, 'baseP')
                this.comprobante.totales.totalTrasladosImpuestoIVA0 = this.sum(trasladoIva0, 'importeP')
                this.comprobante.totales.totalTrasladosBaseIVAExento = this.sum(trasladoIvaExento, 'baseP')

                //TOTAL DEL MONTO   
                this.comprobante.pagos[0].Monto = this.sum(this.comprobante.pagos[0].doctoRelacionados, 'impPagado')
                this.comprobante.totales.montoTotalPagos = this.sum(this.comprobante.pagos[0].doctoRelacionados, 'impPagado')
            },
            sum(array, key) {
                return array.reduce((a, b) => a + (b[key] || 0), 0);
            },
            deletePago(item){
                //BUSCAMOS EL COMPROBANTE A BORRAR Y LO QUITAMOS DE LA LISTA
                let editedIndex = this.itemsComprobantePagos.findIndex(x => x.idDocumento === item.idDocumento)
                this.comprobante.pagos[0].doctoRelacionados.splice(editedIndex, 1)

                //SUMATORIA DE LOS IMPUESTOS DR
                this.listRetenidos = [];
                this.listTrasladados =[];
                let listaP = this.comprobante.pagos[0].doctoRelacionados
                for(var c of listaP){
                    for(var r of c.impuestosDR.retencionesDR){
                        this.listRetenidos.push(r)
                        // console.log(r)
                    }
                    for(var t of c.impuestosDR.trasladosDR){
                        this.listTrasladados.push(t)
                        // console.log(t)
                    }
                }

                //SUMATORIA DE LOS IMPUESTOS P
                this.comprobante.pagos[0].impuestosP.retencionesP.retencionP = []
                var sumRetenidos = this.agrupaRetenidos(this.listRetenidos, 'impuestoDR');
                for (const prop in sumRetenidos) {
                    const ret = Object.assign({}, sumRetenidos[prop])
                    this.comprobante.pagos[0].impuestosP.retencionesP.retencionP.push(ret)
                }
                
                this.comprobante.pagos[0].impuestosP.trasladosP.trasladoP = []
                var sumTrasladados = this.agrupaTrasladados(this.listTrasladados, 'impuestoDR', 'tasaOCuotaDR', 'tipoFactorDR');
                for (const prop in sumTrasladados) {
                    const tra = Object.assign({}, sumTrasladados[prop])
                    this.comprobante.pagos[0].impuestosP.trasladosP.trasladoP.push(tra)
                }

                this.sumasTotales()
            }
        },
    }
</script>