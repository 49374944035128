<template>
    <div>
        <v-card>
            <v-toolbar color="primary" dark>
                <v-btn icon  @click="CerrarVentanaRemolque()">
                    <v-icon large>mdi-close-circle</v-icon>
                  </v-btn>
                  <v-spacer></v-spacer>
                  <h3>Remolque</h3>
                  <v-spacer></v-spacer>
                  <v-btn icon @click="PostRemolque()">
                    <v-icon large>mdi-check-circle</v-icon>
                  </v-btn>
            </v-toolbar>
            <v-card-text>
                <span hidden>{{this.$store.state.remolque}}</span>
                <v-select label="Sub Tipo de Remolque" :items="itemsRemolques" item-text="subTipoRem" v-model="remolque.subTipoRem" return-object persistent-hint></v-select>
                    <v-text-field label="Placa del remolque" v-model="remolque.placa"></v-text-field>
            </v-card-text>
        </v-card>
        <div>
            <v-dialog v-model="dialog" hide-overlay persistent width="300">
                <v-card color="primary" dark>
                    <v-card-text>
                        {{ dialogMessage }}
                        <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </div>
        <!-- SNACKBAR PARA NOTIFICAR LOS ERRORES -->
        <v-snackbar :timeout="5000" v-model="snackbar" color="primary darken-2" elevation="30">{{ snackbarMessage }}
            <template v-slot:action="{ attrs }">
                <v-icon dark @click="snackbar = false">
                  mdi-close-circle
                </v-icon>
              </template>
        </v-snackbar>
    </div>
</template>
<script>
    import axios from 'axios'
    export default {
        name: 'Remolque',
        components:{
            
        },

        data: () => ({
            //DIALOG
            dialog:false,
            dialogMessage:'',

            // SNACKBAR
            snackbar:false,
            snackbarMessage:'',

            itemsRemolques:[],
            SubTipoRem:{},
            placa:'',
        }),
        watch:{
           
        },
        computed: {
             datosEmpresa() {
                return this.$store.state.usuario;
            },
            remolque(){
                return this.$store.state.remolque;
            }
        },
        mounted () {
            this.searchsubTipoRem();
        },

        methods: {
            //Cerrar ventana
            CerrarVentanaRemolque() {
                    this.$store.state.remolque.id = -1

                this.$emit("VentanaNuevoRemolque");
                },  

             async searchsubTipoRem(){
                try{
                    let response = await axios.get('CatalogosCartaPorte/GetSubTipoRem/')
                    this.itemsRemolques = response.data;
                }catch(error){
                    console.log(error);
                }
            },

            
            async PostRemolque(){
                let remolque = Object.assign({},this.$store.state.remolque)
                if (remolque.id > 0){
                    try{
                    let response = await axios.post('CartaPorte/UpdateRemolques/'+ this.datosEmpresa.empresaBase, remolque);
                    console.log(response)
                    this.snackbarMessage = 'Actualizado con exito.'
                    this.snackbar = true
                    this.$store.state.remolque.id = -1
                    this.CerrarVentanaRemolque();
                }catch(err){
                    console.log(err)
                    this.snackbarMessage = 'Error al actuliazar, intentelo de nuevo.'
                    this.snackbar = true
                }
                }else{
                    try{
                    let response = await axios.post('CartaPorte/PostRemolques/'+ this.datosEmpresa.empresaBase, remolque);
                    console.log(response)
                    this.snackbarMessage = 'Guardado con exito.'
                    this.snackbar = true
                    this.$store.state.remolque.id = -1
                    this.CerrarVentanaRemolque();
                }catch(err){
                    console.log(err)
                    this.snackbarMessage = 'Error al guardar, intentelo de nuevo.'
                    this.snackbar = true
                }
                }
               
                try{
                    let response = await axios.post('CartaPorte/PostRemolques/'+ this.datosEmpresa.empresaBase, Remolques);
                    console.log(response)
                    this.snackbarMessage = 'Guardado con exito.'
                    this.snackbar = true
                    this.SubTipoRem = ''
                    this.placa=''
                    this.CerrarVentanaRemolque();
                }catch(err){
                    console.log(err)
                    this.snackbarMessage = 'Error al guardar, intentelo de nuevo.'
                    this.snackbar = true
                }
            },

        }
    }
</script>