<template>
    <div>
        <v-card>
            <v-card-title>Configuración
                <v-spacer></v-spacer>
                <div class="text-center">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn @click="GuardarConfiguracion()" v-bind="attrs" v-on="on" class="mx-2" fab dark
                                color="primary">
                                <v-icon dark>mdi-content-save</v-icon>
                            </v-btn>
                        </template>
                        <span>Guardar</span>
                    </v-tooltip>
                </div>
            </v-card-title>
            <v-card-text>
                <!--Configuración general-->
                <v-system-bar color="secondary" dark><strong>General</strong></v-system-bar>
                <v-row>
                    <v-col cols="8" md="8" sm="8">
                        <!-- <v-text-field label="RFC" v-model="rfc_e" required persistent-hint append-outer-icon="mdi-check"
                            @click:append-outer="validarRFC()"></v-text-field> -->
                            <v-text-field label="RFC" v-model="rfc_e" required persistent-hint 
                            ></v-text-field>
                        <v-text-field label="Nombre de la Empresa" v-model="nombre_e" required persistent-hint>
                        </v-text-field>
                        <v-text-field label="Código Postal" v-model="cp_e" required persistent-hint></v-text-field>
                    </v-col>

                    <v-col cols="4" md="4" sm="4">
                        <div>
                            <v-img :aspect-ratio="16/9" width="400" contain height="160" :src="logpBase64"></v-img>
                        </div>
                        <v-file-input prepend-icon="mdi-camera" label="Seleccione una imagen" v-model="logo_e"
                            accept="image/png, image/jpeg, image/bmp" @change="convertirLogoBase64()"></v-file-input>
                    </v-col>
                    <v-col cols="12">
                        <v-select label="Régimen Fiscal" v-model="regimenFiscal_e" :items="itemsRegimenFiscal"
                            item-text="regimenFiscal" return-object required persistent-hint></v-select>
                    </v-col>
                </v-row>
                <!--Configuración de sellos-->
                <v-system-bar color="secondary" dark><strong>Sellos</strong></v-system-bar>
                <v-row>
                    <v-col cols="12" md="4" sm="12">
                        <v-file-input label="CSD.key" v-model="key_e" accept=".key" @change="convirtiraBase64()">
                        </v-file-input>
                    </v-col>
                    <v-col cols="12" md="4" sm="12">
                        <v-file-input label="CSD.cer" v-model="cer_e" accept=".cer" @change="convirteaBase64()">
                        </v-file-input>
                    </v-col>
                    <v-col cols="12" md="4" sm="12">
                        <v-text-field label="Contraseña" v-model="contra_e" :type="show1 ? 'text' : 'password'"
                            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" @click:append="show1 = !show1" required
                            persistent-hint></v-text-field>
                    </v-col>
                </v-row>
                <!--Configuración de correo-->
                <v-divider></v-divider>
                <v-card-title>Configuración Personal
                    <v-spacer></v-spacer>
                    <div class="text-center">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn @click="GuardarConfiguracionPersonal()" v-bind="attrs" v-on="on" class="mx-2" fab
                                    dark color="primary">
                                    <v-icon dark>mdi-content-save</v-icon>
                                </v-btn>
                            </template>
                            <span>Guardar</span>
                        </v-tooltip>
                    </div>
                </v-card-title>
                <v-system-bar color="secondary" dark><strong>Correo</strong></v-system-bar>
                <v-row>
                    <v-col cols="12" md="6" sm="12">
                        <v-text-field label="Nombre" v-model="nombre_c" required persistent-hint></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" sm="12">
                        <v-text-field label="Correo" v-model="correo_c" type="email" required persistent-hint>
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" md="4" sm="12">
                        <v-text-field label="Contraseña del correo" v-model="contra_c"
                            :type="show2 ? 'text' : 'password'" :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                            @click:append="show2 = !show2" required persistent-hint></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4" sm="12">
                        <v-text-field label="Puerto" v-model="hostt_c" type="number" required persistent-hint>
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" md="4" sm="12">
                        <v-text-field label="Host" v-model="puerto_c" required persistent-hint></v-text-field>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <!-- SNACKBAR PARA NOTIFICAR LOS ERRORES -->
        <v-snackbar :timeout="5000" v-model="snackbar" color="primary darken-2" elevation="30">{{ snackbarMessage }}
            <template v-slot:action="{ attrs }">
                <v-icon dark @click="snackbar = false">
                    mdi-close-circle
                </v-icon>
            </template>
        </v-snackbar>
        <span hidden>{{datosEmpresa}}</span>
    </div>
</template>

<script>
    import axios from 'axios'

    export default {
        name: 'Cponfiguracion',
        components: {

        },

        data: () => ({
            //contraseña
            show1: false,
            show2: false,

            // SNACKBAR
            snackbar: false,
            snackbarMessage: '',

            nombre_e: '',
            rfc_e: '',
            cp_e: '',
            regimenFiscal_e: {},
            contra_e: '',

            key_e: [],
            archivoBase64: '',
            listaArchivos: [],
            listArchivo: { archivoBase64: '' },

            cer_e: [],
            KeyBase64: '',
            listaKey: [],
            listKey: { KeyBase64: '' },

            logo_e: [],
            logpBase64: '',
            listalogo: [],
            listlogo: { logoBase64: '' },

            nombre_c: '',
            correo_c: '',
            contra_c: '',
            puerto_c: '',
            hostt_c: '',

            //items select regimen fiscal
            itemsRegimenFiscal: [],
        }),

        watch: {

        },

        computed: {
            datosEmpresa() {
                console.log(this.$store.state.usuario)
                return this.$store.state.usuario;
            },
        },

        created() {
            this.GetRegimenFiscal();
            this.GetEmpresa();
            this.GetConfiguracion();
        },

        methods: {
            async GetRegimenFiscal() {
                try {
                    let response = await axios.get('CatalogoComprobantes/GetRegimenFiscal')
                    this.itemsRegimenFiscal = response.data
                } catch (err) {
                    console.log(err)
                }
            },
            async GetEmpresa() {
                try {
                    let response = await axios.get('Empresa/GetEmpresa/' + this.datosEmpresa.empresaBase)
                    const empresaR = response.data
                    this.nombre_e = empresaR.nombreEmpresa
                    this.rfc_e = empresaR.rfc
                    this.regimenFiscal_e = empresaR.regimenFiscal
                    this.logpBase64 = 'data:image/png;base64,' + empresaR.logo
                    this.cp_e = empresaR.cp
                } catch (err) {
                    console.log(err)
                }
            },
            async GetConfiguracion() {
                try {
                    let response = await axios.get('Configuracion/GetConfiguracion/config_contago_erp/' + this.datosEmpresa.idusuariosApp)
                    const config = response.data
                    this.nombre_c = config.nombre
                    this.correo_c = config.correo
                    this.contra_c = config.contra
                    this.puerto_c = config.puerto
                    this.hostt_c = config.hostt
                } catch (err) {
                    console.log(err)
                }
            },
            //VALIDAR RFC
            validarRFC() {
                if (this.rfc_e === '') {
                    this.snackbarMessage = 'Error: Indique un RFC'
                    this.snackbar = true;
                    return
                }
                axios.get('https://conectame.ddns.net/rest/api.php?m=rfc&user=prueba&pass=sC%7D9pW1Q%5Dc&val=' + this.rfc_e)
                    .then(data => {
                        console.log(data.data.response);
                        this.snackbarMessage = 'RFC Correcto'
                        this.snackbar = true
                    }).catch(err => {
                        //console.log(err.response);
                        this.snackbarMessage = 'RFC no existe'
                        this.snackbar = true
                        this.rfc_e = ''
                    })
            },
            async GuardarConfiguracion() {
                //this.validarRFC();
                let configEmpre = {
                    nombreEmpresa: this.nombre_e,
                    rfc: this.rfc_e,
                    cp: this.cp_e,
                    password: this.contra_e,
                    regimenFiscal: Object.assign({}, this.regimenFiscal_e),
                    archivoKey: this.listaArchivos[0].archivoBase64,
                    archivoCer: this.listaKey[0].KeyBase64,
                    archivoLogo: this.listalogo[0].logoBase64
                }
                try {
                    let response = await axios.post('Empresa/PostEmpresa/' + this.datosEmpresa.empresaBase, configEmpre);
                    this.snackbarMessage = 'Guardado con exito'
                    this.snackbar = true
                } catch (err) {
                    console.log(err)
                }
            },
            async GuardarConfiguracionPersonal() {
                let config = {
                    nombre: this.nombre_c,
                    correo: this.correo_c,
                    contra: this.contra_c,
                    hostt: this.hostt_c,
                    puerto: this.puerto_c,
                }
                try {
                    let response = await axios.post('Configuracion/PutConfiguracion/config_contago_erp/' + this.datosEmpresa.idusuariosApp, config);
                    this.snackbarMessage = 'Actualizado con exito'
                    this.snackbar = true
                } catch (err) {
                }
            },
            convirteaBase64() {
                this.listaArchivos = [];

                let file = this.cer_e;
                let reader = new FileReader();
                reader.onloadend = (file) => {
                    this.listArchivo.archivoBase64 = reader.result;
                    this.listaArchivos.push(this.listArchivo);
                }
                reader.readAsDataURL(file);
            },
            convirtiraBase64() {
                this.listaKey = [];

                let file = this.key_e;
                let reader = new FileReader();
                reader.onloadend = (file) => {
                    this.listKey.KeyBase64 = reader.result;
                    this.listaKey.push(this.listKey);
                }
                reader.readAsDataURL(file);
            },
            convertirLogoBase64() {
                this.listalogo = [];
                let file = this.logo_e;
                let reader = new FileReader();
                reader.onloadend = (file) => {
                    this.listlogo.logoBase64 = reader.result;
                    this.listalogo.push(this.listlogo);
                    this.logpBase64 = this.listalogo[0].logoBase64
                }
                reader.readAsDataURL(file);
            },
            async convertir64() {
                const file = document.querySelector(this.cer_e).files[0];
                console.log(await toBase64(file));
            }

        },
    }
</script>