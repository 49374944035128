<template>
    <div>
        <v-card>
            <v-card-text>
                <v-row>
                    <v-col>
                        <v-select label="Tipo de relación" :items="itemsTipoRelacion" v-model="cfdiRelacionados.tipoRelacion" item-text="tipoRelacion" return-object></v-select>
                        <!-- BOTON PARA AGREGAR CFDIS RELACIONADOS -->
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn v-bind="attrs" v-on="on" class="mx-2" fab dark color="green" @click="abreDialog()">
                                <v-icon dark>mdi-plus</v-icon>
                                </v-btn>
                            </template>
                            <span>Relacionar CFDI</span>
                        </v-tooltip>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-data-table :headers="headersRelacionados" :items="itemsComprobantesRelacionados">
                            <template v-slot:[`item.actions`]="{ item }">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon color="red" dense class="mr-2" v-bind="attrs" v-on="on" dark @click="deleteCfdi(item)">mdi-delete-empty</v-icon>
                                    </template>
                                    <span>Eliminar</span>
                                </v-tooltip>
                            </template>
                        </v-data-table>

                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <v-dialog persistent v-model="dialogBuscar">
            <v-card>
                <v-toolbar color="primary" dark>
                    <v-btn icon  @click="cerrarDialogRelacionado()">
                        <v-icon large>mdi-close-circle</v-icon>
                      </v-btn>
                      <v-spacer></v-spacer>
                      <h3>Concepto</h3>
                      <v-spacer></v-spacer>
                      <v-btn icon @click="PushCfdi()">
                        <v-icon large>mdi-check-circle</v-icon>
                      </v-btn>
                </v-toolbar>
                <v-card-text>
                    <v-text-field label="Buscar por folio, folio fiscal, nombre o rfc" hint="Presione la tecla <Enter> para buscar" append-outer-icon="mdi-magnify" @click:append-outer="buscarCfdi()" v-model="variable" @keydown.enter="buscarCfdi()"></v-text-field>
                    <v-data-table :headers="headersRelacionados" :items="itemsComprobantes">
                        <!-- FORMATO CON DECIMALES DEL IMPORTE -->
                        <template v-slot:item.subTotal="{ item }">
                            <span>{{ functionFormatPrice(item.subTotal) }}</span>
                        </template>
                        <!-- FORMATO CON DECIMALES DEL TOTAL -->
                        <template v-slot:item.total="{ item }">
                            <span>{{ functionFormatPrice(item.total) }}</span>
                        </template>
                        <!-- muestra el color del estatus de las facturas -->
                        <template v-slot:item.folio="{ item }">
                        <v-chip :color="getColor(item.estatus)" dark>{{ item.folio }}</v-chip>
                    </template>

                    <!-- BOTONES DE ACCION -->
                    <template v-slot:[`item.actions`]="{ item }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                            <v-icon color="green" dense class="mr-2" v-bind="attrs" v-on="on" dark @click="PushCfdi(item)">mdi-plus-circle</v-icon>
                            </template>
                            <span>Agregar</span>
                        </v-tooltip>
                    </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-dialog>
        <!-- DIALOG DE QUE ESTA HACIENDO ALGO -->
        <div>
            <v-dialog v-model="dialog" hide-overlay persistent width="300">
              <v-card color="primary" dark>
                <v-card-text>
                  {{ dialogMessage }}
                  <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                </v-card-text>
              </v-card>
            </v-dialog>
        </div>
    </div>
</template>
<script>
    import axios from 'axios'
    
    export default {
    components:{
    },
    data() {
        return {
            itemsTipoRelacion: [],
            headersRelacionados:[
                { text: 'Acciones', value: 'actions'},
                { text: "Version", value: "version" },
                { text: "Serie", value: "serie" },
                { text: "Folio", value: "folio" },
                { text: "RFC", value: "receptor.rfc" },
                { text: "Nombre", value: "receptor.nombre" },
                { text: "SubTotal", value: "subTotal", align:'right' },
                { text: "Total", value: "total", align:'right' },
                { text: "Moneda", value: "moneda" },
                { text: "Forma de Pago", value: "formaPago" },
                { text: "Metodo de Pago", value: "metodoPago" },
                { text: "Folio fiscal", value: "folioFiscal" },
            ],
            dialogBuscar: false,
            variable: '',
            itemsComprobantes:[],
            itemsComprobantesRelacionados: [],

            //DIALOG
            dialogMessage: '',
            dialog: false,

        };
      },
      mounted() {
        this.GetTipoRelacion();
      },
    
      computed: {
        cfdiRelacionados(){
            console.log(this.$store.state.honorariosStore.cfdiRelacionados)
            return this.$store.state.honorariosStore.cfdiRelacionados;
        },
         datosEmpresa() {
                return this.$store.state.usuario;
            },
    },
    
    methods: {
        cerrarDialogRelacionado(){
                this.dialogBuscar = false
            },
        agregaCfdi(){},
        async GetTipoRelacion(){
            try{
                let response = await axios.get('CatalogoComprobantes/GetTipoRelacion')
                this.itemsTipoRelacion = response.data
            }catch(error){
                console.log(error)
            }
        },
        functionFormatPrice(value) {
            let val = (value/1).toFixed(2)
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },
        getColor (estatus) {
            if (estatus == 'Vigente') return 'green'
            else if (estatus == 'Cancelado') return 'red darken-1'
            else if (estatus == 'En proceso') return 'orange'
            else return 'gray'
        },
    
        abreDialog(){
            this.dialogBuscar = true;
        },
        PushCfdi(item){
            this.itemsComprobantesRelacionados.push(item)
            let inidce = this.itemsComprobantes.indexOf(item)
            this.itemsComprobantes.splice(inidce, 1)

            //FUNCION QUE QUITA LOS REPETIDOS
            let personasMap = this.itemsComprobantesRelacionados.map(item=>{
                return [item.folioFiscal,item]
            });
            var comprobantesMapArr = new Map(personasMap);

            let unicos = [...comprobantesMapArr.values()];
            this.itemsComprobantesRelacionados = unicos.slice();
            this.$store.state.honorariosStore.cfdiRelacionados.cfdiRelacionados = [];
            for(let value of this.itemsComprobantesRelacionados){
                const a = { uuid: value.folioFiscal}
                this.$store.state.honorariosStore.cfdiRelacionados.cfdiRelacionados.push(a)
            }
        },

        deleteCfdi(item){
            let inidce = this.cfdiRelacionados.cfdiRelacionados.indexOf(item)
            this.cfdiRelacionados.cfdiRelacionados.splice(inidce, 1)
        },

        async buscarCfdi(){
            this.dialogMessage = 'Buscando comprobantes, espere...'
            this.dialog = true
            try{
                let response = await axios.get('Comprobantes/GetComprobantesPorVariable/'+ this.datosEmpresa.empresaBase + '/' + this.variable)
                this.itemsComprobantes = response.data
            }catch(error){
                console.log(error)
            }
            this.dialog = false
        },
    },
    };
    </script>