<template>
    <div>
        <v-card>
            <v-toolbar color="primary" dark>
                <v-btn icon @click="CerrarVentanaMe()">
                    <v-icon large>mdi-close-circle</v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <h3>Mercancia</h3>
                <v-spacer></v-spacer>
                <v-btn icon @click="save()">
                    <v-icon large>mdi-check-circle</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text>
                <span hidden>{{this.$store.state.mercancia}}</span>
                <!-- SE DETERMINA SI EL MATERIAL QUE SE TRANSPORTA, ES MATERIAL PELIGROSO -->
                <v-select label="Material peligroso" :items="itemsMaterialPeligroso"
                    v-model="mercancia.materialPeligroso" return-object item-text="respuesta"
                    hint="En este campo se deberá indicar el valor “Sí” o “No” cuando los bienes y/o mercancías que se trasladan son considerados como material peligroso."
                    persistent-hint @change="validaMaterialPeligroso"></v-select>
                <template v-if="mercancia.materialPeligroso.respuesta == 'SI'">
                    <!-- CLAVE DEL MATERIAL PELIGROSO -->
                    <v-autocomplete label="Clave del material peligroso" v-model="mercancia.cveMaterialPeligroso"
                        :items="itemsCveMaterialPeligroso" :loading="loadingCveMaterialPeligroso"
                        :search-input.sync="searchCveMaterialPeligroso" item-text="materialPeligroso"
                        placeholder="Buscar por clave o descripicón" return-object
                        hint="En este campo se podrá registrar la clave del tipo de material peligroso que se transporta de acuerdo a la NOM-002-SCT/2011"
                        persistent-hint></v-autocomplete>
                </template>
                <!-- SE INDICA LA CLAVE DEL SAT DE LA MECANCIA QUE SE TRANSPORTA -->
                <v-autocomplete label="Bienes transportados" v-model="mercancia.bienesTransp" :items="itemsBienesTransp"
                    :loading="loadingBienesTransp" :search-input.sync="searchBienesTransp" item-text="claveProdServCP"
                    placeholder="Buscar por clave o descripción" return-object
                    hint="En este campo se deberá registrar la clave de producto de los bienes y/o mercancías que se trasladan vía Autotransporte"
                    persistent-hint></v-autocomplete>
                <!-- DESCRIPCION INTERNA DE LA MERCANCIA QUE SE TRANSPORTA -->
                <v-text-field label="Descripción" v-model="mercancia.descripcion" maxlength="1000" counter
                    hint="En este campo se deberá registrar la descripción de los bienes y/o mercancías que se trasladan por vía autotransporte"
                    persistent-hint></v-text-field>
                <!-- CLAVE DE LA UNIDAD DE MEDIDA DEL BIEN QUE SE TRANSPORTA -->
                <v-autocomplete label="Clave de unidad" v-model="mercancia.claveUnidad" :items="itemsClaveUnidad"
                    :loading="loadingClaveUnidad" :search-input.sync="searchClaveUnidad" item-text="claveUnidad"
                    placeholder="Buscar por clave o descripicón" return-object hint=" En este campo se deberá registrar la clave de unidad de medida aplicable y que se encuentra en el catálogo c_ClaveUnidad, de CFDI, publicado en el Portal del SAT.
                La unidad debe corresponder con la descripción de los bienes y/o mercancías registrados"
                    persistent-hint></v-autocomplete>
                <!-- DESCRIPCION INTERNA DEL BIEN QUE SE TRANSPORTA -->
                <v-text-field label="Unidad" v-model="mercancia.unidad"
                    hint="En este campo se podrá registrar la descripción de la unidad de medida propia de los bienes y/o mercancías que se trasladan."
                    persistent-hint></v-text-field>
                <!-- CLAVE DEL EMBALAJE -->
                <v-select :items="itemsTipoEmbalaje" v-model="mercancia.embalaje" label="Tipo de embalaje" return-object
                    item-text="tipoEmbalaje"
                    hint="En este campo se podrá registrar la clave del tipo de embalaje del catálogo del complemento Carta Porte, c_TipoEmbalaje"
                    persistent-hint></v-select>
                </v-select>
                <v-text-field label="Descripción del embalaje" v-model="mercancia.descripEmbalaje"
                    hint="En este campo se podrá registrar la descripción del embalaje en el que se transportan los bienes y/o mercancías que se consideran material o residuo peligroso"
                    persistent-hint></v-text-field>
                <!-- MONEDA -->
                <v-select label="Moneda" :items="itemsMoneda" v-model="mercancia.moneda" return-object
                    item-text="moneda"
                    hint="En este campo se deberá registrar siempre que exista información en el campo “ValorMercancia” y sirve para registrar la clave de la moneda utilizada, la cual se encuentra contenida en el catálogo c_Moneda de CFDI, publicado en el Portal del SAT, cuando se use moneda nacional se registrará “MXN”"
                    persistent-hint></v-select>
            </v-card-text>
        </v-card>
        <!-- DIALOG DE QUE ESTA HACIENDO ALGO -->
        <div>
            <v-dialog v-model="dialog" hide-overlay persistent width="300">
                <v-card color="primary" dark>
                    <v-card-text>
                        {{ dialogMessage }}
                        <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </div>
        <!-- SNACKBAR PARA NOTIFICAR LOS ERRORES -->
        <v-snackbar :timeout="5000" v-model="snackbar" color="primary darken-2" elevation="30">{{ snackbarMessage }}
            <template v-slot:action="{ attrs }">
                <v-icon dark @click="snackbar = false">
                    mdi-close-circle
                </v-icon>
            </template>
        </v-snackbar>
    </div>
</template>
<script>
    import axios from 'axios'
    export default {
        name: 'MercanciaMercancias',
        components: {

        },

        data: () => ({
            //DIALOG
            dialog: false,
            dialogMessage: '',

            // SNACKBAR
            snackbar: false,
            snackbarMessage: '',

            //BIENES TRANSPORTADOS BUSQUEDA EN CATALOGO
            id: 0,
            bienesTransp: null,
            itemsBienesTransp: [],
            limitBienesTransp: 60,
            loadingBienesTransp: false,
            searchBienesTransp: null,

            descripcion: '',

            //CLAVE DE UNIDAD, BUSQUEDA EN CATALOGO
            claveUnidad: null,
            itemsClaveUnidad: [],
            limitClaveUnidad: 60,
            loadingClaveUnidad: false,
            searchClaveUnidad: null,

            unidad: '',

            //CLAVE DEL MATERIAL PELIGROSO, BUSQUEDA EN CATALOGO
            materialPeligroso: { respuesta: 'NO', valor: 0 },
            itemsMaterialPeligroso: [{ respuesta: 'SI', valor: 1 }, { respuesta: 'NO', valor: 0 }],
            cveMaterialPeligroso: null,
            itemsCveMaterialPeligroso: [],
            limitCveMaterialPeligroso: 60,
            loadingCveMaterialPeligroso: false,
            searchCveMaterialPeligroso: null,

            //EMBALAJE, BUSQUEDA EN CATALOGO
            tipoEmbalaje: {},
            itemsTipoEmbalaje: [],
            descripcionEmbalaje: '',

            //MONEDA
            moneda: { clave: 'MXN', descripcion: 'Peso Mexicano', moneda: 'MXN | Peso Mexicano' },
            itemsMoneda: [],

            fraccionArancelaria: {},
            itemsFraccionArancelaria: [],
        }),
        watch: {
            async searchBienesTransp(val) {
                if (this.itemsbienesTransp.length > 0) return
                if (this.loadingBienesTransp) return
                this.loadingBienesTransp = true
                try {
                    let response = await axios.get('CatalogosCartaPorte/GetClaveProdServCP/' + this.mercancia.materialPeligroso.valor)
                    this.itemsBienesTransp = response.data;
                } catch (error) {
                    console.log(error)
                }
                this.loadingBienesTransp = false
            },

            async searchClaveUnidad(val) {
                if (this.itemsclaveUnidad.length > 0) return
                if (this.loadingClaveUnidad) return
                this.loadingClaveUnidad = true
                try {
                    let response = await axios.get('CatalogoComprobantes/GetClaveUnidad')
                    this.itemsClaveUnidad = response.data;
                } catch (error) {
                    console.log(error)
                }
                this.loadingClaveUnidad = false
            },

            async searchCveMaterialPeligroso(val) {
                let material = 0;

                if (this.itemscveMaterialPeligroso.length > 0) return
                if (this.loadingCveMaterialPeligroso) return
                this.loadingCveMaterialPeligroso = true
                try {
                    let response = await axios.get('CatalogosCartaPorte/GetMaterialPeligroso')
                    this.itemsCveMaterialPeligroso = response.data
                } catch (error) {
                    console.log(error)
                }
                this.loadingCveMaterialPeligroso = false;
            },


        },
        computed: {
            itemsbienesTransp() {
                return this.itemsBienesTransp.map(entry => {
                    const Description = entry.descripcion.length > this.limitBienesTransp
                        ? entry.descripcion.slice(0, this.limitBienesTransp) + '...'
                        : entry.descripcion

                    return Object.assign({}, entry, { Description })
                })
            },

            itemsclaveUnidad() {
                return this.itemsClaveUnidad.map(clave => {
                    const Descripcion = clave.nombre.length > this.limitClaveUnidad
                        ? clave.nombre.slice(0, this.limitClaveUnidad) + '...'
                        : clave.nombre

                    return Object.assign({}, clave, { Descripcion })
                })
            },

            itemscveMaterialPeligroso() {
                return this.itemsCveMaterialPeligroso.map(clave => {
                    const Descripcion = clave.descripcion.length > this.limitCveMaterialPeligroso
                        ? clave.descripcion.slice(0, this.limitCveMaterialPeligroso) + '...'
                        : clave.descripcion

                    return Object.assign({}, clave, { Descripcion })
                })
            },
            datosEmpresa() {
                return this.$store.state.usuario;
            },
            mercancia() {
                return this.$store.state.mercancia
            }
        },

        mounted() {
            this.searchTipoEmbalaje();
            this.searchMoneda();
        },
        created(){
            this.claveProduc()
            this.claveUnid()
            this.claveMP()
        },
        methods: {
            async claveMP(){
                 try {
                    let response = await axios.get('CatalogosCartaPorte/GetMaterialPeligroso')
                    this.itemsCveMaterialPeligroso = response.data
                } catch (error) {
                    console.log(error)
                }
            },
            async claveProduc() {
                try {
                    let response = await axios.get('CatalogosCartaPorte/GetClaveProdServCP/' + this.mercancia.materialPeligroso.valor)
                    this.itemsBienesTransp = response.data;
                } catch (error) {
                    console.log(error)
                }
            },
            async claveUnid(){
                try {
                    let response = await axios.get('CatalogoComprobantes/GetClaveUnidad')
                    this.itemsClaveUnidad = response.data;
                } catch (error) {
                    console.log(error)
                }
            },

            CerrarVentanaMe() {
                this.$store.state.mercancia.id = -1
                this.$emit("VentanaMercancias");
            },
            async save() {
                if (this.mercancia.bienesTransp == "" || !this.mercancia.bienesTransp) {
                    this.snackbarMessage = 'Indique los bienes transportados para continuar'
                    this.snackbar = true
                    return;
                }
                if (this.mercancia.descripcion == "" || !this.mercancia.descripcion) {
                    this.snackbarMessage = 'Indique la descripción los bienes para continuar'
                    this.snackbar = true
                    return;
                }
                if (this.mercancia.claveUnidad == "" || !this.mercancia.claveUnidad) {
                    this.snackbarMessage = 'Indique la clave de la unidad para continuar'
                    this.snackbar = true
                    return;
                } if (this.mercancia.unidad == "" || !this.mercancia.unidad) {
                    this.snackbarMessage = 'Indique la unidad para continuar'
                    this.snackbar = true
                    return;
                }
                if (this.mercancia.embalaje == "" || !this.mercancia.embalaje) {
                    this.snackbarMessage = 'Indique el tipo de embalaje para continuar'
                    this.snackbar = true
                    return;
                }
                if (this.mercancia.descripEmbalaje == "" || !this.mercancia.descripEmbalaje) {
                    this.snackbarMessage = 'Indique la descripción del embalaje para continuar'
                    this.snackbar = true
                    return;
                }
                
                if (this.$store.state.mercancia.materialPeligroso.valor == 1) {
                    this.$store.state.mercancia.materialPeligroso = 1
                } else {
                    this.$store.state.mercancia.materialPeligroso = 0
                }

                this.$store.state.mercancia.fraccionArancelaria = Object.assign({}, this.fraccionArancelaria)
                let Mercancias = Object.assign({}, this.$store.state.mercancia)

                console.log(Mercancias);
                if (this.$store.state.mercancia.id > 0) {
                    try {
                        console.log('editar')

                        let response = await axios.post('CartaPorte/UpdateMercancias/' + this.datosEmpresa.empresaBase, Mercancias)
                        console.log(response.data)
                        this.$store.state.mercancia.id = -1
                        this.CerrarVentanaMe();
                        this.snackbarMessage = 'Actualizado con exito.'
                        this.snackbar = true
                    } catch (error) {
                        console.log(error)
                        this.snackbarMessage = 'Error al actualizar, intentelo de nuevo.'
                        this.snackbar = true
                    }
                } else {
                    try {
                        let response = await axios.post('CartaPorte/PostMercancias/' + this.datosEmpresa.empresaBase, Mercancias)
                        console.log(response.data)
                        console.log('guaradar')

                        this.snackbarMessage = 'Guardado con exito.'
                        this.snackbar = true
                        this.$store.state.mercancia.id = -1
                        this.CerrarVentanaMe();
                    } catch (error) {
                        console.log(error)
                        this.snackbarMessage = 'Error al guardar, intentelo de nuevo.'
                        this.snackbar = true
                    }
                }

            },
            async searchTipoEmbalaje() {
                try {
                    let response = await axios.get('CatalogosCartaPorte/GetTipoEmbalaje')
                    this.itemsTipoEmbalaje = response.data;
                } catch (error) {
                    console.log(error);
                }
            },
            async searchMoneda() {
                try {
                    let response = await axios.get('CatalogoComprobantes/GetMoneda')
                    this.itemsMoneda = response.data;
                } catch (error) {
                    console.log(error);
                }
            },
            validaMaterialPeligroso() {
                this.bienesTransp = null
                this.itemsBienesTransp = []
            },

        }
    }
</script>