var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app"},[_c('v-toolbar',{attrs:{"flat":"","color":"transparent"}},[_c('v-toolbar-title',{staticClass:"dark"},[_c('h2')]),_c('v-spacer')],1),_c('v-card',{staticClass:"justify-center pt-3 pb-5",attrs:{"flat":"","color":"transparent"}},[_c('div',{staticClass:"text-center mt-4"},[_c('img',{attrs:{"src":require("../assets/logo_cotago facturacion_web_sin_fondo.png"),"height":"150"}})])]),_c('v-card',{staticClass:"justify-center",attrs:{"flat":"","color":"transparent"}},[_c('v-item-group',{attrs:{"mandatory":""}},[_c('v-container',[_c('v-row',{attrs:{"dense":""}},[_c('v-hover',{attrs:{"open-delay":"10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto justify-center mr-5 rounded-lg mb-4",class:{ 'on-hover': hover },attrs:{"elevation":hover ? 16 : 2,"width":"190","height":"180","link":"","to":{ name: 'ListaFactura' }}},[_c('v-card-text',{staticClass:"text-center"},[_c('v-icon',{attrs:{"size":"110","color":"primary"}},[_vm._v("mdi-file-document-multiple-outline")]),_c('h2',{staticClass:"mt-3"},[_vm._v("Factura")])],1)],1)]}}])}),_c('v-hover',{attrs:{"open-delay":"10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto justify-center  ml-5 mr-5 rounded-lg mb-4",class:{ 'on-hover': hover },attrs:{"elevation":hover ? 16 : 2,"width":"190","height":"180","link":"","to":{ name: 'ListaArrendamientos' }}},[_c('v-card-text',{staticClass:"text-center"},[_c('v-icon',{attrs:{"size":"110","color":"primary"}},[_vm._v("mdi-file-table")]),_c('h2',{staticClass:"mt-2"},[_vm._v("Factura Arrendamiento")])],1)],1)]}}])}),_c('v-hover',{attrs:{"open-delay":"10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto justify-center  ml-5 mr-5 rounded-lg mb-4",class:{ 'on-hover': hover },attrs:{"elevation":hover ? 16 : 2,"width":"190","height":"180","link":"","to":{ name: 'ListaHonorarios' }}},[_c('v-card-text',{staticClass:"text-center"},[_c('v-icon',{attrs:{"size":"110","color":"primary"}},[_vm._v("mdi-file-account-outline")]),_c('h2',{staticClass:"mt-2"},[_vm._v("Factura Honorarios")])],1)],1)]}}])}),_c('v-hover',{attrs:{"open-delay":"10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto justify-center ml-5 rounded-lg mb-4",class:{ 'on-hover': hover },attrs:{"elevation":hover ? 16 : 2,"width":"190","height":"180","link":"","to":{ name: 'ListaCartaPorte' }}},[_c('v-card-text',{staticClass:"text-center"},[_c('v-icon',{attrs:{"size":"110","color":"primary"}},[_vm._v("mdi-file-marker-outline")]),_c('h2',{staticClass:"mt-2"},[_vm._v("Ingreso CartaPorte")])],1)],1)]}}])})],1)],1)],1)],1),_c('v-card',{staticClass:"justify-center",attrs:{"flat":"","color":"transparent"}},[_c('v-item-group',{attrs:{"mandatory":""}},[_c('v-container',[_c('v-row',{attrs:{"dense":""}},[_c('v-hover',{attrs:{"open-delay":"10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto justify-center  mr-5 rounded-lg mb-4",class:{ 'on-hover': hover },attrs:{"elevation":hover ? 16 : 2,"width":"190","height":"180","link":"","to":{ name: 'ListaTrasladoCartaPorte' }}},[_c('v-card-text',{staticClass:"text-center"},[_c('v-icon',{attrs:{"size":"110","color":"primary"}},[_vm._v("mdi-file-send-outline")]),_c('h2',{staticClass:"mt-2"},[_vm._v("Traslado CartaPorte")])],1)],1)]}}])}),_c('v-hover',{attrs:{"open-delay":"10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto justify-center ml-5 mr-5 rounded-lg mb-4",class:{ 'on-hover': hover },attrs:{"elevation":hover ? 16 : 2,"width":"190","height":"180","link":"","to":{ name: 'ListaNotaCredito' }}},[_c('v-card-text',{staticClass:"text-center"},[_c('v-icon',{attrs:{"size":"110","color":"primary"}},[_vm._v("mdi-file-document-edit-outline")]),_c('h2',{staticClass:"mt-2"},[_vm._v("Nota de Credito")])],1)],1)]}}])}),_c('v-hover',{attrs:{"open-delay":"10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto justify-center ml-5 mr-5 rounded-lg mb-4",class:{ 'on-hover': hover },attrs:{"elevation":hover ? 16 : 2,"width":"190","height":"180","link":"","to":{ name: 'ListaComercioExterior' }}},[_c('v-card-text',{staticClass:"text-center"},[_c('v-icon',{attrs:{"size":"110","color":"primary"}},[_vm._v("mdi-file-undo-outline")]),_c('h2',[_vm._v("Comercio Exterior")])],1)],1)]}}])}),_c('v-hover',{attrs:{"open-delay":"10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto justify-center ml-5 mr-5 rounded-lg mb-4",class:{ 'on-hover': hover },attrs:{"elevation":hover ? 16 : 2,"width":"190","height":"180","link":"","to":{ name: 'ListaAdenda' }}},[_c('v-card-text',{staticClass:"text-center"},[_c('v-icon',{attrs:{"size":"110","color":"primary"}},[_vm._v("mdi-book-edit-outline")]),_c('h2',{staticClass:"mt-2"},[_vm._v("Factura de Adenda")])],1)],1)]}}])}),_c('v-hover',{attrs:{"open-delay":"10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto justify-center ml-5 rounded-lg mb-4",class:{ 'on-hover': hover },attrs:{"elevation":hover ? 16 : 2,"width":"190","height":"180","link":"","to":{ name: 'ListaComplementoPago' }}},[_c('v-card-text',{staticClass:"text-center"},[_c('v-icon',{attrs:{"size":"110","color":"primary"}},[_vm._v("mdi-credit-card-plus-outline")]),_c('h2',{staticClass:"mt-2"},[_vm._v("Complemento de Pago")])],1)],1)]}}])})],1)],1)],1),_c('v-main',[_c('v-container',{attrs:{"fluid":""}},[_c('v-fade-transition',{attrs:{"mode":"out-in"}},[_c('router-view')],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }