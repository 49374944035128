<template>
  <div>
    <!--Dialog loading-->
    <v-dialog v-model="loading" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Cargando datos...
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!--Dialogo nuevo origen-->
    <v-dialog v-model="dialogOrigenNuevo" persistent>
      <ubicacion-origen @VentanaOrigen="CierraVentanaOrigen()"></ubicacion-origen>
    </v-dialog>
    <v-card>
      <v-card-title style="font: bold;" class="text-h4 text--primary">
        <!--Boton regresar-->
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" class="mx-2" icon large link :to="{ name: 'CatalogosCartaPorte' }">
              <v-icon size="40" color="primary">mdi-arrow-left-thick</v-icon>
            </v-btn>
          </template>
          <span>Regresar</span>
        </v-tooltip>
        Ubicaciones de Origen
        <v-spacer></v-spacer>
        <div class="text-center">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" class="mx-2" fab dark color="blue" @click="listarOrigenes()">
                <v-icon dark>mdi-update</v-icon>
              </v-btn>
            </template>
            <span>Actualizar</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" class="mx-2" fab dark color="green" @click="nuevoOrigen()">
                <v-icon dark>mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>Nuevo</span>
          </v-tooltip>
        </div>
      </v-card-title>
      <v-divider></v-divider>
      <v-data-table :headers="headers" :items="items">
        <!-- BOTONES DE ACCION  -->
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon dense class="mr-2" v-bind="attrs" v-on="on" color="blue" @click="editar(item)">mdi-lead-pencil
              </v-icon>
            </template>
            <span>Editar</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon dense class="mr-2" v-bind="attrs" v-on="on" color="red" @click="deleteItem(item)">mdi-delete
              </v-icon>
            </template>
            <span>Eliminar</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
  import axios from 'axios'
  import UbicacionOrigen from '../Comprobantes/CartaPorte/UbicacionOrigen.vue'
  export default {
    components: {
      UbicacionOrigen
    },
    data() {
      return {
        headers: [
          { text: 'Acciones', value: 'actions' },
          { text: "id", value: "iDUbicacion" },
          { text: "Estado", value: "domicilio.estado.clave" },
          { text: "Municipio", value: "domicilio.municipio.descripcion" },
          { text: "Domicilio", value: "domicilio.calle" },
          { text: "CP", value: "domicilio.codigoPostal" },
          { text: "Nombre del Remitente", value: "nombreRemitenteDestinatario" },
          { text: "RFC Remitente", value: "rfcRemitenteDestinatario" },
          { text: "Tipo de ubicación", value: "tipoUbicacion" },
          { text: "Residencia fiscal", value: "residenciaFiscal" },
          { text: "numRegIdTrib", value: "numRegIdTrib" },
        ],
        items: [],
        // nuevo oriegen
        dialogOrigenNuevo: false,

        // loaging
        loading: false,
      };
    },
    mounted() {

    },
    computed: {
      datosEmpresa() {
        return this.$store.state.usuario;
      },
      origenes() {
        return this.$store.state.origenes;
      },
    },
    created(){
    this.listarOrigenes();
  },
    methods: {
      //ORIGEN DESTINO
      nuevoOrigen() {
        let origenes = {
          id: 0,
          domicilio:{
            estado:'',
            pais: { clave: 'MEX', descripcion: 'México', pais: 'MEX | México' },
          }
        }
        if (this.$store.state.origenes.id = -1) {
          this.$store.state.origenes = Object.assign({}, origenes)
        }
        this.dialogOrigenNuevo = true
      },
      CierraVentanaOrigen() {
        this.dialogOrigenNuevo = false;
      },
      // listar origenes
      async listarOrigenes() {
        let tipo = 'OR'
        this.loading = true;
        try {
          let response = await axios.get('CartaPorte/GetUbicaciones/' + this.datosEmpresa.empresaBase + '/' + tipo);
          this.items = response.data;
          console.log(this.items)
          this.loading = false;
        } catch (error) {
          console.log(error);
        }
      },
      editar(item) {
        let indice = this.items.indexOf(item)
        const origenes = Object.assign({}, item)
        this.$store.state.origenes = Object.assign({}, origenes);
        console.log(this.$store.state.origenes)
        this.dialogOrigenNuevo = true;
      },
      async deleteItem(item) {
        let id = item.id
        try {
          let response = await axios.post('CartaPorte/DeleteUbicacion/' + this.datosEmpresa.empresaBase + '/' + id);
          this.listarOrigenes();
          this.loading = false;
        } catch (error) {
          console.log(error);
        }
      }
    },
  };
</script>