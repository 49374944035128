<template>
    <div>
        <v-card class="auto">
            <v-data-table :headers="headersConceptos" :items="itemsListConceptos" item-key="item">
                 <!-- BOTONES DE ACCION  -->
                <template v-slot:[`item.actions`]="{ item }">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon @click="editItem(item)" color="blue" dense class="mr-2" v-bind="attrs" v-on="on"
                                dark>mdi-lead-pencil</v-icon>
                        </template>
                        <span>Editar</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon @click="eliminarItem(item)" color="red" dense class="mr-2" v-bind="attrs" v-on="on"
                                dark>mdi-delete-empty</v-icon>
                        </template>
                        <span>Eliminar</span>
                    </v-tooltip>
                </template>
                <template v-slot:top>
                    <v-toolbar flat>
                        <v-toolbar-title>Conceptos</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-dialog persistent v-model="dialogConcepto" max-width="800px">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on" fab>
                                    <v-icon>mdi-archive-plus</v-icon>
                                </v-btn>
                            </template>
                            <v-card>
                                <v-toolbar color="primary" dark >
                                    <v-btn icon @click="cerrarDialogNuevoConcepto()">
                                        <v-icon large>mdi-close-circle</v-icon>
                                      </v-btn>
                                      <v-spacer></v-spacer>
                                      <h3>Concepto</h3>
                                      <v-spacer></v-spacer>
                                      <v-btn icon @click="PushConcepto()">
                                        <v-icon large>mdi-check-circle</v-icon>
                                      </v-btn>
                                </v-toolbar>
                                <v-card-text>
                                    <!-- SELECCIONA CONCEPTO PARA AGREGAR A LA LISTA DE CONCEPTOS -->
                                    <v-row>
                                        <!-- BUSCADOR POR CLAVE O DESCRIPCION -->
                                        <v-col cols="12">
                                            <v-autocomplete label="Buscar por clave o descripción" v-model="selectConcepto" :items="$store.state.listConceptosStore" :loading="loadingConcepto" :search-input.sync="searchConcepto" item-text="concepto" append-outer-icon="mdi-tag-plus" @click:append-outer="nuevoConcepto()" return-object></v-autocomplete>
                                        </v-col>
                                        <!-- CLAVE DEL PRODUCTO O SERVICIO SAT -->
                                        <v-col cols="12">
                                            <v-autocomplete label="Clave del producto o servicio" :items="itemsClaveProdServ" :loading="loadingClaveProdServ" :search-input.sync="searchClaveProdServ" item-text="claveProdServ" return-object placeholder="Ingrese el parámetro de bíusqueda" v-model="selectConcepto.claveProdServ"></v-autocomplete>
                                        </v-col>
                                        <!-- OBJETO DE IMPUESTOS -->
                                        <v-col cols="12" md="6" sm="12">
                                            <v-select label="Objeto de impuestos" :items="itemsObjetoImp" v-model="selectConcepto.objetoImp" return-object item-text="objetoImp"></v-select>
                                        </v-col>
                                        <!-- NO DE IDENTIFICACION, CLAVE INTERNA -->
                                        <v-col cols="12" md="6" sm="12">
                                            <v-text-field label="No. de identificación" v-model="selectConcepto.noIdentificacion"></v-text-field>
                                        </v-col>
                                        <!-- DESCRIPCION INTERNA DEL PRODUCTO O SERVICIO -->
                                        <v-col cols="12">
                                            <v-text-field label="Descripción" v-model="selectConcepto.descripcion"></v-text-field>
                                        </v-col>
                                        <!-- CANTIDAD A FACTURARA -->
                                        <v-col cols="12" md="4" sm="12">
                                            <v-text-field label="Cantidad" v-model="selectConcepto.cantidad"></v-text-field>
                                            <span hidden>{{ formatoDosDecimales(selectConcepto.cantidad) }}</span>
                                        </v-col>
                                        <!-- CLAVE DE UNIDAD -->
                                        <v-col cols="12" md="4" sm="12">
                                            <v-autocomplete label="Clave de unidad" :items="itemsClaveUnidad" :loading="loadingClaveUnidad" :search-input.sync="searchClaveUnidad" item-text="claveUnidad" return-object placeholder="Ingrese el parámetro de bíusqueda"  v-model="selectConcepto.claveUnidad"></v-autocomplete>
                                        </v-col>
                                        <!-- UNIDAD DE MEDIDA INTERNA -->
                                        <v-col cols="12" md="4" sm="12">
                                            <v-text-field label="Unidad" v-model="selectConcepto.unidad"></v-text-field>
                                        </v-col>
                                        <!-- RADIO GROUP PARA DEFINIR EL CALCULO POR IMPORTE O POR PRECIO -->
                                        <v-col cols="4">
                                            <v-radio-group v-model="radioGroup" row>
                                                <v-radio label="importe" value="importe"></v-radio>
                                                <!-- <v-radio label="precio" value="precio"></v-radio> -->
                                            </v-radio-group>
                                        </v-col>
                                        <!-- VALOR UNITARIO DEL BIEN O SERVICIO -->
                                        <template v-if="radioGroup === 'importe'">
                                            <v-col cols="8">
                                                <v-text-field label="Valor unitario" v-model="selectConcepto.valorUnitario" @keydown.enter="PushConcepto"></v-text-field>
                                                <span hidden>{{ formatoDosDecimales(selectConcepto.valorUnitario) }}</span>
                                            </v-col>
                                        </template>
                                        <!-- PRECIO UNITARIO DEL BIEN O SERVICIO -->
                                        <template  v-if="radioGroup === 'precio'">
                                            <v-col cols="8">
                                                <v-text-field label="Precio unitario" v-model="selectConcepto.precioUnitario" @keydown.enter="PushConcepto"></v-text-field>
                                                <span hidden>{{ formatoDosDecimales(selectConcepto.precioUnitario) }}</span>
                                            </v-col>
                                        </template>
                                    </v-row>
                                    <v-system-bar color="primary" dark><strong>IMPUESTOS</strong></v-system-bar>
                                    <!-- IMPUESTOS -->
                                    <v-data-table :headers="headersImpuestos" :items="itemsImpuestos" v-model="selectItemsImpuestos" item-key="nombre" show-select>
                                    </v-data-table>
                                </v-card-text>
                            </v-card>
                        </v-dialog>
                    </v-toolbar>
                </template>
            </v-data-table>
        </v-card>
        <span hidden>{{datosEmpresa}}</span>
    </div>
</template>

<script>
    import axios from 'axios'
    import Concepto from '../Concepto.vue'


    export default {
        name: 'NotaCreditoConceptos',
        components:{
            Concepto,
        },

        data: () => ({
            //CLAVE DEL PRODUCTO O SERVICIO
            // claveProdServ: {},
            itemsClaveProdServ: [],
            limitClaveProdServ: 60,
            loadingClaveProdServ: false, 
            searchClaveProdServ: null,

            // LISTA DE CONCEPTOS
            // itemsListConceptos:[],

            //CONCEPTO
            selectConcepto: {},
            defaultConcepto: {},
            searchConcepto: null,
            loadingConcepto: false,
            limitConcepto : 60,

            //PARA AGREGAR UN NUEVO CONCEPTO
            dialogConcepto: false,
            headersConceptos: [
                { text: 'Acciones', sortable:false, value: 'actions' },
                { text: 'item', sortable:false, value: 'item' },
                { text: 'Clave del producto o servicio', sortable:false, value: 'claveProdServ.claveProdServ' },
                { text: 'No de Identificación', sortable:false, value: 'noIdentificacion' },
                { text: 'Descripción', sortable:false, value: 'descripcion' },
                { text: 'Cantidad', sortable:false, value: 'cantidad' },
                { text: 'Valor unitario', sortable:false, value: 'valorUnitario' },
                { text: 'Precio unitario', sortable:false, value: 'precioUnitario' },
                { text: 'Descuento', sortable:false, value: 'descuento' },
                { text: 'Importe', sortable:false, value: 'importe' },
                { text: 'Total', sortable:false, value: 'total' },
            ],

            //OBJETO DE IMPUESTOS
            itemsObjetoImp: [],

            //CLAVE DE UNIDAD
            // claveUnidad: {},
            itemsClaveUnidad: [],
            limitClaveUnidad: 60,
            loadingClaveUnidad: false,
            searchClaveUnidad : null,

            //NUEVO CONCEPTO
            dialogNuevoConcepto: false,

            //RADIO PARA VER SI SE CALCULA POR IMPORTE O POR PRECIO
            radioGroup: 'importe',

            //IMPUESTOS
            headersImpuestos: [
                { text: 'Nombre', sortable:false, value: 'nombre' },
                { text: 'Impuesto', sortable:false, value: 'impuesto' },
                { text: 'Tipo factor', sortable:false, value: 'tipoFactor' },
                { text: 'Tasa o cuota', sortable:false, value: 'tasaOCuota' },
                { text: 'Tipo', sortable:false, value: 'tipo' },
            ],
            itemsImpuestos: [
                { nombre:'IVA(16%)', base_:0, impuesto:'002', tipoFactor:'Tasa', tasaOCuota:16, importe:0, tipo:'traslado' },
                { nombre:'IVA(8%)', base_:0, impuesto:'002', tipoFactor:'Tasa', tasaOCuota:8, importe:0, tipo:'traslado' },
                { nombre: 'IVA(4%)', base_: 0, impuesto: '002', tipoFactor: 'Tasa', tasaOCuota: 4, importe: 0, tipo: 'retenido' },
                { nombre: 'IVA(0%)', base_: 0, impuesto: '002', tipoFactor: 'Tasa', tasaOCuota: 0, importe: 0, tipo: 'traslado' },
                { nombre: 'IVA(%)', base_: 0, impuesto: '002', tipoFactor: 'Tasa', tasaOCuota: 10.6667, importe: 0, tipo: 'retenido' },
            ],
            selectItemsImpuestos:[],

            //TOTAL DE IMPUESTOS
            totalImpuestosTrasladados: 0.00,
            totalImpuestosRetenidos: 0.00,
            subTotal: 0.00,
            total: 0.00,
            listRetenciones:[],
            listTrasladados:[],
        }),

        watch:{
            async searchConcepto(val){
                if (this.itemsconcepto.length > 0) return
                if (this.loadingConcepto) return
                this.loadingConcepto = true
                try{
                    let response = await axios.get('Comprobantes/GetConceptos/' + this.datosEmpresa.empresaBase)
                    this.$store.state.listConceptosStore = response.data;
                }catch(error){
                    console.log(error)
                }
                this.loadingConcepto = false
            },
            async searchClaveProdServ(val){
                if (this.itemsclaveProdServ.length > 0) return
                if (this.loadingClaveProdServ) return
                this.loadingClaveProdServ = true
                try{
                    let response = await axios.get('CatalogoComprobantes/GetClaveProdServ')
                    this.itemsClaveProdServ = response.data;
                }catch(error){
                    console.log(error)
                }
                this.loadingClaveProdServ = false
            },
            async searchClaveUnidad(val){
                if(this.itemsclaveUnidad.length > 0) return
                if(this.loadingClaveUnidad) return
                this.loadingClaveUnidad = true
                try{
                    let response = await axios.get('CatalogoComprobantes/GetClaveUnidad')
                    this.itemsClaveUnidad = response.data;
                }catch(err){
                    console.log(err)
                }
                this.loadingClaveUnidad = false
            },
        },

        computed: {
            itemsconcepto(){
                return this.$store.state.listConceptosStore.map(entry => {
                const Description = entry.descripcion.length > this.limitConcepto
                    ? entry.descripcion.slice(0, this.limitConcepto) + '...'
                    : entry.descripcion

                return Object.assign({}, entry, { Description })
                })
            },
            itemsclaveProdServ(){
                return this.itemsClaveProdServ.map(entry => {
                    const Description = entry.descripcion.length > this.limitClaveProdServ
                        ? entry.descripcion.slice(0, this.limitClaveProdServ) + '...'
                        : entry.descripcion

                    return Object.assign({}, entry, { Description })
                })
            },
            itemsclaveUnidad(){
                return this.itemsClaveUnidad.map(entry => {
                    const Description = entry.nombre.length > this.limitClaveUnidad
                    ? entry.nombre.slice(0, this.limitClaveUnidad) + '...'
                    :entry.nombre
                    return Object.assign( {}, entry, { Description })
                })
            },
            itemsListConceptos(){
                return this.$store.state.notaCreditoStore.conceptos;
            },
            datosEmpresa(){
                return this.$store.state.usuario;
            },
        
        },

        created () {
            this.Get_lista_c_claveprodserv();
            this.Get_lista_c_claveunidadStore();
            this.GetObjetoImp();
        },

        methods: {
            editItem(item) {
                this.editedIndex = this.itemsListConceptos.indexOf(item)
                this.selectConcepto = Object.assign({}, item)

                this.dialogConcepto = true
            },

            cerrarConcepto() {
                this.dialogConcepto = false
                this.$nextTick(() => {
                    this.selectConcepto = Object.assign({}, this.selectConcepto)
                    this.editedIndex = -1
                })
            },

            eliminarItem(item) {
                const editedIndex = this.itemsListConceptos.indexOf(item)
                //this.selectConcepto = Object.assign({}, item)
                this.itemsListConceptos.splice(editedIndex, 1)

                this.listTrasladados = [];

                let sumaImporte = 0.00;
                let sumaTrasladados = 0.00;
                let sumaRetenidos = 0.00;
                let sumaTotal = 0.00

                for (let a of this.itemsListConceptos) {
                    sumaImporte = sumaImporte + a.importe;

                    //IMPUESTOS TRASLADADOS DEL CONCEPTO
                    let sumaT = 0.00;
                    for (let t of a.impuestos.traslados) {
                        sumaTrasladados = sumaTrasladados + t.importe;
                        sumaT = sumaT + t.importe

                        //AGREGAMOS A LA LISTA GENERAL DE IMPUESTOS TRASLADADOS
                        this.listTrasladados.push(t);

                    }
                    let sumaR = 0.00;
                    for (let r of a.impuestos.retenciones) {
                        sumaRetenidos = sumaRetenidos + r.importe;
                        sumaR = sumaR + r.importe
                        sumaR = Math.round(sumaR * 100)/100   
                        //AGREGAMOS A LA LISTA GENERAL DE IMPUESTOS TRASLADADOS
                        this.listRetenidos.push(r);
                    }
                    a.total = (Math.round(a.importe * 100) / 100) + (Math.round(sumaT * 100) / 100)
                    a.total = Math.round(a.total*100)/100
                    a.precioUnitario = Math.round((a.total / a.cantidad) * 100) / 100
                    sumaT = 0.00;
                    sumaR = 0.00;
                }

                this.selectConcepto = {}

                this.subTotal = Math.round(sumaImporte * 100) / 100
                this.totalImpuestosTrasladados = Math.round(sumaTrasladados * 100) / 100
                this.totalImpuestosRetenidos = Math.round(sumaRetenidos * 100) / 100
                this.total = Math.round((this.subTotal + this.totalImpuestosTrasladados - this.totalImpuestosRetenidos) * 100) / 100

                this.$store.state.notaCreditoStore.impuestos.traslados = [];
                var obj = this.agrupaImpuestos(this.listTrasladados, 'impuesto', 'tasaOCuota')
                for (const prop in obj) {
                    let tra = Object.assign({}, obj[prop])
                    this.$store.state.notaCreditoStore.impuestos.traslados.push(tra)
                }
                this.$store.state.notaCreditoStore.impuestos.retenciones = [];
                var objR = this.agrupaImpuestos(this.listRetenidos, 'impuesto', 'tasaOCuota')
                for (const prop in objR) {
                    let ret = Object.assign({}, objR[prop])
                    this.$store.state.notaCreditoStore.impuestos.retenciones.push(ret)
                }
                //this.$store.state.notaCreditoStore.impuestos.retenciones = [];
                this.$store.state.notaCreditoStore.impuestos.totalImpuestosTrasladados = this.totalImpuestosTrasladados;
                this.$store.state.notaCreditoStore.impuestos.totalImpuestosRetenidos = this.totalImpuestosRetenidos;
                this.$store.state.notaCreditoStore.subTotal = this.subTotal;
                this.$store.state.notaCreditoStore.total = this.total;             
            },
             cerrarDialogNuevoConcepto(){
                this.dialogConcepto = false
            },
            //FORMATO NUMERICO
             formatoDosDecimales(value) {
                let val = (value/1).toFixed(2)
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            },
            async Get_lista_c_claveprodserv(){
                if(this.$store.state.lista_c_claveprodservStore.length < 1){
                    try{
                        let response = await axios.get('CatalogoComprobantes/GetClaveProdServ')
                        this.$store.state.lista_c_claveprodservStore = response.data;
                    }catch(error){
                        console.log(error)
                    }
                }
                this.itemsClaveProdServ = this.$store.state.lista_c_claveprodservStore.slice();
            },
            async Get_lista_c_claveunidadStore(){
                if(this.$store.state.lista_c_claveunidadStore.length < 1){
                    try{
                        let response = await axios.get('CatalogoComprobantes/GetClaveUnidad')
                        this.$store.state.lista_c_claveunidadStore = response.data;
                    }catch(error){
                        console.log(error)
                    }
                }
                this.itemsClaveUnidad = this.$store.state.lista_c_claveunidadStore.slice();
            },
            async GetObjetoImp(){
                try{
                    let response = await axios.get('CatalogoComprobantes/GetObjetoImp')
                    this.itemsObjetoImp = response.data
                }catch(err){
                    console.log(err)
                }
            },
            
             PushConcepto() {
                this.listTrasladados = [];
                this.listRetenidos = [];

                var concepto = Object.assign({}, this.selectConcepto)
                concepto.importe = concepto.valorUnitario * concepto.cantidad
                //concepto.item = this.itemsListConceptos.length+1
                var impuestos = {
                    retenciones: [],
                    totalImpuestosRetenidos: 0,
                    traslados: [],
                    totalImpuestosTrasladados: 0,
                }

                let listImpuestos = this.selectItemsImpuestos.slice();

                for (let impuesto of listImpuestos) {
                    const tasaOcuota = impuesto.tasaOCuota / 100;
                    impuesto.base_ = concepto.importe;
                    impuesto.importe = Math.round((concepto.importe * tasaOcuota) * 1000000) / 1000000;
                    //impuesto.importe = Math.round(impuesto.importe * 100)/100   
                    if(impuesto.tipo === 'traslado'){
                        const impuestoT = Object.assign({}, impuesto)
                        impuestos.traslados.push(impuestoT);
                    }else{
                        const impuestoT = Object.assign({}, impuesto)
                        impuestos.retenciones.push(impuestoT);
                    }
                } 

                concepto.impuestos = Object.assign({}, impuestos)

                //AGREGAMOS EL CONCEPTO A LA LISTA
                let contador = 1;
                let sumaImporte = 0.00;
                let sumaTrasladados = 0.00;
                let sumaRetenidos = 0.00;
                let sumaTotal = 0.00
                
                // editar item concepto
                if (this.editedIndex > -1) {
                    console.log('if')
                        Object.assign(this.itemsListConceptos[this.editedIndex], concepto)
                        this.editedIndex = -1

                } else {
                    console.log('else')
                    this.itemsListConceptos.push(concepto);
                }
                for (let a of this.itemsListConceptos) {
                    sumaImporte = sumaImporte + a.importe;
                    a.item = contador++

                    //IMPUESTOS TRASLADADOS DEL CONCEPTO
                    let sumaT = 0.00;
                    for (let t of a.impuestos.traslados) {
                        const sImporte = Math.round(t.importe*100)/100
                        console.log(sImporte)
                        sumaTrasladados = sumaTrasladados + sImporte
                        sumaT = sumaT + sImporte
                        sumaT = Math.round(sumaT * 100)/100  
                        //AGREGAMOS A LA LISTA GENERAL DE IMPUESTOS TRASLADADOS
                        this.listTrasladados.push(t);
                    }

                    let sumaR = 0.00;
                    for (let r of a.impuestos.retenciones) {
                        sumaRetenidos = sumaRetenidos + r.importe;
                        sumaR = sumaR + r.importe
                        sumaR = Math.round(sumaR * 100)/100   
                        //AGREGAMOS A LA LISTA GENERAL DE IMPUESTOS TRASLADADOS
                        this.listRetenidos.push(r);
                    }

                    a.total = (Math.round(a.importe * 100) / 100) + (Math.round(sumaT * 100) / 100) - (Math.round(sumaR * 100)/ 100)
                    a.total = Math.round(a.total*100)/100
                    a.precioUnitario = Math.round((a.total / a.cantidad) * 100) / 100
                    sumaT = 0.00;
                    sumaR = 0.00;
                }
                this.selectConcepto = {}

                this.subTotal = Math.round(sumaImporte * 100) / 100
                this.totalImpuestosTrasladados = Math.round(sumaTrasladados * 100) / 100
                this.totalImpuestosRetenidos = Math.round(sumaRetenidos * 100) / 100
                this.total = Math.round((this.subTotal + this.totalImpuestosTrasladados - this.totalImpuestosRetenidos) * 100) / 100

                this.$store.state.notaCreditoStore.impuestos.traslados = [];
                var obj = this.agrupaImpuestos(this.listTrasladados, 'impuesto', 'tasaOCuota')
                for (const prop in obj) {
                    let tra = Object.assign({}, obj[prop])
                    this.$store.state.notaCreditoStore.impuestos.traslados.push(tra)
                }
                this.$store.state.notaCreditoStore.impuestos.retenciones = [];
                var objR = this.agrupaImpuestos(this.listRetenidos, 'impuesto', 'tasaOCuota')
                for (const prop in objR) {
                    let ret = Object.assign({}, objR[prop])
                    this.$store.state.notaCreditoStore.impuestos.retenciones.push(ret)
                }
                //this.$store.state.notaCreditoStore.impuestos.retenciones = [];
                this.$store.state.notaCreditoStore.impuestos.totalImpuestosTrasladados = this.totalImpuestosTrasladados;
                this.$store.state.notaCreditoStore.impuestos.totalImpuestosRetenidos = this.totalImpuestosRetenidos;
                this.$store.state.notaCreditoStore.subTotal = this.subTotal;
                this.$store.state.notaCreditoStore.total = this.total;

                console.log(this.itemsListConceptos)
                console.log(this.$store.state.notaCreditoStore)
                this.cerrarConcepto();
            },
            agrupaImpuestos(miarray, prop, propa){
                return miarray.reduce(function(groups, item) {
                    var val = item[prop, propa];
                    groups[val] = groups[val] || { nombre: item.nombre, base_: 0, impuesto: item.impuesto, tipoFactor: item.tipoFactor, tasaOCuota: item.tasaOCuota, importe:0, tipo: item.tipo };
                    groups[val].base_ += item.base_;
                    groups[val].importe += item.importe;
                    groups[val].importe = Math.round(groups[val].importe*100)/100;
                    return groups;
                }, {});
            },
        
        },
    }
</script>