<template>
    <div>
        <v-card>
            <v-toolbar color="primary" dark>
                <v-btn icon @click="cierraVentana()">
                    <v-icon large>mdi-close-circle</v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <h3>Ingreso Carta Porte</h3>
                <v-spacer></v-spacer>
                <!-- Boton para guardar factura -->
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" icon @click="GuardarComprobante()">
                            <v-icon large>mdi-content-save</v-icon>
                        </v-btn>
                    </template>
                    <span>Guardar</span>
                </v-tooltip>
                <!-- Boton para ver vista previa -->
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" icon @click="GetComprobante()">
                            <v-icon large>mdi-file-pdf-box</v-icon>
                        </v-btn>
                    </template>
                    <span>Ver vista Previa</span>
                </v-tooltip>
                <!-- Boton para ver vista previa -->
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" icon @click="timbraComprobante()">
                            <v-icon large>mdi-check-circle</v-icon>
                        </v-btn>
                    </template>
                    <span>Timbrar</span>
                </v-tooltip>
            </v-toolbar>
            <v-card-text>
                <span hidden>{{ comprobante }}</span>
                <v-stepper alt-labels v-model="e1" non-linear>
                    <v-stepper-header>
                        <v-stepper-step step="1" :complete="e1 > 1" editable>
                            Datos Generales
                            <small>Obligatorio</small>
                        </v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step step="2" :complete="e1 > 2" editable>
                            Conceptos
                            <small>Obligatorio</small>
                        </v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step step="3" :complete="e1 > 3" editable>
                            Complemento carta porte
                            <small>Opcional</small>
                        </v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step step="4" :complete="e1 > 4" editable>
                            CFDI'S relacionados
                            <small>Opcional</small>
                        </v-stepper-step>
                    </v-stepper-header>

                    <v-stepper-items>
                        <!-- DATOS GENERALES -->
                        <v-stepper-content step="1">
                            <v-card class="auto">
                                <v-row>
                                    <!-- SERIE -->
                                    <v-col cols="12" md="3" sm="6">
                                        <v-combobox label="Serie" :items="itemsSerie" v-model="comprobante.serie"
                                            ref="_serie"></v-combobox>
                                    </v-col>
                                    <!-- FOLIO -->
                                    <v-col cols="12" md="3" sm="6">
                                        <v-text-field label="Folio" type="number" v-model="comprobante.folio"
                                            ref="_folio"></v-text-field>
                                    </v-col>
                                    <!-- FECHA DE ELABORACION -->
                                    <v-col cols="12" md="3" sm="6">
                                        <v-menu v-model="menuDateComprobante" :close-on-content-click="false"
                                            max-width="290">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field :value="computedDateComprobante" label="Fecha" readonly
                                                    v-bind="attrs" v-on="on" locale="es"></v-text-field>
                                            </template>
                                            <v-date-picker locale="es" v-model="dateComprobante"
                                                @change="menuDateComprobante = false"></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <!-- HORA DEL COMPROBANTE -->
                                    <v-col cols="12" md="3" sm="6">
                                        <v-menu ref="menu" v-model="menuTimeComprobante" :close-on-content-click="false"
                                            :nudge-right="40" :return-value.sync="timeComprobante"
                                            transition="scale-transition" offset-y max-width="290px" min-width="290px">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field v-model="timeComprobante" label="Hora"
                                                    prepend-icon="mdi-clock-time-four-outline" readonly v-bind="attrs"
                                                    v-on="on"></v-text-field>
                                            </template>
                                            <v-time-picker v-if="menuTimeComprobante" v-model="timeComprobante"
                                                full-width @click:minute="$refs.menu.save(timeComprobante)">
                                            </v-time-picker>
                                        </v-menu>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <!-- NOMBRE DEL CLIENTE -->
                                    <v-col cols="12">
                                        <v-autocomplete label="Cliente / Receptor" v-model="comprobante.receptor"
                                            :items="$store.state.listReceptorStore" :loading="loadingReceptor"
                                            :search-input.sync="searchReceptor" item-text="receptor" return-object
                                            append-outer-icon="mdi-account-plus" @click:append-outer="nuevoReceptor()">
                                        </v-autocomplete>
                                    </v-col>
                                    <!-- USO DEL CFDI -->
                                    <v-col cols="12">
                                        <v-select :items="itemsUsoCfdi" label="Uso de CFDI"
                                            v-model="comprobante.receptor.usoCFDI" item-text="usoCfdi" return-object>
                                        </v-select>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <!-- METODO DE PAGO -->
                                    <v-col cols="12" md="4" sm="12">
                                        <v-select label="Metodo de pago" :items="itemsMetodoPago"
                                            v-model="comprobante.metodoPago" item-text="metodoPago" return-object
                                            @change="ValidaMetodoPago()"></v-select>
                                    </v-col>
                                    <!-- FORMA DE PAGO -->
                                    <v-col cols="12" md="4" sm="12">
                                        <v-select label="Forma de pago" :items="itemsFormaPago"
                                            v-model="comprobante.formaPago" item-text="formaPago" return-object
                                            :readonly="enabledFormaPago"></v-select>
                                    </v-col>
                                    <!-- CONDICIONES DE PAGO -->
                                    <v-col cols="12" md="4" sm="12">
                                        <v-text-field label="Condiciones de pago"
                                            v-model="comprobante.condicionesDePago"></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <!-- MONEDA -->
                                    <v-col cols="12" md="3" sm="12">
                                        <v-select label="Moneda" :items="itemsMonedas" v-model="comprobante.moneda"
                                            @change="ValidaMoneda()" item-text="moneda" return-object></v-select>
                                    </v-col>
                                    <!-- TIPO DE CAMBIO -->
                                    <v-col cols="12" md="3" sm="12">
                                        <v-text-field label="Tipo de cambio" v-model="comprobante.tipoCambio"
                                            ref="_tipoDeCambio" type="number" :readonly="enabledTipoCambio">
                                        </v-text-field>
                                    </v-col>
                                    <!-- OC -->
                                    <v-col cols="12" md="6" sm="12">
                                        <v-text-field label="Orden de compra" v-model="comprobante.oc"></v-text-field>
                                    </v-col>
                                    <!-- NOTAS -->
                                    <v-col cols="12">
                                        <v-text-field label="Notas" v-model="comprobante.notas"></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-stepper-content>

                        <!-- CONCEPTOS -->
                        <v-stepper-content step="2">
                            <conceptos></conceptos>
                        </v-stepper-content>

                        <!-- COMPLEMENTO CARTA PORTE -->
                        <v-stepper-content step="3">
                            <complemento></complemento>
                        </v-stepper-content>

                        <!-- RELACIONA CFDI -->
                        <v-stepper-content step="4">
                            <relacionados></relacionados>
                        </v-stepper-content>
                    </v-stepper-items>
                </v-stepper>
            </v-card-text>
            <!-- MOSTRAMOS LOS TOTALES -->
            <v-card-actions>
                <v-row>
                    <v-col cols="12" class="text-right">
                        <h1><strong>SubTotal: </strong>{{ formatoDosDecimales($store.state.ingresoCPStore.subTotal) }}
                        </h1>
                    </v-col>
                    <v-col cols="12" class="text-right">
                        <h1><strong>Total: </strong>{{ formatoDosDecimales($store.state.ingresoCPStore.total) }}</h1>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>

        <!-- DIALOG DE QUE ESTA HACIENDO ALGO -->
        <div>
            <v-dialog v-model="dialog" hide-overlay persistent width="300">
                <v-card color="primary" dark>
                    <v-card-text>
                        {{ dialogMessage }}
                        <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </div>

        <!-- SNACKBAR PARA NOTIFICAR LOS ERRORES -->
        <v-snackbar :timeout="10000" v-model="snackbar" color="primary darken-2" elevation="30">{{ snackbarMessage }}
            <template v-slot:action="{ attrs }">
                <v-icon dark @click="snackbar = false">
                    mdi-close-circle
                </v-icon>
            </template>
        </v-snackbar>
        <!-- DIALOG PARA MOSTRAR EL PDF -->
        <v-dialog v-model="dialogPdf" persistent>
            <v-card>
                <v-toolbar>
                    <v-btn fab dark small @click="dialogPdf=false" color="primary darken-1">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-toolbar-title>Vista Previa</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn fab dark small color="primary darken-1" class="mx-2" @click="$refs.pdf.print()">
                        <v-icon>mdi-printer</v-icon>
                    </v-btn>
                    <v-btn fab dark small color="primary darken-1" class="mx-2" @click="descargaComprobante()">
                        <v-icon>mdi-download</v-icon>
                    </v-btn>
                    <v-btn fab dark small color="primary darken-1" @click="dialogCorreo=true">
                        <v-icon>mdi-email-send</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-system-bar color="primary" height="6"></v-system-bar>
                <v-card-text>
                    <template>
                        <div class="text-center">
                            <v-icon color="primary" @click="page--">mdi-minus</v-icon>
                            {{page}} /{{numPages}}
                            <v-icon color="primary" @click="page++">mdi-plus</v-icon>
                            <div style="width: 100%">
                                <div v-if="loadedRatio > 0 && loadedRatio < 1"
                                    style="background-color: green; color: white; text-align: center"
                                    :style="{ width: loadedRatio * 100 + '%' }">{{ Math.floor(loadedRatio * 100) }}%
                                </div>
                                <pdf ref="pdf" style="border: 1px solid red" :src="srcPdf" :page="page" :rotate="rotate"
                                    @progress="loadedRatio = $event" @num-pages="numPages = $event"
                                    @link-clicked="page = $event"></pdf>
                            </div>
                        </div>
                    </template>
                </v-card-text>
            </v-card>
            <template>
            </template>
        </v-dialog>
    </div>
</template>
<script>
    import axios from 'axios'
    import moment from 'moment';
    import { format, parseISO } from 'date-fns';
    import print from 'print-js';
    import pdf from 'vue-pdf';
    import { VueEditor } from "vue2-editor";
    import Vue from 'vue'
    import VueCookies from 'vue-cookies'
    import { mapMutations } from 'vuex'
    import Conceptos from '../CartaPorte/IngresoCpConceptos.vue'
    import Relacionados from '../CartaPorte/IngresoCpRelacionados.vue'
    import Complemento from '../CartaPorte/IngresoCpComplemento.vue'


    Vue.use(VueCookies)

    export default {
        name: 'Factura',
        components: {
            print,
            VueEditor,
            pdf: pdf,
            Conceptos,
            Relacionados,
            Complemento,
        },

        data: () => ({
            e1: 1,
            //DIALOG
            dialog: false,
            dialogMessage: '',

            // SNACKBAR
            snackbar: false,
            snackbarMessage: '',
            //DATOS GENERALES
            idComprobante: 0,
            itemsSerie: [],
            serie: '',
            folio: 0,
            dateComprobante: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
            menuDateComprobante: false,
            timeComprobante: format(parseISO(new Date().toISOString()), 'HH:mm'),
            menuTimeComprobante: false,

            //DATOS DEL RECEPTOR
            receptor: {},
            searchReceptor: null,
            loadingReceptor: false,
            limitReceptor: 60,
            dialogNuevoReceptor: false,

            //USO DEL CFDI
            itemsUsoCfdi: [],

            //DATOS DEL PAGO
            itemsMetodoPago: [],
            metodoPago: {},
            itemsFormaPago: [],
            enabledFormaPago: false,
            formaPago: {},
            condicionPago: '',

            //MONEDA
            itemsMonedas: [],
            moneda: {},
            tipoDeCambio: 0,
            enabledTipoCambio: false,
            oc: '',
            notas: '',

            //MENSAJES PARA EL USUARIO
            dialogMessage: '',
            dialog: false,
            snackbar: false,
            snackbarMessage: '',

            //PARA PDF Y XML
            dialogPdf: false,
            srcPdf: '',
            loadedRatio: 0,
            page: 1,
            numPages: 0,
            rotate: 0,
            nombreArchivo: '',
            scrXml: '',
        }),

        watch: {
            async searchReceptor(val) {
                if (this.itemsreceptor.length > 0) return
                if (this.loadingReceptor) return
                this.loadingReceptor = true
                try {
                    //let response = await axios.get('Clientes/GetClientes/' + this.datosEmpresa.empresaBase)
                    let response = await axios.get('Comprobantes/GetReceptor/' + this.datosEmpresa.empresaBase);
                    this.$store.state.listReceptorStore = response.data.resultado;
                } catch (error) {
                    console.log(error)
                }
                this.loadingReceptor = false
            },

            async searchConcepto(val) {
                if (this.itemsconcepto.length > 0) return
                if (this.loadingConcepto) return
                this.loadingConcepto = true
                try {
                    let response = await axios.get('Conceptos/GetConceptosComprobantes/' + this.datosEmpresa.empresaBase)
                    this.$store.state.listConceptosStore = response.data;
                } catch (error) {
                    console.log(error)
                }
                this.loadingConcepto = false
            },
        },

        computed: {
            computedDateSalida() {
                return this.complementoCp.ubicacionOrigen.fecha ? moment(this.complementoCp.ubicacionOrigen.fecha).format('DD/MM/yyyy') : ''
            },
            computedDateDestino() {
                return this.dateDestino ? moment(this.dateDestino).format('DD/MM/yyyy') : ''
            },
            datosEmpresa() {
                return this.$store.state.usuario;
            },
            computedDateComprobante() {
                return this.dateComprobante ? moment(this.dateComprobante).format('DD/MM/yyyy') : ''
            },
            itemsreceptor() {
                return this.$store.state.listReceptorStore.map(entry => {
                    const Description = entry.nombre.length > this.limitReceptor
                        ? entry.nombre.slice(0, this.limitReceptor) + '...'
                        : entry.nombre

                    return Object.assign({}, entry, { Description })
                })
            },
            itemsconcepto() {
                return this.$store.state.listConceptosStore.map(entry => {
                    const Description = entry.descripcion.length > this.limitConcepto
                        ? entry.descripcion.slice(0, this.limitConcepto) + '...'
                        : entry.descripcion

                    return Object.assign({}, entry, { Description })
                })
            },
            comprobante() {
                this.dateComprobante = this.$store.state.ingresoCPStore.fecha
                this.timeComprobante = this.$store.state.ingresoCPStore.hora

                return this.$store.state.ingresoCPStore
            },
        },

        created() {
            this.GetUsoCdfi();
            this.GetMetodoPago();
            this.GetFormaPago();
            this.GetMoneda();
            this.GetSeries();
            this.emisor();
        },

        methods: {
            async emisor() {
                try {
                    // let response = await axios.get('Clientes/GetClientes/' + this.datosEmpresa.empresaBase)
                    let response = await axios.get('Comprobantes/GetReceptor/' + this.datosEmpresa.empresaBase);
                    this.$store.state.listReceptorStore = response.data.resultado;
                } catch (error) {
                    console.log(error)
                }
            },
            //FORMATO DECIMAL
            formatoDosDecimales(value) {
                let val = (value / 1).toFixed(2)
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            },
            //METODOS DEL STORE
            ...mapMutations(['asignaReceptorStore']),

            async GetUsoCdfi() {
                try {
                    let response = await axios.get('CatalogoComprobantes/GetUsocfdi')
                    this.itemsUsoCfdi = response.data
                } catch (err) {
                    console.log(err)
                }
            },
            async GetMetodoPago() {
                try {
                    let response = await axios.get('CatalogoComprobantes/GetMetodoPago')
                    this.itemsMetodoPago = response.data
                } catch (err) {
                    console.log(err)
                }
            },
            async GetFormaPago() {
                try {
                    let response = await axios.get('CatalogoComprobantes/GetFormaPago')
                    this.itemsFormaPago = response.data
                } catch (err) {
                    console.log(err)
                }
            },
            async GetMoneda() {
                try {
                    let response = await axios.get('CatalogoComprobantes/GetMoneda')
                    this.itemsMonedas = response.data
                } catch (err) {
                    console.log(err)
                }
            },
            async GetSeries() {
                try {
                    let response = await axios.get('Comprobantes/GetSeries/' + this.datosEmpresa.empresaBase + '/' + this.datosEmpresa.rfc + '/IngresoCP')
                    this.itemsSerie = response.data;
                } catch (err) {
                    console.log(err)
                }
            },

            ValidaMetodoPago() {
                if (this.comprobante.metodoPago.clave == "PPD") {
                    let Objecto = this.itemsFormaPago.find(variable => variable.clave === '99');
                    this.comprobante.formaPago = Object.assign({}, Objecto);
                    this.enabledFormaPago = true;
                }
                else {
                    this.comprobante.formaPago = ""
                    this.enabledFormaPago = false;
                };
            },

            ValidaMoneda() {
                if (this.moneda.c_Moneda === 'MXN') {
                    this.enabledTipoCambio = true;
                    this.tipoDeCambio = "1"
                }
                else {
                    this.enabledTipoCambio = false;
                    this.tipoDeCambio = ""
                }
            },

            //RECEPTOR
            nuevoReceptor() {
                let receptor = {
                    id: 0,
                    rfc: '',
                    nombre: null,
                    domicilioFiscal: null,
                    residenciaFiscal: null,
                    numRegIdTrib: null,
                    regimenFiscal: null,
                    usoCFDI: null,
                    itemsContacto: []
                }
                this.asignaReceptorStore(receptor);
                this.dialogNuevoReceptor = true
            },

            async GetComprobante() {
                this.nombreArchivo = 'VistaPrevia' + '_' + this.$store.state.ingresoCPStore.receptor.rfc

                var comprobante = this.$store.state.ingresoCPStore
                this.dialog = true;
                this.dialogMessage = 'Cargando vista...'
                console.log(comprobante)

                try {

                    //let response = await axios.post('http://localhost:53531/api/pdfs/PdfCPVisualizar/', comprobante);
                    let response = await axios.post('https://api-framework.contago.com.mx/api/pdfs/PdfCPVisualizar/', comprobante);
                    this.dialog = false;
                    this.dialogPdf = true;
                    this.srcPdf = 'data:application/pdf;base64,' + response.data.pdf_
                    this.scrXml = response.data.xml_
                    this.page = 1;
                } catch (error) {
                    console.log(error);
                    this.dialog = false;
                }
            },

            //TIMBRADO
            async timbraComprobante() {
                this.snackbar = false;
                this.dialog = true;
                this.dialogMessage = 'Timbrando espere...';
                let comprobante = Object.assign({}, this.$store.state.ingresoCPStore)
                comprobante.fecha = this.dateComprobante + ' ' + this.timeComprobante

                console.log(comprobante)
                try {
                    let response = await axios.post('Comprobantes/timbraIngresoCartaPorte/' + this.datosEmpresa.empresaBase, comprobante)
                    console.log(response.data)
                    //this.$store.state.listIngresoCPStore.push(response.data.getComprobante);
                    this.buscarComprobantes();
                    this.$store.state.ingresoCPStore.resultado = response.data

                    this.$store.state.ingresoCPStore.folio = this.$store.state.ingresoCPStore.resultado.getComprobante.folio
                    //INICIALIZAMOS EL COMPROBANTE
                    this.$store.state.ingresoCPStore.id = -1
                    this.combronate();
                    this.cierraVentana();
                } catch (err) {
                    console.log(err.response.data)
                    this.snackbarMessage = err.response.data;
                    this.snackbar = true;
                }
                this.dialog = false;
            },

            //para visualizar el comprobante timbrado
            async combronate() {
                this.nombreArchivo = this.$store.state.ingresoCPStore.receptor.rfc + '_' + this.$store.state.ingresoCPStore.serie + '_' + this.$store.state.ingresoCPStore.resultado.getComprobante.folio + '_' + this.$store.state.ingresoCPStore.resultado.getComprobante.folioFiscal
                var comprobante = this.$store.state.ingresoCPStore
                this.dialogMessage = 'Generando vista...'
                this.dialog = true;
                console.log("aqui")
                console.log(comprobante)
                try {
                    //let response = await axios.post('http://localhost:53531/api/pdfs/ComprobanteCartaPorte/', comprobante);
                    let response = await axios.post('https://api-framework.contago.com.mx/api/pdfs/ComprobanteCartaPorte/', comprobante);

                    this.loading = false;
                    this.dialogPdf = true;
                    this.srcPdf = 'data:application/pdf;base64,' + response.data.pdf_
                    this.scrXml = response.data.xml_
                    this.page = 1;
                } catch (error) {
                    console.log(error);
                    this.loading = false;
                }
            },

            async GuardarComprobante() {
                this.snackbar = false;
                this.dialog = true;
                this.dialogMessage = 'Gurdando espere...';
                let comprobante = Object.assign({}, this.$store.state.ingresoCPStore)
                comprobante.fecha = this.dateComprobante + ' ' + this.timeComprobante
                comprobante.resultado = {}
                console.log(comprobante)
                try {
                    let response = await axios.post('Comprobantes/GuardarComprobante/' + this.datosEmpresa.empresaBase, comprobante)
                    console.log(response.data)
                    //this.$store.state.listFacturaStore.push(response.data.getComprobante);
                    this.buscarComprobantes();
                    this.$store.state.ingresoCPStore.id = -1;
                    this.snackbarMessage = "Guardado con Exito";
                    this.snackbar = true;
                    this.cierraVentana();
                } catch (err) {
                    console.log(err.response.data)
                    this.snackbarMessage = err.response.data;
                    this.snackbar = true;
                }
                this.dialog = false;
            },

            cierraVentana() {
                this.$emit('closeFactura')
            },
            descargaComprobante() {
                console.log("aqui 1")
                var pdf = this.srcPdf.split(',')
                this.download(pdf[1], this.nombreArchivo, 'pdf');
                if(this.$store.state.ingresoCPStore.folio > 0){
                this.download(this.scrXml, this.nombreArchivo, 'xml');
                }
            },

            download(archivo, nombre, tipo) {
                console.log("aqui 2")
                var arrBuffer = this.base64ToArrayBuffer(archivo);
                var newBlob = new Blob([arrBuffer]);

                var data = window.URL.createObjectURL(newBlob);
                var link = document.createElement('a');
                document.body.appendChild(link);
                link.href = data;
                link.download = nombre + '.' + tipo;
                link.click();
                window.URL.revokeObjectURL(data);
                link.remove();
            },

            base64ToArrayBuffer(base64) {
                var binary_string = window.atob(base64);
                var len = binary_string.length;
                var bytes = new Uint8Array(len);
                for (var i = 0; i < len; i++) {
                    bytes[i] = binary_string.charCodeAt(i);
                }
                return bytes.buffer;
            },
            async buscarComprobantes() {
                //this.loading = true;
                //this.loadingMessage = 'Cargando datos...'
                console.log(this.datosEmpresa)
                try {
                    let comprobante = Object.assign({}, this.$store.state.ingresoCPStore);
                    let fechaI= format(parseISO(new Date().toISOString()), 'yyyy-MM-dd');
                    let fechaF= format(parseISO(new Date().toISOString()), 'yyyy-MM-dd');
                    let response = await axios.get('Comprobantes/ListarComprobantes/' + this.datosEmpresa.empresaBase + '/' + comprobante.emisor.rfc + '/IngresoCP/' + fechaI + '/' + fechaF);
                    this.$store.state.listIngresoCPStore = response.data;
                    console.log(this.items)
                    this.loading = false;
                } catch (error) {
                    console.log(error);
                }
            },
        },
    }
</script>