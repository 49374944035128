<template>
  <div>
    <!--Dialog loading-->
    <v-dialog v-model="loading" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Cargando datos...
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- dialogo de nueva figura de transporte-->
    <v-dialog v-model="dialogNuevaFiguraDelTransporte" persistent max-width="900px">
      <figuras-transporte @VentanaNuevaFiguraTransporte="CierraVentanaNuevaFiguraTransporte()"></figuras-transporte>
    </v-dialog>
    <v-card>
      <v-card-title style="font: bold;" class="text-h4 text--primary">
        <!--Boton regresar-->
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" class="mx-2" icon large link :to="{ name: 'CatalogosCartaPorte' }">
              <v-icon size="40" color="primary">mdi-arrow-left-thick</v-icon>
            </v-btn>
          </template>
          <span>Regresar</span>
        </v-tooltip>
        Lista de Figuras de Transporte
        <v-spacer></v-spacer>
        <div class="text-center">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" class="mx-2" fab dark color="blue" @click="listarFigurasTransporte()">
                <v-icon dark>mdi-update</v-icon>
              </v-btn>
            </template>
            <span>Actualizar</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" class="mx-2" fab dark color="green" @click="nuevaFiguraTransporte()">
                <v-icon dark>mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>Nuevo</span>
          </v-tooltip>
        </div>
      </v-card-title>
      <v-divider></v-divider>
      <v-data-table :headers="headers" :items="items">
        <!-- BOTONES DE ACCION  -->
        <template v-slot:[`item.actions`]="{ item }" :items="items">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon dense class="mr-2" v-bind="attrs" v-on="on" color="blue" @click="editar(item)">mdi-lead-pencil
              </v-icon>
            </template>
            <span>Editar</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon dense class="mr-2" v-bind="attrs" v-on="on" color="red" @click="deleteItem(item)">mdi-delete
              </v-icon>
            </template>
            <span>Eliminar</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
  import axios from 'axios'

  import FigurasTransporte from '../Comprobantes/CartaPorte/FigurasTransporte.vue'

  export default {
    components: {
      FigurasTransporte

    },
    data() {
      return {
        headers: [
          { text: 'Acciones', value: 'actions' },
          { text: "Tipo de figura", value: "tipoFigura.descripcion" },
          { text: "RFC Figura", value: "rfcFigura" },
          { text: "Número de licencia", value: "numLicencia" },
          { text: "Nombre", value: "nombreFigura" },
          { text: "numRegIdTribFigura", value: "numRegIdTribFigura" },
          { text: "Residencia Fiscal", value: "residenciaFiscalFigura" },
          { text: "Transporte", value: "partesDelTransporte.parteTransporte.descripcion" },
          { text: "Pais", value: "domicilio.pais.descripcion" },
          { text: "Estado", value: "domicilio.estado.descripcion" },
          { text: "Municipio", value: "domicilio.municipio.descripcion" },
          { text: "Localidad", value: "domicilio.localidad.descripcion" },
          { text: "Colonia", value: "domicilio.colonia.descripcion" },
          { text: "Calle", value: "domicilio.calle" },
          { text: "Número Exterior", value: "domicilio.numeroExterior" },
          { text: "Número Interior", value: "domicilio.numeroInterior" },
          { text: "Codigo Postal", value: "domicilio.codigoPostal" },
          { text: "Referencia", value: "domicilio.referencia" },

        ],
        items: [],
        //FIGURAS DEL TRANSPORTE
        dialogNuevaFiguraDelTransporte: false,

        // loading 
        loading: false,
      };
    },
    mounted() {

    },
    computed: {
      datosEmpresa() {
        return this.$store.state.usuario;
      },
      figuras() {
        return this.$store.state.figurasTransporte
      }
    },
    created(){
    this.listarFigurasTransporte();
  },
    methods: {

      //NEUVAS FIGURAS TRANSPORTE
      nuevaFiguraTransporte() {
        let figurasTransporte = {
          id: 0,
          tipoFigura: { clave: '' },
          partesDelTransporte: {
            parteTransporte: {
              parteTransporte: '',
              descripcion: '',
              clave: '',
            }

          },
        }
        if (this.$store.state.figurasTransporte.id === -1) {
          this.$store.state.figurasTransporte = Object.assign({}, figurasTransporte)
        }
        this.dialogNuevaFiguraDelTransporte = true;
      },
      CierraVentanaNuevaFiguraTransporte() {
        this.dialogNuevaFiguraDelTransporte = false;
      },
      async listarFigurasTransporte() {
        this.loading = true;
        try {
          let response = await axios.get('CartaPorte/GetFiguras/' + this.datosEmpresa.empresaBase);
          this.items = response.data;
          console.log(this.items)
          this.loading = false;
        } catch (error) {
          console.log(error);
        }
      },
      editar(item) {
        let indice = this.items.indexOf(item)
        const figurasTransporte = Object.assign({}, item)
        this.$store.state.figurasTransporte = Object.assign({}, figurasTransporte);
        console.log(this.$store.state.figurasTransporte)
        this.dialogNuevaFiguraDelTransporte = true;
      },
      async deleteItem(item) {
        let id = item.id
        try {
          let response = await axios.post('CartaPorte/DeleteFiguras/' + this.datosEmpresa.empresaBase + '/' + id);
          this.listarFigurasTransporte();
          this.loading = false;
        } catch (error) {
          console.log(error);
        }
      }
    },
  };
</script>