<template>
  <div>
    <!--Dialog loading-->
    <v-dialog v-model="loading" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Cargando datos...
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!--nuevo seguro-->
    <v-dialog v-model="dialogNuevoSeguro" max-width="600px" persistent>
      <seguro @VentanaNuevoSeguro="CierraVentanaNuevoSeguro()"></seguro>
    </v-dialog>
    <v-card>
      <v-card-title style="font: bold;" class="text-h4 text--primary">
        <!--Boton regresar-->
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" class="mx-2" icon large link :to="{ name: 'CatalogosCartaPorte' }">
              <v-icon size="40" color="primary">mdi-arrow-left-thick</v-icon>
            </v-btn>
          </template>
          <span>Regresar</span>
        </v-tooltip>
        Lista de Seguros
        <v-spacer></v-spacer>
        <v-select label="Tipo de seguro" :items="tipo" v-model="seguro" persistent-hint class="mt-8">
        </v-select>
        <div class="text-center">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" class="mx-2" fab dark color="blue" @click="listarSeguros">
                <v-icon dark>mdi-magnify</v-icon>
              </v-btn>
            </template>
            <span>Buscar</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" class="mx-2" fab dark color="green" @click="nuevoSeguro()">
                <v-icon dark>mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>Nuevo</span>
          </v-tooltip>
        </div>
      </v-card-title>
      <v-divider></v-divider>
      <v-data-table :headers="headers" :items="items">
        <!-- BOTONES DE ACCION  -->
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon dense class="mr-2" v-bind="attrs" v-on="on" color="blue" @click="editar(item)">mdi-lead-pencil</v-icon>
            </template>
            <span>Editar</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon dense class="mr-2" v-bind="attrs" v-on="on" color="red" @click="deleteItem(item)">mdi-delete</v-icon>
            </template>
            <span>Eliminar</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
    <!-- SNACKBAR PARA NOTIFICAR LOS ERRORES -->
    <v-snackbar :timeout="5000" v-model="snackbar" color="primary darken-2" elevation="30">{{ snackbarMessage }}
      <template v-slot:action="{ attrs }">
        <v-icon dark @click="snackbar = false">
          mdi-close-circle
        </v-icon>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
  import axios from 'axios'
  import Seguro from '../Comprobantes/CartaPorte/Seguro.vue'
  import moment from 'moment';
    import { format, parseISO } from 'date-fns';
  export default {
    components: {
      Seguro
    },
    data() {
      return {
        headers: [
          { text: 'Acciones', value: 'actions' },
          { text: "Aseguradora", value: "aseguraRespCivil" },
          { text: "Número de Poliza", value: "polizaRespCivil" },
          { text: "Prima Seguro", value: "primaSeguro" },
          { text: "Vigencia", value: "vigencia" },
          { text: "Tipo", value: "tipo" },

        ],
        items: [],
        // dialog seguros
        dialogNuevoSeguro: false,

        // loading
        loading: false,

        // SNACKBAR
            snackbar:false,
            snackbarMessage:'',

        tipo: ['Responsabilidad Civil', 'Daños al medio ambiente', 'Carga Transportada'],
        seguro: '',
      };
    },
    mounted() {

    },
    computed: {
      datosEmpresa() {
        return this.$store.state.usuario;
      },
      seguros(){
                return this.$store.state.seguros;
            }
    },
    methods: {
      //SEGURO
      nuevoSeguro() {
        let seguros = {
        id: 0,
        vigencia: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
      }
      if (this.$store.state.seguros.id === -1) {
          this.$store.state.seguros = Object.assign({}, seguros)
        }
        this.dialogNuevoSeguro = true;
      },
      CierraVentanaNuevoSeguro() {
        this.dialogNuevoSeguro = false;
      },
      // listar seguros
      async listarSeguros() {
        if (this.seguro == "" || !this.seguro) {
          this.snackbarMessage = 'Indique el tipo de seguro a buscar. '
          this.snackbar = true
          return;
        }
        this.loading = true;
        try {
          let response = await axios.get('CartaPorte/GetSeguro/'  + this.datosEmpresa.empresaBase + '/' + this.seguro);
          this.items = response.data;
          console.log(this.items)
          this.loading = false;
        } catch (error) {
          console.log(error);
        }
      },

      editar(item) {
        let indice = this.items.indexOf(item)
        const seguros = Object.assign({}, item)
        this.$store.state.seguros = Object.assign({}, seguros);
        console.log(this.$store.state.seguros)
        this.dialogNuevoSeguro = true;
      },
      async deleteItem(item){
      let id = item.id
       try {
        let response = await axios.post('CartaPorte/DeleteSeguro/' + this.datosEmpresa.empresaBase + '/' + id);
        this.listarSeguros();
        this.loading = false;
        } catch (error) {
          console.log(error);
      }
    }
    },
  };
</script>