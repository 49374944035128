<template>
<div>
  <!--Dialog loading-->
  <v-dialog v-model="loading" hide-overlay persistent width="300">
    <v-card color="primary" dark>
      <v-card-text>
        Cargando datos...
        <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>
  <v-card>
    <v-card-title style="font: bold;" class="text-h4 text--primary">
    <!--Boton regresar-->
    <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on"  class="mx-2" icon large link :to="{ name: 'Catalogos' }">
                <v-icon size="40" color="primary">mdi-arrow-left-thick</v-icon>
                </v-btn>
            </template>
            <span>Regresar</span>
        </v-tooltip>
      Lista de Conceptos
      <v-spacer></v-spacer>
      <div class="text-center">
        <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" class="mx-2" fab  dark color="blue" @click="listarConceptos()">
                <v-icon dark>mdi-update</v-icon>
                </v-btn>
            </template>
            <span>Actualizar</span>
        </v-tooltip>
        <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" class="mx-2" fab  dark color="green" @click="nuevoConcepto()">
                <v-icon dark>mdi-plus</v-icon>
                </v-btn>
            </template>
            <span>Nuevo</span>
        </v-tooltip>
        </div>
    </v-card-title>
    <v-divider></v-divider>
    <v-data-table :headers="headers" :items="items">
      <!-- BOTONES DE ACCION  -->
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                    <v-icon dense class="mr-2" v-bind="attrs" v-on="on" color="blue" dark @click="editar(item)">mdi-lead-pencil</v-icon>
            </template>
            <span>Editar</span>
        </v-tooltip>
        <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-icon dense class="mr-2" v-bind="attrs" v-on="on" color="red" dark @click="deleteItem(item)">mdi-delete</v-icon>
            </template>
            <span>Eliminar</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </v-card>
  <v-dialog persistent v-model="dialogConceptoNuevo">
    <Concepto @VentanaNuevoConcepto="closeDialogConcepto()"></Concepto>
  </v-dialog>
</div>
</template>
<script>

import axios from 'axios'
import Concepto from '../Conceptos/Concepto';

export default {
  components:{
    Concepto,
  },
data() {
    return {
      headers: [
        { text: 'Acciones', value: 'actions'},
        { text: "Producto Servicio", value: "claveProdServ.claveProdServ" },
        { text: "Concepto", value: "concepto" },
      ],
      dialogConceptoNuevo: false,
      loading: false, 
    };
  },
  mounted() {
    
  },
  computed: {
    items(){
      return this.$store.state.listConceptosStore;
    },
    datosEmpresa(){
      return this.$store.state.usuario;
    },
    conceptos(){
      return this.$store.state.conceptoStore
    }
  },
  created(){
    this.listarConceptos();
  },
  methods: {
    async listarConceptos() {
      this.loading = true;
      try {
        let response = await axios.get('Comprobantes/GetConceptos/' + this.datosEmpresa.empresaBase);
        this.$store.state.listConceptosStore = response.data;
        console.log(this.items)
        this.loading = false;
        } catch (error) {
          console.log(error);
      }
    },

    nuevoConcepto(){
      const concepto = {
        id: 0,
        cuentaPredial:{
          numero: ''
        }
      }
      if(this.$store.state.conceptoStore.id === -1){
        this.$store.state.conceptoStore = Object.assign({}, concepto)
      }
      this.dialogConceptoNuevo = true
    },

    closeDialogConcepto(){
      this.dialogConceptoNuevo = false;
    },
    editar(item) {
        let indice = this.items.indexOf(item)
        const concepto= Object.assign({}, item)
        this.$store.state.conceptoStore = Object.assign({}, concepto);
        console.log(this.$store.state.conceptoStore)
        this.dialogConceptoNuevo = true;
      },
    async deleteItem(item){
      let id = item.id
       try {
        let response = await axios.post('Comprobantes/DeleteConcepto/'  + this.datosEmpresa.empresaBase + '/' + id);
        this.listarConceptos();
        this.loading = false;
        } catch (error) {
          console.log(error);
      }
    }
  },
}; 
</script>