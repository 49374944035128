<template>
<div>
  <!--Dialog loading-->
  <v-dialog v-model="loading" hide-overlay persistent width="300">
    <v-card color="primary" dark>
      <v-card-text>
        Cargando datos...
        <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>
  <!--diealgo para nuevo vehiculo-->
  <v-dialog v-model="dialogNuevoDirecciones" max-width="900px" persistent>
            <Direcciones @VentanaNuevoDirecciones="CierraVentanaNuevoDirecciones()"></Direcciones>
        </v-dialog>
  <v-card>
    <v-card-title style="font: bold;" class="text-h4 text--primary">
    <!--Boton regresar-->
    <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on"  class="mx-2" icon large link :to="{ name: 'CatalogosCartaPorte' }">
                <v-icon size="40" color="primary">mdi-arrow-left-thick</v-icon>
                </v-btn>
            </template>
            <span>Regresar</span>
        </v-tooltip>
      Lista de Direcciones
      <v-spacer></v-spacer>
      <div class="text-center">
        <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" class="mx-2" fab  dark color="blue" @click="listarDirecciones()">
                <v-icon dark>mdi-update</v-icon>
                </v-btn>
            </template> 
            <span>Actualizar</span>
        </v-tooltip>
        <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" class="mx-2" fab  dark color="green" @click="nuevoDirecciones()">
                <v-icon dark>mdi-plus</v-icon>
                </v-btn>
            </template>
            <span>Nuevo</span>
        </v-tooltip>
        </div>
    </v-card-title>
    <v-divider></v-divider>
    <v-data-table :headers="headers" :items="items">
      <!-- BOTONES DE ACCION  -->
      <template v-slot:[`item.actions`]="{ item }" >
        <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                    <v-icon dense class="mr-2" v-bind="attrs" v-on="on" color="blue" @click="editar(item)">mdi-lead-pencil</v-icon>
            </template>
            <span>Editar</span>
        </v-tooltip>
        <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-icon dense class="mr-2" v-bind="attrs" v-on="on" color="red" @click="deleteItem(item)">mdi-delete</v-icon>
            </template>
            <span>Eliminar</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </v-card>
</div>
</template>
<script>
import axios from 'axios'
import Direcciones from '../Comprobantes/CartaPorte/Direcciones.vue'

export default {
  components:{
    Direcciones
        },
data() {
    return {
      headers: [
            { text: 'Acciones', value: 'actions'},
            { text: "id", value: "id" },
            { text: "Pais", value: "pais.descripcion" },
            { text: "Estado", value: "estado.descripcion" },
            { text: "Localidad", value: "localidad.descripcion" },
            { text: "Municipio", value: "municipio.descripcion" },
            { text: "Colonia", value: "colonia.descripcion" },
            { text: "Colonia", value: "colonia.c_CodigoPostal" },
            { text: "Calle", value: "calle" },

      ],
      items: [],
      // Nuevo vehiculo
      dialogNuevoDirecciones: false,

      // loading
      loading: false,
    };
  },
  mounted() {
    
  },
  computed: {
      datosEmpresa() {
        return this.$store.state.usuario;
      },
      direcciones(){
        return this.$store.state.direcciones;
      }
    },
    created(){
    this.listarDirecciones();
  },
  methods: {
    //VEHICULO
    nuevoDirecciones(){
      let direcciones = {
        id: 0,
        estado:'',
            pais: { clave: 'MEX', descripcion: 'México', pais: 'MEX | México' },
      }
      if (this.$store.state.direcciones.id = -1){
          this.$store.state.direcciones = Object.assign({}, direcciones)
      }

      this.dialogNuevoDirecciones=true;
    },
    CierraVentanaNuevoDirecciones() {
      this.dialogNuevoDirecciones = false;
    },
    // listar vehiculos
    async listarDirecciones() {
      this.loading = true;
      try {
        let response = await axios.get('CartaPorte/GetDirecciones/' + this.datosEmpresa.empresaBase);
        this.items = response.data; 
        console.log(this.items)
        this.loading = false;
        } catch (error) {
          console.log(error);
      }
    },
    editar(item) {
        let indice = this.items.indexOf(item)
        const direcciones = Object.assign({}, item)
        this.$store.state.direcciones = Object.assign({}, direcciones);
        console.log(this.$store.state.direcciones)
        this.dialogNuevoDirecciones = true;
      },
    async deleteItem(item) {
        let id = item.id
        try {
          let response = await axios.post('CartaPorte/DeleteDirecciones/' + this.datosEmpresa.empresaBase+'/' + id);
          this.listarDirecciones();
          this.loading = false;
        } catch (error) {
          console.log(error);
        } 
      }
  },
};
</script>