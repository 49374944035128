var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"hide-overlay":"","persistent":"","width":"300"},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},[_c('v-card',{attrs:{"color":"primary","dark":""}},[_c('v-card-text',[_vm._v(" Cargando datos... "),_c('v-progress-linear',{staticClass:"mb-0",attrs:{"indeterminate":"","color":"white"}})],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"600px","persistent":""},model:{value:(_vm.dialogNuevoSeguro),callback:function ($$v) {_vm.dialogNuevoSeguro=$$v},expression:"dialogNuevoSeguro"}},[_c('seguro',{on:{"VentanaNuevoSeguro":function($event){return _vm.CierraVentanaNuevoSeguro()}}})],1),_c('v-card',[_c('v-card-title',{staticClass:"text-h4 text--primary",staticStyle:{"font":"bold"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"icon":"","large":"","link":"","to":{ name: 'CatalogosCartaPorte' }}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"40","color":"primary"}},[_vm._v("mdi-arrow-left-thick")])],1)]}}])},[_c('span',[_vm._v("Regresar")])]),_vm._v(" Lista de Seguros "),_c('v-spacer'),_c('v-select',{staticClass:"mt-8",attrs:{"label":"Tipo de seguro","items":_vm.tipo,"persistent-hint":""},model:{value:(_vm.seguro),callback:function ($$v) {_vm.seguro=$$v},expression:"seguro"}}),_c('div',{staticClass:"text-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"fab":"","dark":"","color":"blue"},on:{"click":_vm.listarSeguros}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-magnify")])],1)]}}])},[_c('span',[_vm._v("Buscar")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"fab":"","dark":"","color":"green"},on:{"click":function($event){return _vm.nuevoSeguro()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v("Nuevo")])])],1)],1),_c('v-divider'),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"dense":"","color":"blue"},on:{"click":function($event){return _vm.editar(item)}}},'v-icon',attrs,false),on),[_vm._v("mdi-lead-pencil")])]}}],null,true)},[_c('span',[_vm._v("Editar")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"dense":"","color":"red"},on:{"click":function($event){return _vm.deleteItem(item)}}},'v-icon',attrs,false),on),[_vm._v("mdi-delete")])]}}],null,true)},[_c('span',[_vm._v("Eliminar")])])]}}],null,true)})],1),_c('v-snackbar',{attrs:{"timeout":5000,"color":"primary darken-2","elevation":"30"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-icon',{attrs:{"dark":""},on:{"click":function($event){_vm.snackbar = false}}},[_vm._v(" mdi-close-circle ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(_vm._s(_vm.snackbarMessage)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }