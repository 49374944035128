<template>
    <div>
        <v-dialog v-model="loading" hide-overlay persistent width="300">
            <v-card color="primary" dark>
                <v-card-text class="pt-3">
                    {{loadingMessage}}
                    <v-progress-linear indeterminate color="white"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
        <!-- DIALOG PARA CREAR UN USUARIO -->
        <v-dialog v-model="DialogCrear" width="600" persistent>
            <v-card>
                <v-toolbar>
                    <b style="color: #3F51B5; font-size: 30px;">Ingresa tus datos</b>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="primary darken-1" dark fab small @click="DialogCrear = false, limpiar()"
                                v-bind="attrs" v-on="on">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </template>
                        <span>Cerrar</span>
                    </v-tooltip>
                </v-toolbar>
                <v-system-bar color="primary" height="6"></v-system-bar>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" xl="6" md="6" sm="12" xs="12">
                            <v-text-field prepend-inner-icon="mdi-account" primary v-model="usuarioN"
                                :disabled="!validar" label="Usuario" required>
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" xl="6" md="6" sm="12" xs="12">
                            <v-text-field prepend-inner-icon="mdi-key" v-model="contraN" :disabled="!validar"
                                label="Contraseña" required>
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" xl="6" md="6" sm="12" xs="12">
                            <v-text-field label="RFC" v-model="rfcValidar" loading :readonly="editar" required>
                                <!-- append-outer-icon="mdi-account-check"  @click:append-outer="validarRFC()"> -->
                                <v-icon slot="append" color="primary" @click="validarRFC()">
                                    mdi-account-check
                                </v-icon>
                            </v-text-field>     
                        </v-col>
                        <v-col cols="12" xl="6" md="6" sm="12" xs="12">
                            <v-text-field prepend-inner-icon="mdi-account" v-model="empresaN" :disabled="!validar" 
                                label="Nombre de la Empresa" required>
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" xl="6" md="6" sm="12" xs="12">
                            <v-text-field prepend-inner-icon="mdi-at" v-model="correoN" :disabled="!validar"
                                label="Correo Electrónico" required>
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" xl="6" md="6" sm="12" xs="12">
                            <v-text-field prepend-inner-icon="mdi-phone" v-model="teleN" :disabled="!validar"
                                label="Telefóno" required>
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-alert icon="mdi-check-decagram" prominent text type="info">
                        Ingrese el RFC y valide que no haya sido registrado anteriormente para poder registrarse.
                    </v-alert>
                    <v-card-actions class="mb-5">
                        <v-row>
                            <v-col cols="12" md="6" sm="6">
                            </v-col>
                            <v-col cols="12" md="6" sm="6">
                                <v-btn width="100%" rounded color="primary" dark>Guardar</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                </v-card-text>
            </v-card>
        </v-dialog>
        <!-- login -->
        <v-layout align-center justify-center>
            <v-flex>
                <v-main>
                    <v-container class="fill-height" fluid>
                        <v-row align="center" justify="center">
                            <v-col cols="12" md="12">
                                <v-card style="margin-top: 40px;" class="elevation-5 mx-auto" max-width="600">
                                    <v-window v-model="step">
                                        <v-window-item :value="1">
                                            <v-row>
                                                <v-col cols="12" md="7" sm="7">
                                                    <v-card-text class="mt-2 text-center">
                                                        <div class="text-center display-1 mb-2">
                                                            <!-- <b style="color: #3F51B5; font-size: 45px;">Iniciar
                                                                Sesión</b> -->
                                                        </div>
                                                        <div class="text-center mt-4">
                                                            <img src="../assets/contago-04-facturacionweb-512x512px.icon.jpg"
                                                                height="80">
                                                        </div>
                                                        <!-- USUARIO -->
                                                        <v-text-field prepend-icon="mdi-account" v-model="nombreU"
                                                            label="Usuario">
                                                        </v-text-field>
                                                        <!-- CONTRASEÑA -->
                                                        <v-text-field :type="show1 ? 'text' : 'password'"
                                                            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                                            @click:append="show1 = !show1" prepend-icon="mdi-lock"
                                                            v-model="password" label="Contraseña" @keydown.enter="validarUsuario"></v-text-field>
                                                    </v-card-text>
                                                    <v-card-actions class="mb-6"> 
                                                        <v-btn width="100%" style="margin: auto"
                                                            @click="validarUsuario()" rounded outlined color="primary">
                                                            Validar Usuario</v-btn>
                                                    </v-card-actions>
                                                </v-col>
                                                <v-col cols="12" sm="5" md="5" class="primary">
                                                    <v-card-text class="white--text mt-12">
                                                        <h2 class="text-center display-1">Registrarse</h2>
                                                        <h5 class="text-center mt-10">
                                                            Ingresa tu información para ser parte de contago.
                                                        </h5>
                                                    </v-card-text>
                                                    <div class="text-center mt-5 mb-10">
                                                        <v-btn width="90%" rounded outlined dark
                                                            @click="DialogCrear = true">Registrarse</v-btn>
                                                    </div>
                                                </v-col>
                                            </v-row>
                                        </v-window-item>
                                        <v-window-item :value="2">
                                            <v-card-text>
                                                <div class="text-center display-1 mb-2">
                                                    <b style="color: #3F51B5; font-size: 35px;">Seleccione su
                                                        empresa</b>
                                                </div>
                                                <div class="text-center mt-3">
                                                    <img src="../assets/contago-04-facturacionweb-512x512px.icon.jpg"
                                                        height="80">
                                                </div>
                                                <v-select v-model="selectEmpresa" :items="itemsEmpresa"
                                                    item-text="nombre_e" item-value="nombreBase"
                                                    prepend-icon="mdi-domain" label="Selecciona tu empresa"
                                                    return-object></v-select>
                                            </v-card-text>
                                            <v-card-actions class="mb-5">
                                                <v-row>
                                                    <v-col cols="12" md="6" sm="6">
                                                        <v-btn width="100%" rounded color="primary" @click="step--"
                                                            dark>Cambiar Usuario</v-btn>
                                                    </v-col>
                                                    <v-col cols="12" md="6" sm="6">
                                                        <v-btn width="100%" rounded color="primary" dark
                                                            @click="ingresar()">Ingresar
                                                        </v-btn>
                                                    </v-col>
                                                </v-row>
                                            </v-card-actions>
                                        </v-window-item>
                                    </v-window>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-main>
            </v-flex>
        </v-layout>
        <v-snackbar rounded="pill" shaped color="primary" v-model="snackbar" :timeout="timeout">{{ error }}
            <template v-slot:action="{ attrs }">
                <v-btn color="white" text v-bind="attrs" @click="snackbar = false">Cerrar</v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
    import axios from 'axios'

    export default {
        name: "Login",
        components: {

        },
        data: () => ({
            loading: false,
            loadingMessage:'',
            snackbar: false,
            error: null,
            timeout: 3000,
            show1: false,
            step: 1,
            DialogCrear: false,

            nombreU: '',
            password: '',

            validar: false,
            editar: false,

            selectEmpresa: {},
            itemsEmpresa: [],
            disabledEmpresa: null,
            disabledUsuario: null,
            hiddenValida: false,
            hiddenEmpresa: false,

            idUsuario: null,
            nombreUsuario: null,
            rolUsuario: null,
            empresa: null,
            empresaBase: null,
            rfc: '',
            firma: null,
            loading: false,

            usuarioN: '',
            contraN: '',
            empresaN: '',
            correoN: '',
            teleN: '',


            rfcValidar: ''
        }
        ),
        computed: {

        },
        methods: {
            async validarUsuario() {

                this.snackbar = false;
                if (this.nombreU == "") {
                    this.error = "Indique un usuario";
                    this.snackbar = true;
                    return;
                }
                if (this.password == "") {
                    this.error = "Indique su contraseña";
                    this.snackbar = true;
                    return;
                }
                this.loading = true;
                // this.loadingMessage = "";
                //VALIDAMOS QUE EL USUARIO SEA VALIDO
                try {
                    let respuesta = await axios.post("https://api-framework.contago.com.mx/api/Usuarios/ValidaAsync", {
                        nombre: this.nombreU,
                        password: this.password,
                    });
                    this.idUsuario = respuesta.data.idUsuario_;
                    this.rolUsuario = respuesta.data.rol_;
                    this.firma = respuesta.data.firma_;
                    this.rfc = respuesta.data.rfc_;
                    this.correo = respuesta.data.correo_;
                    this.listaEmpresas(respuesta.data.idUsuario_);
                    this.disabledEmpresa = !this.disabledEmpresa;
                    this.disabledUsuario = !this.disabledUsuario;
                    this.hiddenValida = !this.hiddenValida;
                    this.hiddenEmpresa = !this.hiddenEmpresa;
                    this.nombreUsuario = respuesta.data.nombre_;
                    this.step++;
                    this.loading = false;

                    console.log(respuesta.data);
                } catch (err) {
                    console.log(err.response.status);

                    if (err.response.status == 400) {
                        this.error = "Usuario inexistente";
                        this.snackbar = true;
                        this.loading = false;
                    } else if (err.response.status == 404) {
                        this.error = "Contraseña incorrecta";
                        this.snackbar = true;
                        this.loading = false;
                    } else if (err.response.status == 401) {
                        this.error = "El usuario ya cuenta con una sesión iniciada";
                        this.snackbar = true;
                        this.loading = false;
                    } else {
                        this.error = "Datos incorrectos, verifique su usuario y contraseña";
                        this.snackbar = true;
                        this.loading = false;
                        this.loading = false;
                    }
                }
            },
            limpiar() {
                this.usuarioN = ''
                this.contraN = ''
                this.empresaN = ''
                this.correoN = ''
                this.teleN = ''
                this.rfcValidar = ''
                this.validar = !this.validarRFC
                this.editar = false
            },

            ingresar() {
                this.snackbar = false;
                // this.nombreUsuario = this.nombreU;
                this.empresa = this.selectEmpresa.nombre_e
                this.empresaBase = this.selectEmpresa.nombreBase
                this.rfc = this.selectEmpresa.rfc

                if (!this.selectEmpresa.nombre_e) {
                    this.error = "Seleccione una empresa de la lista";
                    this.snackbar = true;
                    return
                }
                let objLogin = {
                    idUsuario: this.idUsuario,
                    nombre: this.nombreUsuario,
                    rol: this.rolUsuario,
                    empresa: this.empresa,
                    empresaBase: this.empresaBase,
                    firma: this.firma,
                    rfc: this.rfc
                }

                console.log(this.empresaBase);
                console.log(this.empresa);

                axios.post('https://api-framework.contago.com.mx/api/Usuarios/Login', { idUsuario: this.idUsuario, nombre: this.nombreUsuario, rol: this.rolUsuario, empresa: this.empresa, empresaBase: this.empresaBase, firma: this.firma, rfc: this.rfc })
                    .then(response => {
                        console.log(response.data);
                        return response.data
                    })
                    .then(data => {
                        this.$store.dispatch("guardarToken", data.token)
                        this.$router.push({ name: 'Home' })
                        this.nombre = '';
                        this.password = '';
                    }).catch(err => { 
                        if (err.response.status == 500) {
                            this.error = err.response;
                            this.snackbar = true;
                        }
                    });
            },
            listaEmpresas(item) {
                axios.get('https://api-framework.contago.com.mx/api/Usuarios/Empresas/' + item + '/' + 'ERP')
                    .then(response => {
                        this.itemsEmpresa = response.data;
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            async validarRFC() {
                if (this.rfcValidar == '') {
                    this.error = "Ingrese RFC a validar";
                    this.snackbar = true;
                    return
                }
                let ValidaRFCViewModel = { rfc: this.rfcValidar }
                try {
                    let response = await axios.post('https://api-framework.contago.com.mx/api/Usuarios/ValidaAsyncRFC', ValidaRFCViewModel);
                    console.log(response)
                    this.loading = false;
                    this.error = "RFC registrado anteriormente.";
                    this.snackbar = true;

                } catch (err) {
                    console.log(err.response.status);
                    if (err.response.status == 400) {
                        this.validar = !this.validar
                        this.editar = !this.editar
                        this.usuarioN = ''
                        this.contraN = ''
                        this.empresaN = ''
                        this.correoN = ''
                        this.teleN = ''
                    }
                }
            },
        },
    };
</script>   