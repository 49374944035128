<template>
  <div class="app">
    <v-toolbar flat color="transparent">
      <v-toolbar-title class="dark">
        <h2></h2>
      </v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-card class="justify-center pt-3 pb-5" flat color="transparent">
      <div class="text-center mt-4">
        <img src="../assets/logo_cotago facturacion_web_sin_fondo.png" height="150">
      </div>
    </v-card>
    <v-card class="justify-center" flat color="transparent">
      <v-item-group mandatory>
        <v-container>
          <v-row dense>
            <v-hover v-slot="{ hover }" open-delay="10">
              <v-card :elevation="hover ? 16 : 2" :class="{ 'on-hover': hover }" class="mx-auto justify-center mr-5  rounded-lg mb-4"
                height="220" width="220" link :to="{ name: 'ListaReceptor' }"
                @click="$store.state.nombreComponente = 'Catalogos/Receptor'">
                <v-card-text class="text-center">
                  <v-icon size="150" color="primary">mdi-account-arrow-left</v-icon>
                  <h2 class="mt-4">Cliente / Receptor</h2>
                </v-card-text>
              </v-card>
            </v-hover>

            <v-hover v-slot="{ hover }" open-delay="10">
              <v-card :elevation="hover ? 16 : 2" :class="{ 'on-hover': hover }" class="mx-auto justify-center ml-5 mr-5 rounded-lg mb-4"
              height="220" width="220" link :to="{ name: 'ListaConceptos' }"
                @click="$store.state.nombreComponente = 'Catalogos/Conceptos'">
                <v-card-text class="text-center">
                  <v-icon size="150" color="primary">mdi-basket</v-icon>
                  <h2 class="mt-4">Conceptos</h2>
                </v-card-text>
              </v-card>
            </v-hover>

            <v-hover v-slot="{ hover }" open-delay="10">
              <v-card :elevation="hover ? 16 : 2" :class="{ 'on-hover': hover }" class="mx-auto justify-center ml-5 mr-5 rounded-lg mb-4"
              height="220" width="220" link :to="{ name: 'CatalogosCartaPorte' }"
                @click="$store.state.nombreComponente = 'Catalogos/CartaPorte'">
                <v-card-text class="text-center">
                  <v-icon size="150" color="primary">mdi-chart-timeline</v-icon>
                  <h2 class="mt-4">Carta Porte</h2>
                </v-card-text>
              </v-card>
            </v-hover>

            <v-hover v-slot="{ hover }" open-delay="10">
              <v-card :elevation="hover ? 16 : 2" :class="{ 'on-hover': hover }" class="mx-auto justify-center ml-5 rounded-lg mb-4"
              height="220" width="220" link :to="{ name: '' }">
                <v-card-text class="text-center">
                  <v-icon size="150" color="primary">mdi-file-undo-outline</v-icon>
                  <h2 class="mt-4">Comercio Exterior</h2>
                </v-card-text>
              </v-card>
            </v-hover>
      </v-row>
        </v-container>
      </v-item-group>
      <v-main>
        <v-container fluid>
          <v-fade-transition mode="out-in">
            <router-view></router-view>
          </v-fade-transition>
        </v-container>
      </v-main>

    </v-card>

  </div>
</template>

<script>
  import axios from 'axios'
  export default {
    name: 'menu',
    components: {

    },

    data: () => ({

    }),
    watch: {

    },
    computed: {

    },
    mounted() {

    },

    methods: {
      averquehace() {
        console.log('Boton de acción')
      },
    }
  }
</script>
<style>
  .app {
    background: url('https://img.freepik.com/vector-gratis/fondo-blanco-minimo_1393-354.jpg?w=2000') no-repeat center center fixed !important;
    background-size: cover;
  }
</style>