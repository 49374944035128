<template>
    <div>
        <v-card>
            <v-toolbar color="primary" dark>
                <v-btn icon @click="CerrarVentanaDirecciones()">
                    <v-icon large>mdi-close-circle</v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <h3>Direcciones</h3>
                <v-spacer></v-spacer>
                <v-btn icon @click="save()">
                    <v-icon large>mdi-check-circle</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text>
                <span hidden>{{this.$store.state.direcciones}}</span>
                <v-row>
                    <v-col cols="12">
                        <v-select label="Pais" :items="itemsPais" v-model="pais" append-outer-icon="mdi-check"
                            item-text="pais"
                            hint="Valide el pais para listar los estados, en caso de ser: México, Estados Unidos o Canadá"
                            persistent-hint @click:append-outer="listarEstados" return-object></v-select>
                    </v-col>
                    <v-col cols="12" md="6" sm="12">
                        <v-select label="Estado" :items="itemsEstado" v-model="direcciones.estado" item-text="estado"
                            return-object>
                        </v-select>
                    </v-col>
                    <v-col cols="12" md="6" sm="12">
                        <v-text-field label="Código postal" v-model="direcciones.codigoPostal"
                            append-outer-icon="mdi-check"
                            hint="Valide el código postal para listar los municipíos, localidades y colonias"
                            persistent-hint @click:append-outer="validaCodigoPostal"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" sm="12">
                        <v-select label="Municipio" :items="itemsMunicipio" v-model="direcciones.municipio"
                            item-text="municipio" return-object></v-select>
                    </v-col>
                    <v-col cols="12" md="6" sm="12">
                        <v-select label="Localidad" :items="itemsLocalidad" v-model="direcciones.localidad"
                            item-text="localidad" return-object></v-select>
                    </v-col>
                    <v-col cols="12">
                        <v-select label="Colonia" :items="itemsColonia" v-model="direcciones.colonia"
                            item-text="colonia" return-object></v-select>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field label="Calle" v-model="direcciones.calle"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" sm="12">
                        <v-text-field label="Número exterior" v-model="direcciones.numeroExterior"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" sm="12">
                        <v-text-field label="Número interior" v-model="direcciones.numeroInterior"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field label="Referencia" v-model="direcciones.referencia"></v-text-field>
                    </v-col>
                </v-row>
                <span hidden>AAA:{{ojalaFuncione}}</span>
            </v-card-text>
        </v-card>
        <!-- DIALOG DE QUE ESTA HACIENDO ALGO -->
        <div>
            <v-dialog v-model="dialog" hide-overlay persistent width="300">
                <v-card color="primary" dark>
                    <v-card-text>
                        {{ dialogMessage }}
                        <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </div>
        <!-- SNACKBAR PARA NOTIFICAR LOS ERRORES -->
        <v-snackbar :timeout="5000" v-model="snackbar" color="primary darken-2" elevation="30">{{ snackbarMessage }}
            <template v-slot:action="{ attrs }">
                <v-icon dark @click="snackbar = false">
                    mdi-close-circle
                </v-icon>
            </template>
        </v-snackbar>
    </div>
</template>
<script>
    import axios from 'axios'
    export default {
        name: 'Direcciones',
        components: {

        },

        data: () => ({
            //DIALOG
            dialog: false,
            dialogMessage: '',

            // SNACKBAR
            snackbar: false,
            snackbarMessage: '',

            //DIRECCION
            pais: { clave: 'MEX', descripcion: 'México', pais: 'MEX | México' },
            itemsPais: [],
            codigoPostal: '',
            estado: null,
            itemsEstado: [],
            municipio: '',
            itemsMunicipio: [],
            referencia: '',
            localidad: '',
            itemsLocalidad: [],
            colonia: '',
            itemsColonia: [],
            numeroInterior: '',
            numeroExterior: '',
            calle: '',


        }),
        watch: {

        },
        computed: {
            datosEmpresa() {
                return this.$store.state.usuario;
            },
            direcciones() {
                return this.$store.state.direcciones;
            },
            ojalaFuncione() {
                console.log('entra');
                let direcciones = this.$store.state.direcciones;
                if (this.direcciones.pais.clave === 'MEX') {
                    axios
                        .get('CatalogosCartaPorte/GetCodigoPostal/' + this.direcciones.codigoPostal + '/' + this.direcciones.estado.clave)
                        .then(result => {
                            let response = result;
                            this.listarMunicipio(direcciones.estado.clave, response.data.c_Municipio);
                            this.listarColonia(direcciones.codigoPostal);
                            this.listarLocalidad(direcciones.estado.clave, response.data.c_Localidad);
                            return 'SI'
                        })
                        .catch(error => {
                            console.log(error);
                            return 'NO'
                        });
                }
            }
        },
        mounted() {
            this.listarPaises();
            this.listarEstados();
        },

        methods: {
            CerrarVentanaDirecciones() {
                this.$emit("VentanaNuevoDirecciones");
            },
            async listarPaises() {
                try {
                    let response = await axios.get('CatalogosCartaPorte/GetPaises');
                    this.itemsPais = response.data
                } catch (error) {
                    console.log(error)
                }
            },

            async listarEstados() {
                this.dialogMessage = 'Validando país'
                this.dialog = true;
                try {
                    let response = await axios.get('CatalogosCartaPorte/GetEstado/' + this.direcciones.pais.clave);
                    this.itemsEstado = response.data
                    this.dialog = false;
                } catch (error) {
                    console.log(error)
                    this.dialog = false;
                }
            },
            async validaCodigoPostal() {
                if (this.direcciones.codigoPostal == '' || !this.direcciones.codigoPostal) {
                    this.snackbarMessage = 'Indique un código postal para continuar'
                    this.snackbar = true
                    return
                }

                if (!this.direcciones.estado) {
                    this.snackbarMessage = 'Indique un estado para continuar'
                    this.snackbar = true
                    return
                }


                if (this.direcciones.pais.clave === 'MEX') {
                    try {
                        let response = await axios.get('CatalogosCartaPorte/GetCodigoPostal/' + this.direcciones.codigoPostal + '/' + this.direcciones.estado.clave);
                        this.listarMunicipio(this.direcciones.estado.clave, response.data.c_Municipio);
                        this.listarLocalidad(this.direcciones.estado.clave, response.data.c_Localidad);
                        this.listarColonia(this.direcciones.codigoPostal);
                    } catch (error) {
                        console.log(error)
                    }
                }
            },

            async listarMunicipio(estado, municipio) {
                console.log(estado, municipio)
                try {
                    let response = await axios.get('CatalogosCartaPorte/GetMunicipio/' + estado + '/' + municipio);
                    this.itemsMunicipio = response.data
                } catch (error) {
                    console.log(error)
                }
            },

            async listarLocalidad(estado, localidad) {
                try {
                    let response = await axios.get('CatalogosCartaPorte/GetLocalidad/' + estado + '/' + localidad);
                    this.itemsLocalidad = response.data
                } catch (error) {
                    console.log(error)
                }
            },

            async listarColonia(codigoPostal) {
                try {
                    let response = await axios.get('CatalogosCartaPorte/GetColonia/' + codigoPostal);
                    this.itemsColonia = response.data
                } catch (error) {
                    console.log(error)
                }
            },
            async save() {
                if (this.direcciones.estado == "" || !this.direcciones.estado) {
                    this.snackbarMessage = 'Indique el estado para continuar'
                    this.snackbar = true
                    return;
                }
                if (this.direcciones.pais.pais == "" || !this.direcciones.pais.pais) {
                    this.snackbarMessage = 'Indique el pais para continuar'
                    this.snackbar = true
                    return;
                }
                if (this.direcciones.codigoPostal == "" || !this.direcciones.codigoPostal) {
                    this.snackbarMessage = 'Indique el Codigo Postal para continuar'
                    this.snackbar = true
                    return;
                }
                if (this.direcciones.municipio == "" || !this.direcciones.municipio) {
                    this.snackbarMessage = 'Indique el Municipio para continuar'
                    this.snackbar = true
                    return;
                }
                if (!this.direcciones.localidad) {
                    this.direcciones.localidad = {}
                }
                if (this.direcciones.colonia == "" || !this.direcciones.colonia) {
                    this.snackbarMessage = 'Indique la colonia para continuar'
                    this.snackbar = true
                    return;
                }
                let Domicilio = Object.assign({}, this.$store.state.direcciones)

                console.log(Domicilio)
                if (this.$store.state.direcciones.id > 0) {
                    try {
                        let response = await axios.post('CartaPorte/UpdateDirecciones/' + this.datosEmpresa.empresaBase, Domicilio);
                        console.log(response)
                        this.snackbarMessage = 'Actualizado con exito con exito.'
                        this.snackbar = true
                        this.$store.state.direcciones.id = -1

                        this.CerrarVentanaDirecciones();
                    } catch (error) {
                        console.log(error)
                        this.snackbarMessage = 'Error al actualizar, intentelo de nuevo.'
                        this.snackbar = true
                    }
                } else {
                    try {
                        let response = await axios.post('CartaPorte/PostDirecciones/' + this.datosEmpresa.empresaBase, Domicilio);
                        console.log(response)
                        this.snackbarMessage = 'Guardado con exito.'
                        this.snackbar = true
                        this.$store.state.direcciones.id = -1

                        this.CerrarVentanaDirecciones();
                    } catch (error) {
                        console.log(error)
                        this.snackbarMessage = 'Error al guardar, intentelo de nuevo.'
                        this.snackbar = true
                    }
                }

            },
        }
    }
</script>