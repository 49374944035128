<template>
  <div>
    <!--Dialog loading-->
    <v-dialog v-model="loading" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Cargando datos...
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!--diealgo para nuevo vehiculo-->
    <v-dialog v-model="dialogNuevoRemolque" max-width="500px" persistent>
      <remolque @VentanaNuevoRemolque="CierraVentanaNuevoRemolque()"></remolque>
    </v-dialog>
    <v-card>
      <v-card-title style="font: bold;" class="text-h4 text--primary">
        <!--Boton regresar-->
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" class="mx-2" icon large link :to="{ name: 'CatalogosCartaPorte' }">
              <v-icon size="40" color="primary">mdi-arrow-left-thick</v-icon>
            </v-btn>
          </template>
          <span>Regresar</span>
        </v-tooltip>
        Lista de Remolques
        <v-spacer></v-spacer>
        <div class="text-center">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" class="mx-2" fab dark color="blue" @click="listarRemolque()">
                <v-icon dark>mdi-update</v-icon>
              </v-btn>
            </template>
            <span>Actualizar</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" class="mx-2" fab dark color="green" @click="nuevoRemolque()">
                <v-icon dark>mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>Nuevo</span>
          </v-tooltip>
        </div>
      </v-card-title>
      <v-divider></v-divider>
      <v-data-table :headers="headers" :items="items">
        <!-- BOTONES DE ACCION  -->
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon dense class="mr-2" v-bind="attrs" v-on="on" color="blue" @click="editar(item)">mdi-lead-pencil</v-icon>
            </template>
            <span>Editar</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon dense class="mr-2" v-bind="attrs" v-on="on" color="red" @click="deleteItem(item)">mdi-delete</v-icon>
            </template>
            <span>Eliminar</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
  import axios from 'axios'
  import Remolque from '../Comprobantes/CartaPorte/Remolque.vue'

  export default {
    components: {
      Remolque
    },
    data() {
      return {
        headers: [
          { text: 'Acciones', value: 'actions' },
          { text: "id", value: "id" },
          { text: "Placa", value: "placa" },
          { text: "Remolque", value: "subTipoRem.subTipoRem" },
        ],
        items: [],
        // Nuevo vehiculo
        dialogNuevoRemolque: false,

        // loading
        loading: false,
      };
    },
    mounted() {

    },
    computed: {
      datosEmpresa() {
        return this.$store.state.usuario;
      },
      remolque(){
                return this.$store.state.remolque;
            }
    },
    created(){
    this.listarRemolque();
  },
    methods: {
      //VEHICULO
      nuevoRemolque() {
        let remolque = {
        id: 0
      }
      if (this.$store.state.remolque.id === -1) {
          this.$store.state.remolque = Object.assign({}, remolque)
        }
        this.dialogNuevoRemolque = true;
      },
      CierraVentanaNuevoRemolque() {
        this.dialogNuevoRemolque = false;
      },
      // listar vehiculos
      async listarRemolque() {
        this.loading = true;
        try {
          let response = await axios.get('CartaPorte/GetRemolques/' + this.datosEmpresa.empresaBase);
          this.items = response.data;
          console.log(this.items)
          this.loading = false;
        } catch (error) {
          console.log(error);
        }
      },
      editar(item) {
        let indice = this.items.indexOf(item)
        const remolque = Object.assign({}, item)
        this.$store.state.remolque = Object.assign({}, remolque);
        console.log(this.$store.state.remolque)
        this.dialogNuevoRemolque = true;
      },
      async deleteItem(item){
      let id = item.id
       try {
        let response = await axios.post('CartaPorte/DeleteRemolques/' + this.datosEmpresa.empresaBase + '/' + id);
        this.listarRemolque();
        this.loading = false;
        } catch (error) {
          console.log(error);
      }
    }
    },
  };
</script>