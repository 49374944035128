<template>
    <div>
        <v-card class="auto">
            <v-data-table :headers="headersConceptos" :items="itemsListConceptos">
                <template v-slot:top>
                    <v-toolbar flat>
                        <v-toolbar-title>Conceptos</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-dialog v-model="dialogConcepto" max-width="800px">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on" fab>
                                    <v-icon>mdi-archive-plus</v-icon>
                                </v-btn>
                            </template>
                            <v-card>
                                <v-toolbar color="primary" dark>
                                    <v-btn icon>
                                        <v-icon large>mdi-close-circle</v-icon>
                                      </v-btn>
                                      <v-spacer></v-spacer>
                                      <h3>Concepto</h3>
                                      <v-spacer></v-spacer>
                                      <v-btn icon @click="PushConcepto()">
                                        <v-icon large>mdi-check-circle</v-icon>
                                      </v-btn>
                                </v-toolbar>
                                <v-card-text>
                                    <!-- SELECCIONA CONCEPTO PARA AGREGAR A LA LISTA DE CONCEPTOS -->
                                    <v-row>
                                        <!-- BUSCADOR POR CLAVE O DESCRIPCION -->
                                        <v-col cols="12">
                                            <v-autocomplete label="Buscar por clave o descripción" v-model="selectConcepto" :items="$store.state.listConceptosStore" :loading="loadingConcepto" :search-input.sync="searchConcepto" item-text="concepto" append-outer-icon="mdi-tag-plus" @click:append-outer="nuevoConcepto()" return-object></v-autocomplete>
                                        </v-col>
                                        <!-- CLAVE DEL PRODUCTO O SERVICIO SAT -->
                                        <v-col cols="12">
                                            <v-autocomplete label="Clave del producto o servicio" :items="itemsClaveProdServ" :loading="loadingClaveProdServ" :search-input.sync="searchClaveProdServ" item-text="claveProdServ" return-object placeholder="Ingrese el parámetro de bíusqueda" :value="claveProdServ"></v-autocomplete>
                                        </v-col>
                                        <!-- OBJETO DE IMPUESTOS -->
                                        <v-col cols="12" md="6" sm="12">
                                            <v-select label="Objeto de impuestos" :items="itemsObjetoImp" :value="objetoImp" return-object item-text="objetoImp"></v-select>
                                        </v-col>
                                        <!-- NO DE IDENTIFICACION, CLAVE INTERNA -->
                                        <v-col cols="12" md="6" sm="12">
                                            <v-text-field label="No. de identificación" v-model="selectConcepto.noIdentificacion"></v-text-field>
                                        </v-col>
                                        <!-- DESCRIPCION INTERNA DEL PRODUCTO O SERVICIO -->
                                        <v-col cols="12">
                                            <v-text-field label="Descripción" v-model="selectConcepto.descripcion"></v-text-field>
                                        </v-col>
                                        <!-- CANTIDAD A FACTURARA -->
                                        <v-col cols="12" md="4" sm="12">
                                            <v-text-field label="Cantidad" v-model="selectConcepto.cantidad"></v-text-field>
                                            <span>{{ formatoDosDecimales(selectConcepto.cantidad) }}</span>
                                        </v-col>
                                        <!-- CLAVE DE UNIDAD -->
                                        <v-col cols="12" md="4" sm="12">
                                            <v-autocomplete label="Clave de unidad" :items="itemsClaveUnidad" :loading="loadingClaveUnidad" :search-input.sync="searchClaveUnidad" item-text="claveUnidad" return-object placeholder="Ingrese el parámetro de bíusqueda" :value="claveUnidad"></v-autocomplete>
                                        </v-col>
                                        <!-- UNIDAD DE MEDIDA INTERNA -->
                                        <v-col cols="12" md="4" sm="12">
                                            <v-text-field label="Unidad" v-model="selectConcepto.unidad"></v-text-field>
                                        </v-col>
                                        <!-- RADIO GROUP PARA DEFINIR EL CALCULO POR IMPORTE O POR PRECIO -->
                                        <v-col cols="4">
                                            <v-radio-group v-model="radioGroup" row>
                                                <v-radio label="importe" value="importe"></v-radio>
                                                <v-radio label="precio" value="precio"></v-radio>
                                            </v-radio-group>
                                        </v-col>
                                        <!-- VALOR UNITARIO DEL BIEN O SERVICIO -->
                                        <template v-if="radioGroup === 'importe'">
                                            <v-col cols="8">
                                                <v-text-field label="Valor unitario" v-model="selectConcepto.valorUnitario" @keydown.enter="PushConcepto"></v-text-field>
                                                <span>{{ formatoDosDecimales(selectConcepto.valorUnitario) }}</span>
                                            </v-col>
                                        </template>
                                        <!-- PRECIO UNITARIO DEL BIEN O SERVICIO -->
                                        <template  v-if="radioGroup === 'precio'">
                                            <v-col cols="8">
                                                <v-text-field label="Precio unitario" v-model="selectConcepto.precioUnitario" @keydown.enter="PushConcepto"></v-text-field>
                                                <span>{{ formatoDosDecimales(selectConcepto.precioUnitario) }}</span>
                                            </v-col>
                                        </template>
                                    </v-row>
                                    <v-system-bar color="primary" dark><strong>IMPUESTOS</strong></v-system-bar>
                                    <!-- IMPUESTOS -->
                                    <v-data-table :headers="headersImpuestos" :items="itemsImpuestos" v-model="selectItemsImpuestos" item-key="nombre" show-select>

                                    </v-data-table>
                                </v-card-text>
                            </v-card>
                        </v-dialog>
                    </v-toolbar>
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>

<script>
    import axios from 'axios'
    import Concepto from '../Comprobantes/Concepto.vue'


    export default {
        name: 'ComprobanteConceptos',
        components:{
            Concepto,
        },

        data: () => ({
            //CLAVE DEL PRODUCTO O SERVICIO
            // claveProdServ: {},
            itemsClaveProdServ: [],
            limitClaveProdServ: 60,
            loadingClaveProdServ: false, 
            searchClaveProdServ: null,

            // LISTA DE CONCEPTOS
            // itemsListConceptos:[],

            //CONCEPTO
            selectConcepto: {},
            searchConcepto: null,
            loadingConcepto: false,
            limitConcepto : 60,

            //PARA AGREGAR UN NUEVO CONCEPTO
            dialogConcepto: false,
            headersConceptos: [
                { text: 'Acciones', sortable:false, value: 'actions' },
                { text: 'item', sortable:false, value: 'item' },
                { text: 'Clave del producto o servicio', sortable:false, value: 'claveProdServ' },
                { text: 'No de Identificación', sortable:false, value: 'noIdentificacion' },
                { text: 'Descripción', sortable:false, value: 'descripcion' },
                { text: 'Cantidad', sortable:false, value: 'cantidad' },
                { text: 'Valor unitario', sortable:false, value: 'valorUnitario' },
                { text: 'Precio unitario', sortable:false, value: 'precioUnitario' },
                { text: 'Descuento', sortable:false, value: 'descuento' },
                { text: 'Importe', sortable:false, value: 'importe' },
                { text: 'Total', sortable:false, value: 'total' },
            ],

            //OBJETO DE IMPUESTOS
            itemsObjetoImp: [],

            //CLAVE DE UNIDAD
            // claveUnidad: {},
            itemsClaveUnidad: [],
            limitClaveUnidad: 60,
            loadingClaveUnidad: false,
            searchClaveUnidad : null,

            //NUEVO CONCEPTO
            dialogNuevoConcepto: false,

            //RADIO PARA VER SI SE CALCULA POR IMPORTE O POR PRECIO
            radioGroup: 'importe',

            //IMPUESTOS
            headersImpuestos: [
            { text: 'Nombre', sortable:false, value: 'nombre' },
            { text: 'Impuesto', sortable:false, value: 'impuesto' },
            { text: 'Tipo factor', sortable:false, value: 'tipoFactor' },
            { text: 'Tasa o cuota', sortable:false, value: 'tasaOCuota' },
            { text: 'Tipo', sortable:false, value: 'tipo' },
            ],
            itemsImpuestos: [
                { nombre:'IVA(16%)', base_:0, impuesto:'002', tipoFactor:'Tasa', tasaOCuota:16, importe:0, tipo:'traslado' },
            ],
            selectItemsImpuestos:[],

            //TOTAL DE IMPUESTOS
            totalImpuestosTrasladados: 0.00,
            totalImpuestosRetenidos: 0.00,
            subTotal: 0.00,
            total: 0.00,
            listRetenciones:[],
            listTrasladados:[],
        }),

        watch:{
            async searchConcepto(val){
                if (this.itemsconcepto.length > 0) return
                if (this.loadingConcepto) return
                this.loadingConcepto = true
                try{
                    let response = await axios.get('Conceptos/GetConceptosComprobantes/'+ this.datosEmpresa.empresaBase)
                    this.$store.state.listConceptosStore = response.data;
                }catch(error){
                    console.log(error)
                }
                this.loadingConcepto = false
            },
            async searchClaveProdServ(val){
                if (this.itemsclaveProdServ.length > 0) return
                if (this.loadingClaveProdServ) return
                this.loadingClaveProdServ = true
                try{
                    let response = await axios.get('CatalogoComprobantes/GetClaveProdServ')
                    this.itemsClaveProdServ = response.data;
                }catch(error){
                    console.log(error)
                }
                this.loadingClaveProdServ = false
            },
            async searchClaveUnidad(val){
                if(this.itemsclaveUnidad.length > 0) return
                if(this.loadingClaveUnidad) return
                this.loadingClaveUnidad = true
                try{
                    let response = await axios.get('CatalogoComprobantes/GetClaveUnidad')
                    this.itemsClaveUnidad = response.data;
                }catch(err){
                    console.log(err)
                }
                this.loadingClaveUnidad = false
            },
        },

        computed: {
            itemsconcepto(){
                return this.$store.state.listConceptosStore.map(entry => {
                const Description = entry.descripcion.length > this.limitConcepto
                    ? entry.descripcion.slice(0, this.limitConcepto) + '...'
                    : entry.descripcion

                return Object.assign({}, entry, { Description })
                })
            },
            itemsclaveProdServ(){
                return this.itemsClaveProdServ.map(entry => {
                    const Description = entry.descripcion.length > this.limitClaveProdServ
                        ? entry.descripcion.slice(0, this.limitClaveProdServ) + '...'
                        : entry.descripcion

                    return Object.assign({}, entry, { Description })
                })
            },
            itemsclaveUnidad(){
                return this.itemsClaveUnidad.map(entry => {
                    const Description = entry.nombre.length > this.limitClaveUnidad
                    ? entry.nombre.slice(0, this.limitClaveUnidad) + '...'
                    :entry.nombre
                    return Object.assign( {}, entry, { Description })
                })
            },
            objetoImp(){
                let Objecto = this.itemsObjetoImp.find(variable => variable.clave === this.selectConcepto.objetoImp);
                return Objecto
            },
            claveProdServ(){
                let Objecto = this.itemsClaveProdServ.find(variable => variable.clave === this.selectConcepto.claveProdServ);
                return Objecto
            },
            claveUnidad(){
                let Objecto = this.itemsClaveUnidad.find(variable => variable.c_ClaveUnidad === this.selectConcepto.claveUnidad);
                return Objecto
            },
            itemsListConceptos(){
                return this.$store.state.facturaStore.conceptos;
            },
             datosEmpresa() {
                return this.$store.state.usuario;
            },
        
        },

        created () {
            this.Get_lista_c_claveprodserv();
            this.Get_lista_c_claveunidadStore();
            this.GetObjetoImp();
        },

        methods: {
             //FORMATO NUMERICO
             formatoDosDecimales(value) {
                let val = (value/1).toFixed(2)
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            },
            async Get_lista_c_claveprodserv(){
                if(this.$store.state.lista_c_claveprodservStore.length < 1){
                    try{
                        let response = await axios.get('CatalogoComprobantes/GetClaveProdServ')
                        this.$store.state.lista_c_claveprodservStore = response.data;
                    }catch(error){
                        console.log(error)
                    }
                }
                this.itemsClaveProdServ = this.$store.state.lista_c_claveprodservStore.slice();
            },
            async Get_lista_c_claveunidadStore(){
                if(this.$store.state.lista_c_claveunidadStore.length < 1){
                    try{
                        let response = await axios.get('CatalogoComprobantes/GetClaveUnidad')
                        this.$store.state.lista_c_claveunidadStore = response.data;
                    }catch(error){
                        console.log(error)
                    }
                }
                this.itemsClaveUnidad = this.$store.state.lista_c_claveunidadStore.slice();
            },
            async GetObjetoImp(){
                try{
                    let response = await axios.get('CatalogoComprobantes/GetObjetoImp')
                    this.itemsObjetoImp = response.data
                }catch(err){
                    console.log(err)
                }
            },
            
            PushConcepto(){
                this.listTrasladados=[];
                this.selectConcepto.claveProdServ = this.claveProdServ.clave
                this.selectConcepto.objetoImp = this.objetoImp.clave
                this.selectConcepto.claveUnidad = this.claveUnidad.c_ClaveUnidad
                this.selectConcepto.importe = this.selectConcepto.valorUnitario * this.selectConcepto.cantidad

                let concepto_ = Object.assign( {}, this.selectConcepto )

                //CALCULAMOS LOS IMPUESTOS TRASLADADOS
                let trasladados = this.selectItemsImpuestos.filter(tipo => tipo.tipo === 'traslado')
                for(let impuesto of trasladados){
                    let tasaOcuota = impuesto.tasaOCuota / 100;
                    impuesto.base_ = this.selectConcepto.importe;
                    impuesto.importe = Math.round( (this.selectConcepto.importe * tasaOcuota)*1000000)/1000000;

                    let impuestoT = Object.assign( {}, impuesto)
                    concepto_.impuestos.traslados.traslados.push(impuestoT);
                }

                //AGREGAMOS EL CONCEPTO A LA LISTA
                this.itemsListConceptos.push(concepto_);

                //ASIGNAMOS EL NUMERO DE ITEM Y SACAMOS LA SUMATORIA
                let contador = 1;
                let sumaTrasladados = 0.00;
                let sumaRetenidos = 0.00;
                let sumaImporte = 0.00;
                let sumaTotal = 0.00
                for(let a of this.itemsListConceptos){
                    a.item = contador ++
                    sumaImporte = sumaImporte + a.importe;

                    //IMPUESTOS TRASLADADOS DEL CONCEPTO
                    let sumaT = 0.00;
                    for(let t of a.impuestos.traslados.traslados){
                        sumaTrasladados = sumaTrasladados + t.importe;
                        sumaT = sumaT + t.importe
                        //AGREGAMOS A LA LISTA GENERAL DE IMPUESTOS TRASLADADOS
                        this.listTrasladados.push(t);
                    }
                    a.total = (Math.round(a.importe*100)/100) + (Math.round(sumaT*100)/100)
                    a.precioUnitario = Math.round((a.total / a.cantidad)*100)/100
                    sumaT = 0.00;
                }

                this.subTotal = Math.round(sumaImporte * 100) / 100
                this.totalImpuestosTrasladados = Math.round(sumaTrasladados * 100) / 100
                this.totalImpuestosRetenidos = Math.round(sumaRetenidos * 100) / 100
                this.total = Math.round((this.subTotal + this.totalImpuestosTrasladados - this.totalImpuestosRetenidos) * 100) / 100

                this.selectConcepto = Object.assign({})

                this.$store.state.traslados = this.listTrasladados, 'impuesto', 'tasaOCuota';
                this.$store.state.retenciones = [];
                this.$store.state.totalImpuestosTrasladados = this.totalImpuestosTrasladados;
                this.$store.state.totalImpuestosRetenidos = this.totalImpuestosRetenidos;
                this.$store.state.facturaStore.subTotal = this.subTotal;
                this.$store.state.facturaStore.total = this.total;
            },
            
            agrupaImpuestos(miarray, prop, propa){
                return miarray.reduce(function(groups, item) {
                    var val = item[prop, propa];
                    groups[val] = groups[val] || { nombre: item.nombre, base_: 0, impuesto: item.impuesto, tipoFactor: item.tipoFactor, tasaOCuota: item.tasaOCuota, importe:0, tipo: item.tipo };
                    groups[val].base_ += item.base_;
                    groups[val].importe += item.importe;
                    return groups;
                }, {});
            },
        
        },
    }
</script>