var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app"},[_c('v-toolbar',{attrs:{"flat":"","color":"transparent"}},[_c('v-toolbar-title',{staticClass:"dark"},[_c('h2')]),_c('v-spacer')],1),_c('v-card',{staticClass:"justify-center pt-3 pb-5",attrs:{"flat":"","color":"transparent"}},[_c('div',{staticClass:"text-center mt-4"},[_c('img',{attrs:{"src":require("../assets/logo_cotago facturacion_web_sin_fondo.png"),"height":"150"}})])]),_c('v-card',{staticClass:"justify-center",attrs:{"flat":"","color":"transparent"}},[_c('v-item-group',{attrs:{"mandatory":""}},[_c('v-container',[_c('v-row',{attrs:{"dense":""}},[_c('v-hover',{attrs:{"open-delay":"10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto justify-center mr-5 rounded-lg mb-4",class:{ 'on-hover': hover },attrs:{"elevation":hover ? 16 : 2,"height":"200","width":"200","link":"","to":{ name: 'ListaVehiculo' }}},[_c('v-card-text',{staticClass:"text-center "},[_c('v-icon',{attrs:{"size":"120","color":"primary"}},[_vm._v("mdi-car")]),_c('h2',{staticClass:"mt-4"},[_vm._v("Vehiculo")])],1)],1)]}}])}),_c('v-hover',{attrs:{"open-delay":"10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto justify-center ml-5 mr-5 rounded-lg mb-4",class:{ 'on-hover': hover },attrs:{"elevation":hover ? 16 : 2,"height":"200","width":"200","link":"","to":{ name: 'ListaRemolques' }}},[_c('v-card-text',{staticClass:"text-center "},[_c('v-icon',{attrs:{"size":"120","color":"primary"}},[_vm._v("mdi-car-multiple")]),_c('h2',{staticClass:"mt-4"},[_vm._v("Remolques")])],1)],1)]}}])}),_c('v-hover',{attrs:{"open-delay":"10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto justify-center ml-5 mr-5 rounded-lg mb-4",class:{ 'on-hover': hover },attrs:{"elevation":hover ? 16 : 2,"height":"200","width":"200","link":"","to":{ name: 'ListaPermisoSCT' }}},[_c('v-card-text',{staticClass:"text-center "},[_c('v-icon',{attrs:{"size":"120","color":"primary"}},[_vm._v("mdi-car-select")]),_c('h2',{staticClass:"mt-4"},[_vm._v("Permiso SCT")])],1)],1)]}}])}),_c('v-hover',{attrs:{"open-delay":"10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto justify-center  ml-5 mr-5 rounded-lg mb-4",class:{ 'on-hover': hover },attrs:{"elevation":hover ? 16 : 2,"height":"200","width":"200","link":"","to":{ name: 'ListaSeguro' }}},[_c('v-card-text',{staticClass:"text-center"},[_c('v-icon',{attrs:{"size":"120","color":"primary"}},[_vm._v("mdi-shield-car")]),_c('h2',{staticClass:"mt-4"},[_vm._v("Seguro")])],1)],1)]}}])}),_c('v-hover',{attrs:{"open-delay":"10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto justify-center  ml-5  rounded-lg mb-4",class:{ 'on-hover': hover },attrs:{"elevation":hover ? 16 : 2,"height":"200","width":"200","link":"","to":{ name: 'ListaMercancia' }}},[_c('v-card-text',{staticClass:"text-center"},[_c('v-icon',{attrs:{"size":"120","color":"primary"}},[_vm._v("mdi-package-variant-closed-plus")]),_c('h2',{staticClass:"mt-4"},[_vm._v("Mercancía")])],1)],1)]}}])})],1)],1)],1)],1),_c('v-card',{staticClass:"justify-center",attrs:{"flat":"","color":"transparent"}},[_c('v-item-group',{attrs:{"mandatory":""}},[_c('v-container',[_c('v-row',{attrs:{"dense":""}},[_c('v-hover',{attrs:{"open-delay":"10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto justify-center  mr-5 rounded-lg mb-4",class:{ 'on-hover': hover },attrs:{"elevation":hover ? 16 : 2,"height":"200","width":"200","link":"","to":{ name: 'ListaUbicacionOrigen' }}},[_c('v-card-text',{staticClass:"text-center "},[_c('v-icon',{attrs:{"size":"120","color":"primary"}},[_vm._v("mdi-map-marker-radius")]),_c('h2',{staticClass:"mt-4"},[_vm._v("Ubicación de Origen")])],1)],1)]}}])}),_c('v-hover',{attrs:{"open-delay":"10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto justify-center  ml-5 mr-5 rounded-lg mb-4",class:{ 'on-hover': hover },attrs:{"elevation":hover ? 16 : 2,"height":"200","width":"200","link":"","to":{ name: 'ListaUbicacionDestino' }}},[_c('v-card-text',{staticClass:"text-center"},[_c('v-icon',{attrs:{"size":"120","color":"primary"}},[_vm._v("mdi-map-marker-plus")]),_c('h2',{staticClass:"mt-4"},[_vm._v("Ubicación de Destino")])],1)],1)]}}])}),_c('v-hover',{attrs:{"open-delay":"10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto justify-center ml-5 mr-5 rounded-lg mb-4",class:{ 'on-hover': hover },attrs:{"elevation":hover ? 16 : 2,"height":"200","width":"200","link":"","to":{ name: 'ListaDirecciones' }}},[_c('v-card-text',{staticClass:"text-center "},[_c('v-icon',{attrs:{"size":"120","color":"primary"}},[_vm._v("mdi-map-marker-multiple")]),_c('h2',{staticClass:"mt-4"},[_vm._v("Direcciones")])],1)],1)]}}])}),_c('v-hover',{attrs:{"open-delay":"10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto justify-center  ml-5 mr-5 rounded-lg mb-4",class:{ 'on-hover': hover },attrs:{"elevation":hover ? 16 : 2,"height":"200","width":"200","link":"","to":{ name: 'ListaFigurasTransporte' }}},[_c('v-card-text',{staticClass:"text-center"},[_c('v-icon',{attrs:{"size":"120","color":"primary"}},[_vm._v("mdi-car-cog")]),_c('h2',{staticClass:"mt-4"},[_vm._v("Figuras de transporte")])],1)],1)]}}])}),_c('v-hover',{attrs:{"open-delay":"10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto justify-center ml-5 rounded-lg mb-4",class:{ 'on-hover': hover },attrs:{"elevation":hover ? 16 : 2,"height":"200","width":"200","link":"","to":{ name: 'ListaAutotransporte' }}},[_c('v-card-text',{staticClass:"text-center "},[_c('v-icon',{attrs:{"size":"120","color":"primary"}},[_vm._v("mdi-truck-cargo-container")]),_c('h2',{staticClass:"mt-4"},[_vm._v("Autotransporte")])],1)],1)]}}])})],1)],1)],1),_c('v-main',[_c('v-container',{attrs:{"fluid":""}},[_c('v-fade-transition',{attrs:{"mode":"out-in"}},[_vm._v(" router-view>")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }