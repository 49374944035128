<template>
<div>
  <v-card>
    <v-card-title style="font: bold;" class="text-h4 text--primary">
    <!--Boton regresar-->
    <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on"  class="mx-2" icon large link :to="{ name: 'Ventas' }">
                <v-icon size="40" color="primary">mdi-arrow-left-thick</v-icon>
                </v-btn>
            </template>
            <span>Regresar</span>
        </v-tooltip>
      Cotizaciones
      <v-spacer></v-spacer>
      <!--Fecha Inicial-->
      <v-col cols="12" sm="3" md="3">
      <v-menu v-model="calendario" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field v-model="fechaI" label="Fecha Inicial" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
        </template>
        <v-date-picker v-model="fechaI" @input="calendario = false"></v-date-picker>
      </v-menu>
    </v-col>
    <!--Fecha Final-->
    <v-col cols="12" sm="3" md="3">
      <v-menu v-model="calendario2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field v-model="fechaF" label="Fecha Final" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
        </template>
        <v-date-picker v-model="fechaF" @input="calendario2 = false"></v-date-picker>
      </v-menu>
    </v-col>
      <div class="text-center">
        <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" class="mx-2" fab  dark color="blue">
                <v-icon dark>mdi-magnify</v-icon>
                </v-btn>
            </template>
            <span>Actualizar</span>
        </v-tooltip>
        <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" class="mx-2" fab  dark color="green">
                <v-icon dark>mdi-plus</v-icon>
                </v-btn>
            </template>
            <span>Nuevo</span>
        </v-tooltip>
        </div>
    </v-card-title>
    <v-divider></v-divider>
    <v-data-table :headers="headers">
      <!-- BOTONES DE ACCION  -->
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                    <v-icon dense class="mr-2" v-bind="attrs" v-on="on" dark>mdi-lead-pencil</v-icon>
            </template>
            <span>Editar</span>
        </v-tooltip>
        <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-icon dense class="mr-2" v-bind="attrs" v-on="on" dark>mdi-minus-thick</v-icon>
            </template>
            <span>Eliminar</span>
        </v-tooltip>
        <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-icon dense class="mr-2" v-bind="attrs" v-on="on" dark>mdi-cancel</v-icon>
            </template>
            <span>Cancelar</span>
        </v-tooltip>
        <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-icon dense class="mr-2" v-bind="attrs" v-on="on" dark>mdi-list-status</v-icon>
            </template>
            <span>Estatus SAT</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </v-card>
</div>
</template>
<script>

import axios from 'axios'
import moment from 'moment';
import { format, parseISO } from 'date-fns';
import print from 'print-js';
export default {
data() {
    return {
      headers: [
            { text: 'Acciones', value: 'actions'},
            { text: "Col 1", value: "c" },
            { text: "Col 2", value: "c" },
            { text: "Col 3", value: "c" },
            { text: "Col 4", value: "c" },
      ],
      items: [],

      fechaI: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
      fechaF: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),

      calendario: false,
      calendario2: false,
    };
  },
  mounted() {
    
  },

  methods: {
    
  },
};
</script>