import Vue from 'vue'
import Vuex from 'vuex'
import decode from 'jwt-decode'
import router from '../router/index'

import { format, parseISO } from 'date-fns';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: null,
    usuario: null,
    //NOMBRE COMPONENTE
    nombreComponente:'Home',

    //RECEPTORES
    receptorStore: {
      id: -1
    },
    listReceptorStore: [],

    //CONCEPTOS
    conceptoStore: {
      id:-1
    },
    listConceptosStore: [],

    //CATALOGOS DEL SAT
    lista_c_claveprodservStore: [],
    lista_c_claveunidadStore:[],

    //COMPROBANTES
    listFacturaStore:[],
    facturaStore: {
      id : -1
    },
    listIngresoCPStore:[],
    ingresoCPStore:{
      id : -1,
    },
    listTrasladoCPStore:[],
    trasladoCPStore:{
      id : -1,
    },
    listNotaCreditoStore:[],
    notaCreditoStore: {
      id : -1
    },
    listHonorariosStore:[],
    honorariosStore:{
      id : -1
    },
    listArrendamientosStore:[],
    arrendamientosStore:{
      id : -1
    },
    listComplementoPagoStore:[],
    complementoPagoStore:{
      id : -1
    },

    //CANCELACION
    cancelacion:{},

    //CATALOGOS
    autotransporte:{
      id: -1,
    },
    direcciones:{
      id: -1,
    },
    figurasTransporte:{
      id: -1,
    },
    mercancia:{
      id: -1,
    },
    permisoSCT:{
      id: -1,
    },
    remolque:{
      id: -1,
    },
    seguros:{
      id: -1,
    },
    destinos:{
      id: -1,
    },
    origenes:{
      id: -1,
    },
    vehiculos:{
      id: -1,
    },

    //FECHAS
    fechaIStore: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
    fechaFStore: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
    
    //CORREO
    correoStore:{
      listaCorreos:[],
      listaCorreosCliente:[],
      asunto: '',
      listaDocumentosDefault:[],
    }
  },
  mutations: {
    setToken(state, token) {
      state.token = token
    },
    setUsuario(state, usuario) {
      state.usuario = usuario
    }
  },
  actions: {
    
    guardarToken({ commit }, token) {
      commit("setToken", token)
      commit("setUsuario", decode(token))
      localStorage.setItem("token", token)
    },
    autoLogin({ commit }) {
      let token = localStorage.getItem("token")
      if (token) {
        commit("setToken", token)
        commit("setUsuario", decode(token))
        router.push({ name: 'Home' })
      }
    },
    salir({ commit }) {
      commit("setToken", null)
      commit("setUsuario", null)
      localStorage.removeItem("token")
      router.push({ name: 'Login' })
    }
  },
  modules: {
  }
})
