<template>
<div>
  <!--Dialog loading-->
  <v-dialog v-model="loading" hide-overlay persistent width="300">
    <v-card color="primary" dark>
      <v-card-text>
        Cargando datos...
        <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>
  <!--DIALOG NUEVA MERCANCIA-->
  <v-dialog v-model="dialogMercanciasNuevo" persistent max-width="700px">
    <mercancia-mercancias @VentanaMercancias="CierraVentanaMercancias()"></mercancia-mercancias>
  </v-dialog>
  <v-card>
    <v-card-title style="font: bold;" class="text-h4 text--primary">
    <!--Boton regresar-->
    <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on"  class="mx-2" icon large link :to="{ name: 'CatalogosCartaPorte' }">
                <v-icon size="40" color="primary">mdi-arrow-left-thick</v-icon>
                </v-btn>
            </template>
            <span>Regresar</span>
        </v-tooltip>
      Lista de Mercancias
      <v-spacer></v-spacer>
      <div class="text-center">
        <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" class="mx-2" fab  dark color="blue" @click="listarMercancias()">
                <v-icon dark>mdi-update</v-icon>
                </v-btn>
            </template>
            <span>Actualizar</span>
        </v-tooltip>
        <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" class="mx-2" fab  dark color="green" @click="nuevaMercancia()">
                <v-icon dark>mdi-plus</v-icon>
                </v-btn>
            </template>
            <span>Nuevo</span>
        </v-tooltip>
        </div>
    </v-card-title>
    <v-divider></v-divider>
    <v-data-table :headers="headers" :items="items">
      <!-- BOTONES DE ACCION  -->
      <template v-slot:[`item.actions`]="{ item }" >
        <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                    <v-icon dense class="mr-2" v-bind="attrs" v-on="on" color="blue" @click="editar(item)">mdi-lead-pencil</v-icon>
            </template>
            <span>Editar</span>
        </v-tooltip>
        <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-icon dense class="mr-2" v-bind="attrs" v-on="on" color="red" @click="deleteItem(item)">mdi-delete</v-icon>
            </template>
            <span>Eliminar</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </v-card>
</div>
</template>
<script>
import axios from 'axios'
import MercanciaMercancias from '../Comprobantes/CartaPorte/Mercancia.vue'

export default {
  components:{
    MercanciaMercancias
    },
  data() {
    return {
      headers: [
        { text: 'Acciones', value: 'actions'},
        { text: "Descripcion", value: "bienesTransp.descripcion" },
        { text: "Material", value: "descripcion" },
        { text: "Cantidad", value: "cantidad" },
        { text: "Unidad", value: "unidad" },
        { text: "Material peligroso", value: "materialPeligroso" },
        { text: "Clave Material Peligroso", value: "cveMaterialPeligroso.clave" },
        { text: "Embalaje", value: "embalaje.clave" },
        { text: "Descripción del embalaje", value: "descripEmbalaje" },
        { text: "Peso en KG", value: "pesoEnKg" },
        { text: "Valor", value: "valorMercancia" },
        { text: "Moneda", value: "moneda.clave" },
        { text: "Dimensiones", value: "dimensiones" },

      ],
      items: [],

      // Nueva mercancia 
      dialogMercanciasNuevo: false,

      // loading
      loading: false, 
    };
  },
  mounted() {
    
  },
  computed: {
      datosEmpresa() {
        return this.$store.state.usuario;
      },
      mercancia(){
                return this.$store.state.mercancia
            }
    },
    created(){
    this.listarMercancias();
  },
  methods: {
    nuevaMercancia(){
      let mercancia = {
        id: 0,
        cveMaterialPeligroso: {
          claveMaterialPeligroso: '',
          descripcion: '',
          claseODiv: '',
          materialPeligroso: ''
        },
        materialPeligroso: { respuesta: 'SI', valor: 1 },
        
        moneda: { clave: 'MXN', descripcion: 'Peso Mexicano', moneda:'MXN | Peso Mexicano' },
        fraccionArancelaria:  Object.assign({}, )
      }
      if (this.$store.state.mercancia.id === -1) {
          this.$store.state.mercancia = Object.assign({}, mercancia)
        }
      this.dialogMercanciasNuevo = true;
    },
    //Cerrar ventanas
    CierraVentanaMercancias() {
    this.dialogMercanciasNuevo = false;
    },
    // listar mercancias
    async listarMercancias() {
      this.loading = true;
      try {
        let response = await axios.get('CartaPorte/GetMercancias/' + this.datosEmpresa.empresaBase);
        this.items = response.data; 
        console.log(this.items)
        this.loading = false;
        } catch (error) {
          console.log(error);
      }
    },
    editar(item) {
        let indice = this.items.indexOf(item)
        const mercancia = Object.assign({}, item)
        this.$store.state.mercancia = Object.assign({}, mercancia);
        console.log(this.$store.state.mercancia)
        if(this.$store.state.mercancia.materialPeligroso == 'SI'){
          this.$store.state.mercancia.materialPeligroso= { respuesta: "SI", valor: 1 }
        }else{
          this.$store.state.mercancia.materialPeligroso= { respuesta: "NO", valor: 0 }
        }
        this.dialogMercanciasNuevo = true;
      },
    async deleteItem(item){
      let id = item.id
       try {
        let response = await axios.post('CartaPorte/DeleteMercancias/' + this.datosEmpresa.empresaBase + '/' +id);
        this.listarMercancias();
        this.loading = false;
        } catch (error) {
          console.log(error);
      }
    }
  },
};
</script>