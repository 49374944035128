<template>
    <div>
        <v-card>
            <v-toolbar color="primary" dark>
                <v-btn icon @click="CerrarVentanaAut()">
                    <v-icon large>mdi-close-circle</v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <h3>Autotransporte</h3>
                <v-spacer></v-spacer>
                <v-btn icon @click="save()">
                    <v-icon large>mdi-check-circle</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text>
                <span hidden>{{this.$store.state.autotransporte}}</span>
                <v-select label="Permiso SCT" :items="itemsPermSCT" item-text="numPermisoSCT" v-model="autotransporte.numPermisoSCT"
                    return-object persistent-hint></v-select>
                <v-select label="Configuración Vehicular" :items="itemsVehiculo" item-text="placaVM"
                    v-model="autotransporte.identificacionVehicular.placaVM" return-object persistent-hint></v-select>
                <v-select label="Seguro de Responsabilidad Civil" :items="itemsSegResCivil" item-text="item"
                    v-model="$store.state.autotransporte.aseguraRespCivil" return-object clearable persistent-hint></v-select>
                <v-select label="Seguro de Daños al medio Ambiente" :items="itemsSegAmbiente"
                    item-text="item" v-model="$store.state.autotransporte.aseguraMedAmbiente" return-object clearable persistent-hint>
                </v-select>
                <v-select label="Seguro de Carga" :items="itemsSegCarga" item-text="item" v-model="$store.state.autotransporte.aseguraCarga"
                    persistent-hint clearable return-object></v-select>
                <v-select label="Remolque" :items="itemsRemolques" item-text="placa" v-model="autotransporte.remolqueA" persistent-hint
                    clearable return-object></v-select>
                <v-select label="Remolque 2" :items="itemsRemolques" item-text="placa" v-model="autotransporte.remolqueB"
                    persistent-hint clearable return-object></v-select>
            </v-card-text>
        </v-card>
        <!-- DIALOG DE QUE ESTA HACIENDO ALGO -->
        <div>
            <v-dialog v-model="dialog" hide-overlay persistent width="300">
                <v-card color="primary" dark>
                    <v-card-text>
                        {{ dialogMessage }}
                        <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </div>
        <!-- SNACKBAR PARA NOTIFICAR LOS ERRORES -->
        <v-snackbar :timeout="5000" v-model="snackbar" color="primary darken-2" elevation="30">{{ snackbarMessage }}
            <template v-slot:action="{ attrs }">
                <v-icon dark @click="snackbar = false">
                    mdi-close-circle
                </v-icon>
            </template>
        </v-snackbar>
    </div>
</template>
<script>
    import axios from 'axios'
    export default {
        name: 'Autotransporte',
        components: {
        },

        data: () => ({
            //DIALOG
            dialog:false,
            dialogMessage:'',

            // SNACKBAR
            snackbar:false,
            snackbarMessage:'',

            itemsPermSCT: [],
            permSCT: {},
            itemsVehiculo: [],
            configAuto: {},
            itemsSegResCivil: [],
            segResCivil: {},
            itemsSegCarga: [],
            segCarga: {},
            itemsSegAmbiente: [],
            segAmbiente: {},
            itemsRemolques: [],
            remolque: {},
            remolque2: {},
            permSCT: '',
        }),
        watch: {

        },
        computed: {
           
             datosEmpresa() {
                return this.$store.state.usuario;
            },
            autotransporte(){
                return this.$store.state.autotransporte;
            }
        }, 
        mounted() {
            this.searchPermSCT();
            this.searchConfigAuto();
            this.searchSeguroResCivil();
            this.searchSeguroCarga();
            this.searchSeguroAmbiente();
            this.searchRemolques();
        },

        methods: {
            CerrarVentanaAut() {
                this.$store.state.autotransporte.id= -1
                this.$emit("VentanaAutotransporte");
            },
            async searchPermSCT() {
                try {
                    let response = await axios.get('CartaPorte/GetPermiso/'+ this.datosEmpresa.empresaBase)
                    this.itemsPermSCT = response.data;
                    console.log(this.itemsPermSCT)
                } catch (error) {
                    console.log(error);
                }
            },

            async searchConfigAuto() {
                try {
                    let response = await axios.get('CartaPorte/GetVehiculo/'+ this.datosEmpresa.empresaBase)
                    this.itemsVehiculo = response.data;
                } catch (error) {
                    console.log(error);
                }
            },

            async searchSeguroResCivil() {
                let tipo = 'Responsabilidad Civil'
                try {
                    let response = await axios.get('CartaPorte/GetSeguro/'+ this.datosEmpresa.empresaBase + '/' + tipo)
                    this.itemsSegResCivil = response.data;
                    console.log('permisos', this.itemsSegResCivil)
                    for (let i of this.itemsSegResCivil)
                    {
                        i.item = i.aseguraRespCivil + ' | ' +i.polizaRespCivil 
                    }
                } catch (error) {
                    console.log(error);
                }
            },
            async searchSeguroCarga() {
                let tipo = 'Carga Transportada'
                try {
                    let response = await axios.get('CartaPorte/GetSeguro/' + this.datosEmpresa.empresaBase + '/' + tipo)
                    this.itemsSegCarga = response.data;
                    for (let i of this.itemsSegCarga)
                    {
                        i.item = i.aseguraCarga + ' | ' +i.polizaCarga 
                    }
                } catch (error) {
                    console.log(error);
                }
            },
            async searchSeguroAmbiente() {
                let tipo = 'Daños al medio ambiente'
                try {
                    let response = await axios.get('CartaPorte/GetSeguro/'+ this.datosEmpresa.empresaBase + '/' + tipo)
                    this.itemsSegAmbiente = response.data;
                    console.log(this.itemsSegAmbiente)
                    for (let i of this.itemsSegAmbiente)
                    {
                        i.item = i.aseguraMedAmbiente + ' | ' +i.polizaMedAmbiente 
                    }
                } catch (error) {
                    console.log(error);
                }
            },

            async searchRemolques() {
                try {
                    let response = await axios.get('CartaPorte/GetRemolques/' + this.datosEmpresa.empresaBase)
                    this.itemsRemolques = response.data;
                    console.log(this.itemsRemolques)
                } catch (error) {
                    console.log(error);
                }
            },
            async save() {
                
                console.log('guardar', this.$store.state.autotransporte)
                let Autotransporte = {
                    id :this.$store.state.autotransporte.id,
                    permSCT: Object.assign({}, this.autotransporte.numPermisoSCT),
                    IdentificacionVehicular: Object.assign({}, this.autotransporte.identificacionVehicular.placaVM),
                    aseguraRespCivil: Object.assign({}, this.$store.state.autotransporte.aseguraRespCivil),
                    aseguraMedAmbiente: Object.assign({}, this.$store.state.autotransporte.aseguraMedAmbiente),
                    aseguraCarga: Object.assign({}, this.$store.state.autotransporte.aseguraCarga),
                    remolqueA: Object.assign({}, this.autotransporte.remolqueA),
                    remolqueB: Object.assign({}, this.autotransporte.remolqueB),
                }
                console.log(Autotransporte)
                if (this.autotransporte.numPermisoSCT == "" || !this.autotransporte.numPermisoSCT) {
                    this.snackbarMessage = 'Indique el Permiso SCT'
                    this.snackbar = true
                    return;
                }
                if (this.autotransporte.identificacionVehicular.placaVM == "" || !this.autotransporte.identificacionVehicular.placaVM) {
                    this.snackbarMessage = 'Indique la configuración vehicular'
                    this.snackbar = true
                    return;
                }
                if (this.autotransporte.aseguraRespCivil == "" || !this.autotransporte.aseguraRespCivil) {
                    this.snackbarMessage = 'Indeique un seguro'
                    this.snackbar = true
                    return;
                }
                if(this.$store.state.autotransporte.id > 0)
                {
                    Autotransporte.permSCT = Object.assign({},this.$store.state.autotransporte.permSCT)
                    Autotransporte.permSCT.numPermisoSCT = this.$store.state.autotransporte.numPermisoSCT
                    Autotransporte.IdentificacionVehicular = Object.assign({},this.$store.state.autotransporte.identificacionVehicular)
                    try {
                    let response = await axios.post('CartaPorte/UpAutoTransporte/' + this.datosEmpresa.empresaBase, Autotransporte)
                    console.log(response.data)
                    this.snackbarMessage = 'Actalizado con exito.'
                    this.snackbar = true
                    this.$store.state.autotransporte.id = -1
                    
                    this.CerrarVentanaAut();
                } catch (error) {
                    console.log(error)
                    this.snackbarMessage = 'Error al actualizar, intentelo de nuevo.'
                    this.snackbar = true
                }
                }else{
                     try {
                    let response = await axios.post('CartaPorte/PostAutotransporte/' + this.datosEmpresa.empresaBase, Autotransporte)
                    console.log(response.data)
                    this.snackbarMessage = 'Guardado con exito.'
                    this.snackbar = true
                    this.$store.state.autotransporte.id = -1
                    
                    this.CerrarVentanaAut();
                } catch (error) {
                    console.log(error)
                    this.snackbarMessage = 'Error al guardar, intentelo de nuevo.'
                    this.snackbar = true
                }
                }
               
            }
        }
    }
</script>