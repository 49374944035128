<template>
    <div>
        <v-card>
            <v-toolbar color="primary" dark>
                <v-btn icon @click="CerrarVentanaVehi()">
                    <v-icon large>mdi-close-circle</v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <h3>Vehículo</h3>
                <v-spacer></v-spacer>
                <v-btn icon @click="SaveVehiculo()">
                    <v-icon large>mdi-check-circle</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text>
                <span hidden> {{ vehiculos }}</span>
                <v-select label="Configuración vehicular" return-object v-model="vehiculos.configVehicular"
                    :items="itemsConfigVehicular" item-text="descripcion"></v-select>
                <span hidden>Número de ejes: {{ configVehicular.numeroDeEjes }}</span>
                <br>
                <span hidden>Número de llantas: {{ configVehicular.numeroDeLlantas }}</span>
                <v-text-field label="Placa Vehículo Motor"
                    hint="En este campo se deberá registrar la placa del vehículo en el que se realiza el traslado de bienes y/o mercancías, se deben registrar solo los caracteres alfanuméricos, sin guiones y espacios."
                    maxlength="7" counter v-model="vehiculos.placaVM"></v-text-field>
                <v-text-field label="Año modelo" type="number" maxlength="4" counter
                    hint="En este campo se deberá registrar el año del vehículo que se está utilizando para trasladar los bienes y/o mercancías"
                    v-model="vehiculos.anioModeloVM"></v-text-field>
            </v-card-text>
        </v-card>
        <div>
            <v-dialog v-model="dialog" hide-overlay persistent width="300">
                <v-card color="primary" dark>
                    <v-card-text>
                        {{ dialogMessage }}
                        <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </div>
        <!-- SNACKBAR PARA NOTIFICAR LOS ERRORES -->
        <v-snackbar :timeout="5000" v-model="snackbar" color="primary darken-2" elevation="30">{{ snackbarMessage }}
            <template v-slot:action="{ attrs }">
                <v-icon dark @click="snackbar = false">
                    mdi-close-circle
                </v-icon>
            </template>
        </v-snackbar>
    </div>
</template>
<script>
    import axios from 'axios'
    export default {
        name: 'Vehiculo',
        components: {

        },

        data: () => ({
            //DIALOG
            dialog: false,
            dialogMessage: '',

            // SNACKBAR
            snackbar: false,
            snackbarMessage: '',

            configVehicular: {},
            itemsConfigVehicular: [],
            placaVM: '',
            anioModeloVM: ''
        }),
        watch: {

        },
        computed: {
            datosEmpresa() {
                return this.$store.state.usuario;
            },
            vehiculos() {
                return this.$store.state.vehiculos;
            }
        },
        mounted() {
            this.GetConfigVehicular();
        },

        methods: {
            CerrarVentanaVehi() {
                this.$store.state.vehiculos.id = -1

                this.$emit("VentanaNuevoVehiculo");

            },
            async GetConfigVehicular() {
                try {
                    let response = await axios.get('CatalogosCartaPorte/GetConfigAutotransporte');
                    this.itemsConfigVehicular = response.data
                } catch (error) {
                    console.log(error)
                }
            },
            async SaveVehiculo() {
                let vehiculo = Object.assign({}, this.$store.state.vehiculos)
                if (vehiculo.id > 0) {
                    console.log("editar")
                    try {
                        let response = await axios.post('CartaPorte/UpdateVehiculo/' + this.datosEmpresa.empresaBase, vehiculo);
                        console.log(response)
                        this.snackbarMessage = 'Actualizado con exito.'
                        this.snackbar = true
                        this.$store.state.vehiculos.id = -1
                        this.CerrarVentanaVehi();
                    } catch (err) {
                        console.log(err)
                        this.snackbarMessage = 'Error al actualizar, intentelo de nuevo.'
                        this.snackbar = true
                    }
                }
                else {
                    console.log("guardar")
                    try {
                        let response = await axios.post('CartaPorte/PostVehiculo/' + this.datosEmpresa.empresaBase, vehiculo);
                        console.log(response)
                        this.snackbarMessage = 'Guardado con exito.'
                        this.snackbar = true
                        this.$store.state.vehiculos.id = -1
                        this.CerrarVentanaVehi();
                    } catch (err) {
                        console.log(err)
                        this.snackbarMessage = 'Error al guardar, intentelo de nuevo.'
                        this.snackbar = true
                    }
                }
                console.log(vehiculo)

            },
        }
    }
</script>