<template>
    <div>
        <v-card>
            <v-toolbar>
                <v-btn fab dark small @click="dialogPdf=false" color="primary darken-1">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <v-toolbar-title>Enviar Correo</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn fab dark small color="primary darken-1" @click="PostCorreo()">
                    <v-icon>mdi-email-send</v-icon>
                </v-btn>
            </v-toolbar>
            <v-system-bar color="primary" height="6"></v-system-bar>
            <v-card-text>
                <v-text-field label="Asunto" v-model="datosCorreo.asunto"></v-text-field>
                <v-combobox
                v-model="datosCorreo.listaCorreosCliente"
                :items="datosCorreo.listaCorreos"
                label="Seleccione uno o mas correos"
                item-text="correo"
                multiple></v-combobox>
                <vue-editor v-model="mensaje"></vue-editor>
                <br>
                <v-file-input v-model="files" @change="CargaArchivo()"></v-file-input>
                <a @click="MostrarArchivosAdjuntos" >Archivos adjuntos:{{ itemsDocumentos.length }}</a>
                <template v-if="files.length != 0">
                    <v-progress-linear color="primary accent-4" indeterminate rounded height="6"></v-progress-linear>
                    <span>Cargando archivo......</span>
                </template>
            </v-card-text>       
        </v-card>
        <!-- MUESTRA LOS DOCUMENTOS EXTRA PARA EL CORREO -->
        <v-dialog v-model="dialogDocumentos" width="800px">
            <v-card>
                <v-card-text>
                    <v-data-table :items="itemsDocumentos" :headers="headersDocumentos" hide-default-footer>
                        <template v-slot:[`item.actions`]="{ item }">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                <v-icon color="red" dense class="mr-2" v-bind="attrs" v-on="on" dark @click="DeleteArchivo(item)">mdi-delete</v-icon>
                                </template>
                                <span>Quitar documento</span>
                            </v-tooltip>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-dialog>
        <!-- DIALOG DE QUE ESTA HACIENDO ALGO -->
        <div>
            <v-dialog v-model="dialog" hide-overlay persistent width="300">
                <v-card color="primary" dark>
                    <v-card-text>
                        {{ dialogMessage }}
                        <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </div>

        <!-- SNACKBAR PARA NOTIFICAR LOS ERRORES -->
        <v-snackbar :timeout="10000" v-model="snackbar" color="primary darken-2" elevation="30">{{ snackbarMessage }}
            <template v-slot:action="{ attrs }">
                <v-icon dark @click="snackbar = false">
                    mdi-close-circle
                </v-icon>
            </template>
        </v-snackbar>
    </div>
</template>
<script>
    import axios from 'axios'
    import Vue from "vue";
    import Vue2Editor from "vue2-editor";

    Vue.use(Vue2Editor);

    export default {
        name: 'Correo',
        components: {
            
        },

        data: () => ({
            selectCorreo: [],
            itemsCorreo: [],
            itemsDocumentos: [],
            headersDocumentos:[
                { text: 'Acciones', value: 'actions', sortable: false },
                { text: "Archivo", value: "name", sortable: false },
                { text: "Tamaño", value: "size", sortable: false },
                { text: "", value: "mega", sortable: false },
            ],
            dialogDocumentos: false,
            files: [],
            mensaje: '',

            //NOTIICACIONES
            dialogMessage: '',
            dialog: false,
            snackbarMessage: '',
            snackbar: false,
        }),

        watch: {
            
        },

        computed: {
            datosCorreo(){
                return this.$store.state.correoStore;
            },
        },

        created() {

        },

        methods: {
            async CargaArchivo(){
                try{
                    var archivo = this.files
                    if(archivo.size < 20000000){
                        let docto = await this.convertBase64(archivo);
                        var tamaño = docto.size /1048576
                        var doctoO = {
                            data: docto.data,
                            name: docto.name,
                            size: Math.round(tamaño *1000)/1000,
                            type: docto.type,
                            mega: 'MB',
                            id: 0,
                        }
                        this.itemsDocumentos.push(doctoO)
                        doctoO = {}
                    }
                    let id = 0;
                    for(var d of this.itemsDocumentos){
                        d.id = id;
                        id ++;
                    }
                this.files =[]
                }catch(error){
                    this.files =[]
                    console.log(error)
                }
            },

            async PostCorreo(){
                this.dialog =true;
                this.dialogMessage= 'Enviando correo...'
                //LISTA DE RECEPTORES
                let listaCorreos = []
                for(var correo of this.datosCorreo.listaCorreosCliente){
                    let valida = Object.keys(correo).length
                    if(valida == 4){
                        let nuevo = {
                            correo: correo.correo,
                            nombre: correo.destinatario,
                        }
                        listaCorreos.push(nuevo)
                    }else{
                        let nuevo = {
                            correo: correo,
                            nombre: '',
                        }
                        listaCorreos.push(nuevo)
                    }
                }
                
                //LISTA DE ARCHIVOS
                let listArchivos = []
                for(var a of this.datosCorreo.listaDocumentosDefault){
                    let doc = {
                        nombre: a.name,
                        base64: a.data.split(',')[1],
                    }
                    listArchivos.push(doc);
                }
                for(var a of this.itemsDocumentos){
                    let doc = {
                        nombre: a.name,
                        base64: a.data.split(',')[1],
                    }
                    listArchivos.push(doc);
                }

                let emisor = await this.GetEmisor();
                let correoO = {
                    emisor: {
                        nombre: emisor.nombre,
                        correo: emisor.correo,
                    },
                    receptor: listaCorreos,
                    correo: emisor,
                    asunto: this.datosCorreo.asunto,
                    mensaje: this.mensaje,
                    archivos: listArchivos,
                }

                console.log(correoO)
                try{
                    let respuesta = await axios.post('Correos/EnviaCorreo/', correoO);
                    console.log(respuesta.data)
                    this.dialog =false;
                    this.cierraVentana()
                }catch(error){
                    console.log(error)
                    this.dialog =true;
                }
            },

            convertBase64(file){
                return new Promise((resolve, reject)=>{
                let fileReader = new FileReader();
                fileReader.onload = function(){
                    return resolve({data:fileReader.result, name:file.name, size: file.size, type: file.type});
                }
                fileReader.readAsDataURL(file);
            })
            },
            
            MostrarArchivosAdjuntos(){
                this.dialogDocumentos = true;
            },
            
            DeleteArchivo(item){
                let editedIndex = this.itemsDocumentos.findIndex(x => x.id === item.id)
                this.itemsDocumentos.splice(editedIndex, 1)
            },
            
            async GetEmisor(){
                try{
                    let respuesta = await axios.get('Correos/DatosEmisorAsync/'+ this.$store.state.usuario.idusuariosApp);
                    return respuesta.data
                }catch(error){
                    console.log(error)
                }
            },
        
            cierraVentana(){
                this.$emit('closeCorreo')
            }
        },
    }
</script>