<template>
  <div  class="app">
    <v-toolbar flat  color="transparent">
      <v-toolbar-title class="dark">
        <h2></h2>
      </v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-card class="justify-center pt-3 pb-5" flat color="transparent">
      <div class="text-center mt-4">
        <img src="../assets/logo_cotago facturacion_web_sin_fondo.png" height="150">
      </div>
    </v-card>
    <v-card class="justify-center" flat  color="transparent">
      <v-item-group mandatory>
        <v-container>
          <v-row dense>
            <v-hover v-slot="{ hover }" open-delay="10">
              <v-card :elevation="hover ? 16 : 2" :class="{ 'on-hover': hover }" class="mx-auto justify-center mr-5 rounded-lg mb-4"
              width="190" height="180" link :to="{ name: 'ListaFactura' }">
                <v-card-text class="text-center">
                  <v-icon size="110" color="primary">mdi-file-document-multiple-outline</v-icon>
                  <h2 class="mt-3">Factura</h2>
                </v-card-text>
              </v-card>
            </v-hover>

            <v-hover v-slot="{ hover }" open-delay="10">
              <v-card :elevation="hover ? 16 : 2" :class="{ 'on-hover': hover }" class="mx-auto justify-center  ml-5 mr-5 rounded-lg mb-4"
              width="190" height="180" link :to="{ name: 'ListaArrendamientos' }">
                <v-card-text class="text-center">
                  <v-icon size="110" color="primary">mdi-file-table</v-icon>
                  <h2 class="mt-2">Factura Arrendamiento</h2>
                </v-card-text>
              </v-card>
            </v-hover>

            <v-hover v-slot="{ hover }" open-delay="10">
              <v-card :elevation="hover ? 16 : 2" :class="{ 'on-hover': hover }" class="mx-auto justify-center  ml-5 mr-5 rounded-lg mb-4"
              width="190" height="180" link :to="{ name: 'ListaHonorarios' }">
                <v-card-text class="text-center">
                  <v-icon size="110" color="primary">mdi-file-account-outline</v-icon>
                  <h2 class="mt-2">Factura Honorarios</h2>
                </v-card-text>
              </v-card>
            </v-hover>

            <v-hover v-slot="{ hover }" open-delay="10">
              <v-card :elevation="hover ? 16 : 2" :class="{ 'on-hover': hover }" class="mx-auto justify-center ml-5 rounded-lg mb-4"
              width="190" height="180" link :to="{ name: 'ListaCartaPorte' }">
                <v-card-text class="text-center">
                  <v-icon size="110" color="primary">mdi-file-marker-outline</v-icon>
                  <h2 class="mt-2">Ingreso CartaPorte</h2>
                </v-card-text>
              </v-card>
            </v-hover>
          </v-row>
        </v-container>
      </v-item-group>
    </v-card>

    <v-card class="justify-center" flat  color="transparent">
      <v-item-group mandatory>
        <v-container>
          <v-row dense>
            <v-hover v-slot="{ hover }" open-delay="10">
              <v-card :elevation="hover ? 16 : 2" :class="{ 'on-hover': hover }" class="mx-auto justify-center  mr-5 rounded-lg mb-4"
              width="190" height="180" link :to="{ name: 'ListaTrasladoCartaPorte' }">
                <v-card-text class="text-center">
                  <v-icon size="110" color="primary">mdi-file-send-outline</v-icon>
                  <h2 class="mt-2">Traslado CartaPorte</h2>
                </v-card-text>
              </v-card>
            </v-hover>


            <v-hover v-slot="{ hover }" open-delay="10">
              <v-card :elevation="hover ? 16 : 2" :class="{ 'on-hover': hover }" class="mx-auto justify-center ml-5 mr-5 rounded-lg mb-4"
              width="190" height="180" link :to="{ name: 'ListaNotaCredito' }">
                <v-card-text class="text-center">
                  <v-icon size="110" color="primary">mdi-file-document-edit-outline</v-icon>
                  <h2 class="mt-2">Nota de Credito</h2>
                </v-card-text>
              </v-card>
            </v-hover>

            <v-hover v-slot="{ hover }" open-delay="10">
              <v-card :elevation="hover ? 16 : 2" :class="{ 'on-hover': hover }" class="mx-auto justify-center ml-5 mr-5 rounded-lg mb-4"
              width="190" height="180" link :to="{ name: 'ListaComercioExterior' }">
                <v-card-text class="text-center">
                  <v-icon size="110" color="primary">mdi-file-undo-outline</v-icon>
                  <h2>Comercio Exterior</h2>
                </v-card-text>
              </v-card>
            </v-hover>

            <v-hover v-slot="{ hover }" open-delay="10">
              <v-card :elevation="hover ? 16 : 2" :class="{ 'on-hover': hover }" class="mx-auto justify-center ml-5 mr-5 rounded-lg mb-4"
              width="190" height="180" link :to="{ name: 'ListaAdenda' }">
                <v-card-text class="text-center">
                  <v-icon size="110" color="primary">mdi-book-edit-outline</v-icon>
                  <h2 class="mt-2">Factura de Adenda</h2>
                </v-card-text>
              </v-card>
            </v-hover>

            <v-hover v-slot="{ hover }" open-delay="10">
              <v-card :elevation="hover ? 16 : 2" :class="{ 'on-hover': hover }" class="mx-auto justify-center ml-5 rounded-lg mb-4"
              width="190" height="180" link :to="{ name: 'ListaComplementoPago' }">
                <v-card-text class="text-center">
                  <v-icon size="110" color="primary">mdi-credit-card-plus-outline</v-icon>
                  <h2 class="mt-2">Complemento de Pago</h2>
                </v-card-text>
              </v-card>
            </v-hover>
          </v-row>
        </v-container>
      </v-item-group>
      <v-main>
            <v-container fluid>
              <v-fade-transition mode="out-in">
                <router-view></router-view>
              </v-fade-transition>
            </v-container>
          </v-main>
    </v-card>
    
  </div>
</template>

<script>
  import axios from 'axios'
  export default {
    name: 'menu',
    components: {

    },

    data: () => ({

    }),
    watch: {

    },
    computed: {

    },
    mounted() {

    },

    methods: {
      averquehace() {
        console.log('Boton de acción')
      },
    }
  }
</script>
<style>
  .app {
    background: url('https://img.freepik.com/vector-gratis/fondo-blanco-minimo_1393-354.jpg?w=2000') no-repeat center center fixed !important;
    background-size: cover;
  }
</style>