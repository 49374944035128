<template>
  <div>
    <!--Dialog loading-->
    <v-dialog v-model="loading" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Cargando datos...
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!--Dialogo nuevas autotransporte-->
    <v-dialog v-model="dialogAutotransporte" persistent max-width="700px">
      <autotransporte @VentanaAutotransporte="CierraVentanaAutotransporte()"></autotransporte>
    </v-dialog>
    <v-card>
      <!--titulo-->
      <v-card-title style="font: bold;" class="text-h4 text--primary">
        <!--Boton regresar-->
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" class="mx-2" icon large link :to="{ name: 'CatalogosCartaPorte' }">
              <v-icon size="40" color="primary">mdi-arrow-left-thick</v-icon>
            </v-btn>
          </template>
          <span>Regresar</span>
        </v-tooltip>
        Lista de Autotransporte
        <v-spacer></v-spacer>
        <div class="text-center">
          <!--Boton de Buscar-->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" class="mx-2" fab dark color="blue" @click="listarAutotransporte()">
                <v-icon dark>mdi-update</v-icon>
              </v-btn>
            </template>
            <span>Actualizar</span>
          </v-tooltip>
          <!--Boton nuevo-->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" class="mx-2" fab dark color="green" @click="nuevoAutotransporte()">
                <v-icon dark>mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>Nuevo</span>
          </v-tooltip>
        </div>
      </v-card-title>
      <v-divider></v-divider>
      <!--Tabla-->
      <v-data-table :headers="headers" :items="items" :key="items.idcatalogo_Autotransporte">
        <!-- BOTONES DE ACCION  -->
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon dense class="mr-2" v-bind="attrs" v-on="on" color="blue" @click="editar(item)">mdi-lead-pencil
              </v-icon>
            </template>
            <span>Editar</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon dense class="mr-2" v-bind="attrs" v-on="on" color="red" @click="deleteItem(item)">mdi-delete
              </v-icon>
            </template>
            <span>Eliminar</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
  import axios from 'axios'
  import Autotransporte from '../Comprobantes/CartaPorte/Autotransporte.vue'
  export default {
    components: {
      Autotransporte,
    },
    data() {
      return {
        headers: [
          { text: 'Acciones', value: 'actions' },
          { text: "ID Autotransporte", value: "id" },
          { text: "Permiso SCT", value: "permSCT.permSCT.descripcion" },
          { text: "Num. de Permiso SCT", value: "numPermisoSCT" },
          { text: "Vehiculo - Placa", value: "identificacionVehicular.placaVM" },
          { text: "Seguro de Responsabilidad civil", value: "aseguraRespCivil.seguro" },
          { text: "Seguro de Medio Ambiente", value: "aseguraMedAmbiente.seguro" },
          { text: "Seguro de carga", value: "aseguraCarga.seguro" },
          { text: "Remolque A", value: "remolqueA.placa" },
          { text: "Remolque B", value: "remolqueB.placa" },

        ],
        items: [],

        // loading 
        loading: false,

        //DIALOGO AUTOTRANSPORTE
        dialogAutotransporte: false,
      };


    },
    mounted() {

    },
    computed: {
      datosEmpresa() {
        return this.$store.state.usuario;
      },
      autotransporte(){
        return this.$store.state.autotransporte
      }
    },
    created(){
    this.listarAutotransporte();
  },
    methods: {
      //ABRIR DIALOGO
      nuevoAutotransporte() {
        let autotransporte = {
          id: 0,
          seguros: [],
          remolques:[],
          aseguraRespCivil: {
          },
          aseguraMedAmbiente: {},
          aseguraCarga: {},
          identificacionVehicular: {
            placaVM: ''
          },
          remolqueA: {},  
          remolqueB: {}
        }
        if (this.$store.state.autotransporte.id === -1) {
          this.$store.state.autotransporte = Object.assign({}, autotransporte)
        }
        this.dialogAutotransporte = true;
      },
      //CERRAR DIALOGO
      CierraVentanaAutotransporte() {
        this.dialogAutotransporte = false;
      },
      // listar autotransporte
      async listarAutotransporte() {
        this.loading = true;
        try {
          let response = await axios.get('CartaPorte/GetAutoTransporte/'+ this.datosEmpresa.empresaBase);
          this.items = response.data;
          

          for(let a of this.items){
            for(let s of a.seguros){
                if(s.tipo === 'RC'){
                  s.seguro = s.aseguraRespCivil + ' | ' +s.polizaRespCivil 
                  a.aseguraRespCivil = Object.assign({}, s)
                }
                if(s.tipo === 'MA'){
                  s.seguro = s.aseguraMedAmbiente + ' | ' +s.polizaMedAmbiente 
                  a.aseguraMedAmbiente = Object.assign({}, s)
                }
                if(s.tipo === 'CA'){
                  s.seguro = s.aseguraCarga + ' | ' +s.polizaCarga 
                  a.aseguraCarga = Object.assign({}, s)
                }
                if (a.remolques.length == 2){
                  a.remolqueA =a.remolques[0]
                  a.remolqueB =a.remolques[1]
                }
                if (a.remolques.length == 1){
                  a.remolqueA =a.remolques[0]
                }
              }
            }
                      console.log('lista', this.items)
          this.loading = false;
        } catch (error) {
          console.log(error);
        }
      },
      editar(item) {
        for(let s of item.seguros){
          if(s.tipo === 'RC'){
            s.item = s.aseguraRespCivil + ' | ' +s.polizaRespCivil 
            item.aseguraRespCivil = Object.assign({}, s)

          }
          if(s.tipo === 'MA'){
            s.item = s.aseguraMedAmbiente + ' | ' +s.polizaMedAmbiente 
            item.aseguraMedAmbiente = Object.assign({}, s)
          }
          if(s.tipo === 'CA'){
            s.item = s.aseguraCarga + ' | ' +s.polizaCarga 
            item.aseguraCarga = Object.assign({}, s)
          }
        }
        console.log(item.remolques.length)
        if (item.remolques.length == 2){
          item.remolqueA =item.remolques[0]
          item.remolqueB =item.remolques[1]
        }
        if (item.remolques.length == 1){
          item.remolqueA =item.remolques[0]
        }

          //item.numPermisoSCT = item.permSCT
        //item.permSCT = item.numPermisoSCT
        this.$store.state.autotransporte = Object.assign({}, item);
        // console.log(this.$store.state.autotransporte)
        this.dialogAutotransporte = true;
      },

      async deleteItem(item) {
        let id = item.id
        try {
          let response = await axios.post('CartaPorte/DeleteAutoTransporte/' + this.datosEmpresa.empresaBase+'/' + id);
          this.listarAutotransporte();
          this.loading = false;
        } catch (error) {
          console.log(error);
        }
      }
    },
  };
</script>