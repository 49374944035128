var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-title',{staticClass:"text-h4 text--primary",staticStyle:{"font":"bold"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"icon":"","large":"","link":"","to":{ name: 'Ventas' }}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"40","color":"primary"}},[_vm._v("mdi-arrow-left-thick")])],1)]}}])},[_c('span',[_vm._v("Regresar")])]),_vm._v(" Remisiones "),_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","sm":"3","md":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Fecha Inicial","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.fechaI),callback:function ($$v) {_vm.fechaI=$$v},expression:"fechaI"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.calendario),callback:function ($$v) {_vm.calendario=$$v},expression:"calendario"}},[_c('v-date-picker',{on:{"input":function($event){_vm.calendario = false}},model:{value:(_vm.fechaI),callback:function ($$v) {_vm.fechaI=$$v},expression:"fechaI"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"3","md":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Fecha Final","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.fechaF),callback:function ($$v) {_vm.fechaF=$$v},expression:"fechaF"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.calendario2),callback:function ($$v) {_vm.calendario2=$$v},expression:"calendario2"}},[_c('v-date-picker',{on:{"input":function($event){_vm.calendario2 = false}},model:{value:(_vm.fechaF),callback:function ($$v) {_vm.fechaF=$$v},expression:"fechaF"}})],1)],1),_c('div',{staticClass:"text-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"fab":"","dark":"","color":"blue"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-magnify")])],1)]}}])},[_c('span',[_vm._v("Actualizar")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"fab":"","dark":"","color":"green"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v("Nuevo")])])],1)],1),_c('v-divider'),_c('v-data-table',{attrs:{"headers":_vm.headers},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"dense":"","dark":""}},'v-icon',attrs,false),on),[_vm._v("mdi-lead-pencil")])]}}],null,true)},[_c('span',[_vm._v("Editar")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"dense":"","dark":""}},'v-icon',attrs,false),on),[_vm._v("mdi-minus-thick")])]}}],null,true)},[_c('span',[_vm._v("Eliminar")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"dense":"","dark":""}},'v-icon',attrs,false),on),[_vm._v("mdi-cancel")])]}}],null,true)},[_c('span',[_vm._v("Cancelar")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"dense":"","dark":""}},'v-icon',attrs,false),on),[_vm._v("mdi-list-status")])]}}],null,true)},[_c('span',[_vm._v("Estatus SAT")])])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }