<template>
    <div>
        <v-card>
            <v-toolbar color="primary" dark>
                <v-btn icon @click="cierraVentana()">
                    <v-icon large>mdi-close-circle</v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <h3>Complemento de pago</h3>
                <v-spacer></v-spacer>
                <!-- BOTON DEMO DEL CORREO -->
                <!-- <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" icon @click="openDialogCorreo()">
                            <v-icon large>mdi-email-fast</v-icon>
                        </v-btn>
                    </template>
                    <span>Enviar Correo</span>
                </v-tooltip> -->

                <!-- Boton para ver vista previa -->
                <!-- <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" icon @click="GetComprobante()">
                            <v-icon large>mdi-file-pdf-box</v-icon>
                        </v-btn>
                    </template>
                    <span>Ver vista Previa</span>
                </v-tooltip> -->
                <!-- BOTON PARA TIMBRAR -->
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" icon @click="timbraComprobante()">
                            <v-icon large>mdi-check-circle</v-icon>
                        </v-btn>
                    </template>
                    <span>Timbrar</span>
                </v-tooltip>
            </v-toolbar>
            <v-card-text>
                <span hidden>{{ comprobante }}</span>
                <v-stepper alt-labels v-model="e1" non-linear>
                    <v-stepper-header>
                        <v-stepper-step step="1" :complete="e1 > 1" editable>
                            Datos Generales
                            <small>Obligatorio</small>
                        </v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step step="2" :complete="e1 > 2" editable>
                            Documentos Relacionados
                            <small>Obligatorio</small>
                        </v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step step="3" :complete="e1 > 3" editable>
                            CFDI'S relacionados
                            <small>Opcional</small>
                        </v-stepper-step>
                    </v-stepper-header>

                    <v-stepper-items>
                        <!-- DATOS GENERALES -->
                        <v-stepper-content step="1">
                            <v-card class="auto">
                                <v-row>
                                    <!-- SERIE -->
                                    <v-col cols="12" md="3" sm="6">
                                        <v-combobox label="Serie" :items="itemsSerie" v-model="comprobante.serie"
                                            ref="_serie"></v-combobox>
                                    </v-col>
                                    <!-- FOLIO -->
                                    <v-col cols="12" md="3" sm="6">
                                        <v-text-field label="Folio" type="number" v-model="comprobante.folio"
                                            ref="_folio"></v-text-field>
                                    </v-col>
                                    <!-- FECHA DE ELABORACION -->
                                    <v-col cols="12" md="3" sm="6">
                                        <v-datetime-picker label="Fecha del comprobante" v-model="comprobante.fecha">
                                            <template slot="dateIcon">
                                                <v-icon>mdi-calendar</v-icon>
                                            </template>
                                            <template slot="timeIcon">
                                                <v-icon>mdi-clock-time-six</v-icon>
                                            </template>
                                            <template slot="actions" slot-scope="{ parent }">
                                                <v-btn color="primary" @click="parent.okHandler">Ok</v-btn>
                                            </template>
                                        </v-datetime-picker>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <!-- NOMBRE DEL CLIENTE -->
                                    <v-col cols="12">
                                        <v-autocomplete label="Cliente / Receptor" v-model="comprobante.receptor"
                                            :items="$store.state.listReceptorStore" :loading="loadingReceptor"
                                            :search-input.sync="searchReceptor" item-text="receptor" return-object @change="indicaUsoCfdi()">
                                        </v-autocomplete>
                                    </v-col>
                                    <!-- USO DEL CFDI -->
                                    <v-col cols="12" md="6" sm="12">
                                        <v-select :items="itemsUsoCfdi" label="Uso de CFDI"
                                            v-model="comprobante.receptor.usoCFDI" item-text="usoCfdi" return-object readonly>
                                        </v-select>
                                    </v-col>
                                    <!-- MONEDA -->
                                    <v-col cols="12" md="6" sm="12">
                                        <v-select label="Moneda" :items="itemsMonedas" v-model="comprobante.moneda" item-text="moneda" return-object readonly></v-select>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-stepper-content>

                        <!-- DOCUMENTOS PARA PAGAR -->
                        <v-stepper-content step="2">
                            <documentos></documentos>
                        </v-stepper-content>

                        <!-- RELACIONA CFDI -->
                        <v-stepper-content step="3">
                            <relacionados></relacionados>
                        </v-stepper-content>
                    </v-stepper-items>
                </v-stepper>
            </v-card-text>
        </v-card>

        <!-- DIALOG DE QUE ESTA HACIENDO ALGO -->
        <div>
            <v-dialog v-model="dialog" hide-overlay persistent width="300">
                <v-card color="primary" dark>
                    <v-card-text>
                        {{ dialogMessage }}
                        <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </div>

        <!-- SNACKBAR PARA NOTIFICAR LOS ERRORES -->
        <v-snackbar :timeout="10000" v-model="snackbar" color="primary darken-2" elevation="30">{{ snackbarMessage }}
            <template v-slot:action="{ attrs }">
                <v-icon dark @click="snackbar = false">
                    mdi-close-circle
                </v-icon>
            </template>
        </v-snackbar>
        <!-- DIALOG PARA MOSTRAR EL PDF -->
        <v-dialog v-model="dialogPdf" persistent>
            <v-card>
                <v-toolbar>
                    <v-btn fab dark small @click="dialogPdf=false, cierraVentana()" color="primary darken-1">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-toolbar-title>Vista Previa</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn fab dark small color="primary darken-1" class="mx-2" @click="$refs.pdf.print()">
                        <v-icon>mdi-printer</v-icon>
                    </v-btn>
                    <v-btn fab dark small color="primary darken-1" class="mx-2" @click="descargaComprobante()">
                        <v-icon>mdi-download</v-icon>
                    </v-btn>
                    <v-btn fab dark small color="primary darken-1" @click="openDialogCorreo">
                        <v-icon>mdi-email-send</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-system-bar color="primary" height="6"></v-system-bar>
                <v-card-text>
                    <template>
                        <div class="text-center">
                            <v-icon color="primary" @click="page--">mdi-minus</v-icon>
                            {{page}} /{{numPages}}
                            <v-icon color="primary" @click="page++">mdi-plus</v-icon>
                            <div style="width: 100%">
                                <div v-if="loadedRatio > 0 && loadedRatio < 1"
                                    style="background-color: green; color: white; text-align: center"
                                    :style="{ width: loadedRatio * 100 + '%' }">{{ Math.floor(loadedRatio * 100) }}%
                                </div>
                                <pdf ref="pdf" style="border: 1px solid red" :src="srcPdf" :page="page" :rotate="rotate"
                                    @progress="loadedRatio = $event" @num-pages="numPages = $event"
                                    @link-clicked="page = $event"></pdf>
                            </div>
                        </div>
                    </template>
                </v-card-text>
                <v-card-actions>
                    <v-btn
                      color="deep-purple lighten-2"
                      text
                    >
                      Reserve
                    </v-btn>
                </v-card-actions>
            </v-card>
            <template>
            </template>
        </v-dialog>
        <!-- DIALOG CORREO -->
        <v-dialog v-model="dialogCorreo">
            <correo @closeCorreo="cierraVentanaCorreo"></correo>
        </v-dialog>
        <span hidden>{{datosEmpresa}}</span>
    </div>
</template>
<script>
    import axios from 'axios'
    import moment from 'moment';
    import { format, parseISO } from 'date-fns';
    import print from 'print-js';
    import pdf from 'vue-pdf';
    import { VueEditor } from "vue2-editor";
    import Vue from 'vue'
    import VueCookies from 'vue-cookies'
    import DatetimePicker from 'vuetify-datetime-picker'

    import Relacionados from '../ComplementoPago/ComplementoPagoRelacionados.vue'
    import Documentos from '../ComplementoPago/ComplementoPagoDocumentos.vue'
    import Correo from '../../Correo/Correo.vue'


    Vue.use(DatetimePicker)
    Vue.use(VueCookies)

    export default {
        name: 'ComplementoPago',
        components: {
            print,
            VueEditor,
            pdf: pdf,
            Relacionados,
            Documentos,
            Correo
        },

        data: () => ({
            e1: 1,
            //DIALOG
            dialog: false,
            dialogMessage: '',

            // SNACKBAR
            snackbar: false,
            snackbarMessage: '',

            //DATOS GENERALES
            itemsSerie: [],
            dateComprobante: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
            menuDateComprobante: false,
            timeComprobante: format(parseISO(new Date().toISOString()), 'HH:mm'),
            menuTimeComprobante: false,

            //DATOS DEL RECEPTOR
            receptor: {},
            searchReceptor: null,
            loadingReceptor: false,
            limitReceptor: 60,
            dialogNuevoReceptor: false,

            //USO DEL CFDI
            itemsUsoCfdi: [],

            //DATOS DEL PAGO
            itemsMetodoPago: [],
            itemsFormaPago: [],
            enabledFormaPago: false,

            //MONEDA
            itemsMonedas: [],
            enabledTipoCambio: false,

            //MENSAJES PARA EL USUARIO
            dialogMessage: '',
            dialog: false,
            snackbar: false,
            snackbarMessage: '',

            //PARA PDF Y XML
            dialogPdf: false,
            srcPdf: '',
            loadedRatio: 0,
            page: 1,
            numPages: 0,
            rotate: 0,
            nombreArchivo: '',
            scrXml: '',

            //DATOS DE LA EMPRESA
            empresa:{},

            //DATOS DEL CORREO
            dialogCorreo: false,

            //LISTA DE CONTACTOS
            itesmContactos:[],
            nombreCorreo:''
        }),

        watch: {
            async searchReceptor(val) {
                if (this.itemsreceptor.length > 0) return
                if (this.loadingReceptor) return
                this.loadingReceptor = true
                try {
                    let response = await axios.get('Comprobantes/GetReceptor/' + this.datosEmpresa.empresaBase);
                    this.itesmContactos = response.data.listContactos;
                    this.$store.state.listReceptorStore = response.data.resultado;
                } catch (error) {
                    console.log(error)
                }
                this.loadingReceptor = false
            },
        },

        computed: {
            datosEmpresa() {
                return this.$store.state.usuario;
            },
            computedDateComprobante() {
                return this.dateComprobante ? moment(this.dateComprobante).format('DD/MM/yyyy') : ''
            },
            itemsreceptor() {
                return this.$store.state.listReceptorStore.map(entry => {
                    const Description = entry.nombre.length > this.limitReceptor
                        ? entry.nombre.slice(0, this.limitReceptor) + '...'
                        : entry.nombre

                    return Object.assign({}, entry, { Description })
                })
            },
            comprobante() {
                this.dateComprobante = this.$store.state.complementoPagoStore.fecha
                this.timeComprobante = this.$store.state.complementoPagoStore.hora
                return this.$store.state.complementoPagoStore
            },
        },

        created() {
            this.GetEmpresa();
            this.GetUsoCdfi();
            this.GetMetodoPago();
            this.GetFormaPago();
            this.GetMoneda();
            this.GetSeries();
        },

        methods: {
            //FORMATO DECIMAL
            formatoDosDecimales(value) {
                let val = (value / 1).toFixed(2)
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            },
            async GetEmpresa() {
                try {
                    let response = await axios.get('Empresa/GetEmpresa/' + this.datosEmpresa.empresaBase)
                    this.empresa = response.data
                } catch (err) {
                    console.log(err)
                }
            },
            async GetUsoCdfi() {
                try {
                    let response = await axios.get('CatalogoComprobantes/GetUsocfdi')
                    this.itemsUsoCfdi = response.data
                } catch (err) {
                    console.log(err)
                }
            },
            async GetMetodoPago() {
                try {
                    let response = await axios.get('CatalogoComprobantes/GetMetodoPago')
                    this.itemsMetodoPago = response.data
                } catch (err) {
                    console.log(err)
                }
            },
            async GetFormaPago() {
                try {
                    let response = await axios.get('CatalogoComprobantes/GetFormaPago')
                    this.itemsFormaPago = response.data
                } catch (err) {
                    console.log(err)
                }
            },
            async GetMoneda() {
                try {
                    let response = await axios.get('CatalogoComprobantes/GetMoneda')
                    this.itemsMonedas = response.data
                } catch (err) {
                    console.log(err)
                }
            },
            async GetSeries() {
                try {
                    let response = await axios.get('ComplementoDePago/GetSerie/' + this.datosEmpresa.empresaBase + '/' + this.datosEmpresa.rfc)
                    this.itemsSerie = response.data;
                } catch (err) {
                    console.log(err)
                }
            },

            indicaUsoCfdi(){
                this.comprobante.receptor.usoCFDI = Object.assign({}, { "clave": "CP01", "descripcion": "Pagos", "fisica": "Sí", "moral": "Sí", "usoCfdi": "CP01 | Pagos" })
            },
            //TIMBRADO
            async timbraComprobante() {
                this.snackbar = false;
                this.dialog = true;
                this.dialogMessage = 'Timbrando espere...';
                let comprobante = Object.assign({}, this.$store.state.complementoPagoStore)
                comprobante.fecha = moment(comprobante.fecha).format('yyyy-MM-DD HH:mm');
                try {
                    let response = await axios.post('ComplementoDePago/TimbraComprobante/' + this.datosEmpresa.empresaBase, comprobante)
                    console.log(response.data)
                    
                    let obtienePdf = await this.getPdf(response.data, response.data.resultado.xml_base_64);
                    this.nombreCorreo = response.data.serie + ' ' + response.data.folio
                    this.dialog = false;
                    //AGREGAMOS EL COMPROBANTE A LA LISTA E INICIALIZAMOS EL MISMO
                    this.$store.state.listComplementoPagoStore.push(response.data)
                    this.$store.state.complementoPagoStore.id = -1
                } catch (err) {
                    console.log(err.response.data)
                    this.snackbarMessage = err.response.data;
                    this.snackbar = true;
                    this.dialog = false;
                }
                this.dialog = false;
            },

            async GetComprobante() {
                this.nombreArchivo = 'VistaPrevia' + '_' + this.$store.state.complementoPagoStore.receptor.rfc

                var comprobante = this.$store.state.complementoPagoStore
                this.dialog = true;
                this.dialogMessage = 'Cargando vista...'
                console.log(comprobante)

                try {
                    let response = await axios.post('https://api-framework.contago.com.mx/api/pdfs/PdfVisualizar/', comprobante);
                    this.dialog = false;
                    this.dialogPdf = true;
                    this.srcPdf = 'data:application/pdf;base64,' + response.data.pdf_
                    this.scrXml = response.data.xml_
                    this.page = 1;
                } catch (error) {
                    console.log(error);
                    this.dialog = false;
                }
            },

            cierraVentana() {
                this.$emit('closeFactura')
            },
            cierraVentanaCorreo(){
                this.dialogCorreo =false
            },

            //PARA DESCARGAR XML Y PDF
            async getPdf(comprobante, xmlBase64){
                try {
                    this.dialogMessage = 'Generando PDF...';
                    this.nombreArchivo = comprobante.receptor.rfc + '_' + comprobante.serie + '_' + comprobante.folio + '_' + comprobante.resultado.folioFiscal
                    let response = await axios.post('http://localhost:53531/api/pdfs/PdfFactura/', comprobante);
                    console.log(response.data)
                    this.dialog = false;
                    this.srcPdf = 'data:application/pdf;base64,' + response.data.pdf_
                    this.scrXml = xmlBase64
                    this.page = 1;
                    this.dialogPdf = true;
                } catch (error) {
                    console.log(error);
                    this.dialog = false;
                }
            },

            descargaComprobante() {
                var pdf = this.srcPdf.split(',')
                this.download(pdf[1], this.nombreArchivo, 'pdf');
                this.download(this.scrXml, this.nombreArchivo, 'xml');
            },

            download(archivo, nombre, tipo) {
                var arrBuffer = this.base64ToArrayBuffer(archivo);
                var newBlob = new Blob([arrBuffer]);

                var data = window.URL.createObjectURL(newBlob);
                var link = document.createElement('a');
                document.body.appendChild(link);
                link.href = data;
                link.download = nombre + '.' + tipo;
                link.click();
                window.URL.revokeObjectURL(data);
                link.remove();
            },

            base64ToArrayBuffer(base64) {
                var binary_string = window.atob(base64);
                var len = binary_string.length;
                var bytes = new Uint8Array(len);
                for (var i = 0; i < len; i++) {
                    bytes[i] = binary_string.charCodeAt(i);
                }
                return bytes.buffer;
            },
        
            openDialogCorreo(){
                this.$store.state.correoStore.listaCorreos = this.itesmContactos;
                this.$store.state.correoStore.listaCorreosCliente = this.comprobante.receptor.contactos;
                this.$store.state.correoStore.asunto = 'Se envía complemento de pago ' + this.nombreCorreo;
                this.$store.state.correoStore.listaDocumentosDefault = [];
                if(this.srcPdf != ''){
                    var _pdf = {
                        data: this.srcPdf,
                        name: this.nombreArchivo + '.pdf',
                        size: 0,
                        type: '',
                        mega: 'MB',
                        id: 0,
                    }
                    this.$store.state.correoStore.listaDocumentosDefault.push(_pdf)
                }
                if(this.scrXml != ''){
                    var _xml = {
                        data: '0,'+this.scrXml,
                        name: this.nombreArchivo + '.xml',
                        size: 0,
                        type: '',
                        mega: 'MB',
                        id: 0,
                    }
                    this.$store.state.correoStore.listaDocumentosDefault.push(_xml)
                }

                this.dialogCorreo = true
            },
        
            
        },
    }
</script>