<template>
  <div class="app">
    <v-toolbar flat  color="transparent">
      <v-toolbar-title class="dark">
        <h2></h2>
      </v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-card class="justify-center pt-3 pb-5" flat color="transparent">
      <div class="text-center mt-4">
        <img src="../assets/logo_cotago facturacion_web_sin_fondo.png" height="150">
      </div>
    </v-card>
    <v-card class="justify-center" flat  color="transparent">
      <v-item-group mandatory>
        <v-container>
          <v-row dense>
            <v-hover v-slot="{ hover }" open-delay="10">
              <v-card :elevation="hover ? 16 : 2" :class="{ 'on-hover': hover }"
                class="mx-auto justify-center mr-5 rounded-lg mb-4" width="200" height="200" link
                :to="{ name: 'Comprobantes' }" @click="$store.state.nombreComponente = 'Comprobantes'">
                <v-card-text class="text-center">
                  <v-icon size="120" color="primary">mdi-file-document-multiple-outline</v-icon>
                  <h2 class="mt-8">Comprobantes</h2>
                </v-card-text>
              </v-card>
            </v-hover>
            <v-hover v-slot="{ hover }" open-delay="10">
              <v-card :elevation="hover ? 16 : 2" :class="{ 'on-hover': hover }"
                class="mx-auto justify-center  ml-5 mr-5 rounded-lg mb-4" width="200" height="200" link
                :to="{ name: 'Catalogos' }" @click="$store.state.nombreComponente = 'Cátalogos'">
                <v-card-text class="text-center">
                  <v-icon size="120" color="primary">mdi-archive</v-icon>
                  <h2 class="mt-8">Catálogos</h2>
                </v-card-text>
              </v-card>
            </v-hover>
            <v-hover v-slot="{ hover }" open-delay="10">
              <v-card :elevation="hover ? 16 : 2" :class="{ 'on-hover': hover }"
                class="mx-auto justify-center ml-5 mr-5 rounded-lg mb-4" width="200" height="200" link
                :to="{ name: 'Ventas' }" @click="$store.state.nombreComponente = 'Ventas'">
                <v-card-text class="text-center">
                  <v-icon size="120" color="primary">mdi-point-of-sale</v-icon>
                  <h2 class="mt-8">Ventas</h2>
                </v-card-text>
              </v-card>
            </v-hover>
            <v-hover v-slot="{ hover }" open-delay="10">
              <v-card :elevation="hover ? 16 : 2" :class="{ 'on-hover': hover }"
                class="mx-auto justify-center  ml-5 rounded-lg mb-4" width="200" height="200" link
                :to="{ name: 'Configuracion' }" @click="$store.state.nombreComponente = 'Configuración'">
                <v-card-text class="text-center">
                  <v-icon size="120" color="primary">mdi-cog-outline</v-icon>
                  <h2 class="mt-8">Configuración</h2>
                </v-card-text>
              </v-card>
            </v-hover>
          </v-row>
        </v-container>
      </v-item-group>
    </v-card>
    <v-main>
      <v-container fluid>
        <v-fade-transition mode="out-in">
          <router-view></router-view>
        </v-fade-transition>
      </v-container>
    </v-main>
  </div>
</template>

<script>
  import axios from 'axios'
  export default {
    name: 'menu',
    components: {

    },

    data: () => ({

    }),
    watch: {

    },
    computed: {

    },
    mounted() {

    },

    methods: {
      averquehace() {
        console.log('Boton de acción')
      },
    }
  }
</script>
<style>
  .app {
    background: url('https://img.freepik.com/vector-gratis/fondo-blanco-minimo_1393-354.jpg?w=2000') no-repeat center center fixed !important;
    background-size: cover;
    height: 100%;
  }
</style>