<template>
  <hello-world />
</template>

<script>
    import HelloWorld from '../views/menu.vue'
    //import HelloWorld from '../components/Configuracion/Configuración.vue'
  export default {
    name: 'Home',

    components: {
      HelloWorld,
    },
  }
</script>
