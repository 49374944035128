<template>
<div>
  <!--Dialog loading-->
  <v-dialog v-model="loading" hide-overlay persistent width="300">
    <v-card color="primary" dark>
      <v-card-text>
        Cargando datos...
        <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>
  <v-card>
    <v-card-title style="font: bold;" class="text-h4 text--primary">
    <!--Boton regresar-->
    <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on"  class="mx-2" icon large link :to="{ name: 'Catalogos' }">
                <v-icon size="40" color="primary">mdi-arrow-left-thick</v-icon>
                </v-btn>
            </template>
            <span>Regresar</span>
        </v-tooltip>
      Lista de Receptores
      <v-spacer></v-spacer>
      <div class="text-center">
        <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" class="mx-2" fab  dark color="blue" @click="listarReceptores()"> 
                <v-icon dark>mdi-update</v-icon>
                </v-btn>
            </template>
            <span>Actualizar</span>
        </v-tooltip>
        <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" class="mx-2" fab  dark color="green" @click="nuevoReceptor()">
                <v-icon dark>mdi-plus</v-icon>
                </v-btn>
            </template>
            <span>Nuevo</span>
        </v-tooltip>
        </div>
    </v-card-title>
    <v-divider></v-divider>
    <v-data-table :headers="headers" :items="items">
      <!-- BOTONES DE ACCION  -->
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                    <v-icon dense class="mr-2" v-bind="attrs" v-on="on" color="blue" @click="editar(item)">mdi-lead-pencil</v-icon>
            </template>
            <span>Editar</span>
        </v-tooltip>
        <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-icon dense class="mr-2" v-bind="attrs" v-on="on" color="red" @click="deleteItem(item)">mdi-delete</v-icon>
            </template>
            <span>Eliminar</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </v-card>
  <v-dialog persistent v-model="dialogReceptorNuevo">
    <Receptor @VentanaNuevoReceptor="closeDialogReceptor()"></Receptor>
  </v-dialog>
</div>
</template>
<script>
import axios from 'axios' 
import Receptor from '../Receptor/Receptor'

export default {
components:{
  Receptor,
},
data() {
    return {
      headers: [
            { text: 'Acciones', value: 'actions'},
            { text: "Nombre", value: "nombre" },
            { text: "RFC", value: "rfc" },
            { text: "Uso CFDI", value: "usoCFDI.usoCfdi" },
      ],
      loading: false,
      dialogReceptorNuevo: false,
    };
  },
  mounted() {
    
  },
  computed: {
    items(){
      return this.$store.state.receptorStore;
    },
    items(){
      return this.$store.state.listReceptorStore;
    },
    datosEmpresa(){
      return this.$store.state.usuario;
    },
  },
  created(){
    this.listarReceptores();
  },
  methods: {
    async listarReceptores() {
      this.loading = true;
      try {
        let response = await axios.get('Comprobantes/GetReceptor/' + this.datosEmpresa.empresaBase);
        this.$store.state.listReceptorStore = response.data.resultado;
        console.log(response)
        this.loading = false;
        } catch (error) {
          console.log(error);
      }
    },

    nuevoReceptor(){
      let receptor = {
        id: 0,
        rfc: '',
        nombre: '',
        domicilioFiscalReceptor: '',
        residenciaFiscal: '',
        numRegIdTrib: '',
        regimenFiscal: {
          fisica: 'NO',
          moral: 'NO'
        },
        usoCFDI: {},
        contactos:[]
      }
      if(this.$store.state.receptorStore.id === -1){
        this.$store.state.receptorStore = Object.assign({}, receptor)
      }
      this.dialogReceptorNuevo = true
    },
  
    closeDialogReceptor(){
      this.dialogReceptorNuevo = false;
    },

    editar(item) {
        let indice = this.items.indexOf(item)
        const receptor= Object.assign({}, item)
        this.$store.state.receptorStore = Object.assign({}, receptor);
        console.log(this.$store.state.receptorStore)
        this.dialogReceptorNuevo = true;
      },
    async deleteItem(item){
      let id = item.id
       try {
        let response = await axios.post('Comprobantes/DeleteReceptor/'  + this.datosEmpresa.empresaBase + '/' + id);
        this.listarReceptores();
        this.loading = false;
        } catch (error) {
          console.log(error);
      }
    }
  },
};
</script>