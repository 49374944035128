<template>
    <div>
        <v-card>
            <v-toolbar color="primary" dark>
                <v-btn icon>
                    <v-icon large>mdi-close-circle</v-icon>
                  </v-btn>
                  <v-spacer></v-spacer>
                  <h3>Factura</h3>
                  <v-spacer></v-spacer>
                  <v-btn icon @click="timbraComprobante()">
                    <v-icon large>mdi-check-circle</v-icon>
                  </v-btn>
            </v-toolbar>
            <v-card-text>
                <v-stepper alt-labels v-model="e1" non-linear>
                    <v-stepper-header>
                        <v-stepper-step step="1" :complete="e1 > 1" editable>
                            Datos Generales
                            <small>Obligatorio</small>
                        </v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step step="2" :complete="e1 > 2" editable>
                            Conceptos
                            <small>Obligatorio</small>
                        </v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step step="3" :complete="e1 > 3" editable>
                            CFDI'S relacionados
                            <small>Opcional</small>
                        </v-stepper-step>
                    </v-stepper-header>
        
                    <v-stepper-items>
                        <!-- DATOS GENERALES -->
                        <v-stepper-content step="1">
                            <v-card class="auto">
                                <v-row>
                                    <!-- SERIE -->
                                    <v-col cols="12" md="3" sm="6">
                                        <v-combobox label="Serie" :items="itemsSerie" v-model="selectSerie" ref="_serie"></v-combobox>
                                    </v-col>
                                    <!-- FOLIO -->
                                    <v-col cols="12" md="3" sm="6">
                                        <v-text-field label="Folio" type="number" v-model="selectFolio" ref="_folio"></v-text-field>
                                    </v-col>
                                    <!-- FECHA DE ELABORACION -->
                                    <v-col cols="12" md="3" sm="6">
                                        <v-menu v-model="menuDateComprobante" :close-on-content-click="false" max-width="290">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field :value="computedDateComprobante" label="Fecha" readonly v-bind="attrs" v-on="on" locale="es"></v-text-field>
                                            </template>
                                                <v-date-picker locale="es" v-model="dateComprobante" @change="menuDateComprobante = false"></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <!-- HORA DEL COMPROBANTE -->
                                    <v-col cols="12" md="3" sm="6">
                                        <v-menu ref="menu" v-model="menuTimeComprobante" :close-on-content-click="false" :nudge-right="40" :return-value.sync="timeComprobante" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field v-model="timeComprobante" label="Hora" prepend-icon="mdi-clock-time-four-outline" readonly v-bind="attrs" v-on="on" ></v-text-field>
                                            </template>
                                            <v-time-picker v-if="menuTimeComprobante" v-model="timeComprobante" full-width @click:minute="$refs.menu.save(timeComprobante)"></v-time-picker>
                                        </v-menu>
                                    </v-col>
                                </v-row>
        
                                <v-row>
                                    <!-- NOMBRE DEL CLIENTE -->
                                    <v-col cols="12">
                                        <v-autocomplete label="Cliente / Receptor" v-model="selectReceptor" :items="$store.state.listReceptorStore" :loading="loadingReceptor" :search-input.sync="searchReceptor" item-text="receptor" return-object append-outer-icon="mdi-account-plus" @click:append-outer="nuevoReceptor()"></v-autocomplete>
                                    </v-col>
                                    <!-- USO DEL CFDI -->
                                    <v-col cols="12">
                                        <v-select :items="itemsUsoCfdi" label="Uso de CFDI" :value="selectUsoCFDI" item-text="usoCfdi" return-object></v-select>
                                    </v-col>
                                </v-row>
        
                                <v-row>
                                    <!-- METODO DE PAGO -->
                                    <v-col cols="12" md="4" sm="12">
                                        <v-select label="Metodo de pago" :items="itemsMetodoPago" v-model="selectMetodoPago" item-text="metodoPago" return-object @change="ValidaMetodoPago()"></v-select>
                                    </v-col>
                                    <!-- FORMA DE PAGO -->
                                    <v-col cols="12" md="4" sm="12">
                                        <v-select label="Forma de pago" :items="itemsFormaPago" v-model="selectFormaPago" item-text="formaPago" return-object :readonly="enabledFormaPago"></v-select>
                                    </v-col>
                                    <!-- CONDICIONES DE PAGO -->
                                    <v-col cols="12" md="4" sm="12">
                                        <v-text-field label="Condiciones de pago" v-model="selectCondicionPago"></v-text-field>
                                    </v-col>
                                </v-row>
        
                                <v-row>
                                    <!-- MONEDA -->
                                    <v-col cols="12" md="3" sm="12">
                                        <v-select label="Moneda" :items="itemsMonedas" v-model="selectMoneda" @change="ValidaMoneda()" item-text="moneda" return-object></v-select>
                                    </v-col>
                                    <!-- TIPO DE CAMBIO -->
                                    <v-col cols="12" md="3" sm="12">
                                        <v-text-field label="Tipo de cambio" v-model="tipoDeCambio" ref="_tipoDeCambio" type="number" :readonly="enabledTipoCambio"></v-text-field>
                                    </v-col>
                                    <!-- OC -->
                                    <v-col cols="12" md="6" sm="12">
                                        <v-text-field label="Orden de compra" v-model="selectOc"></v-text-field>
                                    </v-col>
                                    <!-- NOTAS -->
                                    <v-col cols="12">
                                        <v-text-field label="Notas" v-model="selectNotas"></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-stepper-content>
        
                        <!-- CONCEPTOS -->
                        <v-stepper-content step="2">
                            <comprobante-conceptos></comprobante-conceptos>
                        </v-stepper-content>
        
                        <!-- RELACIONA CFDI -->
                        <v-stepper-content step="3">
                            <v-card color="grey lighten-1" height="200px">
                                
                            </v-card>
                        </v-stepper-content>
                    </v-stepper-items>
                </v-stepper>
            </v-card-text>
            <v-card-actions>
                <v-row>
                    <v-col cols="12" class="text-right">
                        <h1><strong>SubTotal: </strong>{{ formatoDosDecimales($store.state.facturaStore.subTotal) }}</h1>
                    </v-col>
                    <v-col cols="12" class="text-right">
                        <h1><strong>Total: </strong>{{ formatoDosDecimales($store.state.facturaStore.total) }}</h1>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
        <!-- DIALOGS PARA NUEVOS ELEMENTOS -->
        <v-dialog v-model="dialogNuevoReceptor">
            <receptor></receptor>
        </v-dialog>

        <!-- DIALOG DE QUE ESTA HACIENDO ALGO -->
        <div>
            <v-dialog v-model="dialog" hide-overlay persistent width="300">
              <v-card color="primary" dark>
                <v-card-text>
                  {{ dialogMessage }}
                  <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                </v-card-text>
              </v-card>
            </v-dialog>
        </div>

        <!-- SNACKBAR PARA NOTIFICAR LOS ERRORES -->
        <v-snackbar :timeout="10000" v-model="snackbar" color="primary darken-2" elevation="30">{{ snackbarMessage }}
            <template v-slot:action="{ attrs }">
                <v-icon dark @click="snackbar = false">
                  mdi-close-circle
                </v-icon>
              </template>
        </v-snackbar>
    </div>
</template>
<script>
    import axios from 'axios'
    import moment from 'moment';
    import { format, parseISO } from 'date-fns';
    import print from 'print-js';
    import pdf from 'vue-pdf';
    import { VueEditor } from "vue2-editor";
    import Vue from 'vue'
    import VueCookies from 'vue-cookies'
    import { mapMutations } from 'vuex'
    import ComprobanteConceptos from '../Comprobantes/ComprobanteConceptos'

    import Receptor from '../Receptor/Receptor.vue'

    Vue.use(VueCookies)

    export default {
        name: 'ComprobanteTrasladoCp',
        components:{
            print,
            VueEditor,
            pdf:pdf,
            Receptor,
            ComprobanteConceptos,
        },

        data: () => ({
            e1: 1,

            //DATOS GENERALES
            itemsSerie: [],
            selectSerie: '',
            selectFolio: 0,
            dateComprobante: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
            menuDateComprobante: false,
            timeComprobante: format(parseISO(new Date().toISOString()), 'HH:mm'),
            menuTimeComprobante: false,

            //DATOS DEL RECEPTOR
            selectReceptor: {},
            dialogNuevoReceptor: false,
            searchReceptor: null,
            loadingReceptor: false,
            limitReceptor : 60,

            //USO DEL CFDI
            itemsUsoCfdi: [],
            // selectUsoCFDI: {},
        
            //DATOS DEL PAGO
            itemsMetodoPago: [],
            selectMetodoPago: {},
            itemsFormaPago: [],
            enabledFormaPago: false,
            selectFormaPago: {},
            selectCondicionPago: '',

            //MONEDA
            itemsMonedas: [],
            selectMoneda: { c_Moneda: 'MXN', descripcion:'Peso Mexicano', moneda:'MXN | Peso Mexicano' },
            tipoDeCambio: 1.00,
            enabledTipoCambio: false,
            selectOc: '',
            selectNotas: '', 

            //MENSAJES PARA EL USUARIO
            dialogMessage: '',
            dialog: false,
            snackbar:false, 
            snackbarMessage:'',
        }),

        watch:{
            async searchReceptor(val){
                if (this.itemsreceptor.length > 0) return
                if (this.loadingReceptor) return
                this.loadingReceptor = true
                try{
                    let response = await axios.get('Clientes/GetClientes/'+ this.datosEmpresa.empresaBase)
                    this.$store.state.listReceptorStore = response.data;
                }catch(error){
                    console.log(error)
                }
                this.loadingReceptor = false
            }, 

            async searchConcepto(val){
                if (this.itemsconcepto.length > 0) return
                if (this.loadingConcepto) return
                this.loadingConcepto = true
                try{
                    let response = await axios.get('Conceptos/GetConceptosComprobantes/'+ this.datosEmpresa.empresaBase)
                    this.$store.state.listConceptosStore = response.data;
                }catch(error){
                    console.log(error)
                }
                this.loadingConcepto = false
            },

        },

        computed: {
            computedDateComprobante () {
                return this.dateComprobante ? moment(this.dateComprobante).format('DD/MM/yyyy') : ''
            },
            selectUsoCFDI(){
                let Objecto = this.itemsUsoCfdi.find(usoCFDI => usoCFDI.clave === this.selectReceptor.usoCFDI);
                return Objecto
            },
            itemsreceptor(){
                return this.$store.state.listReceptorStore.map(entry => {
                const Description = entry.nombre.length > this.limitReceptor
                    ? entry.nombre.slice(0, this.limitReceptor) + '...'
                    : entry.nombre

                return Object.assign({}, entry, { Description })
                })
            },
            itemsconcepto(){
                return this.$store.state.listConceptosStore.map(entry => {
                const Description = entry.descripcion.length > this.limitConcepto
                    ? entry.descripcion.slice(0, this.limitConcepto) + '...'
                    : entry.descripcion

                return Object.assign({}, entry, { Description })
                })
            },
         datosEmpresa() {
                return this.$store.state.usuario;
            },
        },

        created () {
            this.GetUsoCdfi();
            this.GetMetodoPago();
            this.GetFormaPago();
            this.GetMoneda();
        },

        methods: {
            //FORMATO DECIMAL
            formatoDosDecimales(value) {
                let val = (value/1).toFixed(2)
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            },
            //METODOS DEL STORE
            ...mapMutations(['asignaReceptorStore']),

            async GetUsoCdfi(){
                try{
                    let response = await axios.get('CatalogoComprobantes/GetUsocfdi')
                    this.itemsUsoCfdi = response.data
                }catch(err){
                    console.log(err)
                }
            },
            async GetMetodoPago(){
                try{
                    let response = await axios.get('CatalogoComprobantes/GetMetodoPago')
                    this.itemsMetodoPago = response.data
                }catch(err){
                    console.log(err)
                }
            },
            async GetFormaPago(){
                try{
                    let response = await axios.get('CatalogoComprobantes/GetFormaPago')
                    this.itemsFormaPago = response.data
                }catch(err){
                    console.log(err)
                }
            },
            async GetMoneda(){
                try{
                    let response = await axios.get('CatalogoComprobantes/GetMoneda')
                    this.itemsMonedas = response.data
                }catch(err){
                    console.log(err)
                }
            },
            
            ValidaMetodoPago(){
                if(this.selectMetodoPago.clave == "PPD"){
                    let Objecto = this.itemsFormaPago.find(variable => variable.clave === '99');
                    this.selectFormaPago = Object.assign( {}, Objecto );
                    this.enabledFormaPago=true;
                }
                else{
                    this.selectFormaPago = ""
                    this.enabledFormaPago=false;
                };
            },

            ValidaMoneda(){
                if (this.selectMoneda.c_Moneda === 'MXN'){
                    this.enabledTipoCambio=true;
                    this.tipoDeCambio="1"
                }
                else{
                    this.enabledTipoCambio=false;
                    this.tipoDeCambio=""
                }
            },

            //RECEPTOR
            nuevoReceptor(){
                let receptor={
                    id: 0,
                    rfc: '',
                    nombre: null,
                    domicilioFiscal: null,
                    residenciaFiscal: null,
                    numRegIdTrib: null,
                    regimenFiscal: null,
                    usoCFDI: null,
                    itemsContacto: []
                }
                this.asignaReceptorStore(receptor);
                this.dialogNuevoReceptor= true
            },
            
            //TIMBRADO
            async timbraComprobante(){
                this.snackbar = false;
                this.dialog = true;
                this.dialogMessage = 'Timbrando espere...';
                let comprobante = {
                    id : 0,
                    version: '4.0',
                    serie: this.selectSerie,
                    //serie: 'DEMO',
                    folio: this.selectFolio,
                    fecha: this.dateComprobante + ' ' + this.timeComprobante,
                    formaPago: this.selectFormaPago.clave,
                    condicionesDePago: this.selectCondicionPago,
                    subTotal: this.$store.state.facturaStore.subTotal,
                    descuento: this.$store.state.facturaStore.descuento,
                    moneda: this.selectMoneda.c_Moneda,
                    tipoCambio: this.tipoDeCambio,
                    total: this.$store.state.facturaStore.total,
                    tipoDeComprobante: 'I',
                    exportacion: '01',
                    metodoPago: this.selectMetodoPago.clave,
                    lugarExpedicion: '20000',
                    confirmacion: '',
                    estatus: '',
                    tipo: 'Factura',
                    emisor:{
                        rfc: this.empresa.rfc,
                        nombre: this.empresa.nombreEmpresa,
                        regimenFiscal: this.empresa.regimenFiscal,
                    },
                    receptor:{
                        rfc: this.selectReceptor.rfc,
                        nombre: this.selectReceptor.nombre,
                        domicilioFiscalReceptor: this.selectReceptor.domicilioFiscal,
                        regimenFiscal: this.selectReceptor.regimenFiscal,
                        usoCFDI: this.selectUsoCFDI.clave,
                    },
                    conceptos: {
                        conceptos: this.$store.state.facturaStore.conceptos.slice(),
                    },
                    impuestos:{
                        traslados:{
                            traslados: this.$store.state.traslados.slice(),
                        },
                        retenciones:{
                            retenciones: this.$store.state.retenciones.slice(),
                        },
                        totalImpuestosRetenidos: this.$store.state.totalImpuestosRetenidos,
                        totalImpuestosTrasladados: this.$store.state.totalImpuestosTrasladados,
                    },
                }
                
                try{
                    let response  = await axios.post('Comprobantes/timbraFactura/'+ this.datosEmpresa.empresaBase, comprobante)
                    console.log(response.data)
                }catch(err){
                    console.log(err.response.data)
                    this.snackbarMessage = err.response.data;
                    this.snackbar = true;
                }
                this.dialog = false;
            },
        },
    }
</script>