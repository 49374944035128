var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-text',[_c('v-row',[_c('v-col',[_c('v-select',{attrs:{"label":"Tipo de relación","items":_vm.itemsTipoRelacion,"item-text":"tipoRelacion","return-object":""},model:{value:(_vm.cfdiRelacionados.tipoRelacion),callback:function ($$v) {_vm.$set(_vm.cfdiRelacionados, "tipoRelacion", $$v)},expression:"cfdiRelacionados.tipoRelacion"}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"fab":"","dark":"","color":"green"},on:{"click":function($event){return _vm.abreDialog()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v("Relacionar CFDI")])])],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.headersRelacionados,"items":_vm.itemsComprobantesRelacionados},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"red","dense":"","dark":""},on:{"click":function($event){return _vm.deleteCfdi(item)}}},'v-icon',attrs,false),on),[_vm._v("mdi-delete-empty")])]}}],null,true)},[_c('span',[_vm._v("Eliminar")])])]}}],null,true)})],1)],1)],1)],1),_c('v-dialog',{attrs:{"persistent":""},model:{value:(_vm.dialogBuscar),callback:function ($$v) {_vm.dialogBuscar=$$v},expression:"dialogBuscar"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"primary","dark":""}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.cerrarDialogRelacionado()}}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-close-circle")])],1),_c('v-spacer'),_c('h3',[_vm._v("Concepto")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.PushCfdi()}}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-check-circle")])],1)],1),_c('v-card-text',[_c('v-text-field',{attrs:{"label":"Buscar por folio, folio fiscal, nombre o rfc","hint":"Presione la tecla <Enter> para buscar","append-outer-icon":"mdi-magnify"},on:{"click:append-outer":function($event){return _vm.buscarCfdi()},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.buscarCfdi()}},model:{value:(_vm.variable),callback:function ($$v) {_vm.variable=$$v},expression:"variable"}}),_c('v-data-table',{attrs:{"headers":_vm.headersRelacionados,"items":_vm.itemsComprobantes},scopedSlots:_vm._u([{key:"item.subTotal",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.subTotal)))])]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.total)))])]}},{key:"item.folio",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(item.estatus),"dark":""}},[_vm._v(_vm._s(item.folio))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"green","dense":"","dark":""},on:{"click":function($event){return _vm.PushCfdi(item)}}},'v-icon',attrs,false),on),[_vm._v("mdi-plus-circle")])]}}],null,true)},[_c('span',[_vm._v("Agregar")])])]}}],null,true)})],1)],1)],1),_c('div',[_c('v-dialog',{attrs:{"hide-overlay":"","persistent":"","width":"300"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"color":"primary","dark":""}},[_c('v-card-text',[_vm._v(" "+_vm._s(_vm.dialogMessage)+" "),_c('v-progress-linear',{staticClass:"mb-0",attrs:{"indeterminate":"","color":"white"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }